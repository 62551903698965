<div class="container-fluid">
    <div class="row mb-3">
        <div class="col">
            <div class="row mb-3">
                <div class="col">
                    <div class="card custom-cards">
                        <div class="card-header">
                            <h5>{{'orders.ordersDetails.orderId' | translate}}</h5>
                        </div>
                        <div class="card-body">
                            <h1 *ngIf="order && order.contractId">{{order.contractId}}</h1>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card custom-cards">
                        <div class="card-header">
                            <h5>{{'orders.ordersDetails.total' | translate}}</h5>
                        </div>
                        <div class="card-body" *ngIf="order && order.totalPrice">
                            <h1>{{order.totalPrice | currency: 'EUR' }}</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <div class="card custom-cards">
                        <div class="card-header">
                            <h5>{{'orders.ordersDetails.netPrice' | translate}}</h5>
                        </div>
                        <div class="card-body">
                            <h1 *ngIf="order && order.reservation !== 0; else bookingPrice">
                              {{order.discountedPrice | currency: 'EUR' }}
                            </h1>
                            <ng-template #bookingPrice>
                                <h1>{{order.discountedBookingPrice | currency: 'EUR' }}</h1>
                            </ng-template>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="card custom-cards">
                        <div class="card-header">
                            <h5>{{'orders.ordersDetails.orderType' | translate}}</h5>
                        </div>
                        <div class="card-body">
                            <ng-container
                                *ngIf="orderType === 'booking' || orderType === 'reservation'; else elseTemplate1">
                              <h1 *ngIf="!isCancelledBooking; else cancelledBookingTitle">
                                {{order.reservation | bookingReservationStatus | translate}}
                              </h1>
                              <ng-template #cancelledBookingTitle>
                                <h1>{{ 'enums.bookingReservationStatus.cancelledBooking' | translate }}</h1>
                              </ng-template>
                            </ng-container>
                            <ng-template #elseTemplate1>
                                <h1>{{order.orderType | orderType | translate}}</h1>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col" [hidden]="orderType === 'booking' || orderType === 'reservation'">
                    <div class="card custom-cards">
                        <div class="card-header">
                            <h5>{{'orders.ordersDetails.orderStatus' | translate}}</h5>
                        </div>
                        <div class="card-body">
                            <ng-container
                                *ngIf="orderType === 'booking' || orderType === 'reservation'; else elseTemplate2">

                            </ng-container>
                            <ng-template #elseTemplate2>
                                <div class="row">
                                    <div class="col">
                                        <h1>{{order.status | orderStatus | translate}}</h1>
                                    </div>
                                    <div class="col text-right" style="margin-top: 15px;" *ngIf="order.status === 1">
                                        <div class="form-check">
                                            <input #isExtensionCheck class="form-check-input" type="checkbox"
                                                [checked]="isExtension" id="isExtensionCheck"
                                                (change)="onExtensionCheckboxChange(isExtensionCheck.checked)">
                                            <label class="form-check-label" for="isExtensionCheck">
                                                Extend return delay
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col" *ngIf="order && order.clientInformation">
                            <dl>
                                <dt>{{'orders.ordersDetails.firstName' | translate}}</dt>
                                <dd>{{order.clientInformation.firstName}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.lastName' | translate}}</dt>
                                <dd>{{order.clientInformation.lastName}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.address' | translate}}</dt>
                                <dd>{{order.clientInformation. address}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.postalCode' | translate}}</dt>
                                <dd>{{order.clientInformation.postalCode}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.phone' | translate}}</dt>
                                <dd>{{order.clientInformation.phone}}</dd>
                            </dl>
                            <dl>
                                <dt>E-Mail</dt>
                                <dd>{{order.clientInformation.email}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.privacy' | translate}}</dt>
                                <dd>{{'orders.ordersDetails.yes' | translate}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.privacyMarketing' | translate}}</dt>
                                <dd>
                                    {{ order.clientInformation.marketingUsage ? 'Yes' : 'No'}}
                                </dd>
                            </dl>
                        </div>
                        <div class="col" *ngIf="order">
                            <dl>
                                <dt>{{'orders.ordersDetails.pickUpDate' | translate}}</dt>
                                <dd>{{order.startDate | date: 'dd/MM/yyyy'}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.pickUpHour' | translate}}</dt>
                                <dd>{{order.pickUpTime}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.returnDate' | translate}}</dt>
                                <dd>{{order.endDate | date: 'dd/MM/yyyy'}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.rentalPointPickUp' | translate}}</dt>
                                <dd>{{order.startRentalPoint.rentalPointTranslation[currentLang]}}</dd>
                            </dl>
                            <dl>
                                <dt>{{'orders.ordersDetails.rentalPointReturn' | translate}}</dt>
                                <dd>{{order.endRentalPoint.rentalPointTranslation[currentLang]}}</dd>
                            </dl>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="card">
                                <div class="card-body">
                                    <ng-container
                                        *ngIf="orderType !== 'booking' && orderType !== 'reservation'; else elseTemplate">
                                        <ng-container *ngIf="orderType === 'booked-rental'">
                                            <ng-container
                                                *ngIf="order.onlineBooking.reservation !== 0; else invoiceTemplate">
                                                <a class="btn btn-primary mr-2" target="_blank"
                                                    [routerLink]="['/orders/reservation/', order.contractId]"><i
                                                        class="fa fa-print"></i>
                                                    {{'orders.ordersDetails.reservation' | translate}}</a>
                                            </ng-container>
                                            <ng-template #invoiceTemplate>
                                                <a class="btn btn-primary mr-2" target="_blank"
                                                    [routerLink]="['/orders/invoice/', order.contractId]"><i
                                                        class="fa fa-print"></i>
                                                    {{'orders.ordersDetails.invoice' | translate}}</a>
                                            </ng-template>
                                        </ng-container>
                                        <a class="btn btn-primary mr-2" target="_blank"
                                            [routerLink]="['/orders/contract-paper/', order.contractId]"><i
                                                class="fa fa-print"></i>
                                            {{'orders.ordersDetails.contract' | translate}}</a>
                                        <ng-container *ngIf="order.orderReturn">
                                            <ng-container *ngIf="order.status === 3; else returnPaper">
                                                <a class="btn btn-primary mr-2" target="_blank"
                                                    [routerLink]="['/orders/total-cancellation-paper/', order.contractId]"><i
                                                        class="fa fa-print"></i>
                                                    {{'orders.ordersDetails.cancellation' | translate}}</a>
                                            </ng-container>
                                            <ng-template #returnPaper>
                                                <a class="btn btn-primary mr-2" target="_blank"
                                                    [routerLink]="['/orders/return-paper/', order.contractId]"><i
                                                        class="fa fa-print"></i>
                                                    {{'orders.ordersDetails.return' | translate}}</a>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <!--
                                        <ng-container *ngIf="orderType === 'booking'; else reservation">
                                            <a class="btn btn-primary btn-sm mr-2" target="_blank"
                                                [routerLink]="['/orders/invoice/', order.contractId]"><i
                                                    class="fa fa-print"></i>{{'orders.ordersDetails.invoice' |
                                                translate}}</a>
                                        </ng-container>
                                        <ng-template #reservation>
                                            <a class="btn btn-primary btn-sm mr-2" target="_blank"
                                                [routerLink]="['/orders/reservation/', order.contractId]"><i
                                                    class="fa fa-print"></i>{{'orders.ordersDetails.reservation' |
                                                translate}}</a>
                                            <a class="btn btn-warning btn-sm mr-2"
                                                [routerLink]="['/orders/reservation/update/', order.contractId]"><i
                                                    class="fa fa-edit"></i>
                                                {{'orders.ordersDetails.update' | translate}}</a>
                                        </ng-template>
                                        -->
                                        <ng-container *ngIf="orderType === 'booking'; else reservation">
                                          <a class="btn btn-primary btn-sm mr-2" [href]="pdfLink" download target="_blank">
                                            <i class="fa fa-print mr-2"></i>{{'orders.ordersDetails.invoice' | translate}}
                                          </a>
                                            <ng-container *ngIf="!isCancelledBooking; else haveCreditNote">
                                              <a class="btn btn-warning btn-sm"
                                                [routerLink]="['/orders/reservation/update/', order.contractId]"><i
                                                    class="fa fa-edit mr-2"></i>
                                                 {{'orders.ordersDetails.update' | translate}}</a>
                                            </ng-container>
                                              <ng-template #haveCreditNote>
                                                <a class="btn btn-warning btn-sm" [href]="creditNoteLink" download target="_blank">
                                                  <i class="fa fa-newspaper-o mr-2"></i>
                                                   {{ 'orders.credits.creditNote' | translate }}
                                                </a>
                                              </ng-template>
                                        </ng-container>
                                        <ng-template #reservation>
                                          <a class="btn btn-primary btn-sm mr-2" style="background-color: green" [href]="pdfLink" download target="_blank">
                                            <i class="fa fa-download mr-2"></i>{{'orders.ordersDetails.reservation' | translate}}
                                          </a>
                                            <a class="btn btn-warning btn-sm mr-2"
                                                [routerLink]="['/orders/reservation/update/', order.contractId]"><i
                                                    class="fa fa-edit mr-2"></i>
                                                {{'orders.ordersDetails.update' | translate}}</a>
                                        </ng-template>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-header">
                    {{'orders.ordersDetails.rentedBikes' | translate}}
                </div>
                <div class="card-body">
                    <table class="table table-bordered">
                        <thead class="thead-light">
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.bikeType' |
                                    translate}}</span></td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.quantity' |
                                    translate}}</span></td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.qrCode' |
                                    translate}}</span></td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.returnType' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.returnedAt' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span
                                    class="font-weight-bold">{{'orders.ordersDetails.returnedRentalPoint' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.replacing' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span class="font-weight-bold">{{'orders.ordersDetails.replacedAt' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span
                                    class="font-weight-bold">{{'orders.ordersDetails.replacedBikeType' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span
                                    class="font-weight-bold">{{'orders.ordersDetails.replacedQrCode' |
                                    translate}}</span>
                            </td>
                            <td class="text-center"><span
                                    class="font-weight-bold">{{'orders.ordersDetails.replacedRentalPoint' |
                                    translate}}</span>
                            </td>
                            <td></td>
                        </thead>
                        <tbody>
                            <tr *ngFor="let bike of bikes">
                                <td class="text-muted-custom text-center">
                                    {{bike.bikeType.bikeTypeTranslation[currentLang]}} </td>
                                <td class="text-muted-custom text-center"> {{bike.quantity}}</td>
                                <td class="text-muted-custom text-center"> {{bike.qrCode}} </td>
                                <td class="text-muted-custom text-center">{{bike.returnType | returnType | translate}}
                                </td>
                                <td class="text-muted-custom text-center"> {{bike.returnedAt | date: 'dd-MM-yyyy'}}
                                </td>
                                <td class="text-muted-custom text-center">
                                    {{(bike.returnedRentalPoint ?
                                    bike.returnedRentalPoint.rentalPointTranslation[currentLang] : '')}}
                                </td>
                                <td class="text-muted-custom text-center">
                                    <i class="fa fa-check" *ngIf="bike.replaced"></i>
                                </td>
                                <td class="text-muted-custom text-center"> {{bike.replacedAt | date: 'dd-MM-yyyy'}}
                                </td>
                                <td class="text-muted-custom text-center">
                                    {{(bike.replacedBikeTypeId ?
                                    bike.replacedBikeTypeId.bikeTypeTranslation[currentLang] : '')}}
                                </td>
                                <td class="text-muted-custom text-center"> {{bike.replacedQRCode}} </td>
                                <td class="text-muted-custom text-center">
                                    <ng-container *ngIf="bike.replaced">
                                        {{order.startRentalPoint.rentalPointTranslation[currentLang]}}
                                    </ng-container>
                                </td>
                                <td class="text-muted-custom text-right">
                                    <ng-container *ngIf="bike.returnType === 1">
                                        <a class="btn btn-primary btn-sm mr-2" target="_blank"
                                            href="/orders/partial-return-paper/{{bike.returnedAt | date: 'yyyy-MM-dd'}}/{{bike.returnedRentalPoint.accountId}}/{{order.contractId}}">
                                            <i class="fa fa-print"></i></a>
                                    </ng-container>
                                    <ng-container *ngIf="bike.returnType === 2">
                                        <a class="btn btn-primary btn-sm mr-2" target="_blank"
                                            href="/orders/partial-cancellation-paper/{{bike.returnedAt | date: 'yyyy-MM-dd'}}/{{bike.returnedRentalPoint.accountId}}/{{order.contractId}}">
                                            <i class="fa fa-print"></i></a>
                                    </ng-container>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
