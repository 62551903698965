import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { HttpUtils } from '../../shared/http-utils';
import { environment } from '../../../environments/environment';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OrderCancellationService {

  constructor(private _http: HttpClient) { }

  public cancelReservationEmail(contractId: number): Observable<any> {
    const params = {
      contractId: contractId,
      token: 'A763SP0FwvdDzpBJOHsd6Je34mSF18sfpbfrf'
    };
    return this._http.get<any>(environment.serverUrl + 'api/cancelReservationEmail', {params: params}).pipe(
      map((res: any) => res),
    );
  }
}
