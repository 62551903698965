import { Component, OnInit } from '@angular/core';
import { ProfileService } from '../shared/profile.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Account } from '../../../models/Account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-profile',
  templateUrl: './update-profile.component.html',
  styleUrls: ['./update-profile.component.scss']
})
export class UpdateProfileComponent implements OnInit {

  public updateAccountFormGorup: FormGroup;
  public errorArray = [];

  constructor(private _translate: TranslateService, private _profileService: ProfileService, private _snackBar: MatSnackBar) {
    this.updateAccountFormGorup = new FormGroup({
      id: new FormControl(),
      username: new FormControl(),
      email: new FormControl(),
      firstName: new FormControl(),
      lastName: new FormControl(),
      deleted: new FormControl(),
      newPassword: new FormControl
    });
  }

  ngOnInit() {
    const currentUser = SharedUtils.getCurrentUserType();
    this._profileService.getAccount(currentUser.accountId).subscribe(result => {
      this.updateAccountFormGorup.patchValue(result);
    });
  }

  updateAccountSubmit() {
    if (this.updateAccountFormGorup.valid) {
      const newAccount = Account.fromDto(this.updateAccountFormGorup.value);
      if (this.updateAccountFormGorup.value['newPassword'] != null) {
        newAccount.password = this.updateAccountFormGorup.value['newPassword'];
      }
      this._profileService.updateAccount(newAccount).subscribe(result => {
        this._snackBar.open(this._translate.instant('profile.updateProfile.accountUpdated'), '', {
          duration: 3500,
        });
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.updateAccountFormGorup, this.updateAccountFormGorup.controls);
  }

}
