import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from './auth-guard.service';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { UserService } from './services/user.service';
import { StaffComponent } from './components/staff/staff-list/staff.component';
import { StaffViewComponent } from './components/staff/staff-view/staff-view.component';
import { StaffAddComponent } from './components/staff/staff-add/staff-add.component';

// Orders
import { OrderConfirmedComponent } from './components/orders/order-confirmed/order-confirmed.component';
import { OrdersTurnoverComponent } from './components/orders/orders-turnover/orders-turnover.component';
import { QrAssignComponent } from './components/products/qr-assign/qr-assign.component';
import { OrderCancellationComponent } from './components/order-cancellation/order-cancellation.component';
import { TablesComponent } from './components/tables/tables.component';
import { TestPdfComponent } from './components/test-pdf/test-pdf.component';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'orderConfirmed/:contractId', component: OrderConfirmedComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'staff', component: StaffComponent, canActivate: [AuthGuard] },
  { path: 'staff/:staffId', component: StaffViewComponent, canActivate: [AuthGuard] },
  { path: 'multiple-qr-assign', component: QrAssignComponent, canActivate: [AuthGuard] },
  { path: 'staff-add', component: StaffAddComponent, canActivate: [AuthGuard] },
  { path: 'orderConfirmed/:contractId', component: OrderConfirmedComponent },
  { path: 'orders-turnover', component: OrdersTurnoverComponent, canActivate: [AuthGuard] },
  { path: 'reservationCancellation/:lang/:contractId', component: OrderCancellationComponent },
  { path: 'table-data-management', component: TablesComponent, canActivate: [AuthGuard] },
  { path: 'test-pdf', component: TestPdfComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard,
    UserService
  ]
})
export class AppRoutingModule {
}
