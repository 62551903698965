<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="20" (onReset)="onTableReset()">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-input label="{{'orders.searchByQrCode.qrCode' | translate}}" [(model)]="filters.qrCode"
              placeholder="{{'orders.searchByQrCode.qrCode' | translate}}">
            </app-data-filter-input>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-contractId="contractId">
        {{contractId}}
      </ng-template>

      <ng-template #clientName let-clientInformation="clientInformation">
        {{clientInformation.firstName}} {{clientInformation.lastName}}
      </ng-template>

      <ng-template #startRentalPoint let-startRentalPoint="startRentalPoint">
        {{startRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #returnRentalPoint let-endRentalPoint="endRentalPoint">
        {{endRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #startDate let-startDate="startDate">
        {{startDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #returnDate let-endDate="endDate">
        {{endDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #orderAmount let-discountedPrice="discountedPrice">
        {{discountedPrice | currency: '€ '}}
      </ng-template>

      <ng-template #orderType let-orderType="orderType">
        {{orderType | orderType | translate}}
      </ng-template>

      <ng-template #orderStatus let-status="status" let-endDate="endDate">
        {{status | orderStatus:endDate | translate}}
      </ng-template>

      <ng-template #actions let-contractId="contractId" let-orderType="orderType">
        <ng-container *ngIf="orderType === 1; else elseTemplate">
          <a href="orders/direct-rental/details/{{contractId}}" target="_blank"
            class="btn btn-sm btn-primary">{{'orders.allOrders.details' | translate}}</a>
        </ng-container>
        <ng-template #elseTemplate>
          <a href="orders/booked-rental/details/{{contractId}}" target="_blank"
            class="btn btn-sm btn-primary">{{'orders.allOrders.details' | translate}}</a>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>