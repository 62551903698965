import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CASH_FLOW_ROUTES } from './cash-flow.routes';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CashFlowComponent } from './cash-flow.component';
import { OnlineBookingsComponent } from './online-bookings/online-bookings.component';
import { RentalPointCashFlowComponent } from './rental-point-cash-flow/rental-point-cash-flow.component';
import { TurnoverCashFlowComponent } from './turnover-cash-flow/turnover-cash-flow.component';
import { ChartModule } from '@progress/kendo-angular-charts';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';
import { OrdersModule } from '../orders/orders.module';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(CASH_FLOW_ROUTES),
    FormsModule,
    CommonModule,
    ChartModule,
    OrdersModule
  ],
    exports: [],
    declarations: [
        CashFlowComponent,
        OnlineBookingsComponent,
        RentalPointCashFlowComponent,
        TurnoverCashFlowComponent,
        InvoicesListComponent,
    ]
})


export class CashFlowModule { }
