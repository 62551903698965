import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RentalPointsService } from '../shared/rental-points.service';
import { RentalPointSchedule } from '../../../models/RentalPointSchedule';
import { ScheduleException } from '../../../models/ScheduleException';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-manage-schedules',
  templateUrl: './manage-schedules.component.html',
  styleUrls: ['./manage-schedules.component.scss']
})
export class ManageSchedulesComponent implements OnInit {

  public currentRentalPointId: number;
  public rentalPointSchedule: RentalPointSchedule = new RentalPointSchedule();
  public scheduleException: ScheduleException = new ScheduleException();
  public disableOpeningHours = false;
  public disableLunchHours = true;

  constructor(private _translate: TranslateService, private _activatedRoute: ActivatedRoute,
    private _rentalPointsService: RentalPointsService,
    private _snackBar: MatSnackBar) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.currentRentalPointId = params.rentalPointId;
      this._rentalPointsService.getScheduleOfRentalPoint(params.rentalPointId).subscribe(result => {
        this.rentalPointSchedule = result;
      });
    });
  }

  onDeleteButtonClick(id: number) {
    this._rentalPointsService.deleteScheduleExceptionById(id).subscribe(result => {
      const index = this.rentalPointSchedule.scheduleExceptions.indexOf(this.rentalPointSchedule.scheduleExceptions.filter(
        exception => exception.id === id)[0]);
      this.rentalPointSchedule.scheduleExceptions.splice(index, 1);
      this._snackBar.open(this._translate.instant('rentalPoint.manageSchedules.exceptionDeleted'), '', {
        duration: 2500
      });
    });
  }
}
