import { RentalPoint } from './RentalPoint';
import { Account } from './Account';
import { District } from './District';
import { Country } from './Country';

export class ServicePoint {
  public id: number;
  public en: string;
  public it: string;
  public de: string;
  public latitude: string;
  public longitude: string;
  public isClose: boolean;
  public startHour: string;
  public endHour: string;
  public lunchBreakStart: string;
  public lunchBreakEnd: string;
  public bikeAvailable: boolean;
  public region: string;
  public street: string;
  public city: string;
  public phone: string;
  public countryId: number;
  public country: Country;
  public districtId: number;
  public district: District;
  public uniqueId: number;
  public status: number;

  public static fromDto(c): ServicePoint {
    const client = new ServicePoint();
    if (!c) {
      return null;
    }
    client.id = c.id;
    client.en = c.en;
    client.it = c.it;
    client.de = c.de;
    client.latitude = c.latitude;
    client.longitude = c.longitude;
    client.isClose = c.isClose;
    client.startHour = c.startHour;
    client.endHour = c.endHour;
    client.lunchBreakStart = c.lunchBreakStart;
    client.lunchBreakEnd = c.lunchBreakEnd;
    client.bikeAvailable = c.bikeAvailable;
    client.region = c.region;
    client.street = c.street;
    client.city = c.city;
    client.phone = c.phone;
    client.countryId = c.countryId;
    if (c.country) {
      client.country = Country.fromDto(client.country);
    }
    client.districtId = c.districtId;
    if (c.district) {
      client.district = District.fromDto(client.district);
    }
    client.uniqueId = c.uniqueId;
    client.status = c.status;

    return client;
  }
}
