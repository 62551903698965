import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { BikeStatistic } from '../../../models/BikeStatistic';
import { Client } from '../../../models/Client';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { SharedUtils } from '../../../shared/shared-utils';
import { ClientsService } from '../../clients/shared/clients.service';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-bike-statistics',
  templateUrl: './bike-statistics.component.html',
  styleUrls: ['./bike-statistics.component.scss']
})
export class BikeStatisticsComponent implements OnInit {

  public userType = SharedUtils.getCurrentUserType();
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public bikeStatistics: BikeStatistic = null;
  public selectedClientId = '0';
  public selectedRentalPointId = 0;
  public currentLang: string;

  private _currentUserAccountId = this.userType.accountId;
  private _currentRentalPointId = this.userType.rentalPointId;
  private _clients: Client[] = [];

  constructor(private _productsService: ProductsService, private _clientsService: ClientsService,
    private _translate: TranslateService, private _snackBar: MatSnackBar) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: 0, label: this._translate.instant('products.bikeEquipmentSummary.all') }];
    this.rentalPointOptions = [{ value: 0, label: this._translate.instant('products.bikeEquipmentSummary.all') }];
  }

  ngOnInit() {
    let clientId;
    let rentalPointAccountId;
    if (this.userType.userTypeNumber === 1) {
      clientId = this.userType.clientId;
      this.selectedClientId = this.userType.clientId;
    } else {
      if (this.userType.userTypeNumber === 2) {
        rentalPointAccountId = this._currentUserAccountId;
        this._productsService.getRentalPointBikeStatistics(this._currentRentalPointId, this.currentLang).subscribe(result => {
          this.bikeStatistics = result;
        });
      }
    }
    this._clientsService.getClients().subscribe(result => {
      this._clients = result;
      this._clients.forEach(client => {
        this.clientOptions.push({ value: client.id, label: client.businessName });
      });
      if (this.userType.userTypeNumber === 1) {
        this.onClientSelectChange();
      }
    });
  }

  onClientSelectChange() {
    this.selectedRentalPointId = 0;
    this.rentalPointOptions = [{ value: 0, label: 'All' }];
    if (this.selectedClientId.toString() !== '0') {
      const selectedClient = this._clients.filter(client => client.id.toString() === this.selectedClientId.toString())[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.id, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onFilterClick() {
    if (this.selectedRentalPointId === 0) {
      this._snackBar.open(this._translate.instant('products.bikeStatistics.missingRentalPointError'), '', {
        duration: 3000
      });
      return false;
    }
    console.log('_currentRentalPointId:', this.selectedRentalPointId);
    this._productsService.getRentalPointBikeStatistics(this.selectedRentalPointId.toString(), this.currentLang).subscribe(result => {
      this.bikeStatistics = result;
    });
  }

}
