import { OnlineBooking } from './OnlineBooking';
import { BikeType } from './BikeType';
import { InvoiceBikeAccessory } from './InvoiceBikeAccessory';

export class InvoiceBike {
    public id: number;
    public quantity: number;
    public height: number;
    public bikePrice: number;
    public diffRentalCost: number;
    public price: number;
    public onlineBooking: OnlineBooking;
    public accessories: InvoiceBikeAccessory[];
    public bikeType: BikeType;

    public static fromDto(i): InvoiceBike {
        const invoiceBike = new InvoiceBike();
        if (!i) {
            return null;
        }

        invoiceBike.id = i.id;
        invoiceBike.quantity = i.quantity;
        invoiceBike.height = i.height;
        invoiceBike.bikePrice = i.bikePrice;
        invoiceBike.diffRentalCost = i.diffRentalCost;
        invoiceBike.price = i.price;
        invoiceBike.onlineBooking = i.onlineBooking;
        invoiceBike.bikeType = i.bikeType;
        invoiceBike.accessories = i?.invoiceBikeAccessory?.map(a => InvoiceBikeAccessory.fromDto(a));

        return invoiceBike;
    }
}
