import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BikeBrandSearch } from '../../../models/BikeBrand';
import { BikeModelSearch } from '../../../models/BikeModel';
import { BikeColorSearch } from '../../../models/BikeColor';
import { BikeSizeSearch } from '../../../models/BikeSize';
import { BikeTypeSearch } from '../../../models/BikeType';
import { BikeTyreSizeSearch } from '../../../models/BikeTyreSize';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { TablesSearchCriteria } from './tablesSearchCriteria';

@Injectable({
  providedIn: 'root'
})
export class TablesService {

  constructor(private _http: HttpClient) { }

  public searchBikeTypes(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeTypes', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeTypeSearch.fromDto);
      })
    );
  }

  public searchBikeBrands(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeBrands', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeBrandSearch.fromDto);
      })
    );
  }

  public searchBikeModels(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeModels', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeModelSearch.fromDto);
      })
    );
  }

  public searchBikeSizes(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeSizes', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeSizeSearch.fromDto);
      })
    );
  }

  public searchBikeTyreSizes(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeTyreSizes', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeTyreSizeSearch.fromDto);
      })
    );
  }

  public searchBikeColors(page: number, size: number, criteria: TablesSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    console.log(params);

    return this._http.get(environment.serverUrl + 'api/searchBikeColors', { headers, params}).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, BikeColorSearch.fromDto);
      })
    );
  }

  public getBikeTypeById(id: string): Observable<BikeTypeSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeTypes', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeTypeSearch.fromDto(result))
    );
  }

  public getBikeBrandById(id: string): Observable<BikeBrandSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeBrand', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeBrandSearch.fromDto(result))
    );
  }

  public getBikeModelById(id: string): Observable<BikeModelSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeModel', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeModelSearch.fromDto(result))
    );
  }

  public getBikeSizeById(id: string): Observable<BikeSizeSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeSize', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeSizeSearch.fromDto(result))
    );
  }

  public getTyreSizeById(id: string): Observable<BikeTyreSizeSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeTyreSize', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeTyreSizeSearch.fromDto(result))
    );
  }

  public getBikeColorById(id: string): Observable<BikeColorSearch> {
    const params = {id:  id};
    return this._http.get(environment.serverUrl + 'api/getBikeColor', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => BikeColorSearch.fromDto(result))
    );
  }

  public updateBikeType(object: BikeTypeSearch): Observable<BikeTypeSearch> {
    return this._http.post<BikeTypeSearch>(environment.serverUrl + 'api/updateBikeType', { bikeType: object }, {
      headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        console.log('api_returns:', responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public updateBikeProperty(object: any): Observable<any> {
    return this._http.post<any>(environment.serverUrl + 'api/updateBikeProperty', { bikeProperty: object }, {
      headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        console.log('api_returns:', responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public insertBikeType(object: BikeTypeSearch): Observable<BikeTypeSearch> {
    return this._http.post<BikeTypeSearch>(environment.serverUrl + 'api/insertBikeType', { bikeType: object }, {
      headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        console.log('api_returns:', responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

  public insertBikeProperty(object: any): Observable<any> {
    return this._http.post<any>(environment.serverUrl + 'api/insertBikeProperty', { bikeProperty: object }, {
      headers: HttpUtils.createHeaders() }).pipe(
      map(responseData => {
        console.log('api_returns:', responseData);
        return responseData;
      }),
      catchError(errorRes => {
        return throwError(() => errorRes);
      })
    );
  }

}
