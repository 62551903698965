import { Component, OnInit, Input } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { SharedService } from '../../../shared/shared.service';

@Component({
  selector: 'app-header-auth',
  templateUrl: './header-auth.component.html',
  styleUrls: ['./header-auth.component.scss']
})
export class HeaderAuthComponent implements OnInit {
  public status = '';
  public active: boolean;

  // language
  public selectedLanguage: string;
  public locale: string;

  @Input()
  title: string;

  constructor(private translate: TranslateService,
    private router: Router,
    private userService: UserService,
    private _sharedService: SharedService) {

    if (localStorage.getItem('selectedLanguage') !== null) {
      const selectedLanguage = localStorage.getItem('selectedLanguage');
      translate.setDefaultLang(selectedLanguage);
    } else {
      localStorage.setItem('selectedLanguage', 'de');
      translate.setDefaultLang('de');
    }

  }

  logout() {
    this.userService.logout().subscribe(result => {
      localStorage.removeItem('X-Auth-Token');
      localStorage.removeItem('accessRights');
      localStorage.removeItem('X-Access-Token');
      localStorage.removeItem('papinLogged');
      localStorage.removeItem('isClient');
      localStorage.removeItem('isRentalPoint');
      localStorage.removeItem('accountId');
      localStorage.removeItem('clientId');
      localStorage.removeItem('rentalPointId');
      localStorage.removeItem('allowedDeleted');
      localStorage.removeItem('allowedMoveBikes');
      localStorage.clear();
      this._sharedService.change(false);
      this.router.navigate(['/login']);
    });
  }

  ngOnInit() {
    if (localStorage.getItem('selectedLanguage') === null) {
      localStorage.setItem('selectedLanguage', this.translate.defaultLang);
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
      this.translate.use(localStorage.getItem('selectedLanguage'));
      this.locale = this.selectedLanguage;
    } else {
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
      this.translate.use(localStorage.getItem('selectedLanguage'));
      this.locale = this.selectedLanguage;
    }

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      localStorage.setItem('selectedLanguage', event.lang);
      this.selectedLanguage = localStorage.getItem('selectedLanguage');
      this.translate.use(localStorage.getItem('selectedLanguage'));
      this.locale = this.selectedLanguage;

    });
  }
}
