import { RentalPoint } from './RentalPoint';
import { ScheduleException } from './ScheduleException';

export class RentalPointSchedule {
    public id: number;
    public startHour: string;
    public endHour: string;
    public lunchBreakStart: string;
    public lunchBreakEnd: string;
    // Each day starting with Moday is represented by 1 if opened and 0 if closed
    // For example if Mon - Firday is opened and Saturday and Sunday closed we have : 1111100
    public openingDays: Array<string>;
    public badWeather: boolean;
    public rentalPoint: RentalPoint;
    public scheduleExceptions: ScheduleException[];

    public static fromDto(r): RentalPointSchedule {
        const rentalPointSchedule = new RentalPointSchedule();
        if (!r) {
            return null;
        }
        rentalPointSchedule.id = r.id;
        rentalPointSchedule.startHour = r.startHour;
        rentalPointSchedule.endHour = r.endHour;
        rentalPointSchedule.lunchBreakStart = r.lunchBreakStart;
        rentalPointSchedule.lunchBreakEnd = r.lunchBreakEnd;
        rentalPointSchedule.openingDays = r.openingDays.split('');
        rentalPointSchedule.badWeather = r.badWeather;
        rentalPointSchedule.rentalPoint = RentalPoint.fromDto(r.rentalPoint);
        if (r.scheduleExceptions) {
            rentalPointSchedule.scheduleExceptions = r.scheduleExceptions.map(exception => ScheduleException.fromDto(exception));
        }

        return rentalPointSchedule;
    }
}
