import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { CORE_ROUTES } from './core.routes';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(CORE_ROUTES),
        FormsModule,
        CommonModule
    ],
    exports: [],
    declarations: [
        ProfileSettingsComponent
    ]
})

export class CoreModule { }
