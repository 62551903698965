import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../shared/orders.service';
import { Rental } from '../../../../models/Rental';
import { RentalBike } from '../../../../models/RentalBike';
import { RentalPoint } from '../../../../models/RentalPoint';
import { environment } from '../../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-contract-paper',
  templateUrl: './contract-paper.component.html',
  styleUrls: ['./contract-paper.component.scss']
})
export class ContractPaperComponent implements OnInit {

  public contractId: string;
  public rental: Rental = new Rental();
  public bikes: RentalBike[];
  public differentRentalCost = 0;
  public imageFound: boolean;
  public signatureImageUrl: string;
  public startRentalPoint: RentalPoint = new RentalPoint();
  public endRentalPoint: RentalPoint = new RentalPoint();
  public translations;
  public currentLang: string;

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService, private _translate: TranslateService,
    private _router: Router) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.contractId = params['contractId'];
      this._ordersService.getContractPaper(this.contractId).subscribe(result => {
        this.rental = result;
        console.log(this.rental);
        this._translate.getTranslation(this.rental.language).subscribe(translations => {
          this.translations = translations.orders.papers.contractPaper;
          this.currentLang = this.rental.language;
        });
        this.startRentalPoint = RentalPoint.fromDto(this.rental.startRentalPoint);
        this.endRentalPoint = RentalPoint.fromDto(this.rental.endRentalPoint);
        if (this.rental.orderType === 1) {
          this.bikes = this.rental.directBike;
        } else {
          this.bikes = this.rental.bookedBike;
        }
        if (this.startRentalPoint.id !== this.endRentalPoint.id) {
          this.bikes.forEach(bike => {
            this.differentRentalCost += bike.diffRentalCost;
          });
        }
        // this.signatureImageUrl = `${environment.serverUrl}public/uploads/${this.startRentalPoint.id}/${this.rental.contractId}.png`;
        if (this.rental.signature) {
          this.signatureImageUrl = environment.serverUrl + this.rental.signature.slice(2);
        }
        this._checkImageUrl(this.signatureImageUrl);
      });
    }, error => {
      console.log(error);
      if (error.status === 501) {
        this._router.navigate(['/orders/all']);
      }
    });
  }

  private _checkImageUrl(url: string) {
    const image = new Image();
    image.src = url;

    image.onload = () => this.imageFound = true;
    image.onerror = () => this.imageFound = false;
  }

  public print(): void {
    window.print();
  }

}
