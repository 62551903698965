import { Component, OnInit, Input } from '@angular/core';
import { PriceList } from '../../../models/PriceList';
import { DiscountCard } from '../../../models/DiscountCard';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Account } from '../../../models/Account';
import { RentalPointTranslations } from '../../../models/RentalPointTranslations';
import { FormGroup, FormControl, FormArray } from '@angular/forms';
import { ValidationUtils } from '../../../shared/validation.utils';
import { RentalPointsService } from '../shared/rental-points.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rental-point-add',
  templateUrl: './rental-point-add.component.html',
  styleUrls: ['./rental-point-add.component.scss']
})
export class RentalPointAddComponent implements OnInit {

  public user = new Account();
  public rentalPointTranslation = new RentalPointTranslations();
  public showErrors = false;
  public districts;
  public countries = [];
  public accountType;
  public priceLists: PriceList[];
  public discountCardsList: DiscountCard[];
  public errorArray = [];
  public rentalPointForm: FormGroup;
  public currentLang: string;

  private _clientId: number;
  private _choosenDiscountCardsArray: DiscountCard[] = [];

  constructor(private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    private _rentalPointsService: RentalPointsService,
    private _translate: TranslateService,
    private _snackBar: MatSnackBar
    ) {
    this.currentLang = this._translate.currentLang;
    this.accountType = this.getAccountType();

    this.rentalPointForm = new FormGroup({
      username: new FormControl(),
      email: new FormControl(),
      password: new FormControl(),
      lastName: new FormControl(),
      firstName: new FormControl(),
      rentalPoint: new FormGroup({
        region: new FormControl(),
        phone: new FormControl(),
        city: new FormControl(),
        street: new FormControl(),
        country: new FormControl(1),
        district: new FormControl(),
        latitude: new FormControl(),
        longitude: new FormControl(),
        vat: new FormControl('0.20'),
        postalCode: new FormControl(),
        priceLists: new FormControl(undefined),
        discountCards: new FormControl([]),
        isHotel: new FormControl(false),
        rentalPointTranslation: new FormGroup({
          de: new FormControl(),
          it: new FormControl(),
          en: new FormControl(),
        })
      })
    });

  }

  private getAccountType() {
    if (localStorage.isClient === 'false' && localStorage.isRentalPoint === 'false') {
      return 'admin';
    }
    if (localStorage.isClient === 'true' && localStorage.isRentalPoint === 'false') {
      return 'client';
    }
    if (localStorage.isClient === 'false' && localStorage.isRentalPoint === 'true') {
      return 'rentalPoint';
    }
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this._clientId = params['clientId'];
      this._rentalPointsService.getDistricts().subscribe(result => {
        this.districts = result;
      });
      this._rentalPointsService.getCountries().subscribe(result => {
        this.countries = result;
      });
      this._rentalPointsService.getPriceList().subscribe(result => {
        this.priceLists = result;
      });
      this._rentalPointsService.getDiscoutCards().subscribe(result => {
        this.discountCardsList = result;
      });
    });
  }

  onDiscountCardCheckBoxChange(event: any, value: DiscountCard) {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this._choosenDiscountCardsArray.push(value);
    } else {
      const index = this._choosenDiscountCardsArray.findIndex(discount => discount.id === value.id);
      this._choosenDiscountCardsArray.splice(index, 1);
    }
  }

  onCountrySelectChange(countryId: number) {
    const country = this.countries.filter(c => c.id.toString() === countryId)[0];
    this.rentalPointForm.get('rentalPoint').get('vat').setValue(country.vat);
  }

  addRentalPoint() {
    if (this.rentalPointForm.valid) {
      const newRentalPointAccount = Account.fromDto(this.rentalPointForm.value);
      newRentalPointAccount.rentalPoint.clientId = this._clientId;
      newRentalPointAccount.clientId = this._clientId;
      newRentalPointAccount.rentalPoint.discountCards = this._choosenDiscountCardsArray;
      console.log(newRentalPointAccount);
      this.userService.addRentalPoint(newRentalPointAccount).subscribe(result => {
        this.snackBar.open(this._translate.instant('rentalPoint.rentalPointAdd.message1'), '', {
          duration: 2000,
        });
        this.router.navigate(['/clients/details/' + this._clientId]);
      },
      (error => {
        switch (error.status) {
          case 400:
            this._snackBar.open('Username already exists', '', {
              duration: 2500
            });
            break;
          case 600:
            this._snackBar.open('Internal server error', '', {
              duration: 2500
            });
            break;
        }
      }));
    } else {
      this.errorArray = ValidationUtils.getFormValidationErrors(this.rentalPointForm, this.rentalPointForm.controls);
    }
  }
}
