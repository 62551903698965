import { Rental } from './Rental';
import { BikeType } from './BikeType';
import { Account } from './Account';
import { RentalPoint } from './RentalPoint';

/**
 * This is a common Model for Booked and Direct Bikes
 */

export class RentalBike {
    public id: number;
    public price: number;
    public discountedPrice: number;
    public productId: number;
    public diffRentalCost: number;
    public qrCode: string;
    /**
     * 1 - Rented
     * 2 - Returned
     */
    public status: number;
    public returnedAt: string;
    public returnedRentalPoint: RentalPoint;
    public replaced: boolean;
    /**
     * 0 = Normal
     * 1 = Partial return
     * 2 = Partial cancellation
     */
    public returnType: number;
    public comment: string;
    public replacedAt: string;
    public replacedPrice: number;
    public replacedDiscountedPrice: number;
    public replacedProductId: number;
    public replacedBikeTypeId: BikeType;
    public replacedQRCode: number;
    public quantity: number;
    public height: number;
    public directRental: Rental;
    public bookedRental: Rental;
    public bikeType: BikeType;

    public static fromDto(r): RentalBike {
        const rentalBike = new RentalBike();
        if (!r) {
            return null;
        }

        rentalBike.id = r.id;
        rentalBike.price = r.price;
        rentalBike.discountedPrice = r.discountedPrice;
        rentalBike.productId = r.productId;
        rentalBike.diffRentalCost = r.diffRentalCost;
        rentalBike.qrCode = r.qrCode;
        rentalBike.status = r.status;
        rentalBike.returnedAt = r.returnedAt;
        if (r.returnedRentalPoint != null) {
            rentalBike.returnedRentalPoint = RentalPoint.fromDto(r.returnedRentalPoint);
        }
        rentalBike.replaced = r.replaced;
        rentalBike.returnType = r.returnType;
        rentalBike.comment = r.comment;
        rentalBike.replacedAt = r.replacedAt;
        rentalBike.replacedPrice = r.replacedPrice;
        rentalBike.replacedDiscountedPrice = r.replacedDiscountedPrice;
        rentalBike.replacedProductId = r.replacedProductId;
        rentalBike.replacedBikeTypeId = BikeType.fromDto(r.replacedBikeTypeId);
        rentalBike.replacedQRCode = r.replacedQRCode;
        rentalBike.quantity = r.quantity;
        rentalBike.height = r.height;
        if (r.directRental) {
            rentalBike.directRental = Rental.fromDto(r.directRental);
        }
        if (r.bookedRental) {
            rentalBike.bookedRental = Rental.fromDto(r.bookedRental);
        }
        rentalBike.bikeType = BikeType.fromDto(r.bikeType);

        return rentalBike;

    }
}
