import { BikeType } from '../../../models/BikeType';

export class ProductSummary {
    public bikeType: BikeType;
    public quantity: number;

    public static fromDto(p): ProductSummary {
        const productSummary = new ProductSummary();
        if (!p) {
            return null;
        }

        productSummary.bikeType = BikeType.fromDto(p.bikeType);
        productSummary.quantity = p.quantity;

        return productSummary;
    }
}
