<div class="card mb-1">
  <div class="card-body">
      <table class="table table-sm m-0 table-bordered">
          <thead>
              <tr>
                  <th scope="col">{{'products.bikeStatistics.qrCode' | translate}}</th>
                  <th scope="col">{{'products.bikeStatistics.bikeName' | translate}}</th>
                  <th scope="col">{{'products.bikeStatistics.return' | translate}}</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let bike of bikeItems">
                  <th scope="row">{{bike.qrCode}}</th>
                  <td>{{bike.bikeName}}</td>
                  <td>{{bike.return}}</td>
              </tr>
          </tbody>
      </table>
  </div>
</div>
