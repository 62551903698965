import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectFilter'
})
export class ObjectFilterPipe implements PipeTransform {

  transform(items: any[], filter?: any): any {
    return items.filter(item => item.rentalPointId === filter)[0].bikes;
  }

}
