<div class="col-xs-12 col-md-12">
  <form #f="ngForm" (ngSubmit)="getUserByUsername(f)">
    <div class="form-group">
      <label>{{'forgotPassword.resetPassword' | translate}}</label>
      <input type="text" [(ngModel)]="user.email" class="form-control" id="email" name="email" required
      placeholder="{{'forgotPassword.userName/password' | translate}}"/>
    </div>
    <div class="form-group">
      <input type="submit" value="Send" class="btn btn-default submit-button" id="submitButton"/>
    </div>
  </form>
</div>
