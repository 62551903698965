import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../../models/SearchObject';
import { RentalPointsService } from '../../shared/rental-points.service';
import { DataTableColumn } from '../../../../shared/data-table/data-table.component';
import { RealBikeQuantitiesLeakSearchCriteria } from '../../shared/RealBikeQuantitiesLeakSearchCriteria';

@Component({
  selector: 'app-leack-table',
  templateUrl: './leack-table.component.html',
  styleUrls: ['./leack-table.component.scss']
})
export class LeackTableComponent implements OnInit, OnChanges {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  public childFilter: RealBikeQuantitiesLeakSearchCriteria;

  @Output()
  onLoadingFinish: EventEmitter<string> = new EventEmitter();

  @ViewChild('rentalPointName', { static: true })
  rentalPointName: TemplateRef<any>;

  @ViewChild('bikeTypeName', { static: true })
  bikeTypeName: TemplateRef<any>;

  @ViewChild('contingentBuffer', { static: true })
  contingentBuffer: TemplateRef<any>;

  @ViewChild('contingentProducts', { static: true })
  contingentProducts: TemplateRef<any>;

  @ViewChild('lackAmount', { static: true })
  lackAmount: TemplateRef<any>;

  @Input()
  currentLang: string;

  @Input()
  filters: RealBikeQuantitiesLeakSearchCriteria;

  columns: DataTableColumn[] = [];

  constructor(private _rentalPointsService: RentalPointsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    // Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    // Add '${implements OnChanges}' to the class.
    this.childFilter = changes.filters.currentValue;
    for (const key in this.childFilter) {
      if (this.childFilter.hasOwnProperty(key)) {
        const value = this.childFilter[key];
        if (value !== undefined) {
          this.loadPage = (page: number, pageSize: number) => this._rentalPointsService.getRealBikeQuantitiesLeak(page, pageSize,
            this.childFilter);
        }
      }
    }
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'rentalPointName',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.leakTable.rentalPointName',
        template: this.rentalPointName,
        sortable: true
      },
      {
        name: 'bikeTypeName',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.leakTable.bikeTypeName',
        template: this.bikeTypeName,
        sortable: true
      },
      {
        name: 'contingentBuffer',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.leakTable.contingentBuffer',
        template: this.contingentBuffer,
        sortable: true
      },
      {
        name: 'contingentProducts',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.leakTable.contingentProducts',
        template: this.contingentProducts,
        sortable: true
      },
      {
        name: 'lackAmount',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.leakTable.lackAmount',
        template: this.lackAmount,
        sortable: true
      },
    ];
  }

  onTableFinishLoading() {
    this.onLoadingFinish.emit('leack');
  }
}
