import { AuthGuard } from '../../auth-guard.service';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderAuthComponent } from './header-auth/header-auth.component';
import { NavItemsGroupComponent } from './sidebar/nav-items-group/nav-items-group.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        SharedModule
    ],
    declarations: [
        HeaderAuthComponent,
        SidebarComponent,
        NavItemsGroupComponent
    ],
    exports: [
        HeaderAuthComponent,
        SidebarComponent,
        NavItemsGroupComponent
    ],
    providers: [AuthGuard]
})
export class GeneralModule {
}
