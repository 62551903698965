import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProductsPerRentalPoint } from '../../../models/ProductsPerRentalPoint';
import { RentalPointsService } from '../shared/rental-points.service';
import { BikeType } from '../../../models/BikeType';
import { Account } from '../../../models/Account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-default-bike-contingent',
  templateUrl: './default-bike-contingent.component.html',
  styleUrls: ['./default-bike-contingent.component.scss']
})
export class DefaultBikeContingentComponent implements OnInit {

  public currentRentalPointId;
  public contingentAmount: ProductsPerRentalPoint[] = [];
  public bikeTypes: BikeType[];
  public currentLang: string;

  private _currentAccount: Account;

  constructor(private _activatedRoute: ActivatedRoute, private _rentalPointsService: RentalPointsService,
    private _snackBar: MatSnackBar, private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe((params) => {
      this.currentRentalPointId = params.rentalPointId;
      this._rentalPointsService.getRentalPoint(params.rentalPointId).subscribe(accounts => {
        this._currentAccount = Account.fromDto(accounts);
        this.populateContingentTable();
      });
    });
  }

  populateContingentTable() {
    this.contingentAmount = [];
    this._rentalPointsService.getBikeTypes().subscribe(bikeTypes => {
      this.bikeTypes = bikeTypes;
      this.bikeTypes.forEach(bike => {
        const contingent = new ProductsPerRentalPoint();
        contingent.bikeType = bike;
        contingent.buffer = 0;
        contingent.numberOfProducts = 0;
        contingent.account = this._currentAccount;
        this.contingentAmount.push(contingent);
      });

      this._rentalPointsService.getContingentProductsPerRentalPoint(this._currentAccount.id).subscribe(products => {
        products.forEach(product => {
          product.account = this._currentAccount;
          const updateItemIndex = this.contingentAmount.findIndex(contingent => contingent.bikeType.id === product.bikeType.id);
          this.contingentAmount[updateItemIndex] = product;
        });
      });
    });
  }

  public updateDefaultBikeContingent() {
    let error = false;
    this.contingentAmount.forEach(element => {
      if (element.numberOfProducts < 0 || element.buffer < 0) {
        error = true;
      }
    });

    if (!error) {
      this._rentalPointsService.updateContingentProductsPerRentalPoint(this.contingentAmount).
        subscribe(contingentResult => {
          this.populateContingentTable();
          this._snackBar.open(this._translate.instant('rentalPoint.defaultBikeContingent.message1'), '', {
            duration: 2000,
          });
        });
    }
  }

}
