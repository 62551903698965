export class SearchObjectResult<T> {

  public results: T[] = [];
  public count: number;
  public totalCount: number;
  public page: number;
  public totalPages: number;
  public pageSize: number;
  public extra: any;

  static fromDto(s: any, objectMapper: (o: any) => any): SearchObjectResult<any> {
    const search = new SearchObjectResult<any>();
    search.results    = s.results.map(objectMapper);
    search.count      = s.count;
    search.totalCount = s.totalCount;
    search.page       = s.page;
    search.totalPages = s.totalPages;
    search.pageSize   = s.pageSize;
    search.extra      = s.extra;

    return search;
  }
}
