
export class DefectHistory {
  public defect: string;
  public defectType: string;
  public defectTypeId: string;
  public firstName: string;
  public lastName: string;
  public username: string;
  public fixedDate: string;
  public fixedTime: string;

  public static fromDto(d: any): DefectHistory {
    const defectHistory = new DefectHistory();
    if (!d) {
      return null;
    }

    defectHistory.defect = d.defect;
    defectHistory.defectType = d.defectType;
    defectHistory.defectTypeId = d.defectTypeId;
    defectHistory.firstName = d.firstName;
    defectHistory.lastName = d.lastName;
    defectHistory.username = d.username;
    defectHistory.fixedDate = d.fixedDate;
    defectHistory.fixedTime = d.fixedTime;

    return defectHistory;
  }
}
