<div class="row mb-3" *ngIf="showSearch">
  <div class="col-12 data-table-header">
    <div class="card" style="border-color: #2e7dff;">
      <div class="card-body">
        <ng-content select="app-data-filters"></ng-content>
        <ng-content select="app-data-buttons"></ng-content>
      </div>
    </div>
    <div class="card mt-2" style="border-color: #2e7dff;" *ngIf="selectedIds.length > 0">
      <div class="card-body row" style="align-items: center;">
        <app-data-filter-select label="{{'products.bikeEquipmentList.selectAction' | translate}}"  [(model)]="selectedAction" [options]="availableActions"></app-data-filter-select>
        <div style="display: flex; flex-flow: column;" class="ml-lg-3">
          <label style="font-weight: bold;" class="mb-0">{{'products.bikeEquipmentList.selected' | translate }}</label>
          <span>{{'products.bikeEquipmentList.selectedRecords' | translate | replaceTxt : 'xxx' : selectedIds.length.toString() }}</span>
        </div>
        <div class="ml-lg-5">
          <div *ngIf="selectedAction === actionSelectedBikes.DELETE">
            <div class="text-right">
            <span class="btn btn-primary" style="background-color: #dc3545; border:0" (click)="onDeleteAllButtonClickEvent()">
              <i class="fa fa-trash"></i> {{'products.bikeEquipmentList.deleteSelected' | translate}}
            </span>
            </div>
          </div>
          <div *ngIf="selectedAction === actionSelectedBikes.MOVE" class="d-flex">
            <app-data-filter-group class="w-100">
              <app-data-filter-select label="{{'products.bikeEquipmentList.clients' | translate}}"
                                      [(model)]="moveProduct.clientId" [options]="moveClientOptions"
                                      (modelChange)="onMoveProductClientSelectChange($event)">
              </app-data-filter-select>
              <app-data-filter-select-search label="{{'products.bikeEquipmentList.rentalPoints' | translate}}"
                                             [(model)]="moveProduct.rentalPointAccountId" [options]="moveRentalPointOptions">
              </app-data-filter-select-search>
            </app-data-filter-group>
            <div class="text-right">
            <span class="btn btn-primary" style="background-color: #2e7dff; border:0" (click)="onMoveSelectedButtonClickEvent()">
              {{'products.bikeEquipmentList.moveSelected' | translate}}
            </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="table-responsive">
      <table class="table text-left table-bordered">
        <thead>
          <tr>
            <ng-template ngFor [ngForOf]="columns" let-column>
              <th *ngIf="column.displayed" class="{{column.trAddidionalClass}}">{{column.translationKey | translate}}</th>
            </ng-template>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let i of items" (click)="onTableRowClickEvent(i)">
            <ng-template ngFor [ngForOf]="columns" let-column>
              <td *ngIf="column.displayed">
                <app-data-table-column [template]="column.template" [additionalClass]="column.additionalClass"
                  [item]="i"></app-data-table-column>
              </td>
            </ng-template>
          </tr>
          <tr *ngIf="items.length === 0">
            <td [attr.colspan]="columns.length" class="force-text-center"><em [translate]="'data-table.no-result'"></em>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="table-footer">
        <span class="inner-space-left-10">
          <app-pagination [page]="page" [pageSize]="pageSize" [lastPage]="lastPage" (pageChange)="changePage($event)">
          </app-pagination>
        </span>
        <span class="inner-space-right-10" [translate]="'data-table.count'"
          [translateParams]="{ count: totalCount }"></span>
      </div>
    </div>
  </div>
</div>
