import { Component, OnInit } from '@angular/core';
import { Rental } from '../../../../models/Rental';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../shared/orders.service';
import { RentalBike } from '../../../../models/RentalBike';
import { RentalPoint } from '../../../../models/RentalPoint';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-total-cancellation-paper',
  templateUrl: './total-cancellation-paper.component.html',
  styleUrls: ['./total-cancellation-paper.component.scss']
})
export class TotalCancellationPaperComponent implements OnInit {

  public contractId: string;
  public rental: Rental = new Rental();
  public bikes: RentalBike[];
  public startRentalPoint: RentalPoint = new RentalPoint();
  public endRentalPoint: RentalPoint = new RentalPoint();
  public currentLang: string;

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.contractId = params['contractId'];
      this._ordersService.getReturnPaper(this.contractId).subscribe(result => {
        console.log(result);
        this.rental = result;
        this.startRentalPoint = RentalPoint.fromDto(this.rental.startRentalPoint);
        this.endRentalPoint = RentalPoint.fromDto(this.rental.endRentalPoint);
        if (this.rental.orderReturn) {
          this.endRentalPoint = RentalPoint.fromDto(this.rental.orderReturn.rentalPointId);
        }
        if (this.rental.orderType === 2) {
          this.bikes = this.rental.bookedBike;
        } else {
          this.bikes = this.rental.directBike;
        }
      });
    });
  }
}
