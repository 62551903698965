import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'defectStatus'
})
export class DefectStatusPipe implements PipeTransform {

  transform(value: any): any {
    if (value === 0) {
      return 'Not fixed';
    } else {
      return 'Fixed';
    }
  }

}
