export class BikeTypeAvalibities {
    public bikeAvailabilityPeriod: number;
    public bikeTypeId: number;
    public rentalPointId: number;

    public static fromDto(b): BikeTypeAvalibities {
        const bikeTypeAvalibities = new BikeTypeAvalibities();
        if (!b) {
            return null;
        }
        bikeTypeAvalibities.bikeAvailabilityPeriod = b.bikeAvailabilityPeriod;
        bikeTypeAvalibities.bikeTypeId = b.bikeTypeId;
        bikeTypeAvalibities.rentalPointId = b.rentalPointId;

        return bikeTypeAvalibities;
    }
}
