import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../shared/orders.service';
import { forkJoin } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-orders-details',
  templateUrl: './orders-details.component.html',
  styleUrls: ['./orders-details.component.scss']
})
export class OrdersDetailsComponent implements OnInit {

  public order: any;
  public bikes: any;
  public orderType: string;
  public isCancelledBooking = false;
  public pdfLink: string;
  public creditNoteLink: string;
  public currentLang: string;
  public isExtension = false;
  private _contractId: string;

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService,
    private _translateService: TranslateService, private _http: HttpClient) {
    this.currentLang = this._translateService.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.orderType = params['orderType'];
      this._contractId = params['contractId'];
      const serviceCalls = [];
      switch (this.orderType) {
        case 'direct-rental':
          serviceCalls.push(this._ordersService.getDirectRental(this._contractId));
          break;
        case 'booked-rental':
          serviceCalls.push(this._ordersService.getBookedRentalByContractId(this._contractId));
          break;
        default:
          serviceCalls.push(this._ordersService.getOnlineBookingReservationByContractId(this._contractId));
          break;
      }
      forkJoin(serviceCalls).subscribe(result => {
        console.log(result);
        this.order = result[0];
        this.isExtension = this.order.isExtension;
        this.isCancelledBooking = this.order.reservation === 0 && this.order.transactionStatus === 99;
        if (this.order.status === 2 || this.order.status === 3) {
          this.bikes = this.order.orderReturn.returnBike;
        } else {
          switch (this.orderType) {
            case 'direct-rental':
              this.bikes = this.order.directBike;
              break;
            case 'booked-rental':
              this.bikes = this.order.bookedBike;
              break;
            default:
              this.bikes = this.order.invoiceBike;
              break;
          }
        }
        if (this.isCancelledBooking) {
          this._ordersService.generatePdfLink(this._contractId, 'credit').subscribe({
            next: (response) => {
              this.creditNoteLink = response.link;
            }, error: (error) => {
              console.log(error);
            },
          });
        }
      });
    });

    this._ordersService.generatePdfLink(this._contractId, this.orderType).subscribe({
      next: (response) => {
        this.pdfLink = response.link;
      }, error: (error) => {
        console.log(error);
      },
    });
  }

  onExtensionCheckboxChange(value: boolean) {
    this._ordersService.changeExtensionRental(this.order.id, this.order.orderType, value).subscribe(result => {
      console.log(result);
      this.order.isExtension = value;
      this.isExtension = value;
    });
  }

}
