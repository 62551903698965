import { Injectable, Output, EventEmitter, Directive } from '@angular/core';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public loggedId: number;

  @Output()
  fire: EventEmitter<any> = new EventEmitter();

  constructor() { }

  change(value) {
    this.fire.emit(value);
  }

  getEmittedValue() {
    return this.fire;
  }
}
