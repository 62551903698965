import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ServicePointsSearchCriteria } from './ServicePointsSearchCriteria';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { ServicePoint } from '../../../models/ServicePoint';
import { District } from '../../../models/District';
import { Country } from '../../../models/Country';

@Injectable({
  providedIn: 'root'
})
export class ServicePointService {

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: ServicePointsSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchServicePoints', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, ServicePoint.fromDto);
      })
    );
  }

  /**
   * getServicePoint
   */
  public getServicePoint(servicePointId: string): Observable<ServicePoint> {
    const params = { id: servicePointId };
    return this._http.get(environment.serverUrl + 'api/getServicePoints', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => ServicePoint.fromDto(result))
    );
  }

  /**
   * getServicePoints
   */
  public getServicePoints(): Observable<ServicePoint[]> {
    return this._http.get(environment.serverUrl + 'api/getServicePoints', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => ServicePoint.fromDto(res)))
    );
  }

  /**
   * updateServicePoint
   */
  public updateServicePoint(servicePoint: ServicePoint): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/updateServicePoint', { servicePoint: servicePoint }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * insertServicePoint
   */
  public insertServicePoint(servicePoint: ServicePoint): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/insertServicePoint', { servicePoint: servicePoint }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * deleteServicePoint
  */
    public deleteServicePoint(id: number): Observable<any> {
      return this._http.post<any>(
        environment.serverUrl + 'api/deleteServicePoint', { id: id.toString() },
        {
          headers: HttpUtils.createHeaders()
        }
      );
    }

  /**
  * getDistricts
  */
  public getDistricts(): Observable<District[]> {
    return this._http.get(environment.serverUrl + 'api/getDistricts', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => District.fromDto(res)))
    );
  }

    /**
  * getCountries
  */
    public getCountries(): Observable<Country[]> {
      return this._http.get(environment.serverUrl + 'api/getCountries', {
        headers: HttpUtils.createHeaders()
      }).pipe(
        map((result: any[]) => result.map(res => Country.fromDto(res)))
      );
    }

}
