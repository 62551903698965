import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SharedUtils } from '../../../shared/shared-utils';
import { ACCESS_RIGHTS } from '../../login/login.component';
import { environment } from '../../../../environments/environment';

export class RoutesGroup {
  groupTitle: string;
  levelAccess: Array<number>;
  groupItems: RoutesGroupItems[];
}

export class RoutesGroupItems {
  path: string;
  title: string;
  icon?: string;
  class?: string;
  levelAccess: Array<number>;
  index?: number; // index is null if levelaAcess not 0
}

export const ROUTES: RoutesGroup[] = [
  {
    groupTitle: '', levelAccess: [0, 1, 2], groupItems: [
      { path: 'dashboard', title: 'general.sidebar.dashboard', levelAccess: [0, 1, 2], index: 0 },
      { path: 'rental-points/leak-excess', title: 'general.sidebar.bikeLackAndExcess', levelAccess: [0, 1, 2], index: 1 },
      { path: 'tickets/list', title: 'general.sidebar.tickets', levelAccess: [0, 1], index: 2 }
    ]
  },
  {
    groupTitle: 'general.sidebar.priceList', levelAccess: [0], groupItems: [
      { path: 'price-list/update', title: 'general.sidebar.updatePriceList', levelAccess: [0], index: 3 },
    ]
  },
  {
    groupTitle: 'general.sidebar.bikeEcquipment', levelAccess: [0, 1, 2], groupItems: [
      { path: 'products/bikes-equipments', title: 'general.sidebar.all', levelAccess: [0, 1, 2], index: 4},
      { path: 'products/summary', title: 'general.sidebar.summary', levelAccess: [0, 1, 2], index: 5 },
      { path: 'products/preparation', title: 'general.sidebar.preparation', levelAccess: [0, 1, 2], index: 6 },
      { path: 'products/add', title: 'general.sidebar.addNewRealBikes', levelAccess: [0], index: 7 },
      { path: 'products/statistics', title: 'general.sidebar.bikeStatistics', levelAccess: [0, 1, 2], index: 8},
      { path: 'products/bikebyqrcode', title: 'general.sidebar.searchLocationBikeByQrCode', levelAccess: [0, 1, 2], index: 9 },
      { path: 'products/bikes-sell', title: 'general.sidebar.bike-sell', levelAccess: [0, 1, 2], index: 10 }
    ]
  },
  {
    groupTitle: 'general.sidebar.clients', levelAccess: [0, 1], groupItems: [
      { path: 'clients/list', title: 'general.sidebar.all', levelAccess: [0], index: 11 },
      { path: 'clients/details/', title: 'general.sidebar.all', levelAccess: [1], index: null },
      { path: 'clients/add', title: 'general.sidebar.addNewClient', levelAccess: [0], index: 12 },
      { path: 'service-points/list', title: 'general.sidebar.servicePoints', levelAccess: [0], index: 13 },
      { path: 'wartung-users/list', title: 'general.sidebar.wartungUsers', levelAccess: [0], index: 14 },
    ]
  },
  {
    groupTitle: 'general.sidebar.orders', levelAccess: [0, 1, 2], groupItems: [
      { path: 'orders/all', title: 'general.sidebar.rentals', levelAccess: [0, 1, 2], index: 15 },
      { path: 'orders/longTerm', title: 'general.sidebar.longTermRentals', levelAccess: [0, 1, 2], index: 16 },
      { path: 'orders/deleted-orders', title: 'general.sidebar.deletedRentals', levelAccess: [0, 1, 2], index: 17},
      { path: 'orders/return-orders', title: 'Return orders', levelAccess: [2], index: 18 },
      { path: 'orders/booking-reservations', title: 'general.sidebar.onlineBookingsReservations', levelAccess: [0, 1, 2], index: 19 },
      { path: 'orders/cancelled-orders', title: 'general.sidebar.cancelledOrders', levelAccess: [0, 1, 2], index: 20 },
      { path: 'orders/unsuccessful-payments', title: 'general.sidebar.unsuccessfulPayments', levelAccess: [0], index: 21 },
      { path: 'orders/credits', title: 'general.sidebar.clientCredits', levelAccess: [0, 1], index: 22 },
      { path: 'orders/create-credit', title: 'general.sidebar.createCredit', levelAccess: [0], index: 23 },
      { path: 'orders/search-by-qr-code', title: 'general.sidebar.searchByQrCode', levelAccess: [0], index: 24 },
    ]
  },
  {
    groupTitle: 'Statements', levelAccess: [0, 1], groupItems: [ // Abrechnung
      { path: 'cash-flow/online-bookings', title: 'general.sidebar.onlineBookingsReservations', levelAccess: [0, 1], index: 25 },
      { path: 'cash-flow/rental-points', title: 'Cashflow', levelAccess: [0, 1], index: 26 },
      { path: 'cash-flow/turnover', title: 'Turnover', levelAccess: [0], index: 27 },
      { path: 'cash-flow/invoices-list', title: 'general.sidebar.invoicesList', levelAccess: [0], index: 28 }
    ]
  },
  {
    groupTitle: 'Statistics', levelAccess: [0], groupItems: [
      { path: 'statistics/contingent', title: 'general.sidebar.contingent', levelAccess: [0], index: 29 },
      { path: 'statistics/rentedBikes', title: 'general.sidebar.rentedBikes', levelAccess: [0], index: 30 }
    ]
  }
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public userType = SharedUtils.getCurrentUserType();
  public clientId: number;
  public menuItems = [];

  public accessRightsString: string;
  public accessRightsArray: string[];

  constructor(private router: Router, private translate: TranslateService) {
  }

  viewRentalPoints() {
    this.router.navigate(['/rental-points/' + localStorage.getItem('accountId')]);
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);

    const accountId = localStorage.getItem('accountId');
    const magazinHotelId = environment.production ? 1215 : 1208;
    const papinHotelClientId = 67;

    this.menuItems[3].groupItems[1].path = 'clients/details/' + (
      accountId === magazinHotelId.toString()
        ? papinHotelClientId
        : this.userType.clientId
    );

    if (this.userType.accountId !== '1') { //  tomy
      this.menuItems[5].groupItems = this.menuItems[5].groupItems.slice(0, -1);
      this.menuItems[4].groupItems = this.menuItems[4].groupItems.slice(0, 8).concat(this.menuItems[4].groupItems[9]);
    }

    this.accessRightsString = localStorage.getItem('accessRights');
    if (this.accessRightsString) {
      this.accessRightsArray = Array.from(this.accessRightsString);
    } else {
      this.accessRightsArray = Array.from(ACCESS_RIGHTS); // allow by default
    }
  }

  switchLanguage(language: string) {
    this.translate.use(language);
  }
}
