import { Component, Input, OnInit } from '@angular/core';
import { BikeStatisticBike } from '../../../../models/BikeStatistic';

@Component({
  selector: 'app-bike-statistics-card-item',
  templateUrl: './bike-statistics-card-item.component.html',
  styleUrls: ['./bike-statistics-card-item.component.scss']
})
export class BikeStatisticsCardItemComponent implements OnInit {

  @Input() bikeItems: BikeStatisticBike[];

  constructor() { }

  ngOnInit(): void {
  }

}
