import { Routes } from '@angular/router';
import { RentalPointComponent } from './rental-point.component';
import { AuthGuard } from '../../auth-guard.service';
import { RentalPointEditComponent } from './rental-point-edit/rental-point-edit.component';
import { RentalPointAddComponent } from './rental-point-add/rental-point-add.component';
import { LeakExcessComponent } from './leak-excess/leak-excess.component';
import { ManageSchedulesComponent } from './manage-schedules/manage-schedules.component';
import { DefaultBikeContingentComponent } from './default-bike-contingent/default-bike-contingent.component';
import { BikeContingentExceptionsComponent } from './bike-contingent-exceptions/bike-contingent-exceptions.component';
import { ManageBikeTypePeriodComponent } from './manage-bike-type-period/manage-bike-type-period.component';

export const RENTAL_POINT_ROUTES: Routes = [
    {
        path: 'rental-points', component: RentalPointComponent, canActivate: [AuthGuard], children: [
            { path: 'leak-excess', component: LeakExcessComponent, canActivate: [AuthGuard] },
            { path: 'edit/:rentalPointId', component: RentalPointEditComponent, canActivate: [AuthGuard] },
            { path: 'manage-schedules/:rentalPointId', component: ManageSchedulesComponent, canActivate: [AuthGuard] },
            { path: 'default-bike-contingent/:rentalPointId', component: DefaultBikeContingentComponent, canActivate: [AuthGuard] },
            { path: 'bike-contingent-exceptions/:rentalPointId', component: BikeContingentExceptionsComponent, canActivate: [AuthGuard] },
            { path: 'manage-bike-type-period/:rentalPointId', component: ManageBikeTypePeriodComponent, canActivate: [AuthGuard] },
            { path: 'add/:clientId', component: RentalPointAddComponent, canActivate: [AuthGuard] },
            // { path: '**', redirectTo: 'rental-points/list/:clientId' }
        ]
    }
];
