<app-header-auth></app-header-auth>
<div class="headText">
  <a href="/staff">
    <img src="../../../assets/images/back.png">
  </a>
  <p>
    <span>{{'staff.staffAdd.manageAccounts' | translate}}</span> |
    <span>{{'staff.staffAdd.administrator' | translate}}</span>
  </p>
</div>

<hr>

<div class="settingsContent">
  <div class="settings container-fluid container-form">
    <div class="error" *ngIf="showErrors">
      <span>{{'staff.staffAdd.errorMessage' | translate}}</span>
    </div>
    <form (ngSubmit)="updateStaff(updateStaffForm)" #updateStaffForm="ngForm">
      <div class="middleContent">
        <div class="row">
          <div class="col-md-6 col-xs-12 switched">
            <div class="avatar form-group">
              <label>
                <span class="form-text">{{'staff.staffAdd.profilePicture' | translate}}
                  <span>*</span>
                </span>
                <img src={user.avatar} *ngIf="user.avatar" class="user-avatar" />
                <div class="no-avatar" *ngIf="!user.avatar"></div>
                <input type="file" name="avatar" id="avatar" [(ngModel)]="user.avatar" placeholder="Avatar" class="form-control" accept="image/*"
                />
              </label>
            </div>
          </div>
          <div class="col-md-6 col-xs-12 switched">
            <div class="inputContent">
              <input type="hidden" name="id" [(ngModel)]="user.id" />
              <div class="form-group">
                <label for="firstname" class="form-text">{{'staff.staffAdd.firstName' | translate}}
                  <span>*</span>
                </label>
                <input type="text" name="firstName" id="firstname" [(ngModel)]="user.firstName" required placeholder="First Name" class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="lastname" class="form-text">{{'staff.staffAdd.lastName' | translate}}
                  <span>*</span>
                </label>
                <input type="text" name="lastName" id="lastname" [(ngModel)]="user.lastName" required placeholder="Last Name" class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="username" class="form-text">{{'staff.staffAdd.userName' | translate}}
                  <span>*</span>
                </label>
                <input type="text" id="username" name="username" [(ngModel)]="user.username" required placeholder="Username" class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="password" class="form-text">{{'staff.staffAdd.password' | translate}}
                  <span>*</span>
                </label>
                <input type="password" name="password" id="password" [(ngModel)]="user.password" required placeholder="Password" class="form-control"
                />
              </div>
              <div class="form-group">
                <label for="email" class="form-text">{{'staff.staffAdd.emailAddress' | translate}}
                  <span>*</span>
                </label>
                <input type="email" name="email" id="email" [(ngModel)]="user.email" required placeholder="Email" class="form-control" />
              </div>
              <div>
                <label for="userType">{{'staff.staffAdd.userType' | translate}}</label>
                <select name="userType" id="userType" [(ngModel)]="user.userType" class="form-control" required>
                  <option value="1">{{'staff.staffAdd.administrator' | translate}}</option>
                  <option value="2">{{'staff.staffAdd.moderator' | translate}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row middleContent">
          <div class="col-md-12 form-group">
            <button type="submit" id="submit" class="form-control submit-button">{{'staff.staffAdd.save' | translate}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
