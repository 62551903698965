import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PriceList } from '../../../models/PriceList';
import { DiscountCard } from '../../../models/DiscountCard';
import { FormGroup, FormControl } from '@angular/forms';
import { Account } from '../../../models/Account';
import { ValidationUtils } from '../../../shared/validation.utils';
import { RentalPointsService } from '../shared/rental-points.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { District } from '../../../models/District';
import { Country } from '../../../models/Country';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rental-point-edit',
  templateUrl: './rental-point-edit.component.html',
  styleUrls: ['./rental-point-edit.component.scss']
})
export class RentalPointEditComponent implements OnInit {

  public currentRentalPointId: number;
  public clientId: number;
  public districts: District[];
  public countries: Country[];
  public accountType: {};
  public businessName: string;
  public priceLists: PriceList[];
  public discountCardsList: DiscountCard[];
  public errorArray = [];
  public account = new Account();
  public updateRentalPointForm: FormGroup;

  private _choosenDiscountCardsArray: DiscountCard[] = [];

  constructor(
    public _snackBar: MatSnackBar, private _modalService: NgbModal, private _translate: TranslateService,
    private userService: UserService, private activatedRoute: ActivatedRoute, private _rentalPointsService: RentalPointsService,
    public snackBar: MatSnackBar) {
    this.accountType = SharedUtils.getCurrentUserType();
    this.updateRentalPointForm = new FormGroup({
      id: new FormControl(),
      username: new FormControl(),
      email: new FormControl(),
      password: new FormControl(),
      lastName: new FormControl(),
      firstName: new FormControl(),
      rentalPoint: new FormGroup({
        id: new FormControl(),
        region: new FormControl(),
        phone: new FormControl(),
        city: new FormControl(),
        street: new FormControl(),
        country: new FormControl(1),
        district: new FormControl(),
        latitude: new FormControl(),
        longitude: new FormControl(),
        postalCode: new FormControl(),
        vat: new FormControl('0.20'),
        priceLists: new FormControl([PriceList]),
        discountCards: new FormControl([DiscountCard]),
        clientId: new FormControl(),
        rentalPointTranslation: new FormGroup({
          id: new FormControl(),
          de: new FormControl(),
          it: new FormControl(),
          en: new FormControl(),
        }),
        extraField: new FormGroup({
          id: new FormControl(),
          titleEn: new FormControl(),
          titleIt: new FormControl(),
          titleDe: new FormControl(),
          detailEn: new FormControl(),
          detailIt: new FormControl(),
          detailDe: new FormControl(),
          isLink: new FormControl(),
        })
      })
    });
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.currentRentalPointId = params.rentalPointId;
      this._rentalPointsService.getDistricts().subscribe(result => {
        this.districts = result;
      });
      this._rentalPointsService.getCountries().subscribe(result => {
        this.countries = result;
      });
      this._rentalPointsService.getPriceList().subscribe(result => {
        this.priceLists = result;
      });
      this._rentalPointsService.getDiscoutCards().subscribe(result => {
        this.discountCardsList = result;

        this._rentalPointsService.getRentalPoint(params.rentalPointId).subscribe(account => {
          console.log(account);
          this.account = account;
          account.password = undefined;
          this.updateRentalPointForm.patchValue(account);
          if (account.rentalPoint.district) {
            this.updateRentalPointForm.get('rentalPoint').get('district').setValue(account.rentalPoint.district.id);
          }
          if (account.rentalPoint.postalCode) {
            this.updateRentalPointForm.get('rentalPoint').get('postalCode').setValue(account.rentalPoint.postalCode);
          }
          if (account.rentalPoint.priceLists[0]) {
            this.updateRentalPointForm.get('rentalPoint').get('priceLists').setValue(this.priceLists.filter(
              d => d.id === account.rentalPoint.priceLists[0].id)[0]);
          } else {
            this.updateRentalPointForm.get('rentalPoint').get('priceLists').setValue(undefined);
          }
          if (account.rentalPoint.discountCards[0]) {
            this.updateRentalPointForm.get('rentalPoint').get('discountCards').setValue(this.discountCardsList.filter(
              d => d.id === account.rentalPoint.discountCards[0].id)[0]);
          } else {
            this.updateRentalPointForm.get('rentalPoint').get('discountCards').setValue(undefined);
          }
          this._choosenDiscountCardsArray = account.rentalPoint.discountCards;
        });
      });
    });
  }

  onDiscountCardCheckBoxChange(event, value: DiscountCard) {
    if (event.srcElement.checked) {
      this._choosenDiscountCardsArray.push(value);
    } else {
      const index = this._choosenDiscountCardsArray.findIndex(discount => discount.id === value.id);
      this._choosenDiscountCardsArray.splice(index, 1);
    }
    console.log(this._choosenDiscountCardsArray);
  }

  validateChecked(value: DiscountCard) {
    if (this.account.rentalPoint.discountCards.filter(discount => discount.id === value.id)[0]) {
      return true;
    }
    return false;
  }


  public updateRentalPoint() {
    this.errorArray = new Array();
    if (this.updateRentalPointForm.valid) {
      const updatedAccount = Account.fromDto(this.updateRentalPointForm.value);
      updatedAccount.rentalPoint.discountCards = this._choosenDiscountCardsArray;
      this.userService.saveRentalPoint(updatedAccount).subscribe(result => {
        this.snackBar.open(this._translate.instant('rentalPoint.rentalPointEdit.message1'), '', {
          duration: 2000,
        });
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.updateRentalPointForm, this.updateRentalPointForm.controls);
  }

  deleteRentalPoint(content) {
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((modalAction) => {
      this._rentalPointsService.deleteRentalPoint(this.currentRentalPointId).subscribe(result => {
        this.account.rentalPoint.deleted = true;
        this.snackBar.open(this._translate.instant('rentalPoint.rentalPointEdit.message2'), '', {
          duration: 10000,
        });
      });
    }, (reason) => {
    });
  }
}
