<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="20" (onReset)="onTableReset()">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.contractId' | translate}}"
              [(model)]="filters.id" placeholder="{{'orders.bookingReservationOrders.contractId' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.clientName' | translate}}"
              [(model)]="filters.clientName" placeholder="{{'orders.bookingReservationOrders.clientName' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.phone' | translate}}"
               [(model)]="filters.phone" placeholder="{{'orders.bookingReservationOrders.phone' | translate}}">
            </app-data-filter-input>
            <app-data-filter-select label="{{'orders.bookingReservationOrders.clients' | translate}}"
              [(model)]="filters.clientId" [options]="clientOptions" *ngIf="currentUserType.userTypeNumber === 0;"
              (modelChange)="onClientSelectChange($event)">
            </app-data-filter-select>
            <app-data-filter-select-search label="{{'orders.bookingReservationOrders.rentalPoints' | translate}}"
              [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
              *ngIf="currentUserType.userTypeNumber !== 2">
            </app-data-filter-select-search>
            <app-data-filter-datepicker label="{{'orders.bookingReservationOrders.from' | translate}}"
              [(model)]="filters.startDate">
            </app-data-filter-datepicker>
            <app-data-filter-datepicker label="{{'orders.bookingReservationOrders.to' | translate}}"
              [(model)]="filters.endDate">
            </app-data-filter-datepicker>
            <app-data-filter-select label="{{'orders.bookingReservationOrders.orderType' | translate}}"
              [(model)]="filters.orderType" [options]="orderTypeOptions">
            </app-data-filter-select>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-contractId="contractId">
        {{contractId}}
      </ng-template>

      <ng-template #clientName let-clientInformation="clientInformation">
        {{clientInformation.firstName}} {{clientInformation.lastName}}
      </ng-template>

      <ng-template #startRentalPoint let-startRentalPoint="startRentalPoint">
        {{startRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #returnRentalPoint let-endRentalPoint="endRentalPoint">
        {{endRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #startDate let-startDate="startDate">
        {{startDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #returnDate let-endDate="endDate">
        {{endDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #orderAmount let-discountedPrice="discountedPrice">
        {{discountedPrice | currency: '€ '}}
      </ng-template>

      <ng-template #orderType let-reservation="reservation">
          {{reservation | bookingReservationStatus | translate}}
      </ng-template>

      <ng-template #actions let-contractId="contractId" let-reservation="reservation">
        <ng-container *ngIf="reservation !== 0 && reservation !== 3; else elseTemplate">
          <a href="orders/reservation/details/{{contractId}}" target="_blank"
            class="btn btn-sm btn-primary">{{'orders.bookingReservationOrders.details' | translate}}</a>
          <input (click)="onCancelReservation(contentModalReservation, contractId)" type="submit" value="Cancel"
            class="btn btn-sm btn-danger" />
        </ng-container>
        <ng-template #elseTemplate>
          <a href="orders/booking/details/{{contractId}}" target="_blank"
            class="btn btn-sm btn-primary">{{'orders.bookingReservationOrders.details' | translate}}</a>
          <input *ngIf="reservation === 0" (click)="onCancelBooking(contentModalBooking, contractId)" type="submit" value="Cancel"
                 class="btn btn-sm btn-danger" />
        </ng-template>
      </ng-template>

      <ng-template #contentModalReservation let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title-reservation">
            {{'orders.bookingReservationOrders.reservationDeletion' | translate}}</h4>
          <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p><strong>{{'orders.bookingReservationOrders.message2' | translate}}</strong></p>
          <p>{{'orders.bookingReservationOrders.message3' | translate}}<br>
            <span class="text-danger bold">{{'orders.bookingReservationOrders.message4' | translate}}</span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary"
            (click)="modal.dismiss()">{{'orders.bookingReservationOrders.cancel' | translate}}</button>
          <button type="button" class="btn btn-danger"
            (click)="modal.close()">{{'orders.bookingReservationOrders.ok' | translate}}</button>
        </div>
      </ng-template>
      <ng-template #contentModalBooking let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-basic-title-booking">
            {{'orders.bookingReservationOrders.bookingDeletion' | translate}}</h4>
          <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p><strong>{{'orders.bookingReservationOrders.message2b' | translate}}</strong></p>
          <p>{{'orders.bookingReservationOrders.message3b' | translate}}<br>
            <span class="text-danger bold">{{'orders.bookingReservationOrders.message4' | translate}}</span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary"
                  (click)="modal.dismiss()">{{'orders.bookingReservationOrders.cancel' | translate}}</button>
          <button type="button" class="btn btn-danger"
                  (click)="modal.close()">{{'orders.bookingReservationOrders.ok' | translate}}</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>
