import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RentalPointSearchCriteria } from '../../rental-point/shared/RentalPointSearchCriteria';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { RentalPointsService } from '../../rental-point/shared/rental-points.service';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  public currentLang: string;
  public currentClientId: string;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: RentalPointSearchCriteria = {};

  @ViewChild('rentalPointId', { static: true })
  rentalPointId: TemplateRef<any>;

  @ViewChild('rentalPointName', { static: true })
  rentalPointName: TemplateRef<any>;

  @ViewChild('rentalPointUsername', { static: true })
  rentalPointUsername: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _routes: ActivatedRoute, private _rentalPointsService: RentalPointsService, private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._routes.params.subscribe(params => {
      this.filters = { clientId: params['clientId'] };
      this.currentClientId = params['clientId'];
      this.loadPage = (page: number, pageSize: number) => this._rentalPointsService.search(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'rentalPointId',
        displayed: true,
        translationKey: 'clients.clientDetails.rentalPointId',
        template: this.rentalPointId,
        sortable: true
      },
      {
        name: 'rentalPointName',
        displayed: true,
        translationKey: 'clients.clientDetails.rentalPointName',
        template: this.rentalPointName,
        sortable: true
      },
      {
        name: 'rentalPointUsername',
        displayed: true,
        translationKey: 'clients.clientDetails.rentalPointUsername',
        template: this.rentalPointUsername,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'clients.clientDetails.actions',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      }
    ];

  }

  onReset() {
    this.filters = { clientId: this.currentClientId };
    this.loadPage = (page: number, pageSize: number) => this._rentalPointsService.search(page, pageSize, this.filters);
  }

}
