import { Component, OnInit, Input } from '@angular/core';
import { RoutesGroup } from '../sidebar.component';
import { SharedUtils, UserType } from '../../../../shared/shared-utils';

@Component({
  selector: 'app-nav-items-group',
  templateUrl: './nav-items-group.component.html',
  styleUrls: ['./nav-items-group.component.scss']
})
export class NavItemsGroupComponent implements OnInit {

  public userType: UserType;

  @Input()
  menuGroup: RoutesGroup;

  @Input()
  accessRightsArray: string[];

  constructor() { }

  ngOnInit() {
    this.userType = SharedUtils.getCurrentUserType();
    this.menuGroup.groupItems = this.menuGroup.groupItems.filter(Boolean);
  }

  valueInArray(array: number[], needle: number) {
    return array.indexOf(needle) !== -1;
  }

  isMenuIndexInAccessRightsArray(index: number) {
    return (Number(this.accessRightsArray[index]) === 1);
  }

  rentalPointException(path: string) {
    if ((path.toLocaleLowerCase().includes('return-orders')) && (localStorage.getItem('accountId').toString() !== '127')) {
      // 127 is account id of rental point Lienz_Bahnhof. Only this rental point can access to the menu point return-orders.
      return true;
    } else {
      return false;
    }
  }

}
