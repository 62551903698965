import { Routes } from '@angular/router';
import { TablesComponent } from './tables.component';
import { AuthGuard } from '../../auth-guard.service';
import { TablesListComponent } from './tables-list/tables-list.component';
import { TablesUpdateComponent } from './tables-update/tables-update.component';
import { TablesInsertComponent } from './tables-insert/tables-insert.component';

export const TABLES_ROUTES: Routes = [
    {
        path: 'table-data-management', component: TablesComponent, canActivate: [AuthGuard], children: [
            {path: 'list/:tableCode', component: TablesListComponent, canActivate: [AuthGuard]},
            {path: 'insert/:tableCode', component: TablesInsertComponent, canActivate: [AuthGuard]},
            {path: 'update/:tableCode/:recordId', component: TablesUpdateComponent, canActivate: [AuthGuard]},
        ]
    }
];
