

<div class="container-fluid" style="text-align: center;border-bottom:2px solid #6c757d;">
  <span (click)="loadTable('bikeType')" class="btn btn-sm btn-secondary {{tableCode == 'bikeType' ? 'active' : ''}}">{{'tableData.menu.bikeType' | translate}}</span>&nbsp;
  <span (click)="loadTable('bikeBrand')" class="btn btn-sm btn-secondary {{tableCode == 'bikeBrand' ? 'active' : ''}}">{{'tableData.menu.bikeBrand' | translate}}</span>&nbsp;
  <span (click)="loadTable('bikeModel')" class="btn btn-sm btn-secondary {{tableCode == 'bikeModel' ? 'active' : ''}}">{{'tableData.menu.bikeModel' | translate}}</span>&nbsp;
  <span (click)="loadTable('bikeSize')" class="btn btn-sm btn-secondary {{tableCode == 'bikeSize' ? 'active' : ''}}">{{'tableData.menu.bikeSize' | translate}}</span>&nbsp;
  <span (click)="loadTable('tyreSize')" class="btn btn-sm btn-secondary {{tableCode == 'tyreSize' ? 'active' : ''}}">{{'tableData.menu.tyreSize' | translate}}</span>&nbsp;
  <span (click)="loadTable('bikeColor')" class="btn btn-sm btn-secondary {{tableCode == 'bikeColor' ? 'active' : ''}}">{{'tableData.menu.bikeColor' | translate}}</span>&nbsp;
</div>

<router-outlet></router-outlet>
