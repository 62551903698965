export class Country {
    public id: number;
    public name: string;
    public vat: string;

    public static fromDto(c): Country {
        const country = new Country();
        if (!c) {
            return null;
        }

        country.id = c.id;
        country.name = c.name;
        country.vat = c.vat;

        return country;
    }
}
