// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

const local = false;

export const environment = {
  production: false,
  serverUrl: local ? 'http://localhost:3067/' :  'https://papin2api.rrsolutionstest.it/',
  domain: local ? 'http://localhost:4200/' : 'https://papin2.rrsolutionstest.it/',
};
