<form #filtersForm="ngForm" (submit)="apply()">
    <div class="row">
        <div class="col-12">
            <div class="data-filters ibox-content">
                <ng-content select="app-data-filter-group"></ng-content>
            </div>
        </div>
        <div class="col-12 text-right mt-3">
            <div>
                <div class="btn-group" role="group">
                    <button type="submit" class="btn btn-primary btn-sm">
                        <i class="fa fa-check"></i>
                        {{'data-table.filters.buttons.apply' | translate}}
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm" (click)="reset()">
                        <i class="fa fa-ban"></i>
                        {{'data-table.filters.buttons.reset' | translate}}
                    </button>
                    <button type="button" class="btn btn-success btn-sm" (click)="excelExport()"
                        *ngIf="showExcelExportButton">
                        <i class="fa fa-download"></i>
                        {{'data-table.filters.buttons.excelExport' | translate}}
                    </button>
                    <button type="button" class="btn btn-success btn-sm" (click)="onExcelExportBikeListClickEvent()"
                        *ngIf="showExcelExportBikeListButton">
                        <i class="fa fa-download"></i>
                        {{'data-table.filters.buttons.excelExport' | translate}}
                    </button>
                    <button type="button" style="background-color:purple" class="btn btn-success btn-sm" (click)="onCSVExportBikeListForInsuranceClickEvent()"
                        *ngIf="showCSVExportBikeListForInsuranceButton">
                        <i class="fa fa-file-text-o"></i>
                        Versich.csv
                    </button>
                </div>
                <ng-content select="app-data-filters-button"></ng-content>
            </div>
        </div>
    </div>
</form>
