<div class="container-fluid">
    <div class="row" *ngIf="userType.userTypeNumber !== 2">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col-2" *ngIf="userType.userTypeNumber === 0">
                            <div class="form-group">
                                <label class="control-label">{{'products.bikeStatistics.clients' |
                                    translate}}</label>
                                <select class="form-control form-control-sm" [(ngModel)]="selectedClientId"
                                    (change)="onClientSelectChange()">
                                    <option *ngFor="let client of clientOptions" value="{{client.value}}">
                                        {{client.label}}</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-2">
                            <app-select-search label="{{'products.bikeStatistics.rentalPoints' | translate}}"
                                [options]="rentalPointOptions" [(model)]="selectedRentalPointId">
                            </app-select-search>
                        </div>
                        <div class="col-1">
                            <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick()"
                                style="margin-top: 25px;">{{'products.bikeStatistics.filter' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3" *ngIf="bikeStatistics">
        <div class="col-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h2>{{'products.bikeStatistics.arrivingBikeCount' | translate}}</h2>
                        </div>
                        <div class="col text-right">
                            <h2>{{bikeStatistics.arrivingBikeCount}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <app-bike-statistics-card-item [bikeItems]="bikeStatistics.arrivingBikes"></app-bike-statistics-card-item>
        </div>
        <div class="col-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h2>{{'products.bikeStatistics.arrivedBikeCount' | translate}}</h2>
                        </div>
                        <div class="col text-right">
                            <h2>{{bikeStatistics.arrivedBikeCount}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <app-bike-statistics-card-item [bikeItems]="bikeStatistics.arrivedBikes"></app-bike-statistics-card-item>
        </div>
      </div>
      <div class="row mt-3" *ngIf="bikeStatistics">
        <div class="col-6">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <h2>{{'products.bikeStatistics.rentedBikeCount' | translate}}</h2>
                        </div>
                        <div class="col text-right">
                            <h2>{{bikeStatistics.rentedBikeCount}}</h2>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <app-bike-statistics-card-item [bikeItems]="bikeStatistics.rentedBikes"></app-bike-statistics-card-item>
        </div>
        <div class="col-6">
          <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col">
                          <h2>{{'products.bikeStatistics.returnRentedBikes' | translate}}</h2>
                      </div>
                  </div>
              </div>
          </div>
          <hr />
          <app-bike-statistics-return-rented-card-item [bikeItems]="bikeStatistics.returnRentedBikes"></app-bike-statistics-return-rented-card-item>
      </div>
  </div>
</div>
