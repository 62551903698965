import { Component, OnInit } from '@angular/core';
import { Rental } from '../../../../models/Rental';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../shared/orders.service';
import { ReturnBike } from '../../../../models/ReturnBike';
import { RentalPoint } from '../../../../models/RentalPoint';
import { TranslateService } from '@ngx-translate/core';
import { DayDiffPipe } from '../../shared/pipes/day-diff.pipe';

@Component({
  selector: 'app-return-paper',
  templateUrl: './return-paper.component.html',
  styleUrls: ['./return-paper.component.scss']
})
export class ReturnPaperComponent implements OnInit {

  public contractId: string;
  public rental: Rental = new Rental();
  public bikes: ReturnBike[];
  public startRentalPoint: RentalPoint = new RentalPoint();
  public endRentalPoint: RentalPoint = new RentalPoint();
  public currentLang: string;
  public differentRentalCost = 0;
  public delayDays = 0;

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.contractId = params['contractId'];
      this._ordersService.getReturnPaper(this.contractId).subscribe(result => {
        console.log(result);
        this.rental = result;
        const contractEndRentalPoint = RentalPoint.fromDto(this.rental.endRentalPoint);
        this.startRentalPoint = RentalPoint.fromDto(this.rental.startRentalPoint);
        this.endRentalPoint = RentalPoint.fromDto(this.rental.orderReturn.rentalPointId);
        this.bikes = this.rental.orderReturn.returnBike;
        if (this.startRentalPoint.id === contractEndRentalPoint.id) {
          if (this.endRentalPoint.id !== contractEndRentalPoint.id) {
            this.bikes.forEach(bike => {
              this.differentRentalCost += bike.diffRentalCost;
            });
          }
        }
        const delayDays = new DayDiffPipe().transform(this.rental.startDate, this.rental.orderReturn.returnDate);
        const rentalDays = new DayDiffPipe().transform(this.rental.startDate, this.rental.endDate);
        this.delayDays = delayDays - rentalDays;
      });
    });
  }

}
