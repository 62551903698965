<div id="invoice-print-container" class="container" style="width: 1100px !important; max-width: 1100px !important;">
  <div class="row" id="title-bar">
    <div class="col-4">
      <img [attr.src]="imagePath + '/papin-rent-a-bike-logo.png'" id="invoice-logo" alt="Papin Logo"
        class="img-responsive">
    </div>
    <div class="col-4">
      <ng-container *ngIf="order.startRentalPoint.rentalPoint.country === 1; else italyDataTemplate">
        <p>Papin Sport & Freizeit GmbH <br /> Arnbach 41F <br /> 9920 - Arnbach / Sillian<br /> ATU65441346 </p>
      </ng-container>
      <ng-template #italyDataTemplate>
        <p>
          Papin Sport GmbH / srl <br />
          Freisingerstr. 9 Via Freising <br />
          I-39038 Innichen / San Candido (BZ)<br />
          MwSt.Nr.-P.Iva 00480120211
        </p>
      </ng-template>
    </div>
    <ng-template #italyInformations>
      <div class="col-4">
        <p>
          Papin Sport GmbH / srl <br />
          Freisingerstr. 9 Via Freising <br />
          I-39038 Innichen / San Candido (BZ)<br />
          MwSt.Nr.-P.Iva 00480120211
        </p>
      </div>
    </ng-template>
    <div class="col-4 text-right right-column">
      <p class="invoice-blue">{{'orders.invoice.invoice' | translate}}</p>
      <p class="invoice-blue">{{'orders.invoice.onlineBooking' | translate}}</p>
      <p class="invoice-blue">papinsport.com</p>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <p>{{'orders.invoice.orderNr' | translate}} : {{order.contractId}} </p>
    </div>
    <div class="col-6 text-right">
      <p><span>{{'orders.invoice.date' | translate}}</span>: {{order.placementTime | date:"dd.MM.yyyy"}} -
        <span>{{'orders.invoice.invoiceNumber' | translate}}</span>: {{order.invoiceNo}}</p>
    </div>
  </div>
  <div class="row" id="body-container">
    <div class="col-6">
      <div id="rentail-point-container">
        <div id="bike-rental-box">
          <h4 class="invoice-blue">{{'orders.invoice.startRentalPoint' | translate}}</h4>
          <p>
            {{pickUpRentalPoint.rentalPoint.businessName}}<br />
            {{pickUpRentalPoint.rentalPoint.street}}<br />
            {{pickUpRentalPoint.rentalPoint.region}} - {{countries[pickUpRentalPoint.rentalPoint.country]}}
            <br /><br />
            Tel. : {{pickUpRentalPoint.rentalPoint.phone}}<br />
            E-Mail: order&#64;papinsport.com
          </p>
        </div>
        <div id="return-bike-rental-box">
          <h4 class="invoice-blue">{{'orders.invoice.returnRentalPoint' | translate}}</h4>
          <p>
            {{returnRentalPoint.rentalPoint.businessName}}<br />
            {{returnRentalPoint.rentalPoint.street}}<br />
            {{returnRentalPoint.rentalPoint.region}} - {{countries[returnRentalPoint.rentalPoint.country]}}
            <br /><br />
            Tel. : {{returnRentalPoint.rentalPoint.phone}}<br />
            E-Mail: order&#64;papinsport.com
          </p>
        </div>
      </div>
      <div id="rental-period-box">
        <h4 class="invoice-blue">{{'orders.invoice.rentalPeriod' | translate}}</h4>
        <p>
          {{'orders.invoice.from' | translate}}: <strong>{{order.startDate | date:"dd.MM.yyyy"}}</strong><br />
          {{'orders.invoice.to' | translate}}: <strong>{{order.endDate | date:"dd.MM.yyyy"}}</strong>
        </p>
      </div>
    </div>
    <div class="col-6">
      <div class="text-right">
        <ngx-barcode6 [bc-value]="order.contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1"></ngx-barcode6>
      </div>
      <div id="custumer-box">
        <h4 class="invoice-blue">{{'orders.invoice.customer' | translate}}</h4>
        <div *ngIf="order.onlineBooking; else reservationData">
          <p *ngIf="!order.invoice.individual; else individualData">
              {{order.invoice.company.companyName | titlecase}}<br />
              {{order.invoice.company.vatNumber }}<br />
              <ng-container *ngIf="order.invoice.company.taxNumber">
                {{order.invoice.company.taxNumber}}<br/>
              </ng-container>
              {{order.invoice.company.streetName| titlecase}} {{order.invoice.company.streetNumber}}<br/>
              {{order.invoice.company.postalCode}} {{order.invoice.company.village| titlecase}}<br/>
              {{order.invoice.company.country| titlecase}}<br/>
          </p>
          <ng-template #individualData>
              {{order.invoice.individual.firstName| titlecase}} {{order.invoice.individual.lastName| titlecase}}<br />
              {{order.invoice.individual?.taxNumber}}<br *ngIf="order.invoice.individual?.taxNumber"/>
              {{order.invoice.individual.streetName| titlecase}} {{order.invoice.individual.streetNumber}}<br/>
              {{order.invoice.individual.postalCode}} {{ order.invoice.individual.village| titlecase}}<br/>
              {{order.invoice.individual.country| titlecase}}<br/>
          </ng-template>
        </div>
        <ng-template #reservationData>
          <p>
            {{order.clientInformation.firstName}} {{order.clientInformation.lastName}}<br />
            {{order.clientInformation.address}}<br/>
          </p>
        </ng-template>
      </div>
      <div id="rental-duratin-box" class=text-right>
        <h4 class="invoice-blue">{{'orders.invoice.rentalDuration' | translate}}</h4>
        <p id="">
          <strong>{{daysDiff}} {{'orders.invoice.days' | translate}}</strong>
        </p>
      </div>
    </div>
    <div class="col-12" id="order-table-container">
      <table class="table">
        <thead>
          <td>{{'orders.invoice.bikeType' | translate}}</td>
          <td>{{'orders.invoice.quantity' | translate}}</td>
          <td>{{'orders.invoice.priceLordo' | translate}}</td>
          <td>{{'orders.invoice.rentalDays' | translate}}</td>
          <td class="text-center">{{'orders.invoice.fullPrice' | translate}}</td>
        </thead>
        <tbody>
          <tr *ngFor="let bike of order.invoiceBike">
            <td>{{bike.bikeType.name}}</td>
            <td>{{bike.quantity}}</td>
            <td>{{bike.bikePrice | currency : "€ "}}</td>
            <td>{{daysDiff}}</td>
            <td class="text-center">
              <span>{{(bike.quantity * daysDiff * bike.bikePrice) | currency : "€ "}}</span>
            </td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td>
              <p>{{'orders.invoice.extraChargeDifferentReturnPoint' | translate}}</p>
              <ng-container *ngIf="order.totalPrice > order.discountedPrice">
                <p>{{'orders.invoice.discount' | translate}}</p>
              </ng-container>
              <!-- <ng-container *ngIf="order.totalPrice == order.discountedPrice"> -->
              <p>{{'orders.invoice.netProductPrice' | translate}}</p>
              <!-- </ng-container> -->
              <ng-container *ngIf="order.discountedBookingPrice">
                <p>{{'orders.invoice.netPriceWithOnlineDiscount' | translate}}</p>
              </ng-container>
            </td>
            <td class="text-center">
              <p>{{order.costForBikes | currency : "€ "}}</p>
              <ng-container *ngIf="order.totalPrice > order.discountedPrice">
                <p>
                  - {{ order.totalPrice - order.discountedPrice | currency : "€ " }}
                </p>
              </ng-container>
              <ng-container *ngIf="order.totalPrice == order.discountedPrice; else discountTemplate">
                <!-- Ivan fixed task PAP-540 on 25-07-2022 -->
                <!--
                <p>{{ (calculateVatPrice(order.totalPrice) - calculateVatPrice(order.costForBikes)) | currency : "€ "}}
                </p>
                -->
                <p>{{ calculateVatPrice(order.discountedPrice) | currency : "€ "}}</p>
              </ng-container>
              <ng-template #discountTemplate>
                <p>
                  {{ calculateNetPrice() | currency : "€ "}}
                </p>
              </ng-template>
              <ng-container *ngIf="order.discountedBookingPrice">
                <p>
                  {{ calculateNetPrice() * 0.98 | currency : "€ "}}
                </p>
              </ng-container>
            </td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td>
              <p>{{'orders.invoice.vat' | translate}} {{(pickUpRentalPoint.rentalPoint.vat * 100) | number:'1.2-2'}} %
              </p>
            </td>
            <td class="text-center">
              <ng-container *ngIf="order.discountedBookingPrice; else noOnlineDiscountTemplate">
                <p>{{ calculateTaxablePrice(order.discountedBookingPrice) | currency : "€ "}}</p>
              </ng-container>
              <ng-template #noOnlineDiscountTemplate>
                <p>{{ calculateTaxablePrice(order.discountedPrice) | currency : "€ "}}</p>
              </ng-template>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><strong translate>{{'orders.invoice.totalAmountVATPayedCreditCard' | translate}}</strong></td>
            <td colspan="3"></td>
            <td class="text-center">
              <ng-container *ngIf="order.discountedBookingPrice; else elseNoOnlineDiscountTemplate">
                <strong>{{calculateTotalAmountVatInclusive(order.discountedBookingPrice) | currency : "€ "}}</strong>
              </ng-container>
              <ng-template #elseNoOnlineDiscountTemplate>
                <strong>{{calculateTotalAmountVatInclusive(order.discountedPrice) | currency : "€ "}}</strong>
              </ng-template>
            </td>
          </tr>
        </tfoot>
      </table>
      <div id="print-invoice">
        <a class="btn btn-primary btn-round"
          (click)="downloadPdf()">{{'orders.invoice.printInvoice' | translate}}</a>
      </div>
    </div>
  </div>
</div>
