<div class="container" style="padding-bottom: 20px;">
  <div class="row">
    <div class="col">
      <div class="card" id="return-paper">
        <div class="card-body">
          <blockquote class="blockquote text-center" >
            <p class="mb-0" style="font-weight: normal;">
              {{'orders.papers.returnPaper.rückgabebestätigungAusleih' | translate}}</p>
          </blockquote>
          <ng-container *ngIf="endRentalPoint.country === 1; else italyTitleTemplate">
            <blockquote class="blockquote text-center" >
              <p class="mb-0">Papin Sport & Freizeit GmbH <br /> Arnbach 41F 9920 Arnbach / Sillian<br />
                ATU65441346 </p>
            </blockquote>
          </ng-container>
          <ng-template #italyTitleTemplate>
            <blockquote class="blockquote text-center" >
              <p class="mb-0">
                Papin Sport GmbH / srl <br />
                Freisingerstr. 9 Via Freising <br />
                I-39038 Innichen / San Candido (BZ)<br />
                MwSt.Nr.-P.Iva 00480120211
              </p>
            </blockquote>
          </ng-template>
          <div class="text-center mt-2 mb-2">
            <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="papin-logo" alt="Papin Logo"
              class="img-responsive" height="65">
          </div>
          <div class="text-center">
            <ngx-barcode6 [bc-value]="contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1"></ngx-barcode6>
          </div>

          <div id="contract-details" class="mt-3">
            <dl class="row">
              <dt class="col-6">{{'orders.papers.returnPaper.contractNumber' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.contractId}}</dd>
              <dt class="col-6">{{'orders.papers.returnPaper.clientName' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.clientInformation.firstName}} {{rental.clientInformation.lastName}}
              </dd>
              <dt class="col-6">{{'orders.papers.returnPaper.from' | translate}}:</dt>
              <dd class="col-6 text-right">{{startRentalPoint.rentalPointTranslation[currentLang]}}</dd>
              <dt class="col-6">{{'orders.papers.returnPaper.to' | translate}}:</dt>
              <dd class="col-6 text-right">{{endRentalPoint.rentalPointTranslation[currentLang]}}</dd>
              <dt class="col-6">{{'orders.papers.returnPaper.start' | translate}}:</dt>
              <!--<dd class="col-6 text-right">{{rental.startDate | date: 'dd-MM-yyyy'}} {{rental.pickUpTime}}</dd>-->
              <dd class="col-6 text-right">{{rental.contractDate}}</dd>
              <dt class="col-6">{{'orders.papers.returnPaper.end' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.orderReturn.returnDate | date: 'dd-MM-yyyy'}}
                {{rental.orderReturn.returnTime}}</dd>
              <dt class="col-6">{{'orders.papers.returnPaper.duration' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.startDate | dayDiff:rental.orderReturn.returnDate}}
                {{'orders.papers.returnPaper.day' | translate}} /
                {{'orders.papers.returnPaper.days' | translate}}</dd>
              <ng-container *ngIf="rental.discountCard">
                <dt class="col-6">{{'orders.papers.returnPaper.discountCard' | translate}}:</dt>
                <dd class="col-6 text-right">{{(rental.discountCard ? rental.discountCard.name : '&nbsp;')}}</dd>
              </ng-container>
            </dl>
          </div>
          <div id="bikes-list">
            <table class="table table-borderless">
              <tbody>
                <tr *ngFor="let bike of bikes;">
                  <td *ngIf="bike.returnType === 0">
                    <ng-container *ngIf="!bike.replaced; else replacedBikeTemplate">
                      <strong>1 {{bike.bikeType.bikeTypeTranslation[currentLang]}}
                        {{(bike.qrCode ? '(' + bike.qrCode + ')' : '')}}</strong>
                    </ng-container>
                    <ng-template #replacedBikeTemplate>
                      <strong>1 {{bike.bikeType.bikeTypeTranslation[currentLang]}}
                        {{(bike.qrCode ? '(' + bike.qrCode + ')' : '')}} ->
                        {{bike.replacedBikeTypeId.bikeTypeTranslation[currentLang]}}
                        {{(bike.replacedQRCode ? '(' + bike.replacedQRCode + ')' : '')}}</strong>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="delay-credit-container">
            <div class="row" *ngIf="delayDays > 0">
              <dt class="col-6">{{'orders.papers.returnPaper.delay' | translate}}:</dt>
              <dd class="col-6 text-right">{{delayDays}}
                {{(delayDays > 1 ? 'orders.papers.returnPaper.days' : 'orders.papers.returnPaper.day' ) | translate}}
              </dd>
            </div>
            <div class="row" *ngIf="rental.orderReturn.delayPrice > 0">
              <dt class="col-6">{{'orders.papers.returnPaper.delayCost' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.orderReturn.delayPrice | currency: '€ '}}</dd>
            </div>
            <div class="row" *ngIf="differentRentalCost > 0">
              <dt class="col-6">{{'orders.papers.returnPaper.differentRental' | translate}}:</dt>
              <dd class="col-6 text-right">{{differentRentalCost | currency:'€ '}}</dd>
            </div>
            <ng-container *ngIf="rental.orderReturn.credit !==0 || rental.orderReturn.delayPrice !==0">

              <hr class="dashed" />
              <div class="row">
                <ng-container *ngIf="rental.orderReturn.credit > 0; else delayTemplate">
                  <dt class="col-6">{{'orders.papers.returnPaper.guthaben' | translate}}:</dt>
                  <dd class="col-6 text-right">{{rental.orderReturn.credit | currency:'€ '}}</dd>
                </ng-container>
                <ng-template #delayTemplate>
                  <dt class="col-6">{{'orders.papers.returnPaper.openAmount' | translate}}:</dt>
                  <dd class="col-6 text-right">{{(rental.orderReturn.delayPrice + differentRentalCost) | currency:'€ '}}
                  </dd>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
