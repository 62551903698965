import { Component, OnInit } from '@angular/core';
import { ClientsService } from '../shared/clients.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Client } from '../../../models/Client';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.scss']
})
export class AddClientComponent implements OnInit {

  public addClientForm: FormGroup;
  public errorArray = [];
  private _translate: TranslateService;

  constructor(private _clientsService: ClientsService, public _snackBar: MatSnackBar) {

    this.addClientForm = new FormGroup({
      id: new FormControl(),
      address: new FormControl(),
      businessName: new FormControl(),
      phone: new FormControl(),
      vatNumber: new FormControl(),
      accountId: new FormControl(),
      deleted: new FormControl(false),
      account: new FormGroup({
        id: new FormControl(),
        accountType: new FormControl(),
        clientId: new FormControl(),
        deleted: new FormControl(),
        parentId: new FormControl(),
        userType: new FormControl(),
        rentalPoint: new FormControl(),
        rentalPointId: new FormControl(),
        username: new FormControl(),
        email: new FormControl(),
        newPassword: new FormControl(),
        lastName: new FormControl(),
        firstName: new FormControl(),
      })
    });
  }

  ngOnInit() {
  }

  addClientSubmit() {
    if (this.addClientForm.valid) {
      const newClient = Client.fromDto(this.addClientForm.value);
      if (this.addClientForm.value.account.newPassword !== null) {
        newClient.account.password = this.addClientForm.value.account.newPassword;
      }
      this._clientsService.addClient(newClient).subscribe(result => {
        this.addClientForm.reset();

        this._snackBar.open(this._translate.instant('clients.addClients.clientAdded'), '', {
          duration: 3500,
        });
      },
      (error => {
        switch (error.status) {
          case 400:
            this._snackBar.open('Username already exists', '', {
              duration: 2500
            });
            break;
          case 600:
            this._snackBar.open('Internal server error', '', {
              duration: 2500
            });
            break;
        }
      }));
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.addClientForm, this.addClientForm.controls);
  }

}
