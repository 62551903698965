import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BikeTypeSearch } from '../../../models/BikeType';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { ValidationUtils } from '../../../shared/validation.utils';
import { TablesService } from '../shared/tables.service';

@Component({
  selector: 'app-tables-insert',
  templateUrl: './tables-insert.component.html',
  styleUrls: ['./tables-insert.component.scss']
})
export class TablesInsertComponent implements OnInit {

  public tableCode: string;
  public id: string;
  public tableForm: FormGroup;
  public errorArray = [];
  public productTypeOptions: Option[] = [];

  constructor(private _translate: TranslateService, private _route: ActivatedRoute, private _tablesService: TablesService,
    public _snackBar: MatSnackBar, private _formBuilder: FormBuilder, private _router: Router) {

      this._route.params.subscribe(params => {
        this.tableCode = params['tableCode'];
        this.id = params['recordId'];
      });

    }

  public ngOnInit(): void {

      switch (this.tableCode) {
        case 'bikeType':
          this._setFormBikeType();
          break;
        case 'bikeBrand':
          this._setFormBikeBrand();
          break;
        case 'bikeModel':
          this._setFormBikeModel();
          break;
        case 'bikeSize':
          this._setFormBikeSize();
          break;
        case 'tyreSize':
          this._setFormTyreSize();
          break;
        case 'bikeColor':
          this._setFormBikeColor();
          break;
      }
  }


  public onSubmitHandler() {
    this.tableForm.markAllAsTouched();
    if (this.tableForm.valid) {
      switch (this.tableCode) {
        case 'bikeType':
          this._insertBikeType();
        break;
        case 'bikeBrand':
          this._insertBikeBrand();
        break;
        case 'bikeModel':
          this._insertBikeModel();
        break;
        case 'bikeSize':
          this._insertBikeSize();
        break;
        case 'tyreSize':
          this._insertTyreSize();
        break;
        case 'bikeColor':
          this._insertBikeColor();
        break;
      }
    } else {
      this._manageErrors();
    }

  }

  private _setFormBikeType() {
    this.tableForm = this._formBuilder.group({
      bikeType_id: [{ value: '', disabled: true }, <any>Validators.required],
      bikeType_name_en: ['', <any>Validators.required],
      bikeType_name_it: ['', <any>Validators.required],
      bikeType_name_de: ['', <any>Validators.required],
      bikeType_bikeCost: ['', [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      bikeType_productType: [1, [Validators.required, Validators.min(1), Validators.max(2), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      // bikeType_bikeAvailabilityPeriod: [null, [Validators.required, Validators.min(0), Validators.pattern('^(0|[1-9][0-9]*)$')]],
    });

    this.productTypeOptions.push({ value: 1, label: 'bike' }, { value: 2, label: 'equipment' });

  }

  private _setFormBikeBrand() {
    this.tableForm = this._formBuilder.group({
      bikeBrand_id: [{ value: '', disabled: true }, <any>Validators.required],
      bikeBrand_name: ['', <any>Validators.required]
    });

  }

  private _setFormBikeModel() {
    this.tableForm = this._formBuilder.group({
      bikeModel_id: [{ value: '', disabled: true }, <any>Validators.required],
      bikeModel_name: ['', <any>Validators.required]
    });

  }

  private _setFormBikeSize() {
    this.tableForm = this._formBuilder.group({
      bikeSize_id: [{ value: '', disabled: true }, <any>Validators.required],
      bikeSize_size: ['', <any>Validators.required]
    });

  }

  private _setFormTyreSize() {
    this.tableForm = this._formBuilder.group({
      tyreSize_id: [{ value: '', disabled: true }, <any>Validators.required],
      tyreSize_size: ['', <any>Validators.required]
    });

  }

  private _setFormBikeColor() {
    this.tableForm = this._formBuilder.group({
      bikeColor_id: [{ value: '', disabled: true }, <any>Validators.required],
      bikeColor_name: ['', <any>Validators.required]
    });

  }

  private _insertBikeType() {
    const bikeTypeObject: BikeTypeSearch = new BikeTypeSearch();
    bikeTypeObject.id = this.tableForm.controls['bikeType_id'].value;
    bikeTypeObject.name_en = this.tableForm.controls['bikeType_name_en'].value;
    bikeTypeObject.name_it = this.tableForm.controls['bikeType_name_it'].value;
    bikeTypeObject.name_de = this.tableForm.controls['bikeType_name_de'].value;
    bikeTypeObject.bikeCost = this.tableForm.controls['bikeType_bikeCost'].value;
    bikeTypeObject.productType = this.tableForm.controls['bikeType_productType'].value;
    bikeTypeObject.bikeAvailabilityPeriod = 3;

    console.log('bikeTypeObject:', bikeTypeObject);

    this._tablesService.insertBikeType(bikeTypeObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _insertBikeBrand() {
    const bikeBrandObject: any = {};
    bikeBrandObject.type = 'brand'; // fixed
    bikeBrandObject.id = this.tableForm.controls['bikeBrand_id'].value;
    bikeBrandObject.name = this.tableForm.controls['bikeBrand_name'].value;
    bikeBrandObject.size = null;
    console.log('bikeBrandObject:', bikeBrandObject);

    this._tablesService.insertBikeProperty(bikeBrandObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _insertBikeModel() {
    const bikeModelObject: any = {};
    bikeModelObject.type = 'model'; // fixed
    bikeModelObject.id = this.tableForm.controls['bikeModel_id'].value;
    bikeModelObject.name = this.tableForm.controls['bikeModel_name'].value;
    bikeModelObject.size = null;
    console.log('bikeModelObject:', bikeModelObject);

    this._tablesService.insertBikeProperty(bikeModelObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _insertBikeSize() {
    const bikeSizeObject: any = {};
    bikeSizeObject.type = 'size'; // fixed
    bikeSizeObject.id = this.tableForm.controls['bikeSize_id'].value;
    bikeSizeObject.name = null;
    bikeSizeObject.size = this.tableForm.controls['bikeSize_size'].value;
    console.log('bikeSizeObject:', bikeSizeObject);

    this._tablesService.insertBikeProperty(bikeSizeObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _insertTyreSize() {
    const tyreSizeObject: any = {};
    tyreSizeObject.type = 'tyreSize'; // fixed
    tyreSizeObject.id = this.tableForm.controls['tyreSize_id'].value;
    tyreSizeObject.name = null;
    tyreSizeObject.size = this.tableForm.controls['tyreSize_size'].value;
    console.log('tyreSizeObject:', tyreSizeObject);

    this._tablesService.insertBikeProperty(tyreSizeObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _insertBikeColor() {
    const bikeColorObject: any = {};
    bikeColorObject.type = 'color'; // fixed
    bikeColorObject.id = this.tableForm.controls['bikeColor_id'].value;
    bikeColorObject.name = this.tableForm.controls['bikeColor_name'].value;
    bikeColorObject.size = null;
    console.log('bikeColorObject:', bikeColorObject);

    this._tablesService.insertBikeProperty(bikeColorObject).subscribe({
        next: (result) => {
          this._manageAfterInsert(result);
        },
        error: (error) => {
          this._manageApiError(error);
        }
    });
  }

  private _manageAfterInsert(result: any) {
    this.tableForm.clearValidators();
    this.errorArray = [];
    this._snackBar.open(this._translate.instant('tableData.form.messageInsertCompleted'), '', {
      duration: 2000,
    }).afterDismissed().subscribe(() => {
      this._router.navigate(['/table-data-management/list/', this.tableCode]);
    });
  }

  private _manageApiError(error: any) {
    this.tableForm.clearValidators();
    this.errorArray = [];
    this._snackBar.open(this._translate.instant('Internal server error!/n' + error.error), '', {
      duration: 3500,
    });
  }

  private _manageErrors() {
    this.errorArray = ValidationUtils.getFormValidationErrors(this.tableForm, this.tableForm.controls);
    this._snackBar.open(this._translate.instant('tableData.form.messageInsertError'), '', {
      duration: 3500,
    }).afterDismissed().subscribe(() => {
      this.tableForm.clearValidators();
      this.errorArray = [];
    });
  }

}
