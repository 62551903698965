<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="updateClientForm" (ngSubmit)="updateCientSubmit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>{{'clients.editClient.businessName' | translate}}*</label>
                  <input type="text" name="businessName" id="businessName" formControlName="businessName" required
                    placeholder="{{'clients.editClient.businessName' | translate}}"
                    class="form-control {{errorArray['businessName'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['businessName']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'clients.editClient.phone' | translate}}</label>
                  <input type="text" name="phone" id="phone" formControlName="phone"
                    placeholder="{{'clients.editClient.phone' | translate}}"
                    class="form-control {{errorArray['phone'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['phone']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'clients.editClient.address' | translate}}</label>
                  <input type="text" name="address" id="address" formControlName="address"
                    placeholder="{{'clients.editClient.address' | translate}}"
                    class="form-control {{errorArray['address'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['address']}}
                  </div>
                </div>
              </div>
              <div class="col">
                <fieldset formGroupName="account">
                  <div class="form-group">
                    <label>{{'clients.editClient.userName' | translate}} *</label>
                    <input type="text" name="username" id="username" formControlName="username" required
                      placeholder="{{'clients.editClient.userName' | translate}}"
                      class="form-control {{errorArray['username'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['username']}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{'clients.editClient.firstName' | translate}}*</label>
                    <input type="text" name="firstName" id="firstName" formControlName="firstName" required
                      placeholder="{{'clients.editClient.firstName' | translate}}"
                      class="form-control {{errorArray['firstName'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['firstName']}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{'clients.editClient.lastName' | translate}}*</label>
                    <input type="text" name="lastName" id="lastName" formControlName="lastName" required
                      placeholder="{{'clients.editClient.lastName' | translate}}"
                      class="form-control {{errorArray['lastName'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['lastName']}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{'clients.editClient.email' | translate}}*</label>
                    <input type="email" name="email" id="email" formControlName="email" required
                      placeholder="{{'clients.editClient.email' | translate}}"
                      class="form-control {{errorArray['email'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['email']}}
                    </div>
                  </div>
                  <div class="form-group">
                    <label>{{'clients.editClient.password' | translate}}</label>
                    <input type="password" name="newPassword" id="newPassword" formControlName="newPassword"
                      placeholder="{{'clients.editClient.password' | translate}}"
                      class="form-control {{errorArray['newPassword'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['newPassword']}}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary">{{'clients.editClient.update' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>