<div style="scroll-behavior: smooth">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <app-data-filter-group>
                <app-data-filter-select label="{{'statements.cashflow.clients' | translate}}"
                  [(model)]="filters.clientId" [options]="clientOptions"
                  *ngIf="currentUserType.userTypeNumber === 0;"
                  (modelChange)="onClientSelectChange($event)">
                </app-data-filter-select>
                <app-data-filter-select-search label="{{'statements.cashflow.rentalPoints' | translate}}"
                 [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
                 *ngIf="currentUserType.userTypeNumber !== 2">
                </app-data-filter-select-search>
                <app-data-filter-datepicker label="{{'statements.cashflow.from' | translate}}" [(model)]="filters.startDate"></app-data-filter-datepicker>
                <app-data-filter-datepicker label="{{'statements.cashflow.to' | translate}}" [(model)]="filters.endDate"></app-data-filter-datepicker>
              </app-data-filter-group>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col text-right">
              <div class="btn-group btn-group-sm" role="group">
                <button type="button" class="btn btn-primary"
                        (click)="onApplyButtonClick()">{{'statements.cashflow.apply' | translate}}</button>
                <button type="button" class="btn btn-secondary"
                        (click)="onResetButtonClick()">{{'statements.cashflow.reset' | translate}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="!filters.clientId || !filters.rentalPointAccountId" style="display: flex; text-align: center" class="mt-3">
    <span>{{'rentalPoint.shared.missingRentalPoint' | translate}}</span>
  </div>
  <div class="row mt-3" *ngIf="!showResultContent && !firstLoading">
    <div class="col">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3" *ngIf="showResultContent">
    <span style="font-weight: bold">{{'rentalPoint.shared.goToBike' | translate}}</span>
    <div style="display: flex; flex-wrap: wrap; gap: 10px;">
      <a *ngFor="let data of chartData" [routerLink]="[]" [fragment]="data.id" [style.color]="getColor(data.id)">{{data.bikeType}}</a>
    </div>
    <ng-container *ngFor="let data of chartData;">
      <h5 style="text-align: center" [attr.id]="data.id" class="mt-3">{{data.bikeType}}</h5>
      <kendo-chart>
        <kendo-chart-area [height]="450"></kendo-chart-area>
        <kendo-chart-series-defaults type="area" [highlight]="{ inactiveOpacity: 0.3 }"></kendo-chart-series-defaults>
        <kendo-chart-category-axis>
          <kendo-chart-category-axis-item [categories]="data.labels"></kendo-chart-category-axis-item>
        </kendo-chart-category-axis>
        <kendo-chart-series>
          <kendo-chart-series-item type="area" [data]="data.contingents" [color]="'#a1d8ff'"></kendo-chart-series-item>
          <kendo-chart-series-item type="area" [data]="data.rentals" [color]="'#1f4d9a'"></kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </ng-container>
  </div>
</div>
