import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { PriceList } from '../../../models/PriceList';
import { PricePerPeriod } from '../../../models/PricePerPeriod';

@Injectable({
  providedIn: 'root'
})
export class PriceListService {

  constructor(private _http: HttpClient) { }

  public getPriceLists(): Observable<PriceList[]> {
    return this._http.get(environment.serverUrl + 'api/getPriceList', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => PriceList.fromDto(res)))
    );
  }

  public getPriceList(priceListId): Observable<PriceList> {
    return this._http.get(environment.serverUrl + 'api/getPriceList', {
      headers: HttpUtils.createHeaders(), params: { priceListId }
    }).pipe(
      map((result: any[]) => PriceList.fromDto(result))
    );
  }

  public updatePricePerPeriod(pricePerPeriods: PricePerPeriod[]): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/updatePrice', { pricePerPeriods }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }
}
