import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { SearchObjectResult } from '../../../models/SearchObject';
import { WartungUsersSearchCriteria } from '../shared/WartungUsersSearchCriteria';
import { WartungUsersService } from '../shared/wartung-users.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wartung-users-list',
  templateUrl: './wartung-users-list.component.html',
  styleUrls: ['./wartung-users-list.component.scss']
})
export class WartungUsersListComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  filters: WartungUsersSearchCriteria = {};

  @ViewChild('email', { static: true })
  email: TemplateRef<any>;

  @ViewChild('username', { static: true })
  username: TemplateRef<any>;

  @ViewChild('firstName', { static: true })
  firstName: TemplateRef<any>;

  @ViewChild('lastName', { static: true })
  lastName: TemplateRef<any>;

  @ViewChild('status', { static: true })
  status: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];
  public clientOptions: Option[] = [];
  public statusOptions: Option[] = [];

  constructor(
    private translate: TranslateService,
    private _wartungUserService: WartungUsersService,
    private _modalService: NgbModal,
  ) {
    this.statusOptions = [
      { value: true, label: this.translate.instant('wartungUser.active') },
      { value: false, label: this.translate.instant('wartungUser.inactive') }
    ];
    this.setColumns();
  }

  ngOnInit() {
    this._wartungUserService.getWartungClients().subscribe({
      next: (result) => {
        this.clientOptions.push(...result.map(client => ({ value: client.id, label: client.businessName })));
        this.filters = {
          clientId: this.clientOptions[0].value,
          status: this.statusOptions[0].value
        };
        this.loadPage = (page: number, pageSize: number) => this._wartungUserService.search(page, pageSize, this.filters);
        this.setColumns();
      },
      error: () => console.log('Unable to fetch one or more filters'),
    });
  }

  public beforeSubmit() {
    if (!this.filters.clientId && this.clientOptions[0]?.value) {
      this.filters.clientId = this.clientOptions[0].value;
    }
    if (this.filters.status === undefined) {
      this.filters.status = this.statusOptions[0].value;
    }
  }

  private setColumns() {
    this.columns = [
      {
        name: 'email',
        displayed: true,
        translationKey: 'wartungUser.email',
        template: this.email,
        sortable: true,
        excel: {
          objectRef: ['email']
        }
      },
      {
        name: 'username',
        displayed: true,
        translationKey: 'wartungUser.username',
        template: this.username,
        sortable: true,
        excel: {
          objectRef: ['username']
        }
      },
      {
        name: 'firstName',
        displayed: true,
        translationKey: 'wartungUser.firstName',
        template: this.firstName,
        sortable: true,
        excel: {
          objectRef: ['firstName']
        }
      },
      {
        name: 'lastName',
        displayed: true,
        translationKey: 'wartungUser.lastName',
        template: this.lastName,
        sortable: true,
        excel: {
          objectRef: ['lastName']
        }
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'wartungUser.status',
        template: this.status,
        sortable: true,
        excel: {
          objectRef: ['status']
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
      }
    ];
  }

}
