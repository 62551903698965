import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RentalPointSearchCriteria } from './RentalPointSearchCriteria';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { RentalPoint } from '../../../models/RentalPoint';
import { RealBikeQuantitiesLeakSearchCriteria } from './RealBikeQuantitiesLeakSearchCriteria';
import { RealBikeQuantitiesExcessSearchCriteria } from './RealBikeQuantitiesExcessSearchCriteria';
import { ExcessLack } from '../../../models/ExcessLack';
import { Account } from '../../../models/Account';
import { District } from '../../../models/District';
import { Country } from '../../../models/Country';
import { PriceList } from '../../../models/PriceList';
import { DiscountCard } from '../../../models/DiscountCard';
import { BikeType } from '../../../models/BikeType';
import { ProductsPerRentalPoint } from '../../../models/ProductsPerRentalPoint';
import { RentalPointSchedule } from '../../../models/RentalPointSchedule';
import { ScheduleException } from '../../../models/ScheduleException';
import { BikeTypeAvalibities } from '../../../models/BikeTypeAvalibities';

@Injectable({
  providedIn: 'root'
})
export class RentalPointsService {

  constructor(private _http: HttpClient) { }


  /**
   * getRentalPoint
   */
  public getRentalPoint(rentalPointId): Observable<Account> {
    return this._http.get(environment.serverUrl + 'api/getRentalPoint', {
      headers: HttpUtils.createHeaders(), params: { rentalPointId }
    }).pipe(
      map((result: any) => Account.fromDto(result))
    );
  }

  /**
  * getRentalPointByAccountId
  */
  public getRentalPointByAccountId(accountId): Observable<Account> {
    return this._http.get(environment.serverUrl + 'api/getRentalPointByAccountId', {
      headers: HttpUtils.createHeaders(), params: { accountId }
    }).pipe(
      map((result: any) => Account.fromDto(result))
    );
  }

  /**
   * getScheduleOfRentalPoint
   */
  public getScheduleOfRentalPoint(rentalPointId): Observable<RentalPointSchedule> {
    return this._http.get(environment.serverUrl + 'api/getScheduleOfRentalPoint', {
      headers: HttpUtils.createHeaders(), params: { rentalPointId }
    }).pipe(
      map((result: any) => RentalPointSchedule.fromDto(result))
    );
  }

  /**
   * getDistricts
   */
  public getDistricts(): Observable<District[]> {
    return this._http.get(environment.serverUrl + 'api/getDistricts', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => District.fromDto(res)))
    );
  }

  /**
   * getCountries
   */
  public getCountries(lang?: string): Observable<Country[]> {
    const options = {
      headers: HttpUtils.createHeaders(),
      params: {
        lang: lang ?? 'en'
      }
    };

    return this._http.get(environment.serverUrl + 'api/getCountries', options).pipe(
      map((result: any[]) => result.map(res => Country.fromDto(res)))
    );
  }

  /**
   * getCountries
   */
  public getPriceList(): Observable<PriceList[]> {
    return this._http.get(environment.serverUrl + 'api/getPriceList',
      { headers: HttpUtils.createHeaders() }).pipe(
        map((result: any[]) => result.map(res => PriceList.fromDto(res))));
  }

  /**
   * getDiscoutCards
   */
  public getDiscoutCards(): Observable<DiscountCard[]> {
    return this._http.get(environment.serverUrl + 'api/getDiscoutCards',
      { headers: HttpUtils.createHeaders() }).pipe(
        map((result: any[]) => result.map(res => DiscountCard.fromDto(res))));
  }

  /**
   * getBikeTypes
   */
  public getBikeTypes(): Observable<BikeType[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeTypes',
      { headers: HttpUtils.createHeaders() }).pipe(
        map((result: any[]) => result.map(res => BikeType.fromDto(res))));
  }

  /**
   * getContingentProductsPerRentalPoint
   */
  public getContingentProductsPerRentalPoint(accountId): Observable<ProductsPerRentalPoint[]> {
    return this._http.get<any>(environment.serverUrl + 'api/getContingentProductsPerRentalPoint', {
      headers: HttpUtils.createHeaders(),
      params: { accountId }
    }).pipe(
      map((result: any[]) => result.map(res => ProductsPerRentalPoint.fromDto(res)))
    );
  }

  public updateContingentProductsPerRentalPoint(contingents: any[]): any {
    return this._http.post<any>(
      environment.serverUrl + 'api/updateContingentProductsPerRentalPoint', { contingents },
      { headers: HttpUtils.createHeaders() }
    );
  }

  /**
   * addScheduleException
   */
  public addScheduleException(exception: ScheduleException, rentalPointScheduleId: number): Observable<ScheduleException> {
    return this._http.post<any>(
      environment.serverUrl + 'api/addScheduleException', { exception, rentalPointScheduleId },
      { headers: HttpUtils.createHeaders() }
    ).pipe(
      map((result: any) => ScheduleException.fromDto(result))
    );
  }

  /**
   * deleteScheduleExceptionById
   */
  public deleteScheduleExceptionById(id: number) {
    return this._http.post<any>(
      environment.serverUrl + 'api/deleteScheduleException', { id },
      { headers: HttpUtils.createHeaders() }
    );
  }

  /**
   * addContingentException
   */
  public addContingentException(contingentException) {
    return this._http.post<any>(
      environment.serverUrl + 'api/addContingentException', { contingentException },
      {
        headers: HttpUtils.createHeaders()
      }
    );
  }

  /**
   * addContingentException
   */
  public deleteBikeContingentException(exceptionId) {
    return this._http.post<any>(
      environment.serverUrl + 'api/deleteBikeContingentException', { exceptionId },
      {
        headers: HttpUtils.createHeaders()
      }
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: RentalPointSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchRentalPoints', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, RentalPoint.fromDto);
      })
    );
  }

  public getRealBikeQuantitiesLeak(page: number, size: number, criteria: RealBikeQuantitiesLeakSearchCriteria):
    Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/getRealBikeQuantities?type=lack', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, ExcessLack.fromDto);
      })
    );
  }

  public getRealBikeQuantitiesExcess(page: number, size: number, criteria: RealBikeQuantitiesExcessSearchCriteria):
    Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/getRealBikeQuantities?type=excess', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, ExcessLack.fromDto);
      })
    );
  }


  // delete rental Point
  public deleteRentalPoint(rentalPointId: any): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/deleteRentalPoint', {
      headers: HttpUtils.createHeaders(), params: { rentalPointId }
    });
  }

  public getBikeTypePeriodByRentalPointId(rentalPointId): Observable<BikeTypeAvalibities[]> {
    return this._http.get<any>(environment.serverUrl + 'api/getBikeTypePeriod', {
      headers: HttpUtils.createHeaders(),
      params: { rentalPointId }
    }).pipe(
      map((result: any[]) => result['bikeTypeAvalibities'].map(res => BikeTypeAvalibities.fromDto(res)))
    );
  }

  public saveBikeTypePeriod(bikeTypeAvalibities): Observable<any> {
    return this._http.post<any>(
      environment.serverUrl + 'api/saveBikeTypePeriod', { bikeTypeAvalibities },
      { headers: HttpUtils.createHeaders() }
    );
  }

}
