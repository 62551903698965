import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '../../../../shared/data-table/data-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '../../../../shared/shared.module';
import { DatePipe } from '@angular/common';
import { Product } from '../../../../models/Product';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../../models/SearchObject';
import { TicketsService } from '../../../tickets/shared/tickets.service';
import { TicketSearchCritertia } from '../../../tickets/shared/TicketSearchCritertia';

@Component({
  selector: 'app-maintenance-history',
  standalone: true,
  imports: [
    TranslateModule,
    SharedModule,
    DatePipe
  ],
  templateUrl: './maintenance-history.component.html',
  styleUrl: './maintenance-history.component.scss'
})
export class MaintenanceHistoryComponent implements OnInit {

  @Input () product: Product;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: TicketSearchCritertia = {};

  @ViewChild('staff', { static: true }) staff: TemplateRef<any>;
  @ViewChild('defect', { static: true }) defect: TemplateRef<any>;
  @ViewChild('defectType', { static: true }) defectType: TemplateRef<any>;
  @ViewChild('fixDate', { static: true }) fixDate: TemplateRef<any>;
  @ViewChild('fixTime', { static: true }) fixTime: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _ticketService: TicketsService) {}

  ngOnInit() {
    this.filters.qrCode = this.product.qrCode;
    this.loadPage = (page: number, pageSize: number) => this._ticketService.searchProductDefects(page, pageSize, this.filters);
    this.columns = [
      {
        name: 'staff',
        template: this.staff,
        translationKey: 'tickets.ticketDetails.staff',
        displayed: true,
        sortable: true
      },
      {
        name: 'defect',
        template: this.defect,
        translationKey: 'tickets.ticketDetails.defect',
        displayed: true,
        sortable: true
      },
      {
        name: 'defectType',
        template: this.defectType,
        translationKey: 'tickets.ticketDetails.defectType',
        displayed: true,
        sortable: true
      },
      {
        name: 'fixDate',
        template: this.fixDate,
        translationKey: 'tickets.ticketDetails.fixDate',
        displayed: true,
        sortable: true
      },
      {
        name: 'fixTime',
        template: this.fixTime,
        translationKey: 'tickets.ticketDetails.fixTime',
        displayed: true,
        sortable: true
      }
    ];
  }
}
