<div class="container-fluid">
  <div class="row mt-3 mb-5">
    <div class="col">
      <app-data-table [selectedIds]="selectedArray" [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="25" (onReset)="onTableReset()">
        <app-data-filters #dataFilters [showExcelExportBikeListButton]="false" [showCSVExportBikeListForInsuranceButton]="false">
          <app-data-filter-group>
            <app-data-filter-select label="{{'products.bikeEquipmentList.bikeType' | translate}}"
              [(model)]="filters.bikeTypeId" [options]="bikeTypeOptions">
            </app-data-filter-select>
            <app-data-filter-input label="{{'products.bikeEquipmentList.qrCode' | translate}}"
              [(model)]="filters.qrCode" placeholder="{{'products.bikeEquipmentList.qrCode' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.keyNumber' | translate}}"
              [(model)]="filters.keyNumber" placeholder="{{'products.bikeEquipmentList.keyNumber' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.serialNumber' | translate}}"
              [(model)]="filters.serialNumber" placeholder="{{'products.bikeEquipmentList.serialNumber' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.frameId' | translate}}"
              [(model)]="filters.frameId" placeholder="{{'products.bikeEquipmentList.frameId' | translate}}">
            </app-data-filter-input>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #createdAt let-createdAt="createdAt">
        <ng-container *ngIf="createdAt">{{createdAt | date:'dd/MM/yyyy HH:mm:ss'}}</ng-container>
      </ng-template>

      <ng-template #accountName let-accountName="accountName">
        <ng-container *ngIf="accountName">{{accountName}}</ng-container>
      </ng-template>

      <ng-template #bikeType let-bikeType="bikeType">
        {{bikeType.bikeTypeTranslation[currentLang]}}
      </ng-template>

      <ng-template #brandName let-bikeBrand="bikeBrand">
        <ng-container *ngIf="bikeBrand">{{bikeBrand.name}}</ng-container>
      </ng-template>

      <ng-template #model let-bikeModel="bikeModel">
        <ng-container *ngIf="bikeModel">{{bikeModel.name}}</ng-container>
      </ng-template>

      <ng-template #bikeSize let-bikeSize="bikeSize">
        <ng-container *ngIf="bikeSize">{{bikeSize.size}}</ng-container>
      </ng-template>

      <ng-template #tyreSize let-bikeTyreSize="bikeTyreSize">
        <ng-container *ngIf="bikeTyreSize">{{bikeTyreSize.size}}</ng-container>
      </ng-template>

      <ng-template #qrCode let-qrCode="qrCode">
        <ng-container *ngIf="qrCode">{{qrCode}}</ng-container>
      </ng-template>

      <ng-template #serialNumber let-serialNumber="serialNumber">
        <ng-container *ngIf="serialNumber">{{serialNumber}}</ng-container>
      </ng-template>

      <ng-template #keyNumber let-keyNumber="keyNumber">
        <ng-container *ngIf="keyNumber">{{keyNumber}}</ng-container>
      </ng-template>

      <ng-template #frameId let-frameId="frameId">
        <ng-container *ngIf="frameId">{{frameId}}</ng-container>
      </ng-template>

      <ng-template #location let-location="location">
        <ng-container *ngIf="location.rentalPoint">
          {{location.rentalPoint.rentalPointTranslation[currentLang]}}
        </ng-container>
        <ng-container *ngIf="!location.rentalPoint && location.client">
          {{location.client.businessName}}
        </ng-container>
        <ng-container *ngIf="!location.rentalPoint && !location.client">
          {{location.firstName}}
        </ng-container>
      </ng-template>

      <ng-template #imagePath let-imagePath="imagePath">
        <a [href]="imagePath" target="_blank" class="btn btn-secondary btn-sm btn-block" *ngIf="imagePath"
          style=" display:block; width:35px; margin:0 auto;">
            <i class="fa fa-image"></i>
        </a>
      </ng-template>

      <ng-template #status let-status="status">
        {{status | productStatus | translate}}
      </ng-template>

      <ng-template #actions let-soldCheckListBikeId="soldCheckListBikeId">
        <a href="products/bikes-sell-detail/{{soldCheckListBikeId}}"
          class="btn btn-primary btn-sm btn-block">Detail</a>
      </ng-template>

    </div>
  </div>
</div>
