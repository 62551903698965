<app-header-auth *ngIf="logged"></app-header-auth>
<div class="container-fluid">
  <div class="row">
    <app-sidebar *ngIf="logged"></app-sidebar>
    <main role="main"
      [ngClass]="{'main-container-padding col-lg-10 col-md-9 ml-sm-auto': logged, 'main-container-no-padding col-lg-12 col-md-12' : !logged}">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
