import { Product } from './Product';

export class BikeSize {
    public id: number;
    public size: string;
    public products: Product[];

    public static fromDto(b): BikeSize {
        const bikeSize = new BikeSize();
        if (!b) {
            return null;
        }
        bikeSize.id = b.id;
        bikeSize.size = b.size;
        if (b.products) {
            bikeSize.products = b.products.map(product => Product.fromDto(product));
        }

        return bikeSize;
    }
}


export class BikeSizeSearch {
  public id: number;
  public size: string;

  public static fromDto(b): BikeSizeSearch {
      const bikeSize = new BikeSizeSearch();
      if (!b) {
          return null;
      }
      bikeSize.id = b.id;
      bikeSize.size = b.size;

      return bikeSize;
  }
}
