import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../../models/SearchObject';
import { DataTableColumn } from '../../../../shared/data-table/data-table.component';
import { RentalPointsService } from '../../shared/rental-points.service';
import { RealBikeQuantitiesLeakSearchCriteria } from '../../shared/RealBikeQuantitiesLeakSearchCriteria';

@Component({
  selector: 'app-excess-table',
  templateUrl: './excess-table.component.html',
  styleUrls: ['./excess-table.component.scss']
})
export class ExcessTableComponent implements OnInit, OnChanges {

  public childFilter: RealBikeQuantitiesLeakSearchCriteria;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('rentalPointName', { static: true })
  rentalPointName: TemplateRef<any>;

  @ViewChild('bikeTypeName', { static: true })
  bikeTypeName: TemplateRef<any>;

  @ViewChild('contingentBuffer', { static: true })
  contingentBuffer: TemplateRef<any>;

  @ViewChild('contingentProducts', { static: true })
  contingentProducts: TemplateRef<any>;

  @ViewChild('excessAmount', { static: true })
  excessAmount: TemplateRef<any>;

  @Input()
  currentLang: string;

  @Input()
  filters;

  @Output()
  onLoadingFinish: EventEmitter<string> = new EventEmitter();

  columns: DataTableColumn[] = [];

  constructor(private _rentalPointsService: RentalPointsService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.childFilter = changes.filters.currentValue;
    for (const key in this.childFilter) {
      if (this.childFilter.hasOwnProperty(key)) {
        const value = this.childFilter[key];
        if (value !== undefined) {
          this.loadPage = (page: number, pageSize: number) => this._rentalPointsService.getRealBikeQuantitiesExcess(page, pageSize,
            this.childFilter);
        }
      }
    }
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'rentalPointName',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.excessTable.rentalPointName',
        template: this.rentalPointName,
        sortable: true
      },
      {
        name: 'bikeTypeName',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.excessTable.bikeTypeName',
        template: this.bikeTypeName,
        sortable: true
      },
      {
        name: 'contingentBuffer',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.excessTable.contingentBuffer',
        template: this.contingentBuffer,
        sortable: true
      },
      {
        name: 'contingentProducts',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.excessTable.contingentProducts',
        template: this.contingentProducts,
        sortable: true
      },
      {
        name: 'excessAmount',
        displayed: true,
        translationKey: 'rentalPoint.leakExcess.excessTable.excessAmount',
        template: this.excessAmount,
        sortable: true
      },
    ];
  }

  onTableFinishLoading() {
    this.onLoadingFinish.emit('excess');
  }
}
