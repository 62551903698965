import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CLIENTS_ROUTES } from './clients.routes';
import { ClientsComponent } from './clients.component';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { AddClientComponent } from './add-client/add-client.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(CLIENTS_ROUTES),
        FormsModule,
        CommonModule
    ],
    exports: [],
    declarations: [
        ClientsComponent,
        ClientsListComponent,
        ClientDetailsComponent,
        EditClientComponent,
        AddClientComponent
    ]
})

export class ClientsModule { }
