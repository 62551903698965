import { Component, OnInit, ViewChild, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { HeaderComponent } from '../general/header/header.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { SharedService } from '../../shared/shared.service';
import { HttpClientModule, HttpClient } from '@angular/common/http';

export const ACCESS_RIGHTS = '111111111111111111111111111111';

@Injectable({
  providedIn: 'root'
})

class User {
  public username: string;
  public password: string;
}

//noinspection JSAnnotator
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {
  public clientId: number;

  isAnimationOn = 'bigDiv';
  user = new User();
  @ViewChild(HeaderComponent) header: HeaderComponent;
  constructor(
    private userService: UserService,
    private _translate: TranslateService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _sharedService: SharedService
  ) {
    _translate.setDefaultLang('de');
  }

  login() {
    this.userService.login(this.user.username, this.user.password).subscribe({
      next: (response: any) => {
        localStorage.clear();
        localStorage.setItem('X-Access-Token', response.headers.get('X-Access-Token'));
        localStorage.setItem('papinLogged', 'true');
        localStorage.setItem('isClient', response.body.account.isClient); //  get client type
        localStorage.setItem('isRentalPoint', response.body.account.isRentalPoint); //  get RP type
        localStorage.setItem('accountId', response.body.account.id); // get client id
        localStorage.setItem('clientId', response.body.account.clientId);
        localStorage.setItem('rentalPointId', response.body.account.rentalPointId);
        localStorage.setItem('allowedDeleted', response.body.account.allowedDeleted);
        localStorage.setItem('allowedMoveBikes', response.body.account.allowedMoveBikes);
        if (response.body.account.accessRights) {
          localStorage.setItem('accessRights' , response.body.account.accessRights);
        } else {
          localStorage.setItem('accessRights' , ACCESS_RIGHTS);
        }

        this._sharedService.change(true);
        const redirect = this.userService.redirectUrl ? this.userService.redirectUrl : '/dashboard';
        this.router.navigate([redirect]);
        this._sharedService.loggedId = response.body.account.id;

      },
      error: () =>
        this.snackBar.open(this._translate.instant('login.invalidPassword'), '', {
          duration: 2000,
        })
    });
  }

  ngOnInit() {
    if (localStorage.getItem('papinLogged') === 'true') {
      this.router.navigate(['/dashboard']);
    }
  }
}
