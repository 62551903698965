import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceTypeStatus'
})
export class InvoiceTypeStatusPipe implements PipeTransform {

  transform(value: any): any {
    let status = '';
    switch (value?.toLowerCase()) {
      case 'booking':
        status = 'enums.bookingReservationStatus.onlineBooking';
        break;
      case 'booked rental':
        status = 'enums.orderType.bookedRental';
        break;
      case 'cancelled':
        status = 'enums.bookingReservationStatus.cancelledBooking';
        break;
      case 'refunded':
        status = 'enums.invoiceTypeStatus.refunded';
        break;
      default:
        status = 'Unknown';
    }
    return status;
  }
}
