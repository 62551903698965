import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  public tableCode = '';

  constructor(private _route: Router, private _routes: ActivatedRoute) { }

  ngOnInit() {

  }

  loadTable(code: string) {
    this.tableCode = code;
    window.location.href = '/table-data-management/list/' + code;
  }

}
