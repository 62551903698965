import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SERVICEPOINTS_ROUTES } from './service-points.routes';
import { ServicePointsComponent } from './service-points.component';
import { ServicePointsListComponent } from './service-points-list/service-points-list.component';
import { ServicePointDetailsComponent } from './service-point-details/service-point-details.component';
import { ServicePointEditComponent } from './service-point-edit/service-point-edit.component';
import { ServicePointAddComponent } from './service-point-add/service-point-add.component';



@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(SERVICEPOINTS_ROUTES),
    FormsModule,
    CommonModule
  ],
  exports: [],
  declarations: [
      ServicePointsComponent,
      ServicePointsListComponent,
      ServicePointDetailsComponent,
      ServicePointEditComponent,
      ServicePointAddComponent
  ]
})
export class ServicePointsModule { }
