import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { OrdersService } from '../shared/orders.service';
import { UnsuccessfulPaymentCriteria } from '../shared/UnsuccessfulPaymentCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { forkJoin } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { SharedUtils } from '../../../shared/shared-utils';
import { Observable } from 'rxjs';
import { Client } from '../../../models/Client';
import { ClientsService } from '../../clients/shared/clients.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-turnover',
  templateUrl: './turnover.component.html',
  styleUrls: ['./turnover.component.scss']
})
export class TurnoverComponent implements OnInit {

  public filters: UnsuccessfulPaymentCriteria = {};
  myArray: [];
  public columns: DataTableColumn[] = [];
  public unsuccessullPaymetsClient: UnsuccessfulPaymentCriteria[];
  public currentUserType = SharedUtils.getCurrentUserType();
  public clientOptions: Option[] = [{ value: undefined, label: 'All' }];
  public rentalPointOptions: Option[] = [{ value: undefined, label: 'All' }];
  private _clients: Client[];
  public accountsRequest: Observable<Client> | Observable<Client[]>;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('rentalPointClient', { static: true })
  rentalPointClient: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('totalAmount', { static: true })
  totalAmount: TemplateRef<any>;

  @ViewChild('relevantAmount', { static: true })
  relevantAmount: TemplateRef<any>;

  @ViewChild('action', { static: true })
  action: TemplateRef<any>;


  constructor(private _snackbar: MatSnackBar, private _ordersService: OrdersService, private _clientsService: ClientsService) { }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
      this.loadPage = (page: number, pageSize: number) => this._ordersService.searchUnsuccessfulPayments(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'Contract Id',
        template: this.contractId,
        sortable: true
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'Name',
        template: this.startRentalPoint,
        sortable: true
      },
      {
        name: 'rentalPointClient',
        displayed: true,
        translationKey: 'RentalPointClient',
        template: this.clientName,
        sortable: true
      },

      {
        name: 'clientName',
        displayed: true,
        translationKey: 'Client Name',
        template: this.clientName,
        sortable: true
      },

      {
        name: 'startDate',
        displayed: true,
        translationKey: 'Start Date',
        template: this.startDate,
        sortable: true
      },

      {
        name: 'totalAmount',
        displayed: true,
        translationKey: 'Total Amount',
        template: this.totalAmount,
        sortable: true
      },

      {
        name: 'relevantAmount',
        displayed: true,
        translationKey: 'Relevant Amount',
        template: this.relevantAmount,
        sortable: true
      },
      {
        name: 'action',
        displayed: true,
        translationKey: 'Action',
        template: this.action,
        sortable: true
      },
    ];
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.businessName });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }


  // onClientSelectChange(clientId: number) {
  //   this.rentalPointOptions = [{ value: undefined, label: 'All' }];
  //   if (clientId) {
  //     const selectedClient = this._clients.filter(client => client.id === clientId)[0];
  //     selectedClient.rentalPoints.forEach(rentalPoint => {
  //       this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.businessName });
  //     });
  //   }
  // }


  onFilterClicked() {
    if (this.contractId) {
      this._snackbar.open('Please select a Client', '', {
        duration: 2500
      });
      return;
    } else
      if (this.startDate === undefined) {
        this._snackbar.open('Please select a Start Date', '', {
          duration: 2500
        });
        return;
      }
  }
}
