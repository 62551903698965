import { Component, OnInit, Input, Injectable, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DatePickerUtils } from '../../datepicker-utils';
import * as moment from 'moment';
import { TranslationWidth } from '@angular/common';


// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

  constructor(private _translate: TranslateService) {
    super();
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth): string {
    throw new Error('Method not implemented.');
  }

  getDayAriaLabel(date: import('@ng-bootstrap/ng-bootstrap').NgbDateStruct): string {
    return '';
  }

  getWeekdayShortName(weekday: number): string {
    return this._translate.instant('calendar.weekdays')[weekday - 1];
  }
  getMonthShortName(month: number): string {
    return this._translate.instant('calendar.months')[month - 1];
  }
  getMonthFullName(month: number): string {
    return this.getMonthShortName(month);
  }
}

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { day: this.toInteger(dateParts[0]), month: null, year: null };
      } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: null };
      } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
        return { day: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), year: this.toInteger(dateParts[2]) };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${this.isNumber(date.day) ? this.padNumber(date.day) : ''}-${this.isNumber(date.month) ?
        this.padNumber(date.month) : ''}-${date.year}` :
      '';
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }

  isNumber(value: any): value is number {
    return !isNaN(this.toInteger(value));
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }
}

@Component({
  selector: 'app-data-filter-datepicker',
  templateUrl: './data-filter-datepicker.component.html',
  styleUrls: ['./data-filter-datepicker.component.scss']
})
export class DataFilterDatepickerComponent implements OnInit, OnChanges {

  @Input()
  placeHolder = 'yyyy-mm-dd';

  @Input()
  label: string;

  @Input()
  model;

  @Input()
  disabled = false;

  @Input()
  maxDate;

  @Input()
  minDate;

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();


  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model.currentValue) {
      this.model = DatePickerUtils.fromModel(moment(changes.model.currentValue));
    }
  }

  change() {
    this.modelChange.emit(DatePickerUtils.toModel(this.model).format('YYYY-MM-DD').toString());
  }


}
