import { Component, OnInit, SimpleChanges, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';
import { SharedUtils } from '../../../shared/shared-utils';
import { ClientsService } from '../../clients/shared/clients.service';
import { forkJoin, Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { RealBikeQuantitiesLeakSearchCriteria } from '../shared/RealBikeQuantitiesLeakSearchCriteria';


@Component({
  selector: 'app-leak-excess',
  templateUrl: './leak-excess.component.html',
  styleUrls: ['./leak-excess.component.scss']
})
export class LeakExcessComponent implements OnInit {

  public filters: RealBikeQuantitiesLeakSearchCriteria = {};
  public childFilter: RealBikeQuantitiesLeakSearchCriteria = {};

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  public loadingLack = false;
  public loadingExcess = false;

  public hideTables = false;
  public currentLang: string;
  public disableApplyButton = true;

  public clientOptions: Option[] = [];
  public rentalPointOptions: Option[] = [];

  public currentUserType = SharedUtils.getCurrentUserType();
  private _clients: Client[];
  private _currentRentalPoint = [];


  constructor(private _translate: TranslateService, private _clientsService: ClientsService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {

    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      // if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      // }
    });
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        this.clientOptions.push({ value: undefined, label: 'All' });
        this.rentalPointOptions.push({ value: undefined, label: 'All' });
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      case 2:
        const currentClient = result.filter(item => item.id.toString() === this.currentUserType.clientId.toString());
        this.clientOptions.push({ value: currentClient[0].id, label: currentClient[0].businessName });
        this._currentRentalPoint = currentClient[0].rentalPoints.filter(
          item => item.id.toString() === this.currentUserType.rentalPointId.toString());
        break;
      default:
        this.clientOptions.push({ value: undefined, label: 'All' });
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }


  onClientSelectChange(clientId: number) {
    if (clientId === undefined) {
      this.disableApplyButton = true;
    } else {
      this.disableApplyButton = false;
    }
    if (this.currentUserType.userTypeNumber !== 2) {
      this.rentalPointOptions = [{ value: undefined, label: 'All' }];
      if (clientId) {
        const selectedClient = this._clients.filter(client => client.id === clientId)[0];
        selectedClient.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
      }
    } else {
      this.rentalPointOptions = [{ value: this._currentRentalPoint[0].accountId, label: this._currentRentalPoint[0].businessName }];
    }
  }


  onApplyButtonClick() {

    let showTable = true;
    const clientId = this.filters.clientId;
    const rentalPointAccountId = this.filters.rentalPointAccountId;

    if (this.currentUserType.userTypeNumber === 2) {
      showTable = false;
      if (clientId && rentalPointAccountId) {
        showTable = true;
      }
    }

    if (showTable) {
      this.loadingLack = true;
      this.loadingExcess = true;
      this.hideTables = true;
      this.childFilter = { ... this.filters };

      if (this.childFilter.rentalPointAccountId) {
        this.childFilter.clientId = undefined;
      }
    } else {
      this.disableApplyButton = true;
    }
  }


  showTableContent(tableType) {
    if (tableType === 'leack') {
      this.loadingLack = false;
    } else {
      this.loadingExcess = false;
    }
  }

  onResetButtonClick() {
    this.filters = {};
    this.hideTables = false;
  }
}
