import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceTxt'
})
export class ReplaceTxtPipe implements PipeTransform {

  transform(value: string, find: string, replace: string): string {
    if ( !value || !find || !replace) {
      return value;
    }
    return value.replace(find, replace);
  }

}
