import { Component } from '@angular/core';

@Component({
  selector: 'app-service-point-details',
  templateUrl: './service-point-details.component.html',
  styleUrls: ['./service-point-details.component.scss']
})
export class ServicePointDetailsComponent {

}
