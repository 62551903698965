<nav class="navbar navbar-dark fixed-top bg-dark p-0">
  <span class="navbar-brand mb-0 h1 pl-3"></span>
  <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
    <li class="nav-item pr-3">
      <a class="nav-link" href="profile/update">{{'general.headerAuth.profile' | translate}}</a>
    </li>
    <li class="nav-item pr-3">
      <a class="nav-link" (click)="logout()" href="javascript:void(0)">{{'general.headerAuth.logout' | translate}}</a>
    </li>
  </ul>
</nav>