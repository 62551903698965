export class UserType {
    userTypeNumber: number;
    userTypeName: string;
    accountId: string;
    clientId: string;
    rentalPointId: string;

    public static create(name, number, accountId, clientId, rentalPointId) {
        const userType = new UserType();
        userType.userTypeName = name;
        userType.userTypeNumber = number;
        userType.accountId = accountId;
        userType.clientId = clientId;
        userType.rentalPointId = rentalPointId;
        return userType;
    }
}
export class SharedUtils {

    public static getCurrentUserType() {
        const isClient = localStorage.getItem('isClient');
        const isRentalPoint = localStorage.getItem('isRentalPoint');
        const accountId = localStorage.getItem('accountId');
        const clientId = localStorage.getItem('clientId');
        const rentalPointId = localStorage.getItem('rentalPointId');
        if (isClient === 'true') {
            return UserType.create('client', 1, accountId, clientId, rentalPointId);
        } else if (isRentalPoint === 'true') {
            return UserType.create('rentalPoint', 2, accountId, clientId, rentalPointId);
        } else {
            return UserType.create('admin', 0, accountId, clientId, rentalPointId);
        }
    }
}
