import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../shared/products.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-bike-sell-detail',
  templateUrl: './bike-sell-detail.component.html',
  styleUrls: ['./bike-sell-detail.component.scss']
})
export class BikeSellDetailComponent implements OnInit {

  private _soldCheckListBikeId: number;
  public soldCheckListBike: any;

  constructor(
    private _translate: TranslateService, private _activatedRoute: ActivatedRoute,
    private _productsService: ProductsService, private _snackBar: MatSnackBar, public datepipe: DatePipe
  ) {}
  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this._soldCheckListBikeId = params['soldCheckListBikeId'];
      this._productsService.getSoldBikeCheckListById(this._soldCheckListBikeId).subscribe(rs => {
        this.soldCheckListBike = rs;
      });
    });
  }
}
