import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Order} from '../../../models/Order';
import {OrdersService} from '../../../services/orders.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderConfirmedComponent implements OnInit {

  public result: any;
  public contractId: any;
  public pdfLink: string;

  constructor(private route: ActivatedRoute,
              private translate: TranslateService,
              private orderService: OrdersService) {
    translate.setDefaultLang('de');
  }

  setLanguage() {
    const language = window.navigator.language;
    if (language === 'en') {
      this.translate.use('en');
    } else if (language === 'it') {
      this.translate.use('it');
    } else {
      this.translate.use('de');
    }
  }

  ngOnInit() {
    this.setLanguage();
    this.route.params.subscribe(params => {
      console.log(params);
      const paramsFrom: any = {};

      if (location.search) {
        const parts = location.search.substring(1).split('&');

        for (let i = 0; i < parts.length; i++) {
          const nv = parts[i].split('=');
          if (!nv[0]) {
            continue;
          }
          paramsFrom[nv[0]] = nv[1] || true;
        }
      }

// Now you can get the parameters you want like so:
      const resourcePath = paramsFrom.resourcePath;
      this.contractId = params.contractId;
      this.orderService.confirmOrder(params.contractId, resourcePath).subscribe(result => {
        this.result = result;
        this.pdfLink = result.pdfLink;
        console.log(result);
      });

    });
  }
}
