import { Component, OnInit, Inject, LOCALE_ID } from '@angular/core';
import { TurnoverListService } from '../shared/turnover-list.service';
import { Turnover } from '../../../models/Turnover';
import { ChashFlowService } from '../shared/chash-flow.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { TranslateService } from '@ngx-translate/core';
import { SharedUtils } from '../../../shared/shared-utils';
import { ClientsService } from '../../clients/shared/clients.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, forkJoin } from 'rxjs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import { Client } from '../../../models/Client';
import * as FileSaver from 'file-saver';
import { DatePipe } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

class HeaderColumns {
  header: string;
  id: string;
  width?: number;
}

class RentalPointCashFlowSearchCriteria {
  rentalPointAccountId?: string;
  clientId?: string;
  startDate?: string;
  endDate?: string;
  language?: string;
}
@Component({
  selector: 'app-turnover-cash-flow',
  templateUrl: './turnover-cash-flow.component.html',
  styleUrls: ['./turnover-cash-flow.component.scss']
})
export class TurnoverCashFlowComponent implements OnInit {

  public turnoverList: Turnover[] = [];
  public filters: RentalPointCashFlowSearchCriteria = {};
  public currentLang: string;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public currentUserType = SharedUtils.getCurrentUserType();
  private _clients: Client[];
  public disableExcelExportButton = true;
  public showResultContent = false;
  public filterClick = false;

  constructor(private _turnoverListService: TurnoverListService, private _translate: TranslateService, public _snackBar: MatSnackBar,
    private _clientsService: ClientsService, @Inject(LOCALE_ID) private _locale: string) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.currentUserType = SharedUtils.getCurrentUserType();
  }

  ngOnInit() {
    this.turnoverList = [];
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
    });
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onExcelExportButtonClick() {
    const turnoverActionsArr = [];
    const headers: HeaderColumns[] = [
      { header: 'Contract Id', id: 'contractId', width: 15 },
      { header: 'Name', id: 'name', width: 15 },
      { header: 'Date', id: 'date', width: 15 },
      { header: 'Rental Point', id: 'rentalPoint', width: 15 },
      { header: 'Relative Amount', id: 'amount', width: 15 },
      { header: 'Type', id: 'type', width: 15 },
      { header: 'Online Booking', id: 'onlineBooking', width: 15 }
    ];

    // Create workbook and worksheet
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Turnover');
    worksheet.columns = headers;
    console.log('this.turnoverList', this.turnoverList);
    this.turnoverList.forEach(elem => {
      let onlineBooking = '------';
      if (elem.onlineBooking !== undefined) {
        if (elem.onlineBooking === true) {
          onlineBooking = 'YES';
        } else {
          onlineBooking = 'NO';
        }
      }
      console.log('elem.onlineBooking', elem.onlineBooking);
      console.log('onlineBooking', onlineBooking);
      elem.payments.forEach(action => {
        turnoverActionsArr.push(
          new DatePipe(this._locale).transform(action.date, 'dd.MM.yyyy'),
          (action.rentalPoint ? action.rentalPoint.rentalPointTranslation[this.currentLang] : ''),
          action.amount,
          action.type
        ),
          worksheet.addRow([
            elem.contractId,
            elem.name,
            new DatePipe(this._locale).transform(action.date, 'dd.MM.yyyy'),
            (action.rentalPoint ? action.rentalPoint.rentalPointTranslation[this.currentLang] : ''),
            action.amount,
            action.type,
            onlineBooking
          ]);
      });
    });

    // tslint:disable-next-line:no-shadowed-variable
    workbook.xlsx.writeBuffer().then((data) => {
      this._saveAsExcelFile(data, 'Turnover');
    });
  }

  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  onApplayButtonClick() {
    this.filterClick = true;
    this.showResultContent = false;
    const tempFilters = { ...this.filters };
    if (tempFilters.rentalPointAccountId) {
      tempFilters.clientId = undefined;
    }
    tempFilters.language = this.currentLang;
    this._turnoverListService.getTurnovers(tempFilters).subscribe(result => {
      this.turnoverList = result;
      this.disableExcelExportButton = false;
      this.showResultContent = true;
    }, error => {
      console.log(error);
      this.filterClick = false;
      this.showResultContent = false;
      switch (error.status) {
        case 601:
          this._snackBar.open('Please select a Start Date', '', {
            duration: 2500
          });
          break;
        case 602:
          this._snackBar.open('Please select a End Date', '', {
            duration: 2500
          });
          break;
        case 603:
          this._snackBar.open('Please select a Client', '', {
            duration: 2500
          });
          break;
        default:
          this._snackBar.open('Other error', '', {
            duration: 2500
          });
          break;
      }
    });
  }

  onResetButtonClick() {
    this.filters = {};
    this.turnoverList = [];
    this.disableExcelExportButton = true;
  }
}
