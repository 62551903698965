import { Rental } from './Rental';
import { Credits } from './Credits';
import { ReturnBike } from './ReturnBike';
import { RentalPoint } from './RentalPoint';

export class OrderReturn {
    public id: number;
    public returnDate: string;
    public returnTime: string;
    public dayType: number;
    public delayPrice: number;
    public rentalPointId: RentalPoint;
    public comment: string;
    public credit: number;
    public returnBike: ReturnBike[];
    public directRental: Rental;
    public bookedRental: Rental;
    public credits: Credits;

    public static fromDto(o): OrderReturn {
        const orderReturn = new OrderReturn();
        if (!o) {
            return null;
        }
        orderReturn.id = o.id;
        orderReturn.returnDate = o.returnDate;
        orderReturn.returnTime = o.returnTime;
        orderReturn.dayType = o.dayType;
        orderReturn.delayPrice = o.delayPrice;
        orderReturn.rentalPointId = (!isNaN(o.rentalPointId) ? RentalPoint.fromDto(o.rentalPointId) : o.rentalPointId);
        orderReturn.comment = o.comment;
        orderReturn.credit = o.credit;
        if (o.returnBike) {
            orderReturn.returnBike = o.returnBike.map(bike => ReturnBike.fromDto(bike));
        }
        if (o.directRental) {
            orderReturn.directRental = Rental.fromDto(o.directRental);
        }
        if (o.bookedRental) {
            orderReturn.bookedRental = Rental.fromDto(o.bookedRental);
        }
        orderReturn.credits = Credits.fromDto(o.credits);

        return orderReturn;
    }
}
