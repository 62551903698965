import { Pipe, PipeTransform } from '@angular/core';
import { BookingReservationStatusEnum } from '../../../../shared/enums/BookingReservationStatusEnum';

@Pipe({
  name: 'bookingReservationStatus'
})
export class BookingReservationStatusPipe implements PipeTransform {

  transform(value: any): any {
    let status = '';
    switch (value) {
      case 1:
        status = BookingReservationStatusEnum.ONE;
        break;
      case 2:
        status = BookingReservationStatusEnum.TWO;
        break;
      case 3:
        status = BookingReservationStatusEnum.THREE;
        break;
      case 4:
        status = BookingReservationStatusEnum.FOUR;
        break;
      default:
        status = BookingReservationStatusEnum.ZERO;
        break;
    }
    return status;
  }

}
