import { Routes } from '@angular/router';
import { PriceListComponent } from './price-list.component';
import { AuthGuard } from '../../auth-guard.service';
import { UpdatePriceListComponent } from './update-price-list/update-price-list.component';

export const PRICE_LIST_ROUTES: Routes = [
    {
        path: 'price-list', component: PriceListComponent, canActivate: [AuthGuard], children: [
            { path: 'update', component: UpdatePriceListComponent, canActivate: [AuthGuard] },
        ]
    }
];
