import { Pipe, PipeTransform } from '@angular/core';
import { CreditStatusEnum } from '../../../../shared/enums/CreditStatusEnum';

@Pipe({
  name: 'creditsStatus'
})
export class CreditsStatusPipe implements PipeTransform {

  transform(value: any): any {
    // if (value === 1) {
    //   status = 'Partially Consumed';
    // } else if (value === 2) {
    //   status = 'Fully Consumed';
    // }
    if (value > 0) {
      return CreditStatusEnum.TWO;
    }
    return CreditStatusEnum.ZERO;
  }

}
