<div class="container" style="padding-bottom: 20px;">
    <div class="row">
        <div class="col">
            <div class="card" id="contract-paper">
                <div class="card-body">
                    <ng-container *ngIf="startRentalPoint.country === 1; else italyTitleTemplate">
                        <blockquote class="blockquote text-center">
                            <p class="mb-0">Papin Sport & Freizeit GmbH <br /> Arnbach 41F 9920 Arnbach / Sillian<br />
                                ATU65441346 </p>
                        </blockquote>
                    </ng-container>
                    <ng-template #italyTitleTemplate>
                        <blockquote class="blockquote text-center">
                          <p class="mb-0">
                            Papin Sport GmbH / srl <br />
                            Freisingerstr. 9 Via Freising <br />
                            I-39038 Innichen / San Candido (BZ)<br />
                            MwSt.Nr.-P.Iva 00480120211
                          </p>
                        </blockquote>
                    </ng-template>
                    <div class="text-center mt-2 mb-2">
                        <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="papin-logo" alt="Papin Logo"
                            class="img-responsive" height="65">
                    </div>
                    <div class="text-center">
                        <ngx-barcode6 [bc-value]="contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1">
                        </ngx-barcode6>
                    </div>
                    <div id="contract-details" class="mt-3">
                        <dl class="row">
                            <dt class="col-6">{{translations?.contractDate}}:</dt>
                            <!--<dd class="col-6 text-right">{{rental.startDate | date: 'dd-MM-yyyy'}}
                                {{rental.pickUpTime}}</dd>-->
                            <dd class="col-6 text-right">{{rental.contractDate}}</dd>
                            <dt class="col-6">{{translations?.contractNumber}}:</dt>
                            <dd class="col-6 text-right">{{rental.contractId}}</dd>
                            <dt class="col-6">{{translations?.clientName}}:</dt>
                            <dd class="col-6 text-right">{{rental.clientInformation.firstName}}
                                {{rental.clientInformation.lastName}}
                            </dd>
                            <ng-container *ngIf="rental.clientInformation.phone">
                                <dt class="col-6">{{translations?.phoneNumber}}:</dt>
                                <dd class="col-6 text-right">
                                    {{(rental.clientInformation.phone ? rental.clientInformation.phone : '&nbsp;')}}
                                </dd>
                            </ng-container>
                            <dt class="col-6">{{translations?.from}}:</dt>
                            <dd class="col-6 text-right">{{startRentalPoint.rentalPointTranslation[currentLang]}}
                            </dd>
                            <dt class="col-6">{{translations?.to}}:</dt>
                            <dd class="col-6 text-right">{{endRentalPoint.rentalPointTranslation[currentLang]}}</dd>
                            <dt class="col-6">{{translations?.start}}:</dt>
                            <dd class="col-6 text-right">{{rental.startDate | date: 'dd-MM-yyyy'}}</dd>
                            <dt class="col-6">{{translations?.end}}:</dt>
                            <dd class="col-6 text-right">{{rental.endDate | date: 'dd-MM-yyyy'}}</dd>
                            <dt class="col-6">{{translations?.duration}}:</dt>
                            <!-- <ng-container *ngIf="rental.dayType; else noDayTypeTemplate"> -->
                            <ng-container *ngIf="rental.monthId === 0; else monthlyRentalTemplate">
                                <dd class="col-6 text-right">
                                    {{rental.dayType | rentalDuration:rental.startDate:rental.endDate:translations}}
                                </dd>
                            </ng-container>
                            <ng-template #monthlyRentalTemplate>
                                <dd class="col-6 text-right">
                                    {{rental.monthlyDurationDays}} {{translations?.days}}
                                </dd>
                            </ng-template>
                            <!-- </ng-container>
                            <ng-template #noDayTypeTemplate>
                                <dd class="col-6 text-right">
                                    {{rental.startDate | dayDiff:rental.endDate:translations}}</dd>
                            </ng-template> -->
                            <ng-container *ngIf="rental.discountCard">
                                <dt class="col-6">{{translations?.discountCard}}:</dt>
                                <dd class="col-6 text-right">
                                    {{(rental.discountCard ? rental.discountCard.name : '&nbsp;')}}</dd>
                            </ng-container>
                            <ng-container *ngIf="rental.bikeMobileCard">
                                <ng-container *ngIf="rental.bikeMobileCard.adultCards > 0">
                                    <dt class="col-6">BMC Adult:</dt>
                                    <dd class="col-6 text-right">
                                        {{rental.bikeMobileCard.adultCards}}</dd>
                                </ng-container>
                                <ng-container *ngIf="rental.bikeMobileCard.juniorCards > 0">
                                    <dt class="col-6">BMC Junior:</dt>
                                    <dd class="col-6 text-right">
                                        {{rental.bikeMobileCard.juniorCards}}</dd>
                                </ng-container>
                            </ng-container>
                        </dl>
                    </div>
                    <div id="bikes-list">
                        <table class="table table-borderless">
                            <tbody>
                                <tr *ngFor="let bike of bikes;">
                                    <ng-container *ngIf="bike.replaced; else normalBikeTemplate">
                                        <td>
                                            <strong>1 {{bike.replacedBikeTypeId.bikeTypeTranslation[currentLang]}}
                                                {{(bike.replacedQRCode ? '(' + bike.replacedQRCode + ')' :
                                                '')}}</strong>
                                        </td>
                                        <td class="text-right">{{bike.replacedPrice | currency: '€ '}}</td>
                                    </ng-container>
                                    <ng-template #normalBikeTemplate>
                                        <td>
                                            <strong>1 {{bike.bikeType.bikeTypeTranslation[currentLang]}}
                                                {{(bike.qrCode ? '(' + bike.qrCode + ')' : '')}}</strong>
                                        </td>
                                        <td class="text-right">{{bike.price | currency: '€ '}}</td>
                                    </ng-template>
                                </tr>
                                <tr *ngIf="differentRentalCost > 0">
                                    <td><strong>{{translations?.differentRental}}: </strong></td>
                                    <td class="text-right">{{differentRentalCost | currency: '€ '}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="price-container">
                        <div class="row">
                            <dt class="col-6">{{translations?.price}}:</dt>
                            <dd class="col-6 text-right">{{rental.totalPrice | currency: '€ '}}</dd>
                            <ng-container *ngIf="rental.totalPrice !== rental.discountedPrice">
                                <dt class="col-6">{{translations?.discount}}:</dt>
                                <dd class="col-6 text-right">
                                    {{(rental.totalPrice - rental.discountedPrice) | currency: '€ '}}</dd>
                            </ng-container>
                        </div>
                    </div>
                    <hr class="dashed" />
                    <div id="full-amount-container">
                        <div class="row">
                            <ng-container *ngIf="rental.orderType === 1; else bookedOrderTemplate">
                                <ng-container
                                    *ngIf="rental.discountCardValueRental && rental.discountCardValueRental.length > 0; else elseTemplate">
                                    <dt class="col-6">{{translations?.cardValue}}:</dt>
                                    <dd class="col-6 text-right">
                                        {{rental.discountCardValueRental[0].amount | currency: '€ '}}</dd>
                                    <dt class="col-6">{{translations?.openAmount}}:</dt>
                                    <ng-container
                                        *ngIf="(rental.discountedPrice - rental.discountCardValueRental[0].amount) < 0; else elseTemplate">
                                        <dd class="col-6 text-right">
                                            {{0 | currency: '€ '}}</dd>
                                    </ng-container>
                                    <ng-template #elseTemplate>
                                        <dd class="col-6 text-right">
                                            {{(rental.discountedPrice - rental.discountCardValueRental[0].amount) |
                                            currency: '€ '}}
                                        </dd>
                                    </ng-template>
                                </ng-container>
                                <ng-template #elseTemplate>
                                    <ng-container *ngIf="rental.credits.length > 0; else noCreditTemplate">
                                        <dt class="col-6">{{translations?.guthaben}}:</dt>
                                        <dd class="col-6 text-right">{{rental.credits[0].consumed | currency: '€ '}}
                                        </dd>
                                        <dt class="col-6">{{translations?.openAmount}}:</dt>
                                        <dd class="col-6 text-right">
                                            {{rental.discountedPrice - rental.credits[0].consumed | currency: '€ '}}
                                        </dd>
                                    </ng-container>
                                    <ng-template #noCreditTemplate>
                                        <dt class="col-6">{{translations?.openAmount}}:</dt>
                                        <dd class="col-6 text-right">{{rental.discountedPrice | currency: '€ '}}
                                        </dd>
                                    </ng-template>
                                </ng-template>
                            </ng-container>
                            <ng-template #bookedOrderTemplate>
                                <dt class="col-6">{{translations?.totalCost}}:</dt>
                                <dd class="col-6 text-right">{{rental.discountedPrice | currency: '€ '}}</dd>
                                <ng-container *ngIf="rental.onlineBooking.reservation === 0; else reservationTemplate">
                                    <dt class="col-6">{{translations?.onlinePayment}}:</dt>
                                    <dd class="col-6 text-right">
                                        {{rental.onlineBooking.discountedPrice | currency: '€ '}}</dd>
                                    <ng-container
                                        *ngIf="(rental.discountedPrice - rental.onlineBooking.discountedPrice) < 0; else toPayTemplate">
                                        <dt class="col-6">{{translations?.guthaben}}:</dt>
                                        <dd class="col-6 text-right">
                                            {{(rental.discountedPrice - rental.onlineBooking.discountedPrice) * -1 |
                                            currency: '€ '}}
                                        </dd>
                                    </ng-container>
                                    <ng-template #toPayTemplate>
                                        <dt class="col-6">{{translations?.offenerBeitrag}}:</dt>
                                        <dd class="col-6 text-right">
                                            {{(rental.discountedPrice - rental.onlineBooking.discountedPrice) |
                                            currency: '€ '}}
                                        </dd>
                                    </ng-template>
                                </ng-container>
                                <ng-template #reservationTemplate>
                                    <dt class="col-6">{{translations?.onlinePayment}}:</dt>
                                    <dd class="col-6 text-right">{{0 | currency: '€ '}}</dd>
                                    <dt class="col-6">{{translations?.offenerBeitrag}}:</dt>
                                    <dd class="col-6 text-right">
                                        {{(rental.discountedPrice - 0) | currency: '€ '}}</dd>
                                </ng-template>
                            </ng-template>
                        </div>
                    </div>
                    <div id="privacy-signature-container">
                        <p class="mt-3 mb-3">{{rental.contractText}}</p>
                        <div class="row">
                            <dt class="col-6">{{translations?.privacy}}:</dt>
                            <dd class="col-6 text-right">{{translations?.yes}}</dd>
                            <dt class="col-6">{{translations?.privacyMarketing}}:</dt>
                            <dd class="col-6 text-right">
                                {{(rental.clientInformation.marketingUsage ? translations?.yes : translations?.no)}}
                            </dd>
                        </div>
                        <section class="customer-signature">
                            <div *ngIf="imageFound" class="signature text-center">
                                <img [src]="signatureImageUrl" alt="Customer Signature">
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4 d-print-none">
        <div class="col text-center">
            <button (click)="print()" type="button"
                class="btn btn-primary btn-round text-uppercase pl-5 pr-5">{{'orders.papers.contractPaper.print' |
                translate}}
                <i class="fa fa-print" aria-hidden="true"></i></button>
        </div>
    </div>
</div>
