<div class="container-fluid">
  <div class="row">
    <div class="col text-right">
      <a href="/service-points/add" class="btn btn-sm btn-primary">{{'servicePoints.servicePointsList.addServicePoint' | translate}}</a>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="15">
        <app-data-filters #dataFilters>
          <app-data-filter-group>
            <app-data-filter-input label="{{'servicePoints.servicePointsList.name' | translate}}"
              [(model)]="filters.name" placeholder="{{'servicePoints.servicePointsList.name' | translate}}">
            </app-data-filter-input>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>
      <ng-template #id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #en let-en="en">
        {{en}}
      </ng-template>
      <ng-template #it let-it="it">
        {{it}}
      </ng-template>
      <ng-template #de let-de="de">
        {{de}}
      </ng-template>
      <ng-template #actions let-id="id">
        <div class="btn-group" role="group">
          <a href="/service-points/edit/{{id}}" class="btn btn-primary btn-sm">{{'servicePoints.servicePointsList.edit' | translate}}</a>
          <span (click)="deleteSelectedConfirm(contentConfirmDeleteModal, id)" class="btn btn-secondary btn-sm">{{'servicePoints.servicePointsList.delete' | translate}}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #contentConfirmDeleteModal let-modal>
  <div class="modal-header" style="background-color:#dc3545; color:#FFFFFF">
    <h4 class="modal-title" id="modal-basic-title">{{'servicePoints.servicePointsList.confirmDeleteModal.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true" style="color:#FFFFFF">&times;</span>
    </button>
  </div>
  <div class="modal-body text-left" style="font-size: 15px;">
    {{'servicePoints.servicePointsList.confirmDeleteModal.question' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="servicePointDelete()">{{'servicePoints.servicePointsList.confirmDeleteModal.confirm' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close')">{{'servicePoints.servicePointsList.confirmDeleteModal.abort' | translate}}</button>
  </div>
</ng-template>
