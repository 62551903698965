import { Injectable } from '@angular/core';
import { ProductSearchCritertia } from './ProductSearchCritertia';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Product } from '../../../models/Product';
import { BikeSize } from '../../../models/BikeSize';
import { BikeType } from '../../../models/BikeType';
import { BikeModel } from '../../../models/BikeModel';
import { BikeBrand } from '../../../models/BikeBrand';
import { BikeTyreSize } from '../../../models/BikeTyreSize';
import { ProductSummary } from './ProductSummary';
import { BikeColor } from '../../../models/BikeColor';
import { BikePreparation } from '../../../models/BikePreparation';
import { BikeStatistic } from '../../../models/BikeStatistic';
import { SoldBike } from '../../../models/SoldBike';
import { BikeCheckinSearchCriteria } from '../edit-bike-equipment/bike-checkin-list/BikeCheckinSearchCriteria';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: ProductSearchCritertia): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchBikesEquipment', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Product.fromDto);
      })
    );
  }

  public searchSoldBike(page: number, size: number, criteria: ProductSearchCritertia): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchSoldCheckListBikes', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, SoldBike.fromDto);
      })
    );
  }

  public searchProductHistory(page: number, size: number, criteria: BikeCheckinSearchCriteria): Observable<SearchObjectResult<Event>> {
    const criteriaCopy = { ...criteria };
    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchProductHistory', { headers, params }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  /**
   * getBikeSizes
   */
  public getBikeSizes(): Observable<BikeSize[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeSizes', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeSize.fromDto(res)))
    );
  }

  /**
   * getBikeTypes
   */
  public getBikeTypes(): Observable<BikeType[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeTypesExtended', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeType.fromDto(res)))
    );
  }

  /**
   * getBikeModels
   */
  public getBikeModels(): Observable<BikeModel[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeModels', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeModel.fromDto(res)))
    );
  }

  /**
   * getBikeBrands
   */
  public getBikeBrands(): Observable<BikeBrand[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeBrands', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeBrand.fromDto(res)))
    );
  }

  /**
   * getBikeColors
   */
  public getBikeColors(): Observable<BikeColor[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeColors', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeColor.fromDto(res)))
    );
  }

  /**
   * getBikeTyreSizes
   */
  public getBikeTyreSizes(): Observable<BikeTyreSize[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeTyreSizes', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeTyreSize.fromDto(res)))
    );
  }

  /**
   * addProduct
   */
  public addProduct(bike: any): Observable<any> {
    return this._http.post(environment.serverUrl + 'api/addBike', { bike }, {
      headers: HttpUtils.createHeaders()
    });
  }

  /**
   * updateProduct
   */
  public updateProduct(bike: any, wartungUserId?: number): Observable<any> {
    if (wartungUserId) {
      bike.wartungUserId = wartungUserId;
    }
    return this._http.post(environment.serverUrl + 'api/updateProduct', { bike }, {
      headers: HttpUtils.createHeaders()
    });
  }

  /**
   * deleteProducts
   */
  public deleteProducts(productIds: string, userId: number): Observable<any> {
    return this._http.post(environment.serverUrl + 'api/deleteProducts', { productIds: productIds, userId: userId }, {
      headers: HttpUtils.createHeaders()
    });
  }

  /**
   * Move bikes from one rental point to another
   */
  public moveProducts(productIds: string, rentalPointAccountId: string): Observable<any> {
    return this._http.post(environment.serverUrl + 'api/moveProducts', {
      productIds: productIds,
      rentalPointAccountId: rentalPointAccountId
    }, {
      headers: HttpUtils.createHeaders()
    });
  }

  /**
   * getProductById
   */
  public getProductById(productId): Observable<Product> {
    return this._http.get(environment.serverUrl + 'api/getProductById', {
      headers: HttpUtils.createHeaders(), params: { productId }
    }).pipe(
      map((result: any) => Product.fromDto(result))
    );
  }

  /**
   * getProductsSummary
   */
  public getProductsSummary(clientId?: string, rentalPointAccountId?: string): Observable<ProductSummary[]> {
    let params = {};
    if (rentalPointAccountId) {
      params = { rentalPointAccountId };
    } else {
      if (clientId) {
        params = { clientId };
      }
    }
    return this._http.get(environment.serverUrl + 'api/getProductsSummary', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => result.map(res => ProductSummary.fromDto(res)))
    );
  }

  /**
   * getPrepareBikes
   */
  public getPrepareBikes(startDate: string, clientId: string, endDate?: string, rentalPointAccountId?: string) {
    let params = {};
    if (rentalPointAccountId) {
      params = { rentalPointAccountId };
    } else {
      if (clientId) {
        params = { clientId };
      }
    }
    params['startDate'] = startDate;
    if (endDate) {
      params['endDate'] = endDate;
    }
    return this._http.get(environment.serverUrl + 'api/prepareBikes', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any) => BikePreparation.fromDto(result))
    );
  }

  /**
   * changeOnlineOrderPreparationValue
   */
  public changeOnlineOrderPreparationValue(contractId, prepared): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/changeOnlineOrderPreparationValue', {
      headers: HttpUtils.createHeaders(), params: { contractId, prepared }
    });
  }

  public getRentalPointBikeStatistics(rentalPointId: string, language: string): Observable<BikeStatistic> {
    return this._http.get(environment.serverUrl + 'api/getRentalPointBikeStatistics', {
      headers: HttpUtils.createHeaders(), params: { rentalPointId, language }
    }).pipe(
      map((result: any) => result)
    );
  }

  /**
   * getBikeByQrCode
   */
  public getBikeByQrCode(qrCode): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/getBikeLocation', {
      headers: HttpUtils.createHeaders(), params: { qrCode }
    });
  }

  /**
   * getSoldBikeCheckListById
   */
    public getSoldBikeCheckListById(soldCheckListBikeId): Observable<any> {
      return this._http.get(environment.serverUrl + 'api/getSoldCheckListBikeDetail', {
        headers: HttpUtils.createHeaders(), params: { soldCheckListBikeId }
      });
    }

  /**
   * Save the image
   */
  public saveProductImage(productId: number, fileName: string, imageData: File): Observable<any> {
    const formData = new FormData();
    formData.append('productId', productId.toString());
    formData.append('filename', fileName);
    formData.append('image', imageData, fileName);
    return this._http.post(environment.serverUrl + 'api/saveBikeImage',
      formData
    , {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((res: any) => res));
  }

  /**
   * getProductsExcelExportFile
  */
  public getProductsExcelExportFile(
      clientId: number,
      rentalPointAccountId: number,
      bikeTypeId: number,
      bikeBrandId: number,
      bikeModelId: number,
      bikeSizeId: number,
      bikeTyreSizeId: number,
      qrCode: string,
      keyNumber: string,
      serialNumber: string,
      frameId: string,
      status: number
    ): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/downloadBikes', {
      headers: HttpUtils.createHeaders(), params: {
        clientId: clientId,
        rentalPointAccountId: rentalPointAccountId,
        bikeTypeId: bikeTypeId,
        bikeBrandId: bikeBrandId,
        bikeModelId: bikeModelId,
        bikeSizeId: bikeSizeId,
        bikeTyreSizeId: bikeTyreSizeId,
        qrCode: qrCode,
        keyNumber: keyNumber,
        serialNumber: serialNumber,
        frameId: frameId,
        status: status
      }
    });
  }
  /**
   * getProductsCSVForInsuranceExportFile
  */
  public getProductsCSVForInsuranceExportFile(
      clientId: number,
      rentalPointAccountId: number,
      bikeTypeId: number,
      bikeBrandId: number,
      bikeModelId: number,
      bikeSizeId: number,
      bikeTyreSizeId: number,
      qrCode: string,
      keyNumber: string,
      serialNumber: string,
      frameId: string,
      status: number
    ): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/downloadInsuranceCSV', {
      headers: HttpUtils.createHeaders(), params: {
        clientId: clientId,
        rentalPointAccountId: rentalPointAccountId,
        bikeTypeId: bikeTypeId,
        bikeBrandId: bikeBrandId,
        bikeModelId: bikeModelId,
        bikeSizeId: bikeSizeId,
        bikeTyreSizeId: bikeTyreSizeId,
        qrCode: qrCode,
        keyNumber: keyNumber,
        serialNumber: serialNumber,
        frameId: frameId,
        status: status
      }
    });
  }
}
