import { BikeType } from './BikeType';

export class BikeTypeTranslation {
    public id: number;
    public en: string;
    public it: string;
    public de: string;
    public bikeType: BikeType;

    public static fromDto(b): BikeTypeTranslation {
        const bikeTypeTranslations = new BikeTypeTranslation();
        if (!b) {
            return null;
        }

        bikeTypeTranslations.id = b.id;
        bikeTypeTranslations.en = b.en;
        bikeTypeTranslations.it = b.it;
        bikeTypeTranslations.de = b.de;
        bikeTypeTranslations.bikeType = b.bikeType;
    }
}
