import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { SharedUtils } from '../../../shared/shared-utils';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from '../../clients/shared/clients.service';
import { forkJoin } from 'rxjs';
import { ChashFlowService } from '../shared/chash-flow.service';
import { OnlineBookingSearchCriteria } from '../shared/OnlineBookingSearchCriteria';

@Component({
  selector: 'app-online-bookings',
  templateUrl: './online-bookings.component.html',
  styleUrls: ['./online-bookings.component.scss']
})
export class OnlineBookingsComponent implements OnInit {
  public filters: OnlineBookingSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public currentUserType = SharedUtils.getCurrentUserType();
  public rentalPointsLang: string;

  private _clients: Client[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('returnRentalPoint', { static: true })
  returnRentalPoint: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('returnDate', { static: true })
  returnDate: TemplateRef<any>;

  @ViewChild('orderAmount', { static: true })
  orderAmount: TemplateRef<any>;

  constructor(private _translate: TranslateService, public _snackBar: MatSnackBar,
    private _cashFlowService: ChashFlowService, private _clientsService: ClientsService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
      this.loadPage = (page: number, pageSize: number) => this._cashFlowService.searchOnlineBookings(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.clientName',
        template: this.clientName,
        sortable: true,
        excel: {
          objectRef: ['clientInformation.firstName', 'clientInformation.lastName']
        }
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['startRentalPoint.businessName']
        }
      },
      {
        name: 'returnRentalPoint',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.returnRentalPoint',
        template: this.returnRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['endRentalPoint.businessName']
        }
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.startRentalDate',
        template: this.startDate,
        sortable: true,
        excel: {
          objectRef: ['startDate'],
          pipe: 'date'
        }
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.returnRentalDate',
        template: this.returnDate,
        sortable: true,
        excel: {
          objectRef: ['endDate'],
          pipe: 'date'
        }
      },
      {
        name: 'orderAmount',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.orderAmount',
        template: this.orderAmount,
        sortable: true,
        excel: {
          objectRef: ['discountedPrice'],
          pipe: 'currency'
        }
      }
    ];
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: 'All' }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }
}
