import { Directive } from '@angular/core';

@Directive({
  selector: '[appCustomTableFilter]'
})
export class CustomTableFilterDirective {

  constructor() { }

}
