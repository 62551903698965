import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { HttpUtils } from '../../../shared/http-utils';
import { map } from 'rxjs/operators';
import { Account } from '../../../models/Account';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {

  constructor(private _http: HttpClient) { }

  /**
   * getAccount
   */
  public getAccount(accountId: string): Observable<Account> {
    return this._http.get(environment.serverUrl + 'api/getAccount', {
      headers: HttpUtils.createHeaders(), params: { accountId }
    }).pipe(
      map((result: any[]) => Account.fromDto(result))
    );
  }

  /**
   * updateAccount
   */
  public updateAccount(user: any): Observable<any> {
    return this._http.put<any>(
      environment.serverUrl + 'api/updateAccount',
      { user },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }
}
