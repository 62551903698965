<div class="row">
  <div class="col-12">
    <table class="table table-bordered text-center">
      <thead>
        <tr>
          <th>Start Day</th>
          <th>End Day</th>
          <th>Closed Day</th>
          <th>Start Hour</th>
          <th>Close Hour</th>
          <th>Lunch Brake</th>
          <th>Lunch Start Hour</th>
          <th>Lunch Close Hour</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
          <th>S</th>
        </tr>
      </thead>
      <tbody>
        <td>
          <app-datepicker [(model)]="scheduleException.startDay" ngDefaultControl></app-datepicker>
        </td>
        <td>
          <app-datepicker [(model)]="scheduleException.endDay" ngDefaultControl></app-datepicker>
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="scheduleException.closingException" (change)="closeDayCheckboxChange()" />
        </td>
        <td>
          <app-hours-select [(model)]="scheduleException.startHour" [disabled]="disableOpeningHours">
          </app-hours-select>
        </td>
        <td>
          <app-hours-select [(model)]="scheduleException.endHour" [disabled]="disableOpeningHours"></app-hours-select>
        </td>
        <td>
          <input type="checkbox" (change)="lunchBreackCheckboxChange($event)" [disabled]="disableLunchBreakCheckbox" />
        </td>
        <td>
          <app-hours-select [(model)]="scheduleException.lunchBreakStart" [disabled]="disableLunchHours">
          </app-hours-select>
        </td>
        <td>
          <app-hours-select [(model)]="scheduleException.lunchBreakEnd" [disabled]="disableLunchHours">
          </app-hours-select>
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[0]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[1]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[2]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[3]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[4]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[5]" />
        </td>
        <td>
          <input type="checkbox" [(ngModel)]="openingDaysArray[6]" />
        </td>
      </tbody>
    </table>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col">
        <div class="invalid-feedback" style="display: block;">
          {{errorMsg}}
        </div>
      </div>
      <div class="col text-right">
        <button type="button" class="btn btn-sm btn-secondary mr-2">Reset</button>
        <button type="button" class="btn btn-sm btn-primary" (click)="addNewScheduleException()">Add Exception</button>
      </div>
    </div>
  </div>
</div>