<div class="container-fluid" style="display: flex; justify-content: center; flex-direction: column; width: 300px">
  <h3 style="text-align: center">{{'credit.header' | translate}}</h3>
  <div class="card">
    <div class="card-body">
      <form class="k-form k-form-md" [formGroup]="creditForm">
        <kendo-formfield>
          <kendo-label
            text="{{'credit.title' | translate}}"
            [for]="name">
          </kendo-label>
          <kendo-textbox
            formControlName="name"
            #name
            required>
          </kendo-textbox>
          <kendo-formerror>
            {{'credit.insertError' | translate}}
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="{{'credit.quantity' | translate}}"
            [for]="quantity">
          </kendo-label>
          <kendo-numerictextbox
            formControlName="quantity"
            format="#"
            #quantity
            required>
          </kendo-numerictextbox>
          <kendo-formerror>
            {{'credit.insertError' | translate}}
          </kendo-formerror>
        </kendo-formfield>
        <kendo-formfield>
          <kendo-label
            text="{{'credit.amount' | translate}}"
            [for]="amount">
          </kendo-label>
          <kendo-numerictextbox
            formControlName="amount"
            format="#.00 €"
            #amount
            required>
          </kendo-numerictextbox>
          <kendo-formerror>
            {{'credit.insertError' | translate}}
          </kendo-formerror>
        </kendo-formfield>
        <div class="k-form-buttons" style="justify-content: center">
          <button class="btn btn-primary" (click)="submitCredit()">{{'credit.confirm' | translate}}</button>
        </div>
      </form>
    </div>
  </div>
</div>
