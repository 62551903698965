import { RentalPoint } from './RentalPoint';

export class District {
    public id: number;
    public name: string;
    public rentalPoints: RentalPoint[];

    public static fromDto(d): District {
        const district = new District();
        if (!d) {
            return null;
        }

        district.id = d.id;
        district.name = d.name;
        if (d.rentalPoints) {
            district.rentalPoints = d.rentalPoints.map(rentalPoint => RentalPoint.fromDto(rentalPoint));
        }

        return district;
    }
}
