import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'rentalDuration'
})
export class RentalDurationPipe implements PipeTransform {
  constructor() { }
  transform(dayType: any, startDate: any, endDate: any, translations: any): any {
    switch (dayType) {
      case 0:
        return '1 ' + translations.hour;
      case 1:
        return '2 ' + translations.hours;
      case 2:
        return '1/2 ' + translations.day;
      case 3:
        return '1 ' + translations.day;
      default:
        const dayDiff = moment(endDate).diff(moment(startDate), 'days') + 1;
        return dayDiff + ' ' + (dayDiff > 1 ? translations.days : translations.day);
    }
  }
}
