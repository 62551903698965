import { Component, OnInit, Input } from '@angular/core';
import { RentalPointsService } from '../rental-points.service';

@Component({
  selector: 'app-rental-point-subnav',
  templateUrl: './rental-point-subnav.component.html',
  styleUrls: ['./rental-point-subnav.component.scss']
})
export class RentalPointSubnavComponent implements OnInit {

  public rentalPointName: string;

  @Input()
  rentalPointId: number;

  constructor(private _rentalPointsService: RentalPointsService) { }

  ngOnInit() {
    this._rentalPointsService.getRentalPoint(this.rentalPointId).subscribe(result => {
      console.log(result);
      this.rentalPointName = result.rentalPoint.rentalPointTranslation.de;
    });
  }

}
