import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { ClientSearchCritertia } from '../shared/ClientSearchCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { ClientsService } from '../shared/clients.service';

@Component({
  selector: 'app-clients-list',
  templateUrl: './clients-list.component.html',
  styleUrls: ['./clients-list.component.scss']
})
export class ClientsListComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: ClientSearchCritertia = {};

  @ViewChild('clientId', { static: true })
  clientId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('clientAccountUsername', { static: true })
  clientAccountUsername: TemplateRef<any>;

  @ViewChild('clientRentalPointsAmount', { static: true })
  clientRentalPointsAmount: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;


  columns: DataTableColumn[] = [];

  constructor(private _clientsService: ClientsService) {
    this.loadPage = (page: number, pageSize: number) => this._clientsService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'clientId',
        displayed: true,
        translationKey: 'clients.clientsList.clientId',
        template: this.clientId,
        sortable: true
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'clients.clientsList.clientName',
        template: this.clientName,
        sortable: true
      },
      {
        name: 'clientAccountUsername',
        displayed: true,
        translationKey: 'clients.clientsList.clientAccountUsername',
        template: this.clientAccountUsername,
        sortable: true
      },
      {
        name: 'clientRentalPointsAmount',
        displayed: true,
        translationKey: 'clients.clientsList.clientRentalPointsAmount',
        template: this.clientRentalPointsAmount,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      },
    ];
  }

}
