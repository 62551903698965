import { RentalPoint } from './RentalPoint';
import { BikeType } from './BikeType';

class Quantity {
    public contingentBuffer: number;
    public contingentNumberOfProducts: number;
    public count: number;

    public static fromDto(q): Quantity {
        const quantity = new Quantity();
        if (!q) {
            return null;
        }

        quantity.contingentBuffer = q.contingentBuffer;
        quantity.contingentNumberOfProducts = q.contingentNumberOfProducts;
        quantity.count = q.count;

        return quantity;
    }
}

export class ExcessLack {
    public rentalPoint: RentalPoint;
    public bikeType: BikeType;
    public quantity: Quantity;


    public static fromDto(e): ExcessLack {
        const excessLack = new ExcessLack();
        if (!e) {
            return null;
        }

        excessLack.rentalPoint = RentalPoint.fromDto(e.rentalPoint);
        excessLack.bikeType = BikeType.fromDto(e.bikeType);
        excessLack.quantity = Quantity.fromDto(e.quantity);

        return excessLack;
    }
}
