import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bike } from '../models/Bike';
import { environment } from '../../environments/environment';
import { Equipment } from '../models/Equipment';
import { RentalPoint } from '../models/RentalPoint';
import { HttpUtils } from '../shared/http-utils';

@Injectable()
export class ProductService {

  constructor(private http: HttpClient) { }

  public getBikes(accountId): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'api/getBikes', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      },
      params: {
        accountId
      }
    });
  }

  public getBikesForTransfer(): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'api/getBikesForTransfer', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public getClientBike(id, accountType): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 'api/getProductsOnClientOrRentalPoint', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      },
      params: {
        accountId: id,
        accountType
      }
    });
  }

  public sendRentalPointId(rentalPoint: RentalPoint): Observable<any> {
    return this.http.post(environment.serverUrl + 'api/sendRP', rentalPoint, {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public addEquipment(equipment: Equipment): Observable<any> {
    return this.http.post(environment.serverUrl + 'api/addEquipment', equipment, {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public getEquipment(): Observable<any[]> {
    return this.http.get<any[]>(environment.serverUrl + 'api/getEquipment', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public getEquipmentById(id): Observable<Equipment> {
    return this.http.get<Equipment>(environment.serverUrl + 'api/getEquipmentById', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      },
      params: {
        equipmentId: id
      }
    });
  }

  public getBike(id) {
    return this.http.get(environment.serverUrl + 'api/getBike', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      },
      params: {
        productId: id
      }
    });
  }

  public editBike(bike: Bike): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/updateBike',
      { bike },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public editEquipment(equipment: Equipment): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/updateEquipment',
      { equipment },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public assignBikes(bikes, accountId, toId): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/assignBikes',
      {
        bikes,
        accountId,
        toId
      },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public updatePrice(bike): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/editBikePriceFromRentalPoint',
      {
        bike
      },
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public sendBikesToPapin(bike: any): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/assignFromClientBackToPapin',
      {
        bike
      },
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getBikeTypes(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeTypes'
    );
  }

  public getBikeSizes(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeSizes',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getBikeBrands(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeBrands',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getBikeModels(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeModels',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getBikeTyreSizes(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeTyreSizes',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getBikeColors(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getBikeColors',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public getRentedBikes(date: string, rentalPointAccountId: string, language: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this.http.get<any>(
      environment.serverUrl + 'api/getRentedBikes', {
        headers,
        params: {
          startDate: date,
          language: language,
          rentalPointAccountId: rentalPointAccountId
        },
      }
    );
  }

  public getStatsRentedBikes(date: string, rentalPointAccountId: string, language: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this.http.get<any>(
      environment.serverUrl + 'api/getStatsRentedBikes', {
        headers,
        params: {
          startDate: date,
          language: language,
          rentalPointAccountId: rentalPointAccountId
        },
      }
    );
  }

  public getContingentBikesOnSpot(date: string, rentalPointAccountId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this.http.get<any>(
      environment.serverUrl + 'api/getContingentBikesOnSpot', {
        headers,
        params: {
          date: date,
          rentalPointAccountId: rentalPointAccountId,
          onlyBikes: false
        },
      }
    );
  }

  public getEarlearBikeContingetExceptions(accountId, exceptionId) {
    return this.http.get<any>(environment.serverUrl + 'api/getEarlearBikeContingetExceptions', {
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      },
      params: {
        accountId,
        exceptionId
      }
    });
  }

  public assignMultipleQrCodesToSpecificBike(startQrCode: number, endQrCode: number, locationId: number, bike: Bike): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/assignMultipleQrCodesToSpecificBike',
      {
        startQrCode,
        endQrCode,
        locationId,
        'bikeDatas': bike
      },
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public updateBikeContingentException(
    rentalPointId: any, bikeQuantity: any, bufferQuantity: any, bikeTypeId: number,
    startDate: string, endDate: string, exceptionId: number): any {
    return this.http.post<any>(
      environment.serverUrl + 'api/updateBikeContingentException',
      {
        rentalPointId,
        bikeQuantity,
        bufferQuantity,
        bikeTypeId,
        startDate,
        endDate,
        exceptionId
      },
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }

  public deleteBikeContingentException(exceptionId: number) {
    return this.http.post<any>(
      environment.serverUrl + 'api/deleteBikeContingentException',
      { exceptionId },
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') }
      }
    );
  }
}
