import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { User } from '../models/User';
import { Client } from '../models/Client';
import { RentalPoint } from '../models/RentalPoint';
import { Country } from '../models/Country';
import { HttpUtils } from '../shared/http-utils';

@Injectable()
export class UserService {
  public redirectUrl: string;

  constructor(private http: HttpClient) {
  }

  public getUserByUsername(username: string): Observable<User> {
    return this.http.post<User>(environment.serverUrl + 'api/checkEmail', { username: username });
  }

  public login(username: string, password: string) {
    return this.http.post(environment.serverUrl + 'api/login', { username, password }, { observe: 'response' });
  }

  public checkAuthStatus(): any {
    return this.http.post<any>(
      environment.serverUrl + 'api/checkAuth',
      {},
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } })
      .toPromise();

  }

  public logout(): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/logout',
      {},
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public getAccount(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getAccount',
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } });
  }

  public getStaff(): Observable<User[]> {
    return this.http.get<User[]>(
      environment.serverUrl + 'api/getStaff',
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public getStaffAccount(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getAccount',
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } });
  }

  public addStaff(user: User): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/addAccount',
      { user },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public getClient(id: any): Observable<User> {
    return this.http.get<User>(
      environment.serverUrl + 'api/getClient',
      {
        params: {
          'userId': id
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      });
  }

  public updateAccount(user: any): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/updateAccount',
      { user },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public updateStaffAccount(user: any): Observable<any> {
    return this.http.put<any>(
      environment.serverUrl + 'api/updateAccount',
      { user },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public getClients(): Observable<User[]> {
    return this.http.get<User[]>(
      environment.serverUrl + 'api/getClients',
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } });
  }

  public addClient(user: User): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/addClient',
      { user },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public saveClient(client: any): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/updateClient',
      { client },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public deleteClient(id: any): Observable<any> {
    return this.http.delete(environment.serverUrl + 'api/deleteClient', {
      params: {
        userId: id
      },
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public deleteRentalPoint(id: any): Observable<any> {
    return this.http.delete(environment.serverUrl + 'api/deleteRentalPoint', {
      params: {
        userId: id
      },
      headers: {
        'X-Access-Token': localStorage.getItem('X-Access-Token')
      }
    });
  }

  public addRentalPoint(account): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/addRentalPoint',
      { account },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } });
  }

  public getRentalPoints(clientId: any): Observable<RentalPoint[]> {
    return this.http.get<RentalPoint[]>(
      environment.serverUrl + 'api/getRentalPoints',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') },
        params: { clientId: clientId }
      });
  }

  public getAllRentalPoints(): Observable<RentalPoint[]> {
    return this.http.get<RentalPoint[]>(
      environment.serverUrl + 'api/getRentalPoints',
      {
        headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') },
      });
  }

  public saveRentalPoint(account): Observable<any> {
    return this.http.post<any>(
      environment.serverUrl + 'api/updateRentalPoint',
      { account },
      { headers: { 'X-Access-Token': localStorage.getItem('X-Access-Token') } }
    );
  }

  public getRentalPoint(rentalPointId: any): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getRentalPoint',
      {
        params: { userId: rentalPointId }
      }
    );
  }

  public getUsers(): Observable<User[]> {
    return this.http.get<User[]>(
      environment.serverUrl + 'api/getUsers',
      {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      }
    );
  }

  public getUser(id): Observable<User> {
    return this.http.get<User>(
      environment.serverUrl + 'api/getUser',
      {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        },
        params: { userId: id }
      }
    );
  }

  public getClientsWithRentalPoints(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getClientsAndRentalPoints',
      {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      }
    );
  }

  public getCountries(lang?: string): Observable<any> {
    const options = {
      headers: HttpUtils.createHeaders(),
      params: {
        lang: lang ?? 'en'
      }
    };
    return this.http.get(environment.serverUrl + 'plugin/getCountries', options).pipe(
      map((result: any[]) => result.map(res => Country.fromDto(res)))
    );
  }

  public getDistricts(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getDistricts',
      {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      }
    );
  }

  public getWartungUsers(): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getWartungUsers',
      {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      }
    );
  }

}
