import { TurnoverActions } from './TurnoverActions';

export class Turnover {
    public contractId: number;
    public name: number;
    public amount: number;
    public onlineBooking: boolean;
    public payments: TurnoverActions[] = [];

    public static fromDto(t): Turnover {
        const turnover = new Turnover();
        if (!t) {
            return null;
        }
        turnover.contractId = t.contractId;
        turnover.name = t.name;
        turnover.amount = t.amount;
        turnover.onlineBooking = t.onlineBooking;
        turnover.payments = t.payments.map(payment => TurnoverActions.fromDto(payment));

        return turnover;
    }
}
