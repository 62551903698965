import { NgModule } from '@angular/core';
import { STATISTICS_ROUTES } from './statistics.routes';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { StatisticsComponent } from './statistics.component';
import { ChartModule } from '@progress/kendo-angular-charts';
import { ContingentStatsComponent } from './contingent/contingent-stats.component';
import { RentedBikeStatsComponent } from './rented-bikes/rented-bikes-stats.component';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(STATISTICS_ROUTES),
    FormsModule,
    CommonModule,
    ChartModule,
  ],
  exports: [],
  declarations: [
    StatisticsComponent,
    ContingentStatsComponent,
    RentedBikeStatsComponent,
  ]
})
export class StatisticsModule {
}
