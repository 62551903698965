import { Product } from './Product';

export class BikeBrand {
    public id: number;
    public name: string;
    public products: Product[];

    public static fromDto(b): BikeBrand {
        const bikeBrand = new BikeBrand();
        if (!b) {
            return null;
        }
        bikeBrand.id = b.id;
        bikeBrand.name = b.name;
        if (b.products) {
            bikeBrand.products = b.products.map(product => Product.fromDto(product));
        }

        return bikeBrand;
    }
}


export class BikeBrandSearch {
  public id: number;
  public name: string;

  public static fromDto(b): BikeBrandSearch {
      const bikeBrand = new BikeBrandSearch();
      if (!b) {
          return null;
      }
      bikeBrand.id = b.id;
      bikeBrand.name = b.name;

      return bikeBrand;
  }
}
