<header>
  <div class="container-fluid">
    <div class="row">
      <div class="col-2">
        <a href="#" id="header">
          <img [src]="imagePath" class="logo">
        </a>
      </div>
    </div>
  </div>
</header>
