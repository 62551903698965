import { Pipe, PipeTransform } from '@angular/core';
import { ReturnTypeEnum } from '../../../../shared/enums/ReturnTypeEnum';

@Pipe({
  name: 'returnType'
})
export class ReturnTypePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let returnType;
    switch (value) {
      case 1:
        returnType = ReturnTypeEnum.ONE;
        break;
      case 2:
        returnType = ReturnTypeEnum.TWO;
        break;
      default:
        // returnType = ReturnTypeEnum.ZERO;
        break;
    }
    return returnType;
  }

}
