import { Routes } from '@angular/router';
import { StatisticsComponent } from './statistics.component';
import { AuthGuard } from '../../auth-guard.service';
import { ContingentStatsComponent } from './contingent/contingent-stats.component';
import { RentedBikeStatsComponent } from './rented-bikes/rented-bikes-stats.component';

export const STATISTICS_ROUTES: Routes = [
  {
    path: 'statistics', component: StatisticsComponent, canActivate: [AuthGuard], children: [
      { path: 'contingent', component: ContingentStatsComponent, canActivate: [AuthGuard] },
      { path: 'rentedBikes', component: RentedBikeStatsComponent, canActivate: [AuthGuard] },
    ]
  }
];
