import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { RENTAL_POINT_ROUTES } from './rental-point.routes';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { LeakExcessComponent } from './leak-excess/leak-excess.component';
import { LeackTableComponent } from './leak-excess/leack-table/leack-table.component';
import { ExcessTableComponent } from './leak-excess/excess-table/excess-table.component';
import { RentalPointComponent } from './rental-point.component';
import { ManageSchedulesComponent } from './manage-schedules/manage-schedules.component';
import { RentalPointSubnavComponent } from './shared/rental-point-subnav/rental-point-subnav.component';
import { HoursSelectComponent } from './shared/hours-select/hours-select.component';
import { NewScheduleExceptionFormComponent } from './shared/new-schedule-exception-form/new-schedule-exception-form.component';
import { DefaultBikeContingentComponent } from './default-bike-contingent/default-bike-contingent.component';
import { BikeContingentExceptionsComponent } from './bike-contingent-exceptions/bike-contingent-exceptions.component';
import { ManageBikeTypePeriodComponent } from './manage-bike-type-period/manage-bike-type-period.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(RENTAL_POINT_ROUTES),
        Angular2SmartTableModule,
        FormsModule,
        CommonModule
    ],
    declarations: [
        RentalPointComponent,
        LeakExcessComponent,
        LeackTableComponent,
        ExcessTableComponent,
        ManageSchedulesComponent,
        RentalPointSubnavComponent,
        HoursSelectComponent,
        NewScheduleExceptionFormComponent,
        DefaultBikeContingentComponent,
        BikeContingentExceptionsComponent,
        ManageBikeTypePeriodComponent
    ],
    exports: [
        RentalPointSubnavComponent,
        HoursSelectComponent,
        NewScheduleExceptionFormComponent
    ]
})

export class RentalPointModule { }
