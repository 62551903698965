<div class="container">
  <div class="row">
    <div class="col">
      <form (ngSubmit)="login()" class="form-signin text-center">
        <img class="mb-4" [src]="'assets/images/pbikecolor-02.png'" alt="" width="180">
        <div class="form-group">
          <input type="text" [(ngModel)]="user.username" required class="form-control" id="username" name="username"
            placeholder="{{'login.userName' | translate }}" />
        </div>
        <div class="form-group">
          <input type="password" [(ngModel)]="user.password" name="password" class="form-control" id="passwordLogin"
            required placeholder="{{'login.password' | translate}}" />
        </div>
        <div class="form-group">
          <input type="submit" value="{{ 'login.login' | translate }}" class="btn btn-lg btn-primary btn-block" />
        </div>
        <p><a routerLink="/forgot-password" [routerLinkActive]="['active']"><span translate>{{'login.forgotPassword' | translate}}</span>?</a>
        </p>
      </form>
    </div>
  </div>
</div>