import { Product } from './Product';

export class BikeColor {
    public id: number;
    public name: string;
    public products: Product[];

    public static fromDto(b): BikeColor {
        const bikeColor = new BikeColor();
        if (!b) {
            return null;
        }
        bikeColor.id = b.id;
        bikeColor.name = b.name;
        if (b.products) {
            bikeColor.products = b.products.map(product => Product.fromDto(product));
        }

        return bikeColor;
    }
}

export class BikeColorSearch {
  public id: number;
  public name: string;

  public static fromDto(b): BikeColorSearch {
      const bikeColor = new BikeColorSearch();
      if (!b) {
          return null;
      }
      bikeColor.id = b.id;
      bikeColor.name = b.name;
      return bikeColor;
  }
}
