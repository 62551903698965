import { BikeSize } from './BikeSize';
import { BikeBrand } from './BikeBrand';
import { BikeColor } from './BikeColor';
import { BikeModel } from './BikeModel';
import { BikeTyreSize } from './BikeTyreSize';
import { BikeType } from './BikeType';

export class SoldBike {
    public soldCheckListBikeId: number;
    public createdAt: string;
    public accountName: string;
    public bikeTypeId: number;
    public bikeSizeId: number;
    public bikeBrandId: number;
    public bikeModelId: number;
    public year: number;
    public keyNumber: string;
    public bikeTyreSizeId: number;
    public serialNumber: string;
    public qrCode: string;
    public frameId: string;
    public bikeColorId: number;
    public deleted: boolean;
    public price: number;
    /**
     * Status 1 Available
     * Status 2 Rented
     * Status 3 Big maintainance --> bike will be send to papinbike or even to the supplier
     * Status 4 local maintainance --> fixed in the rentalpoint soon be available
     * Status 5 bike in transit
     * Status 6 bike no more available --> total damaged, stolen, to be returned to supplier not for maintenance
     * Status 7 the bike was sold to a private client
     */
    public status: number;
    public bikeSize: BikeSize = new BikeSize();
    public bikeBrand: BikeBrand = new BikeBrand();
    public bikeColor: BikeColor = new BikeColor();
    public bikeModel: BikeModel = new BikeModel();
    public bikeTyreSize: BikeTyreSize = new BikeTyreSize();
    public bikeType: BikeType = new BikeType();
    public static fromDto(p): SoldBike {
        const soldBike = new SoldBike();
        if (!p) {
            return null;
        }

        soldBike.soldCheckListBikeId = p.soldCheckListBikeId;
        soldBike.createdAt = p.createdAt;
        soldBike.accountName = p.accountName;
        soldBike.bikeTypeId = p.bikeTypeId;
        soldBike.bikeSizeId = p.bikeSizeId;
        soldBike.bikeBrandId = p.bikeBrandId;
        soldBike.bikeModelId = p.bikeModelId;
        soldBike.year = p.year;
        soldBike.keyNumber = p.keyNumber;
        soldBike.bikeTyreSizeId = p.bikeTyreSizeId;
        soldBike.serialNumber = p.serialNumber;
        soldBike.qrCode = p.qrCode;
        soldBike.frameId = p.frameId;
        soldBike.bikeColorId = p.bikeColorId;
        soldBike.deleted = p.deleted;
        soldBike.price = p.price;
        soldBike.status = p.status;
        soldBike.bikeSize = BikeSize.fromDto(p.bikeSize);
        soldBike.bikeBrand = BikeBrand.fromDto(p.bikeBrand);
        soldBike.bikeColor = BikeColor.fromDto(p.bikeColor);
        soldBike.bikeModel = BikeModel.fromDto(p.bikeModel);
        soldBike.bikeTyreSize = BikeTyreSize.fromDto(p.bikeTyreSize);
        soldBike.bikeType = BikeType.fromDto(p.bikeType);

        return soldBike;
    }
}
