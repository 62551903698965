<app-rental-point-subnav [rentalPointId]="rentalPointId"></app-rental-point-subnav>
<div class="container" style="padding-top: 20px; padding-bottom: 20px;">
    <div class="row">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>{{'rentalPoint.manageBikeTypePeriod.bikeType' | translate}}</th>
                    <th>{{'rentalPoint.manageBikeTypePeriod.days' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let element of bikeTypeAvalibities">
                    <td style="vertical-align: middle;">{{element.bikeType}}</td>
                    <td>
                        <input type="number" class="form-control form-control-sm"
                            [(ngModel)]="element.bikeAvailabilityPeriod" min="0"
                            placeholder="{{'rentalPoint.manageBikeTypePeriod.days' | translate}}" />
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="row">
        <div class="col text-center">
            <button type="button" class="btn btn-primary"
                (click)="updateBikeTypeAvalibities()">{{'rentalPoint.manageBikeTypePeriod.save' |
                translate}}</button>
        </div>
    </div>
</div>