import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../../models/User';
import { UserService } from '../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Input() user = new User();

  constructor(
    private userService: UserService,
    private router: Router,
    public snackBar: MatSnackBar,
    private _translate: TranslateService,
  ) { }

  getUserByUsername = (form) => {
    const user = this.userService.getUserByUsername(this.user.username);
    user.subscribe({
      next: (data) => {
        this.user = data;
      },
      error: () =>
        this.snackBar.open(this._translate.instant('forgotPassword.invalidUserName'), '', {
          duration: 2000,
        })
    });
    return false;
  }

  ngOnInit() {
  }

}
