import { Account } from './Account';
import { BikeSize } from './BikeSize';
import { BikeBrand } from './BikeBrand';
import { BikeColor } from './BikeColor';
import { BikeModel } from './BikeModel';
import { BikeTyreSize } from './BikeTyreSize';
import { BikeType } from './BikeType';

export class Product {
    public id: number;
    public bikeTypeId: number;
    public bikeSizeId: number;
    public bikeBrandId: number;
    public bikeModelId: number;
    public year: number;
    public keyNumber: string;
    public bikeTyreSizeId: number;
    public serialNumber: string;
    public qrCode: string;
    public frameId: string;
    public bikeColorId: number;
    public deleted: boolean;
    public location: Account = new Account();
    public price: number;
    /**
     * Status 1 Available
     * Status 2 Rented
     * Status 3 Big maintainance --> bike will be send to papinbike or even to the supplier
     * Status 4 local maintainance --> fixed in the rentalpoint soon be available
     * Status 5 bike in transit
     * Status 6 bike no more available --> total damaged, stolen, to be returned to supplier not for maintenance
     * Status 7 the bike was sold to a private client
     */
    public status: number;
    public createdAt: string;
    public imagePath: string;
    public bikeSize: BikeSize = new BikeSize();
    public bikeBrand: BikeBrand = new BikeBrand();
    public bikeColor: BikeColor = new BikeColor();
    public bikeModel: BikeModel = new BikeModel();
    public bikeTyreSize: BikeTyreSize = new BikeTyreSize();
    public bikeType: BikeType = new BikeType();
    public static fromDto(p): Product {
        const product = new Product();
        if (!p) {
            return null;
        }

        product.id = p.id;
        product.bikeTypeId = p.bikeTypeId;
        product.bikeSizeId = p.bikeSizeId;
        product.bikeBrandId = p.bikeBrandId;
        product.bikeModelId = p.bikeModelId;
        product.year = p.year;
        product.keyNumber = p.keyNumber;
        product.bikeTyreSizeId = p.bikeTyreSizeId;
        product.serialNumber = p.serialNumber;
        product.qrCode = p.qrCode;
        product.frameId = p.frameId;
        product.bikeColorId = p.bikeColorId;
        product.deleted = p.deleted;
        product.location = Account.fromDto(p.location);
        product.price = p.price;
        product.status = p.status;
        product.createdAt = p.createdAt;
        product.bikeSize = BikeSize.fromDto(p.bikeSize);
        product.bikeBrand = BikeBrand.fromDto(p.bikeBrand);
        product.bikeColor = BikeColor.fromDto(p.bikeColor);
        product.bikeModel = BikeModel.fromDto(p.bikeModel);
        product.bikeTyreSize = BikeTyreSize.fromDto(p.bikeTyreSize);
        product.bikeType = BikeType.fromDto(p.bikeType);
        product.imagePath = p.imagePath;

        return product;
    }
}
