<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="addServicePointForm" (ngSubmit)="addServicePointSubmit()">
            <div class="row">
              <div class="col">
                <div class="row" style="display:none">
                  <div class="form-group">
                    <label>{{'servicePoints.addServicePoint.id' | translate}} *</label>
                    <input type="text" name="id" id="id" formControlName="id" required
                      placeholder="{{'servicePoints.addServicePoint.id' | translate}}"
                      class="form-control {{errorArray['id'] ? 'is-invalid' : ''}}" readonly />
                    <div class="invalid-feedback">
                      {{errorArray['id']}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label>{{'servicePoints.editServicePoint.en' | translate}}*</label>
                    <input type="text" name="en" id="en" formControlName="en" required
                      placeholder="{{'servicePoints.editServicePoint.en' | translate}}"
                      class="form-control {{errorArray['en'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['en']}}
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label>{{'servicePoints.editServicePoint.it' | translate}}*</label>
                    <input type="text" name="it" id="it" formControlName="it" required
                      placeholder="{{'servicePoints.editServicePoint.it' | translate}}"
                      class="form-control {{errorArray['it'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['it']}}
                    </div>
                  </div>
                  <div class="form-group col-4">
                    <label>{{'servicePoints.editServicePoint.de' | translate}}*</label>
                    <input type="text" name="de" id="de" formControlName="de" required
                      placeholder="{{'servicePoints.editServicePoint.de' | translate}}"
                      class="form-control {{errorArray['de'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['de']}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.region' | translate}}*</label>
                    <input type="text" name="region" id="region" formControlName="region" required
                      placeholder="{{'servicePoints.editServicePoint.region' | translate}}"
                      class="form-control {{errorArray['region'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['region']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.street' | translate}}*</label>
                    <input type="text" name="street" id="street" formControlName="street" required
                      placeholder="{{'servicePoints.editServicePoint.street' | translate}}"
                      class="form-control {{errorArray['street'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['street']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="districtId">{{'servicePoints.editServicePoint.district' | translate}} *</label>
                    <select name="districtId" id="districtId" formControlName="districtId" required class="form-control">
                      <option *ngFor="let district of districtOptions" [value]="district.value">{{district.label}}</option>
                    </select>
                    <div class="invalid-feedback">
                      {{errorArray['districtId']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label for="countryId">{{'servicePoints.editServicePoint.country' | translate}} *</label>
                    <select name="countryId" id="countryId" formControlName="countryId" required class="form-control">
                      <option *ngFor="let country of countryOptions" [value]="country.value">{{country.label}}</option>
                    </select>
                    <div class="invalid-feedback">
                      {{errorArray['countryId']}}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.city' | translate}}*</label>
                    <input type="text" name="city" id="city" formControlName="city" required
                      placeholder="{{'servicePoints.editServicePoint.city' | translate}}"
                      class="form-control {{errorArray['city'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['city']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.phone' | translate}}*</label>
                    <input type="text" name="phone" id="phone" formControlName="phone" required
                      placeholder="{{'servicePoints.editServicePoint.phone' | translate}}"
                      class="form-control {{errorArray['phone'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['phone']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.latitude' | translate}}*</label>
                    <input type="text" name="latitude" id="latitude" formControlName="latitude" required
                      placeholder="{{'servicePoints.editServicePoint.latitude' | translate}}"
                      class="form-control {{errorArray['latitude'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['latitude']}}
                    </div>
                  </div>
                  <div class="form-group col-3">
                    <label>{{'servicePoints.editServicePoint.longitude' | translate}}*</label>
                    <input type="text" name="longitude" id="longitude" formControlName="longitude" required
                      placeholder="{{'servicePoints.editServicePoint.longitude' | translate}}"
                      class="form-control {{errorArray['longitude'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['longitude']}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary">{{'servicePoints.addServicePoint.insert' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
