import { Pipe, PipeTransform } from '@angular/core';
import { OrderStatusEnum } from '../../../../shared/enums/OrderStatusEnum';
import * as moment from 'moment';

@Pipe({
  name: 'orderStatus'
})
export class OrderStatusPipe implements PipeTransform {

  transform(value: any, endDate?: any, isExtension?: boolean): any {
    let orderStatus = '';
    switch (value) {
      case 2:
        orderStatus = OrderStatusEnum.TWO;
        break;
      case 3:
        orderStatus = OrderStatusEnum.THREE;
        break;
      default:
        orderStatus = OrderStatusEnum.ONE;
        if (endDate) {
          const momentEndDate = moment(endDate);
          const today = moment().startOf('day');
          if (today > momentEndDate) {
            if (isExtension) {
              orderStatus = OrderStatusEnum.FIVE;
            } else {
              orderStatus = OrderStatusEnum.FOUR;
            }
          }
        }
        break;
    }
    return orderStatus;
  }

}
