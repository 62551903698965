<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="editProductFormGroup" (ngSubmit)="editBikeSubmit()">
            <div class="row">
              <div class="col-4">
                <a [href]="this.product.imagePath" target="_blank" *ngIf="this.product">
                  <img [src]="this.product.imagePath" max-width="100%" height="200px" width="auto" />
                </a>
              </div>
              <div class="col-8">
                <div class="row">
                  <div class="form-group col-6">
                    <label for="qrCode">{{'products.editBikeEquipment.qrCode' | translate}} *</label>
                    <input type="text" id="qrCode" name="qrCode" required formControlName="qrCode" class="form-control" />
                  </div>
                  <div class="form-group col-6">
                    <label for="status">{{'products.editBikeEquipment.bikeStatus' | translate}}</label>
                    <select name="status" id="status" formControlName="status" required class="form-control">
                      <option *ngFor="let bikeStatus of productsStatus" [value]="bikeStatus.value">
                        {{bikeStatus.label | translate}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="bikeType">{{'products.editBikeEquipment.bikeType' | translate}} *</label>
                    <select name="bikeType" id="bikeType" formControlName="bikeTypeId" required class="form-control">
                      <option *ngFor="let bikeType of bikeTypeOptions" [value]="bikeType.value">{{bikeType.label}}</option>
                    </select>
                  </div>
                  <div class="form-group col-6">
                    <label for="bikeType">{{'products.editBikeEquipment.brandName' | translate}}</label>
                    <select name="brandName" id="brandName" formControlName="bikeBrandId" class="form-control">
                      <option *ngFor="let bikeBrand of bikeBrandOptions" [value]="bikeBrand.value">{{bikeBrand.label}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="model">{{'products.editBikeEquipment.bikeModel' | translate}}</label>
                    <select name="model" id="model" formControlName="bikeModelId" class="form-control">
                      <option *ngFor="let bikeModel of bikeModelOptions" [value]="bikeModel.value">{{bikeModel.label}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <label for="bikeSize">{{'products.editBikeEquipment.bikeSize' | translate}}</label>
                    <select name="bikeSize" id="bikeSize" formControlName="bikeSizeId" class="form-control">
                      <option *ngFor="let bikeSize of bikeSizeOptions" [value]="bikeSize.value">{{bikeSize.label}}</option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <label for="tyreSize">{{'products.editBikeEquipment.tyreSize' | translate}}</label>
                    <select name="tyreSize" id="tyreSize" formControlName="bikeTyreSizeId" class="form-control">
                      <option *ngFor="let bikeTyreSize of bikeTyreSizeOptions" [value]="bikeTyreSize.value">
                        {{bikeTyreSize.label}}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-4">
                    <label for="year">{{'products.editBikeEquipment.year' | translate}}</label>
                    <select id="year" name="year" class="form-control" formControlName="year">
                      <option *ngFor="let year of yearOptions" [value]="year.value">
                        {{year.label}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <label for="color">{{'products.editBikeEquipment.color' | translate}}</label>
                    <select name="color" id="color" class="form-control" formControlName="bikeColorId">
                      <option *ngFor="let bikeTyreSize of bikeColorOptions" value="{{bikeTyreSize.value}}">
                        {{bikeTyreSize.label}}
                      </option>
                    </select>
                  </div>
                  <div class="form-group col-4">
                    <label for="keyNumber">{{'products.editBikeEquipment.keyNumber' | translate}}</label>
                    <input type="text" id="keyNumber" name="keyNumber" formControlName="keyNumber" class="form-control" />
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-6">
                    <label for="serialNumber">{{'products.editBikeEquipment.serialNumber' | translate}}</label>
                    <input type="text" id="serialNumber" name="serialNumber" formControlName="serialNumber"
                      class="form-control" />
                  </div>
                  <div class="form-group col-6">
                    <label for="frameId">{{'products.editBikeEquipment.frameId' | translate}}</label>
                    <input type="text" id="frameId" name="frameId" formControlName="frameId" class="form-control" readonly/>
                  </div>
                </div>
                <div class="row">
                  <div class="col text-right">
                    <button type="submit"
                      class="btn btn-primary btn-sm">{{'products.editBikeEquipment.update' | translate}}</button>&nbsp;&nbsp;
                    <button type="button" (click)="openWebcam()"
                      class="btn btn-secondary btn-sm">{{'products.editBikeEquipment.openWebcam' | translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row webcam-container card" *ngIf="showWebcam">
            <div class="webcam-title webcam-maintitle">{{'products.editBikeEquipment.webcamTitle' | translate}}</div>
            <div class="row">
              <div class="column-left webcam">
                <div class="webcam-title">Webcam</div>
                <div class="webcam-webcam">
                  <webcam
                    [trigger]="triggerObservable"
                    (imageCapture)="handleImage($event)"
                    [width]=300
                  ></webcam>
                </div>
                <div class="webcam-button">
                  <button class="btn btn-primary" (click)="triggerSnapshot();">{{'products.editBikeEquipment.takeSnapshot' | translate}}</button>
                  <br/>
                  <ng-container *ngIf="productImagePath">
                    <div class="my-4 col-6"  style="display: inline-block;" *ngIf="!product.imagePath">
                      <label for="wartungUser">{{ 'products.editBikeEquipment.selectWartungUser' | translate }}</label>
                      <select name="wartungUser" id="wartungUser" required class="form-control"  [(ngModel)]="wartungUserIdSelected">
                        <option *ngFor="let wartungUser of wartungUserOptions" [value]="wartungUser.value" [disabled]="!wartungUser.value">{{wartungUser.label}}</option>
                      </select>
                    </div>
                    <button class="btn btn-success" (click)="savePicture();" [disabled]="!product.imagePath && !wartungUserIdSelected">
                      {{'products.editBikeEquipment.savePicture' | translate}}
                    </button>
                  </ng-container>
                </div>
              </div>
              <div class="column-right" *ngIf="productImagePath">
                <div class="webcam-result">
                  <div class="webcam-title">{{'products.editBikeEquipment.previewPicture' | translate}}</div>
                  <img src="{{ productImagePath }}" width="100%">
                </div>
              </div>
            </div>
          </div>
          <hr />
          <app-bike-checkin-list *ngIf="product && product.id" [product]="product"></app-bike-checkin-list>
        </div>
      </div>
    </div>
  </div>
</div>
