import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../shared/orders.service';
import { Credits } from '../../../../models/Credits';

@Component({
  selector: 'app-credit-paper',
  templateUrl: './credit-paper.component.html',
  styleUrls: ['./credit-paper.component.scss']
})
export class CreditPaperComponent implements OnInit {

  public credit: Credits = new Credits();

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this._ordersService.getCreditByCreditCode(params['creditCode']).subscribe(result => {
        console.log(result);
        this.credit = result;
      });
    });
  }

}
