<div style="border:2px solid #23496e; padding: 50px; border-radius:20px; width:728px; margin:80px auto 0px auto; background-color: #FFFFFF;">
  <p style="text-align: center;">
    <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="img-fluid" width="150" />
  </p>
  <h1 style="text-align: center; margin-top:50px; margin-bottom:50px;">
    {{ 'ordercancellation.title' | translate }}<br>{{ contractId }}</h1>
  <h4 style="text-align: center;" *ngIf="!confirmed; else elseTemplate">
    {{ 'ordercancellation.confirmMessage' | translate }}
    <br/><br/>
    <input (click)="onCancelOrderConfirm()" value="{{'ordercancellation.confirmButton' | translate}}"
           class="btn btn-sm btn-danger"/>
  </h4>
  <ng-template #elseTemplate>
    {{ clientMessage }}
  </ng-template>
</div>
