import { Component } from '@angular/core';


@Component({
  selector: 'app-orders-long-term',
  templateUrl: './orders-long-term.component.html',
  styleUrls: ['./orders-long-term.component.scss']
})
export class OrdersLongTermComponent {
}
