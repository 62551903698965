import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { ClientsService } from '../../clients/shared/clients.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { forkJoin } from 'rxjs';
import { ProductSummary } from '../shared/ProductSummary';
import { Client } from '../../../models/Client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bike-equipment-summary',
  templateUrl: './bike-equipment-summary.component.html',
  styleUrls: ['./bike-equipment-summary.component.scss']
})
export class BikeEquipmentSummaryComponent implements OnInit {

  public userType = SharedUtils.getCurrentUserType();
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public productSummary: ProductSummary[] = [];
  public selectedClientId = '0';
  public selectedRentalPointAccountId = 0;
  public currentLang: string;

  private _currentUserAccountId = this.userType.accountId;
  private _clients: Client[] = [];

  constructor(private _productsService: ProductsService, private _clientsService: ClientsService,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: 0, label: this._translate.instant('products.bikeEquipmentSummary.all') }];
    this.rentalPointOptions = [{ value: 0, label: this._translate.instant('products.bikeEquipmentSummary.all') }];
  }

  ngOnInit() {
    let clientId;
    let rentalPointAccountId;
    if (this.userType.userTypeNumber === 1) {
      clientId = this.userType.clientId;
      this.selectedClientId = this.userType.clientId;
    } else {
      if (this.userType.userTypeNumber === 2) {
        rentalPointAccountId = this._currentUserAccountId;
      }
    }
    forkJoin([
      this._productsService.getProductsSummary(clientId, rentalPointAccountId),
      this._clientsService.getClients()
    ]).subscribe(result => {
      console.log(result);
      this.productSummary = result[0].filter(res => res.bikeType.status !== 0);
      this._clients = result[1];
      this._clients.forEach(client => {
        this.clientOptions.push({ value: client.id, label: client.businessName });
      });
      if (this.userType.userTypeNumber === 1) {
        this.onClientSelectChange();
      }
    });
  }

  onClientSelectChange() {
    this.selectedRentalPointAccountId = 0;
    this.rentalPointOptions = [{ value: 0, label: 'All' }];
    if (this.selectedClientId.toString() !== '0') {
      const selectedClient = this._clients.filter(client => client.id.toString() === this.selectedClientId.toString())[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onFilterClick() {
    this._productsService.getProductsSummary(
      (this.selectedClientId.toString() === '0' ? undefined : this.selectedClientId.toString()),
      (this.selectedRentalPointAccountId.toString() === '0' ? undefined : this.selectedRentalPointAccountId.toString()))
      .subscribe(result => {
        this.productSummary = result.filter(res => res.bikeType.status !== 0);
        console.log(this.productSummary);
      });
  }
}
