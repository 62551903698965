import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypeEnum } from '../../../../shared/enums/OrderTypeEnum';

@Pipe({
  name: 'orderTypeAllOrders'
})

export class OrderTypeAllOrdersPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 1:
        return OrderTypeEnum.ONE;
      case 2:
        return OrderTypeEnum.TWO;
      case 3:
        return OrderTypeEnum.THREE;
      default:
        return OrderTypeEnum.TWO;
    }
  }

}
