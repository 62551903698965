import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchObjectResult } from '../../../models/SearchObject';
import { ProductSearchCritertia } from '../shared/ProductSearchCritertia';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { ProductsService } from '../shared/products.service';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { ProductStatus } from '../shared/ProductStatus';
import { ClientsService } from '../../clients/shared/clients.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { Client } from '../../../models/Client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bike-equipment-list',
  templateUrl: './bike-equipment-list.component.html',
  styleUrls: ['./bike-equipment-list.component.scss']
})
export class BikeEquipmentListComponent implements OnInit {
  public currentUserType = SharedUtils.getCurrentUserType();
  public currentLang: string;
  public bikeSizeOptions: Option[];
  public bikeTypeOptions: Option[];
  public bikeModelOptions: Option[];
  public bikeBrandOptions: Option[];
  public bikeTyreSizeOptions: Option[];
  public productsStatus: Option[];
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public sortOptions: Option[];
  public orderOptions: Option[];
  public year: Option[];
  public choosenQrCode: string;

  public _clients: Client[];
  public allMoveClients: Client[];
  public moveClientOptions: Option[];

  public selectedArray: number [] = [];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: ProductSearchCritertia = {};

  @ViewChild('bikeId', { static: true })
  bikeId: TemplateRef<any>;

  @ViewChild('bikeType', { static: true })
  bikeType!: TemplateRef<any>;

  @ViewChild('brandName', { static: true })
  brandName: TemplateRef<any>;

  @ViewChild('model', { static: true })
  model: TemplateRef<any>;

  @ViewChild('bikeSize', { static: true })
  bikeSize: TemplateRef<any>;

  @ViewChild('tyreSize', { static: true })
  tyreSize: TemplateRef<any>;

  @ViewChild('qrCode', { static: true })
  qrCode: TemplateRef<any>;

  @ViewChild('serialNumber', { static: true })
  serialNumber: TemplateRef<any>;

  @ViewChild('keyNumber', { static: true })
  keyNumber: TemplateRef<any>;

  @ViewChild('frameId', { static: true })
  frameId: TemplateRef<any>;

  @ViewChild('location', { static: true })
  location: TemplateRef<any>;

  @ViewChild('status', { static: true })
  status: TemplateRef<any>;

  @ViewChild('imagePath', { static: true })
  imagePath: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _productsService: ProductsService, private _translate: TranslateService, private _clientsService: ClientsService,
    private _modalService: NgbModal, private _snackBar: MatSnackBar, private _router: Router) {
    this.currentLang = this._translate.currentLang;
    this.bikeSizeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeTypeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeModelOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeBrandOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeTyreSizeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.productsStatus = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.clientOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.year = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.orderOptions = [
      { value: 'ASC', label: this._translate.instant('data-table.asc') },
      { value: 'DESC', label: this._translate.instant('data-table.desc') },
    ];
    this.sortOptions = [
      { value: undefined, label: this._translate.instant('data-table.unsorted') },
      { value: 'bikeType', label:  this._translate.instant('products.bikeEquipmentList.bikeType')},
      { value: 'brandName', label: this._translate.instant('products.bikeEquipmentList.brandName')},
      { value: 'model', label:  this._translate.instant('products.bikeEquipmentList.model')},
      { value: 'bikeSize', label:  this._translate.instant('products.bikeEquipmentList.bikeSize')},
      { value: 'tyreSize', label:  this._translate.instant('products.bikeEquipmentList.tyreSize')},
      { value: 'qrCode', label:  this._translate.instant('products.bikeEquipmentList.qrCode')},
      { value: 'serialNumber', label:  this._translate.instant('products.bikeEquipmentList.serialNumber')},
      { value: 'keyNumber', label:  this._translate.instant('products.bikeEquipmentList.keyNumber')},
      { value: 'frameId', label:  'Frame ID' },
      { value: 'status', label:  this._translate.instant('products.bikeEquipmentList.status')},
    ];
  }

  ngOnInit() {

    const bikeSizes = this._productsService.getBikeSizes();
    const bikeTypes = this._productsService.getBikeTypes();
    const bikeModels = this._productsService.getBikeModels();
    const bikeBrands = this._productsService.getBikeBrands();
    const bikeTyreSizes = this._productsService.getBikeTyreSizes();
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([bikeSizes, bikeTypes, bikeModels, bikeBrands, bikeTyreSizes, accountsRequest]).subscribe(result => {
      result[0].forEach(bikeSize => {
        this.bikeSizeOptions.push({ value: bikeSize.id, label: bikeSize.size });
      });
      result[1].forEach(bikeType => {
        this.bikeTypeOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
      });
      result[2].forEach(bikeModel => {
        this.bikeModelOptions.push({ value: bikeModel.id, label: bikeModel.name });
      });
      result[3].forEach(bikeBrand => {
        this.bikeBrandOptions.push({ value: bikeBrand.id, label: bikeBrand.name });
      });
      result[4].forEach(bikeTyreSize => {
        this.bikeTyreSizeOptions.push({ value: bikeTyreSize.id, label: bikeTyreSize.size });
      });
      ProductStatus.getProductStatus().forEach(status => {
        this.productsStatus.push({ value: status.status, label: status.statusName });
      });
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[5]);
      }
      this.filters.lang = this.currentLang;
      this.filters.orderBy = this.orderOptions[0].value;
      this.loadPage = (page: number, pageSize: number) => this._productsService.search(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'bikeId',
        displayed: true,
        translationKey: '',
        template: this.bikeId,
        sortable: true
      },
      {
        name: 'bikeType',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.bikeType',
        template: this.bikeType,
        sortable: true
      },
      {
        name: 'brandName',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.brandName',
        template: this.brandName,
        sortable: true
      },
      {
        name: 'model',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.model',
        template: this.model,
        sortable: true
      },
      {
        name: 'bikeSize',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.bikeSize',
        template: this.bikeSize,
        sortable: true
      },
      {
        name: 'tyreSize',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.tyreSize',
        template: this.tyreSize,
        sortable: true
      },
      {
        name: 'qrCode',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.qrCode',
        template: this.qrCode,
        sortable: true
      },
      {
        name: 'serialNumber',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.serialNumber',
        template: this.serialNumber,
        sortable: true
      },
      {
        name: 'keyNumber',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.keyNumber',
        template: this.keyNumber,
        sortable: true
      },
      {
        name: 'frameId',
        displayed: true,
        translationKey: 'Frame Id',
        template: this.frameId,
        sortable: true
      },
      {
        name: 'location',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.location',
        template: this.location,
        sortable: true
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.status',
        template: this.status,
        sortable: true
      },
      {
        name: 'imagePath',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.imagePath',
        template: this.imagePath,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.actions',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      }
    ];
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions = [...this.rentalPointOptions,
          { value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] }];
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        this.allMoveClients = result;
        this.moveClientOptions = this.allMoveClients.map(m => ({ value: m.id, label: m.businessName }));
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      this.rentalPointOptions.push({ value: selectedClient.account.id, label: selectedClient.businessName });
      selectedClient.rentalPoints.forEach(rentalPoint => {
        if (rentalPoint.deleted === false) {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        }
      });
    }
  }

  openQrCodeModal(content, qrCode: string) {
    this.choosenQrCode = qrCode;
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {
      this.choosenQrCode = undefined;
    }, (reason) => {
      this.choosenQrCode = undefined;
    });
  }

  onTableReset() {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2:
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
    }
  }

  addRemoveFromSelectedArray(id: number, event) {
    if (!this.selectedArray.includes(id)) {
      this.selectedArray.push(id);
    } else {
      const index = this.selectedArray.findIndex(value => value === id);
      this.selectedArray.splice(index, 1);
    }
  }

  public allowDelete(): boolean {
    return localStorage.getItem('allowedDeleted') === '1';
  }

  public allowMoveBikes(): boolean {
    return localStorage.getItem('allowedMoveBikes') === '1';
  }

  isInSelectedArray(id: number) {
    return this.selectedArray.includes(id);
  }

  deleteSelectedConfirm(content: any) {
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {});
  }

  moveBikesSelectedConfirm(content: any, errorContent: any, selectedRentalPointAccountId: number) {
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title',  backdrop: false }).result.then((result) => {
      if (result && this.selectedArray.length > 0) {
        const productIds = this.selectedArray.join(',');
        this._productsService.moveProducts(productIds, selectedRentalPointAccountId.toString()).subscribe({
          next: (res) => {
            this._snackBar.open(this._translate.instant('products.bikeEquipmentList.messageMoveBikesCompleted'), '', {
              duration: 2000
            });
            this.selectedArray = [];
            this.loadPage = (page: number, pageSize: number) => this._productsService.search(page, pageSize, this.filters);
            this._modalService.dismissAll();
          },
          error: (err) => {
            if (err.status === 632) {
              this._modalService.open(errorContent);
            } else {
              this._snackBar.open(err.error, '', {duration: 2000});
            }
          },
        });
      }
    });
  }

  deleteSelected() {
    if (this.selectedArray.length > 0) {
      const productIds = this.selectedArray.join(',');
      // tslint:disable-next-line:radix
      const accountId = parseInt(localStorage.getItem('accountId'));
      this._productsService.deleteProducts(productIds, accountId).subscribe(() => {
        this._snackBar.open(this._translate.instant('products.bikeEquipmentList.messageDeleteProductsCompleted'), '', {
          duration: 2000,
        });
        this.selectedArray = [];
        this.loadPage = (page: number, pageSize: number) => this._productsService.search(page, pageSize, this.filters);
        this._modalService.dismissAll();
      },
      (error) => {
        this._snackBar.open(error.error, '', {
          duration: 2000,
        });
      });
    }
  }

  openExcelExportBikeList(filter: ProductSearchCritertia) {
    if (filter) {
      const clientId = (!filter.clientId) ? 0 : Number(filter.clientId) ;
      const rentalPointAccountId = (!filter.rentalPointAccountId) ? 0 : Number(filter.rentalPointAccountId);
      const bikeTypeId = (!filter.bikeTypeId) ? 0 : Number(filter.bikeTypeId);
      const bikeBrandId = (!filter.brandNameId) ? 0 : Number(filter.brandNameId);
      const bikeModelId = (!filter.bikeModelId) ? 0 : Number(filter.bikeModelId);
      const bikeSizeId = (!filter.bikeSizeId) ? 0 : Number(filter.bikeSizeId);
      const bikeTyreSizeId = (!filter.tyreSizeId) ? 0 : Number(filter.tyreSizeId);
      const qrCode = (!filter.qrCode) ? '' : filter.qrCode;
      const keyNumber = (!filter.keyNumber) ? '' : filter.keyNumber;
      const serialNumber = (!filter.serialNumber) ? '' : filter.serialNumber;
      const frameId = (!filter.frameId) ? '' : filter.frameId;
      const status = (!filter.status) ? 0 : Number(filter.status);

      this._productsService.getProductsExcelExportFile(
        clientId,
        rentalPointAccountId,
        bikeTypeId,
        bikeBrandId,
        bikeModelId,
        bikeSizeId,
        bikeTyreSizeId,
        qrCode,
        keyNumber,
        serialNumber,
        frameId,
        status
        ).subscribe((res) => {
        window.open(res.download, '_blank');
      },
      (error) => {
        this._snackBar.open(error.error, '', {
          duration: 2000,
        });
      });
    }
  }

  openCSVExportBikeListForInsurance(filter: ProductSearchCritertia) {
    if (filter) {
      const clientId = (!filter.clientId) ? 0 : Number(filter.clientId) ;
      const rentalPointAccountId = (!filter.rentalPointAccountId) ? 0 : Number(filter.rentalPointAccountId);
      const bikeTypeId = (!filter.bikeTypeId) ? 0 : Number(filter.bikeTypeId);
      const bikeBrandId = (!filter.brandNameId) ? 0 : Number(filter.brandNameId);
      const bikeModelId = (!filter.bikeModelId) ? 0 : Number(filter.bikeModelId);
      const bikeSizeId = (!filter.bikeSizeId) ? 0 : Number(filter.bikeSizeId);
      const bikeTyreSizeId = (!filter.tyreSizeId) ? 0 : Number(filter.tyreSizeId);
      const qrCode = (!filter.qrCode) ? '' : filter.qrCode;
      const keyNumber = (!filter.keyNumber) ? '' : filter.keyNumber;
      const serialNumber = (!filter.serialNumber) ? '' : filter.serialNumber;
      const frameId = (!filter.frameId) ? '' : filter.frameId;
      const status = (!filter.status) ? 0 : Number(filter.status);

      this._productsService.getProductsCSVForInsuranceExportFile(
        clientId,
        rentalPointAccountId,
        bikeTypeId,
        bikeBrandId,
        bikeModelId,
        bikeSizeId,
        bikeTyreSizeId,
        qrCode,
        keyNumber,
        serialNumber,
        frameId,
        status
        ).subscribe((res) => {
        window.open(res.download, '_blank');
      },
      (error) => {
        this._snackBar.open(error.error, '', {
          duration: 2000,
        });
      });
    }
  }
}
