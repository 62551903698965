import { Component, OnInit } from '@angular/core';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { UserService } from '../../../services/user.service';
import { Router } from '@angular/router';
import { OrdersService } from '../../../services/orders.service';
import * as moment from 'moment';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ExcelService } from '../../../services/excel.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-orders-turnover',
  templateUrl: './orders-turnover.component.html',
  styleUrls: ['./orders-turnover.component.scss']
})
export class OrdersTurnoverComponent implements OnInit {

  public datePickerConfig: Partial<BsDatepickerConfig> = new BsDatepickerConfig();
  public isClient;
  public isRentalPoint;
  public accountId;
  public accountType;
  public orders = [];
  public clients: any[];
  public rentalPointsArray: any = [];
  public rentalPoints: any = [];
  public clientId: number;
  public selectedClient: any;
  public selectedRentalPoint: any = '';
  public startDate: string;
  public endDate: string;
  public source: any;
  public settings = <any>{};
  public excelData = [];

  constructor(private _userService: UserService, private _router: Router,
    private _orderService: OrdersService, public snackBar: MatSnackBar,
    private _excelService: ExcelService,
    private _translate: TranslateService) {
    this.datePickerConfig.containerClass = 'theme-dark-blue';
    this.datePickerConfig.dateInputFormat = 'DD-MM-YYYY';
    this.datePickerConfig.showWeekNumbers = false;
    this.isClient = localStorage.getItem('isClient');
    this.isRentalPoint = localStorage.getItem('isRentalPoint');
    this.accountId = localStorage.getItem('accountId');
    this.source = [];
    this.accountType = this.getAccountType();

  }

  private getAccountType() {
    let accountType;
    if (localStorage.getItem('isClient') === 'false' && localStorage.getItem('isRentalPoint') === 'false') {
      accountType = 'admin';
    }
    if (localStorage.getItem('isClient') === 'true' && localStorage.getItem('isRentalPoint') === 'false') {
      accountType = 'client';
    }
    if (localStorage.getItem('isClient') === 'false' && localStorage.getItem('isRentalPoint') === 'true') {
      accountType = 'rentalPoint';
    }
    return accountType;
  }

  back() {
    this._router.navigate(['/dashboard']);
  }

  changeClients(event) {
    const selectEl = event.target;
    this.clientId = parseInt(selectEl.options[selectEl.selectedIndex].getAttribute('data-clientid'), 10);
    this.rentalPoints = [];
    this.selectedRentalPoint = '';
    if (parseInt(this.selectedClient, 10) === 1) { // SUPER USER
      this.clients.forEach(item => {
        if (item.rentalPoints.length !== 0) {
          for (let i = 0; i < item.rentalPoints.length; i++) {
            const element = item.rentalPoints[i];
            this.rentalPoints.push(element);
          }
        }
      });
    } else {
      for (let i = 0; i < this.rentalPointsArray.length; i++) {
        const element = this.rentalPointsArray[i];
        if (this.clientId === parseInt(element.clientId, 10)) {
          this.rentalPoints.push(element);
        }
      }
    }
  }

  excelExportTurnover() {
    if (this.excelData.length > 0) {
      this._excelService.exportAsExcelFile(this.excelData, 'Turnover');
    } else {
      this.snackBar.open(this._translate.instant('orders.ordersTurnover.message1'), '', {
        duration: 2000,
      });
    }
  }

  filterForTurnover() {
    const formatedStatDate = moment(this.startDate).format('YYYY-MM-DD');
    const formatedEndDate = moment(this.endDate).format('YYYY-MM-DD');
    if (this.selectedClient === undefined && this.selectedRentalPoint === '') {
      this.snackBar.open(this._translate.instant('orders.ordersTurnover.message2'), '', {
        duration: 2000,
      });
      return;
    } else {
      this._orderService.getTurnover(formatedStatDate, formatedEndDate,
        (this.selectedRentalPoint === '' ? this.selectedClient : this.selectedRentalPoint)).subscribe(result => {
          if (result.length === 0) {
            this.snackBar.open(this._translate.instant('orders.ordersTurnover.message3'), '', {
              duration: 2000,
            });
            return;
          }
          this.orders = [];
          result.forEach(order => {
            const currentPayment = order.payment;
            this.orders.push({
              contractId: order.contractId,
              rentalClient: currentPayment[0].clientBusinessName,
              rentalPoint: currentPayment[0].rentalPointBusinessName,
              clientName: currentPayment[0].name,
              startDate: moment(currentPayment[0].date).format('DD-MM-YYYY'),
              totalAmount: parseFloat(currentPayment[0].totalAmmount).toFixed(2) + ' €',
              relevantAmount: parseFloat(currentPayment[0].relevantAmmount).toFixed(2) + ' €',
              action: currentPayment[0].action
            });
            this.excelData.push({
              'Contract ID': order.contractId,
              'Rental Point Client': currentPayment[0].clientBusinessName,
              'Rental Point': currentPayment[0].rentalPointBusinessName,
              'Client Name': currentPayment[0].name,
              'Start Date': moment(currentPayment[0].date).format('DD-MM-YYYY'),
              'Total Amount': parseFloat(currentPayment[0].totalAmmount).toFixed(2),
              'Relevant Amount': parseFloat(currentPayment[0].relevantAmmount).toFixed(2),
              'Action': currentPayment[0].action
            });
            if (currentPayment.length > 1) {
              for (let i = 1; i < currentPayment.length; i++) {
                const element = currentPayment[i];
                this.orders.push({
                  contractId: order.contractId,
                  rentalClient: element.clientBusinessName,
                  rentalPoint: element.rentalPointBusinessName,
                  clientName: element.name,
                  startDate: moment(element.date).format('DD-MM-YYYY'),
                  totalAmount: parseFloat(element.totalAmmount).toFixed(2) + ' €',
                  relevantAmount: parseFloat(element.relevantAmmount).toFixed(2) + ' €',
                  action: element.action
                });
                this.excelData.push({
                  'Contract ID': order.contractId,
                  'Rental Point Client': element.clientBusinessName,
                  'Rental Point': element.rentalPointBusinessName,
                  'Client Name': element.name,
                  'Start Date': moment(element.date).format('DD-MM-YYYY'),
                  'Total Amount': parseFloat(element.totalAmmount).toFixed(2),
                  'Relevant Amount': parseFloat(element.relevantAmmount).toFixed(2),
                  'Action': element.action
                });
              }
            }
          });
          this.source = this.orders;
        });
    }
  }

  ngOnInit() {
    this._userService.getClientsWithRentalPoints().subscribe(result => {
      this.clients = result;
      this.clients.forEach(item => {
        if (parseInt(item.accountId, 10) === parseInt(this.accountId, 10)) {
          this.clientId = parseInt(item.id, 10);
        }
        if (item.rentalPoints.length !== 0) {
          for (let i = 0; i < item.rentalPoints.length; i++) {
            const element = item.rentalPoints[i];
            this.rentalPointsArray.push(element);
          }
        }
      });
      if (this.accountType === 'client') {
        this.selectedClient = this.accountId;
        for (let i = 0; i < this.rentalPointsArray.length; i++) {
          const element = this.rentalPointsArray[i];
          if (this.clientId === parseInt(element.clientId, 10)) {
            this.rentalPoints.push(element);
          }
        }
      } else if (this.accountType === 'rentalPoint') {
        this.selectedRentalPoint = this.accountId;
        for (let i = 0; i < this.rentalPointsArray.length; i++) {
          const element = this.rentalPointsArray[i];
          if (parseInt(this.accountId, 10) === parseInt(element.accountId, 10)) {
            this.rentalPoints.push(element);
          }
        }
        this.selectedRentalPoint = this.accountId;
      } else {
        console.log('SUPER ADMIN');
        for (let i = 0; i < this.rentalPointsArray.length; i++) {
          const element = this.rentalPointsArray[i];
          this.rentalPoints.push(element);
        }
      }
      this.settings = {
        actions: false,
        hideHeader: true,
        columns: {
          contractId: {
            title: 'Contract ID'
          },
          rentalClient: {
            title: 'Client'
          },
          rentalPoint: {
            title: 'Rental Point'
          },
          clientName: {
            title: 'Name'
          },
          startDate: {
            title: 'Start Date'
          },
          totalAmount: {
            title: 'Total Amount'
          },
          relevantAmount: {
            title: 'Relevant Amount'
          },
          action: {
            title: 'Action'
          }
        }
      };
    });
  }
}
