import { Component, OnInit } from '@angular/core';
import { PriceListService } from '../shared/price-list.service';
import { Option } from '../../../shared/data-filters/data-filter-select-search/data-filter-select-search.component';
import { TranslateService } from '@ngx-translate/core';
import { PriceList } from '../../../models/PriceList';
import { Period } from '../../../models/Period';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PricePerPeriod } from '../../../models/PricePerPeriod';

@Component({
  selector: 'app-update-price-list',
  templateUrl: './update-price-list.component.html',
  styleUrls: ['./update-price-list.component.scss']
})
export class UpdatePriceListComponent implements OnInit {

  public priceListId = undefined;
  public periodId = undefined;
  public disablePeriodSelect = true;
  public disableSaveBtn = true;
  public loading = false;
  public showContainer = false;
  public priceListsArray: Option[];
  public periodsArray: Option[];
  public pricePerPeriodArray: PricePerPeriod[];

  private _priceListObjectsArray: PriceList[];

  constructor(private _priceListService: PriceListService, private _translate: TranslateService, private _snackBar: MatSnackBar) {
    this.priceListsArray = [{ value: undefined, label: this._translate.instant('priceList.update.selectPriceList') }];
    this.periodsArray = [{ value: undefined, label: this._translate.instant('priceList.update.selectPeriod') }];
  }

  ngOnInit() {
    this._priceListService.getPriceLists().subscribe(result => {
      this._priceListObjectsArray = result;
      this._priceListObjectsArray.forEach(element => {
        this.priceListsArray.push({ value: element.id, label: element.name });
      });
    });

  }

  onPriceListSelectChange() {
    this.periodsArray = [{ value: undefined, label: this._translate.instant('priceList.update.selectPeriod') }];
    if (this.priceListId === 'undefined') {
      this.disablePeriodSelect = true;
    } else {
      const choosenPriceList = this._priceListObjectsArray.filter(priceList => priceList.id.toString() === this.priceListId)[0];
      choosenPriceList.periods.forEach(period => {
        this.periodsArray.push({
          value: period.id, label: period.name +
            ' ( ' + moment(period.startDate).format('DD/MM/YYYY') + ' - ' + moment(period.endDate).format('DD/MM/YYYY') + ' ) '
        });
      });
      this.disablePeriodSelect = false;
    }
  }

  onSelectButtonClick() {
    this.pricePerPeriodArray = [];
    this.disableSaveBtn = true;
    this.loading = false;
    if (this.priceListId !== 'undefined' && this.periodId !== 'undefined' &&
      this.priceListId !== undefined && this.periodId !== undefined) {
      this.showContainer = true;
      this._priceListService.getPriceList(this.priceListId).subscribe(result => {
        this.pricePerPeriodArray = result.periods.filter(period => period.id.toString() === this.periodId)[0].pricePerPeriod.filter(p => p.bikeType.status !== 0);
        this.disableSaveBtn = false;
        this.loading = true;
      });
    } else {
      this._snackBar.open(this._translate.instant('priceList.update.missingPriceListPeriodError'), '', {
        duration: 3500,
      });
    }
  }

  onSaveButtonClick() {
    this.loading = false;
    this.disableSaveBtn = true;
    this._priceListService.updatePricePerPeriod(this.pricePerPeriodArray).subscribe(result => {
      this.showContainer = false;
      this.periodId = undefined;
      this.periodsArray = [{ value: undefined, label: this._translate.instant('priceList.update.selectPeriod') }];
      this.priceListId = undefined;
      this.disablePeriodSelect = true;
      this._snackBar.open(this._translate.instant('priceList.update.successPriceUpdate'), '', {
        duration: 3500,
      });
    });
  }

}
