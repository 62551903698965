<app-rental-point-subnav [rentalPointId]="currentRentalPointId"></app-rental-point-subnav>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col">
            <h4>{{'rentalPoint.manageSchedules.defaultSchedule' | translate}}</h4>
        </div>
    </div>
    <div class="row">
        <div class="col">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th>{{'rentalPoint.manageSchedules.startHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.closeHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.lunchBrake' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.startHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.closeHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.m' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.t' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.w' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.t' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.f' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.s' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.s' | translate}}</th>
                        <!-- <th></th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{rentalPointSchedule.startHour}}</td>
                        <td>{{rentalPointSchedule.endHour}}</td>
                        <ng-container *ngIf="rentalPointSchedule.lunchBreakStart === '' && rentalPointSchedule.lunchBreakEnd === ''; else elseTemplate">
                            <td><i class="fa fa-times" style="color: red;"></i></td>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <td><i class="fa fa-check" style="color: green;"></i></td>
                        </ng-template>
                        <td>{{rentalPointSchedule.lunchBreakStart}}</td>
                        <td>{{rentalPointSchedule.lunchBreakEnd}}</td>
                        <td *ngFor="let day of rentalPointSchedule.openingDays">
                            <ng-container *ngIf="day === '0'; else elseTemplate">
                                <i class="fa fa-times" style="color: red;"></i>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <i class="fa fa-check" style="color: green;"></i>
                            </ng-template>
                        </td>
                        <!-- <td><button type="button" class="btn btn-sm btn-secondary"><i class="fa fa-edit"></i></button></td> -->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <h4>
                {{'rentalPoint.manageSchedules.exceptions' | translate}}
            </h4>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <app-new-schedule-exception-form [scheduleException]="scheduleException" [rentalPointSchedule]="rentalPointSchedule"></app-new-schedule-exception-form>
        </div>
        <div class="col-12 mt-3">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                        <th>{{'rentalPoint.manageSchedules.startDay' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.endDay' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.closedDay' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.startHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.closeHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.lunchBrake' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.startHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.closeHour' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.m' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.t' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.w' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.t' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.f' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.s' | translate}}</th>
                        <th>{{'rentalPoint.manageSchedules.s' | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let exception of rentalPointSchedule.scheduleExceptions">
                        <td>{{exception.startDay | date:'dd-MM-yyyy'}}</td>
                        <td>{{exception.endDay | date:'dd-MM-yyyy'}}</td>
                        <ng-container *ngIf="exception.closingException === false; else elseTemplate">
                            <td><i class="fa fa-times" style="color: red;"></i></td>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <td><i class="fa fa-check" style="color: green;"></i></td>
                        </ng-template>
                        <td>{{exception.startHour}}</td>
                        <td>{{exception.endHour}}</td>
                        <ng-container *ngIf="(exception.lunchBreakStart === null && exception.lunchBreakEnd === null) || (exception.lunchBreakStart === '' && exception.lunchBreakEnd === ''); else elseTemplate">
                            <td><i class="fa fa-times" style="color: red;"></i></td>
                        </ng-container>
                        <ng-template #elseTemplate>
                            <td><i class="fa fa-check" style="color: green;"></i></td>
                        </ng-template>
                        <td>{{exception.lunchBreakStart}}</td>
                        <td>{{exception.lunchBreakEnd}}</td>
                        <td *ngFor="let day of exception.openingDays">
                            <ng-container *ngIf="day === '0'; else elseTemplate">
                                <i class="fa fa-times" style="color: red;"></i>
                            </ng-container>
                            <ng-template #elseTemplate>
                                <i class="fa fa-check" style="color: green;"></i>
                            </ng-template>
                        </td>
                        <td>
                            <div class="btn-group" role="group">
                                <!-- <button type="button" class="btn btn-sm btn-secondary"
                  (click)="onEditScheduleExceptionClick(exception.id)"><i class="fa fa-edit"></i></button> -->
                                <button type="button" class="btn btn-sm btn-danger" (click)="onDeleteButtonClick(exception.id)"><i
                    class="fa fa-trash"></i></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>