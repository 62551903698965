import { Period } from './Period';
import { BikeType } from './BikeType';

export class PricePerPeriod {
    public id: number;
    public price: number;
    public pricePerHour: number;
    public priceHalfDay: number;
    public period: Period;
    public bikeType: BikeType;

    public static fromDto(p): PricePerPeriod {
        const pricePerPeriod = new PricePerPeriod();
        if (!p) {
            return null;
        }
        pricePerPeriod.id = p.id;
        pricePerPeriod.price = p.price;
        pricePerPeriod.pricePerHour = p.pricePerHour;
        pricePerPeriod.priceHalfDay = p.priceHalfDay;
        pricePerPeriod.period = Period.fromDto(p.period);
        pricePerPeriod.bikeType = BikeType.fromDto(p.bikeType);

        return pricePerPeriod;
    }
}
