import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { forkJoin } from 'rxjs';
import { ClientsService } from '../../clients/shared/clients.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { FormGroup, FormControl } from '@angular/forms';
import { Client } from '../../../models/Client';
import { ValidationUtils } from '../../../shared/validation.utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

export class Product {
  public location: string;
  public quantity: string;
  public bikeTypeId: string;
}

@Component({
  selector: 'app-add-bike-equipment',
  templateUrl: './add-bike-equipment.component.html',
  styleUrls: ['./add-bike-equipment.component.scss']
})
export class AddBikeEquipmentComponent implements OnInit {

  public bikeTypeOption: Option[];
  public clientOption: Option[];
  public rentalPointOption: Option[];
  public productFormGroup: FormGroup;
  public errorArray = [];
  public currentLang: string;

  private _clients: Client[];

  constructor(private _translate: TranslateService, private _productsService: ProductsService,
    private _clientsService: ClientsService, private _snackBar: MatSnackBar) {
    this.currentLang = this._translate.currentLang;
    this.bikeTypeOption = [{ value: null, label: this._translate.instant('products.addBikeEquipment.bikeType') }];
    this.clientOption = [{ value: null, label: this._translate.instant('products.addBikeEquipment.clients') }];
    this.rentalPointOption = [{ value: null, label: this._translate.instant('products.addBikeEquipment.rentalPoints') }];
    this.productFormGroup = new FormGroup({
      clientId: new FormControl(),
      rentalPointAccountId: new FormControl(),
      quantity: new FormControl(),
      bikeTypeId: new FormControl()
    });
  }

  ngOnInit() {
    const bikeTypes = this._productsService.getBikeTypes();
    const clients = this._clientsService.getClients();
    forkJoin([bikeTypes, clients]).subscribe(result => {
      console.log(result);
      result[0].forEach(bikeType => {
        this.bikeTypeOption.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
      });
      this._clients = result[1];
      this._clients.forEach(client => {
        this.clientOption.push({ value: client.id, label: client.businessName });
      });
    });
  }

  onClientSelectChange() {
    const clientId = this.productFormGroup.value['clientId'];
    this.rentalPointOption = [{ value: undefined, label: this._translate.instant('products.addBikeEquipment.rentalPoints') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id.toString() === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOption.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  submitBike() {
    if (this.productFormGroup.valid) {
      console.log(this.productFormGroup.value);
      const product = new Product();
      product.location = this.productFormGroup.get('clientId').value;
      if (this.productFormGroup.get('rentalPointAccountId').value) {
        product.location = this.productFormGroup.get('rentalPointAccountId').value;
      }
      product.bikeTypeId = this.productFormGroup.get('bikeTypeId').value;
      product.quantity = this.productFormGroup.get('quantity').value;
      console.log(product);
      this._productsService.addProduct(product).subscribe(result => {
        this._snackBar.open(this._translate.instant('products.addBikeEquipment.productAdded'), '', {
          duration: 2000,
        });
        this.productFormGroup.reset();
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.productFormGroup, this.productFormGroup.controls);

  }

}
