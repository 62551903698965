<div class="data-filter" style="padding: 0px 5px;">
  <label style="margin: 0px; font-weight: bold;">{{label}}</label>
  <div class="input-group input-group-sm">
    <input placeholder="{{placeHolder}}" class="form-control" [minDate]="minDate" [maxDate]="maxDate" name="dp" [(ngModel)]="model"
      ngbDatepicker (ngModelChange)="change()" #d="ngbDatepicker" readonly="readonly" [disabled]="disabled">
    <div class="input-group-append">
      <button class="btn btn-outline-secondary btn-sm" (click)="d.toggle()" type="button"><i
          class="fa fa-calendar"></i></button>
    </div>
  </div>
</div>