import { Component, OnInit, Input } from '@angular/core';
import { Bike } from '../../../models/Bike';
import { ProductService } from '../../../services/product.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-qr-assign',
  templateUrl: './qr-assign.component.html',
  styleUrls: ['./qr-assign.component.scss']
})
export class QrAssignComponent implements OnInit {

  @Input() bike = new Bike();
  public startQrCode: number;
  public endQrCode: number;
  public rentalPointName: string;
  public selectedRentalPoint: number;
  public accountType;
  public bikeTypes = [];
  public bikeSizes = [];
  public bikeBrands = [];
  public bikeModels = [];
  public bikeTyreSizes = [];
  public bikeColors = [];
  public selectedBike = [];
  public rentalPoints = [];
  public years: number[] = [];

  constructor(private _productService: ProductService, public snackBar: MatSnackBar, private _userService: UserService) {
    console.log(localStorage.getItem('isClient'));
    console.log(localStorage.getItem('isRentalPoint'));
    this.accountType = this.getAccountType();
    console.log(this.accountType);
  }

  private getAccountType() {
    let accountType;

    if (localStorage.getItem('isClient') === 'false' && localStorage.getItem('isRentalPoint') === 'false') {
      accountType = 'admin';
    }
    if (localStorage.getItem('isClient') === 'true' && localStorage.getItem('isRentalPoint') === 'false') {
      accountType = 'client';
    }
    if (localStorage.getItem('isClient') === 'false' && localStorage.getItem('isRentalPoint') === 'true') {
      accountType = 'rentalPoint';
    }

    return accountType;
  }

  public back() {

  }

  ngOnInit() {
    this.generateYears();
    this._productService.getBikeTypes().subscribe(results => {
      this.bikeTypes = results.filter(res => res.status !== 0);
    });
    this._productService.getBikeSizes().subscribe(results => {
      this.bikeSizes = results;
    });
    this._productService.getBikeBrands().subscribe(results => {
      this.bikeBrands = results;
    });
    this._productService.getBikeModels().subscribe(results => {
      this.bikeModels = results;
    });
    this._productService.getBikeTyreSizes().subscribe(results => {
      this.bikeTyreSizes = results;
    });
    this._productService.getBikeColors().subscribe(results => {
      this.bikeColors = results;
    });
    this._userService.getClientsWithRentalPoints().subscribe(result => {
      // console.log(result);
      if (this.accountType === 'admin') {
        result.forEach(element => {
          element.rentalPoints.forEach(rentalpoint => {
            this.rentalPoints.push(rentalpoint);
          });
        });
      } else if (this.accountType === 'client') {
        result.forEach(element => {
          if (element.accountId === localStorage.getItem('accountId')) {
            element.rentalPoints.forEach(rentalpoint => {
              this.rentalPoints.push(rentalpoint);
            });
          }
        });
      } else {
        result.forEach(element => {
          element.rentalPoints.forEach(rentalpoint => {
            if (rentalpoint.accountId === parseInt(localStorage.getItem('accountId'), 10)) {
              this.rentalPointName = rentalpoint.businessName;
              this.selectedRentalPoint = rentalpoint.accountId;
            }
          });
        });
      }
    });
  }

  public assignBike(assignBikeForm) {
    if (assignBikeForm.valid) {
      this._productService.assignMultipleQrCodesToSpecificBike(
        this.startQrCode, this.endQrCode, this.selectedRentalPoint, this.bike).subscribe((result) => {
        console.log(result);
        this.snackBar.open(result.message, result.action, {
          duration: 2000,
        });
      }, (error) => {
        console.log(error);
        this.snackBar.open(error.error, error.action, {
          duration: 2000,
        });
      });
    } else {
      console.log('ERROR 1');
      this.snackBar.open('Bitte Pflichfälder ausfüllen', '', {
        duration: 2000,
      });
    }
  }

  private generateYears() {
    const startYear = 2010;
    const currentYear = new Date().getFullYear();
    for (let year = startYear; year <= currentYear + 1; year++) {
      this.years.push(year);
    }
  }
}
