<div class="container-fluid">
    <div class="row d-print-none">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="col-2" *ngIf="userType.userTypeNumber === 0">
                            <div class="form-group">
                                <label class="control-label">{{'products.bikeEquipmentPreparation.clients' | translate}}</label>
                                <select class="form-control form-control-sm" name="clientId" [(ngModel)]="selectedClientId" (change)="onClientSelectChange()" ngDefaultControl>
                  <option *ngFor="let client of clientOptions" value="{{client.value}}">{{client.label | translate}}
                  </option>
                </select>
                            </div>
                        </div>
                        <div class="col-2" *ngIf="userType.userTypeNumber !== 2">
                            <app-select-search label="{{'products.bikeEquipmentPreparation.rentalPoints' | translate}}" [options]="rentalPointOptions" [(model)]="selectedRentalPointAccountId" ngDefaultControl></app-select-search>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>{{'products.bikeEquipmentPreparation.startDate' | translate}}</label>
                                <app-datepicker [(model)]="startDate"></app-datepicker>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-group">
                                <label>{{'products.bikeEquipmentPreparation.endDate' | translate}}</label>
                                <app-datepicker [(model)]="endDate"></app-datepicker>
                            </div>
                        </div>
                        <div class="col-1">
                            <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick()">{{'products.bikeEquipmentPreparation.filter' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col" [hidden]="hideResultContainer">
            <ng-container *ngIf="loading; else elseTemplate">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </ng-container>
            <ng-template #elseTemplate>
                <app-rental-point-card-item *ngFor="let rentalPoint of bikePreparation.rentalPoint" [rentalPoint]="rentalPoint" (onPreparationChange)="onRentalPreparedValueChange($event)">
                </app-rental-point-card-item>
            </ng-template>
        </div>
    </div>
</div>