<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label>{{'products.qrAssign.startQrCode' | translate}}*</label>
                <input type="text" name="startQrCode" id="startQrCode" [(ngModel)]="startQrCode"
                  class="form-control form-control-sm" required="required">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>{{'products.qrAssign.endQrCode' | translate}}*</label>
                <input type="text" name="endQrCode" id="endQrCode" [(ngModel)]="endQrCode"
                  class="form-control form-control-sm" required="required">
              </div>
            </div>
            <div class="col">
              <div class="form-group">
                <label>Client</label>
                <select class="form-control form-control-sm" name="clientId" id="clientId" [(ngModel)]="clientId">

                </select>
              </div>
            </div>
            <div class="col">

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
