import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { BookedOrderSearchCriteria } from '../shared/BookedOrderSearchCriteria';
import { Observable, forkJoin } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { OrdersService } from '../shared/orders.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { SharedUtils } from '../../../shared/shared-utils';
import { Client } from '../../../models/Client';
import { ClientsService } from '../../clients/shared/clients.service';
import { BookingReservationStatusEnum } from '../../../shared/enums/BookingReservationStatusEnum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-booking-reservation-orders',
  templateUrl: './booking-reservation-orders.component.html',
  styleUrls: ['./booking-reservation-orders.component.scss']
})
export class BookingReservationOrdersComponent implements OnInit {
  public focus: BookingReservationOrdersComponent;
  public filters: BookedOrderSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public orderTypeOptions: Option[] = [
    { value: undefined, label: 'orders.bookingReservationOrders.all' },
    { value: '0', label: BookingReservationStatusEnum.ZERO },
    { value: '1', label: BookingReservationStatusEnum.ONE },
    { value: '2', label: BookingReservationStatusEnum.TWO },
    { value: '3', label: BookingReservationStatusEnum.THREE }
  ];
  public currentUserType = SharedUtils.getCurrentUserType();

  private _clients: Client[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('returnRentalPoint', { static: true })
  returnRentalPoint: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('returnDate', { static: true })
  returnDate: TemplateRef<any>;

  @ViewChild('orderAmount', { static: true })
  orderAmount: TemplateRef<any>;

  @ViewChild('orderType', { static: true })
  orderType: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  constructor(private _modalService: NgbModal, private _translate: TranslateService, public _snackBar: MatSnackBar,
    private _ordersService: OrdersService, private _clientsService: ClientsService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      console.log(result);
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
      this.loadPage = (page: number, pageSize: number) => this._ordersService.searchReservedOrders(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.clientName',
        template: this.clientName,
        sortable: true,
        excel: {
          objectRef: ['clientInformation.firstName', 'clientInformation.lastName']
        }
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['startRentalPoint.businessName']
        }
      },
      {
        name: 'returnRentalPoint',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.returnRentalPoint',
        template: this.returnRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['endRentalPoint.businessName']
        }
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.startRentalDate',
        template: this.startDate,
        sortable: true,
        excel: {
          objectRef: ['startDate'],
          pipe: 'date'
        }
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.returnRentalDate',
        template: this.returnDate,
        sortable: true,
        excel: {
          objectRef: ['endDate'],
          pipe: 'date'
        }
      },
      {
        name: 'orderAmount',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.orderAmount',
        template: this.orderAmount,
        sortable: true,
        excel: {
          objectRef: ['discountedPrice'],
          pipe: 'currency'
        }
      },
      {
        name: 'orderType',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.orderType',
        template: this.orderType,
        sortable: true,
        excel: {
          objectRef: ['reservation'],
          pipe: 'bookingReservationStatus'
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.actions',
        template: this.actions,
        sortable: true
      },
    ];
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          // this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
          this.rentalPointOptions = [...this.rentalPointOptions,
          { value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] }];
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: 'All' }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onCancelReservation(content, contractId) {
    this._modalService.open(content, {ariaLabelledBy: 'modal-basic-title-reservation', centered: true}).result.then(() => {
      this._ordersService.cancelReservation(contractId).subscribe(result => {
        this._snackBar.open(this._translate.instant('orders.bookingReservationOrders.message1'), '', {
          duration: 3500,
        });
        this.loadPage = (page: number, pageSize: number) => this._ordersService.searchReservedOrders(page, pageSize, this.filters);
      });
    }, (reason) => {
    });
  }

  onCancelBooking(content, contractId) {
    this._modalService.open(content, {ariaLabelledBy: 'modal-basic-title-booking', backdrop: false }).result.then((modalAction) => {
      this._ordersService.cancelReservation(contractId).subscribe(result => {
        this._snackBar.open(this._translate.instant('orders.bookingReservationOrders.message1b'), '', {
          duration: 3500,
        });
        this.loadPage = (page: number, pageSize: number) => this._ordersService.searchReservedOrders(page, pageSize, this.filters);
      });
    }, (reason) => {
    });
  }

  onTableReset() {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2:
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        break;
    }
  }
}
