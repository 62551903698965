import { RentalPoint } from './RentalPoint';

export class ExtraField {
  public id: number;
  public titleEn: string;
  public titleIt: string;
  public titleDe: string;
  public detailEn: string;
  public detailIt: string;
  public detailDe: string;
  public isLink: boolean;
  public rentalPoint: RentalPoint;

  public static fromDto(e: any): ExtraField {
    const extraField = new ExtraField();
    if (!e) { return null; }
    extraField.id = e.id;
    extraField.titleEn = e.titleEn;
    extraField.titleIt = e.titleIt;
    extraField.titleDe = e.titleDe;
    extraField.detailEn = e.detailEn;
    extraField.detailIt = e.detailIt;
    extraField.detailDe = e.detailDe;
    extraField.isLink = e.isLink;
    extraField.rentalPoint = e.rentalPoint;
    return extraField;
  }
}
