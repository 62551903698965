<app-rental-point-subnav [rentalPointId]="currentRentalPointId"></app-rental-point-subnav>
<div class="container" style="padding-top: 20px; padding-bottom: 20px;">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <form [formGroup]="updateRentalPointForm" (ngSubmit)="updateRentalPoint()">
                          <fieldset formGroupName="rentalPoint">
                            <fieldset formGroupName="rentalPointTranslation">
                                <div class="row">
                                    <div class="form-group col">
                                        <label for="name-de">{{'rentalPoint.rentalPointEdit.name' | translate }}<span> DE*</span></label>
                                        <input type="text" name="name-de" id="name-de" formControlName="de" required placeholder="{{'rentalPoint.rentalPointEdit.name' | translate }}" class="form-control {{errorArray['de'] ? 'is-invalid' : ''}}" />
                                        <div class="invalid-feedback">
                                            {{errorArray['de']}}
                                        </div>
                                    </div>
                                    <div class="form-group col">
                                        <label for="name-en">{{'rentalPoint.rentalPointEdit.name' | translate }}<span> EN</span></label>
                                        <input type="text" name="name-en" id="name-en" formControlName="en" placeholder="{{'rentalPoint.rentalPointEdit.name' | translate }}" class="form-control" />
                                    </div>
                                    <div class="form-group col">
                                        <label for="name-it">{{'rentalPoint.rentalPointEdit.name' | translate }}<span> IT</span></label>
                                        <input type="text" name="name-it" id="name-it" formControlName="it" placeholder="{{'rentalPoint.rentalPointEdit.name' | translate }}" class="form-control" />
                                    </div>
                                </div>
                            </fieldset>
                            <hr/>
                            <div class="row">
                                <div class="form-group col-3">
                                    <label for="region">{{'rentalPoint.rentalPointEdit.region' | translate }}</label>
                                    <input type="text" name="region" id="region" formControlName="region" placeholder="{{'rentalPoint.rentalPointEdit.region' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="address">{{'rentalPoint.rentalPointEdit.address' | translate }}</label>
                                    <input type="text" id="address" name="address" formControlName="street" placeholder="{{'rentalPoint.rentalPointEdit.address' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="district">{{'rentalPoint.rentalPointEdit.district' | translate }}<span>*</span></label>
                                    <select name="district" id="district" formControlName="district" class="form-control {{errorArray['district'] ? 'is-invalid' : ''}}" required>
                    <option *ngFor="let district of districts" value="{{district.id}}">{{district.name}}</option>
                  </select>
                                    <div class="invalid-feedback">
                                        {{errorArray['district']}}
                                    </div>
                                </div>
                                <div class="form-group col-3">
                                    <label for="country">{{'rentalPoint.rentalPointEdit.country' | translate }}<span>*</span></label>
                                    <select name="country" id="country" formControlName="country" class="form-control" required>
                    <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
                  </select>
                                </div>
                                <div class="form-group col-3">
                                    <label for="town">{{'rentalPoint.rentalPointEdit.town' | translate }}</label>
                                    <input type="text" name="town" id="town" formControlName="city" placeholder="{{'rentalPoint.rentalPointEdit.town' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="phone">{{'rentalPoint.rentalPointEdit.phoneNumber' | translate }}</label>
                                    <input type="text" name="phone" id="phone" formControlName="phone" placeholder="{{'rentalPoint.rentalPointEdit.phoneNumber' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="latitude">{{'rentalPoint.rentalPointEdit.latitude' | translate }}</label>
                                    <input type="text" name="latitude" id="latitude" formControlName="latitude" placeholder="{{'rentalPoint.rentalPointEdit.latitude' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="longitude">{{'rentalPoint.rentalPointEdit.longitude' | translate }}</label>
                                    <input type="text" name="longitude" id="longitude" formControlName="longitude" placeholder="{{'rentalPoint.rentalPointEdit.longitude' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                  <label for="postalCode">{{'rentalPoint.rentalPointEdit.postalCode' | translate }}</label>
                                  <input type="text" name="zipCode" id="postalCode" formControlName="postalCode" placeholder="{{'rentalPoint.rentalPointEdit.postalCode' | translate }}" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="vat">{{'rentalPoint.rentalPointEdit.vatNumber' | translate }}<span>*</span></label>
                                    <input type="text" name="vat" id="vat" formControlName="vat" placeholder="{{'rentalPoint.rentalPointEdit.vatNumber' | translate }}" class="form-control" readonly />
                                </div>
                                <div class="form-group col-3" *ngIf="(accountType['userTypeName'] == 'admin')">
                                    <label for="district">{{'rentalPoint.rentalPointEdit.priceList' | translate }}<span>*</span></label>
                                    <select name="priceLists" id="priceLists" formControlName="priceLists" class="form-control {{errorArray['priceList'] ? 'is-invalid' : ''}}" required>
                    <option *ngFor="let pricelist of priceLists" [ngValue]="pricelist">{{pricelist.name}}</option>
                  </select>
                                    <div class="invalid-feedback">
                                        {{errorArray['priceLists']}}
                                    </div>
                                </div>
                                <div class="form-group col-12" *ngIf="(accountType['userTypeName'] == 'admin')">
                                    <label style="display: block;">{{'rentalPoint.rentalPointEdit.discountCard' | translate }}</label>
                                    <div class="form-check form-check-inline" *ngFor="let discountCard of discountCardsList; let i = index">
                                        <input class="form-check-input" type="checkbox" id="inlineCheckbox{{i}}" [value]="discountCard" (change)="onDiscountCardCheckBoxChange($event, discountCard)" [checked]="validateChecked(discountCard)">
                                        <label class="form-check-label" for="inlineCheckbox{{i}}">{{discountCard.name}}</label>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <fieldset formGroupName="extraField">
                              <div style="display: inline-block">
                                <strong>{{'rentalPoint.rentalPointEdit.extra' | translate }}: </strong>
                                <span>{{'rentalPoint.rentalPointEdit.extraFieldDesc' | translate}}</span>
                                <div class="ml-3 form-check form-check-inline">
                                  <input type="checkbox" name="isLink" id="isLink" formControlName="isLink" class="form-check-input"/>
                                  <label for="isLink" class="form-check-label">{{'rentalPoint.rentalPointEdit.isLink' | translate }}</label>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="form-group col">
                                  <label for="titleDe">{{'rentalPoint.rentalPointEdit.extra' | translate }}<span> DE</span></label>
                                  <input type="text" name="titleDe" id="titleDe" formControlName="titleDe" placeholder="Parking, alert, ..." class="form-control {{errorArray['de'] ? 'is-invalid' : ''}}" />
                                  <div class="invalid-feedback">
                                    {{errorArray['de']}}
                                  </div>
                                </div>
                                <div class="form-group col">
                                  <label for="titleEn">{{'rentalPoint.rentalPointEdit.extra' | translate }}<span> EN</span></label>
                                  <input type="text" name="titleEn" id="titleEn" formControlName="titleEn" placeholder="Parking, alert, ..." class="form-control" />
                                </div>
                                <div class="form-group col">
                                  <label for="titleIt">{{'rentalPoint.rentalPointEdit.extra' | translate }}<span> IT</span></label>
                                  <input type="text" name="titleIt" id="titleIt" formControlName="titleIt" placeholder="Parking, alert, ..." class="form-control" />
                                </div>
                              </div>
                              <div class="row">
                                <div class="form-group col">
                                  <label for="detailDe">{{'rentalPoint.rentalPointEdit.detailExtra' | translate }}</label>
                                  <input type="text" name="detailDe" id="detailDe" formControlName="detailDe" placeholder="link, description, ..." class="form-control" />
                                </div>
                                <div class="form-group col">
                                  <label for="detailEn">{{'rentalPoint.rentalPointEdit.detailExtra' | translate }}</label>
                                  <input type="text" name="detailEn" id="detailEn" formControlName="detailEn" placeholder="link, description, ..." class="form-control" />
                                </div>
                                <div class="form-group col">
                                  <label for="detailIt">{{'rentalPoint.rentalPointEdit.detailExtra' | translate }}</label>
                                  <input type="text" name="detailIt" id="detailIt" formControlName="detailIt" placeholder="link, description, ..." class="form-control" />
                                </div>
                              </div>
                            </fieldset>
                        </fieldset>
                        <hr />
                        <fieldset>
                            <div class="row">
                                <div class="form-group col-3">
                                    <label for="firstName">{{'rentalPoint.rentalPointEdit.firstName' | translate }}</label>
                                    <input type="text" name="firstName" id="firstName" formControlName="firstName" placeholder="First name" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="LastName">{{'rentalPoint.rentalPointEdit.lastName' | translate }}</label>
                                    <input type="text" name="LastName" id="LastName" formControlName="lastName" placeholder="Last Name" class="form-control" />
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group col-3">
                                    <label for="username">{{'rentalPoint.rentalPointEdit.userName' | translate }}</label>
                                    <input type="text" name="username" id="username" formControlName="username" placeholder="Username" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="email">{{'rentalPoint.rentalPointEdit.emailAddress' | translate }}</label>
                                    <input type="email" name="email" id="email" formControlName="email" placeholder="email" class="form-control" />
                                </div>
                                <div class="form-group col-3">
                                    <label for="password">{{'rentalPoint.rentalPointEdit.password' | translate }}</label>
                                    <input type="text" name="password" id="password" formControlName="password" placeholder="password" class="form-control" />
                                </div>
                            </div>
                        </fieldset>
                        <div class="row">
                            <div class="col text-right">
                                <div class="save">
                                    <button type="submit" class="btn btn-primary">{{'rentalPoint.rentalPointEdit.save' | translate }}</button>
                                </div>
                            </div>
                            <div class="col">
                                <div class="delete">
                                    <ng-container *ngIf="account.rentalPoint.deleted === false">
                                        <button type="button" (click)="deleteRentalPoint(content)" class="btn btn-danger">
                                            {{'rentalPoint.rentalPointEdit.remove' | translate }}
                                        </button>
                                    </ng-container>
                                    <ng-template #content let-modal>
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">
                                                {{'rentalPoint.rentalPointEdit.message3' | translate}}</h4>
                                            <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
                                                <span aria-hidden="true">&times;</span>
                                              </button>
                                        </div>
                                        <div class="modal-body">
                                            <p><strong>{{'rentalPoint.rentalPointEdit.message4' | translate}}</strong></p>
                                            <p>{{'rentalPoint.rentalPointEdit.message5' | translate}}
                                                <span class="text-danger bold">{{'rentalPoint.rentalPointEdit.message6' | translate}}</span>
                                            </p>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">{{'orders.bookingReservationOrders.cancel' | translate}}</button>
                                            <button type="button" class="btn btn-danger" (click)="modal.close('Ok click')">{{'orders.bookingReservationOrders.ok' | translate}}</button>
                                        </div>
                                    </ng-template>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
