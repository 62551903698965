import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wartung-users',
  templateUrl: './wartung-users.component.html',
  styleUrls: ['./wartung-users.component.scss']
})
export class WartungUsersComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
