import { RentalPoint } from './RentalPoint';
import { ClientInformation } from './ClientInformation';

export class RentalPointCashFlow {
    public amount: number;
    public clientInformation: ClientInformation;
    public contractId: string;
    public orderType: number;
    public rentalPoint: RentalPoint;
    public actionDate: string;

    public static fromDto(r): RentalPointCashFlow {
        const rentalPointCashFlow = new RentalPointCashFlow();
        if (!r) {
            return null;
        }
        rentalPointCashFlow.amount = r.amount;
        rentalPointCashFlow.clientInformation = ClientInformation.fromDto(r.clientInformation);
        rentalPointCashFlow.contractId = r.contractId;
        rentalPointCashFlow.orderType = r.orderType;
        rentalPointCashFlow.rentalPoint = RentalPoint.fromDto(r.rentalPoint);
        rentalPointCashFlow.actionDate = (r.startDate ? r.startDate : r.endDate);

        return rentalPointCashFlow;
    }
}
