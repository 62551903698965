import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
const { jsPDF } = require('jspdf'); // will automatically load the node version

@Component({
  selector: 'app-test-pdf',
  templateUrl: './test-pdf.component.html',
  styleUrls: ['./test-pdf.component.scss']
})
export class TestPdfComponent implements OnInit {

  private _htmlFile: string;

  constructor(public http: HttpClient) { }

  ngOnInit() {
    const doc = new jsPDF();

    this.http.get('assets/templates/invoice.html',  { responseType: 'text' }).subscribe(data => {
      console.log(data.toString());
      this._htmlFile = data.toString();

      doc.html(this._htmlFile, {
        // tslint:disable-next-line:no-shadowed-variable
        callback: function (doc) {
          doc.internal.write(0, 'Tw');
          doc.save('testPDF.pdf');
        },
        x: 10,
        y: 10
      });
    });
  }
}
