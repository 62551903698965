import { Pipe, PipeTransform } from '@angular/core';
import { ProductsStatusEnum } from '../../../../shared/enums/ProductsStatusEnum';

@Pipe({
  name: 'productStatus'
})
export class ProductStatusPipe implements PipeTransform {

  transform(status: any): any {
    let productStatus = '';
    switch (status) {
      case 2:
        productStatus = ProductsStatusEnum.TWO;
        break;
      case 3:
        productStatus = ProductsStatusEnum.THREE;
        break;
      case 4:
        productStatus = ProductsStatusEnum.FOUR;
        break;
      case 5:
        productStatus = ProductsStatusEnum.FIVE;
        break;
      case 6:
        productStatus = ProductsStatusEnum.SIX;
        break;
      case 7:
        productStatus = ProductsStatusEnum.SEVEN;
        break;
      case 8:
        productStatus = ProductsStatusEnum.EIGHT;
        break;
      default:
        productStatus = ProductsStatusEnum.ONE;
        break;
    }
    return productStatus;
  }

}
