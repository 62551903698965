<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a class="navbar-brand" href="#">{{rentalPointName}}</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav"
    aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNav">
    <ul class="navbar-nav">
      <!-- <li class="nav-item">
        <a class="nav-link" href="#">Details</a>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" href="rental-points/edit/{{rentalPointId}}">{{'rentalPoint.shared.rentalPointSubnav.edit' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  href="rental-points/default-bike-contingent/{{rentalPointId}}">{{'rentalPoint.shared.rentalPointSubnav.defaultBikeContingent' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link"  href="rental-points/bike-contingent-exceptions/{{rentalPointId}}">{{'rentalPoint.shared.rentalPointSubnav.bikeContingentException' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="rental-points/manage-schedules/{{rentalPointId}}">{{'rentalPoint.shared.rentalPointSubnav.manageSchedules' | translate}}</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" href="rental-points/manage-bike-type-period/{{rentalPointId}}">{{'rentalPoint.shared.rentalPointSubnav.manageBikeTypePeriod' | translate}}</a>
      </li>
    </ul>
  </div>
</nav>