import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import * as moment from 'moment';
import { filter, forkJoin, Observable } from 'rxjs';
import { Client } from '../../../models/Client';
import { SharedUtils, UserType } from '../../../shared/shared-utils';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ViewportScroller } from '@angular/common';
import { Router, Scroll } from '@angular/router';
import { ClientsService } from '../../clients/shared/clients.service';
import { ProductService } from '../../../services/product.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';

class ContingentStatsSearchCriteria {
  rentalPointAccountId?: string;
  clientId?: string;
  startDate?: string;
  endDate?: string;
}

@Component({
  selector: 'app-rented-bikes-stats',
  templateUrl: './rented-bikes-stats.component.html',
  styleUrls: ['./rented-bikes-stats.component.scss']
})

export class RentedBikeStatsComponent implements OnInit {


  private dateFormat = 'YYYY-MM-DD';

  @ViewChild('container', { read: ViewContainerRef, static: true })
  public popupContainer: ViewContainerRef;

  public filters: ContingentStatsSearchCriteria = {};
  public currentLang: string;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public currentUserType: UserType;
  public showResultContent = false;
  public firstLoading = true;
  public chartData: {id: string, bikeType: string, dates: string[], labels: string[], contingents: number[], rentals: number[]}[] = [];

  private _clients: Client[];

  constructor(private _translate: TranslateService, public _snackBar: MatSnackBar, router: Router, viewportScroller: ViewportScroller,
    private _productService: ProductService, private _clientsService: ClientsService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.currentUserType = SharedUtils.getCurrentUserType();
    viewportScroller.setOffset([0, 50]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) { // anchor navigation
        setTimeout(() => viewportScroller.scrollToAnchor(e.anchor));
      } else if (e.position) { // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else { // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        // this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        // this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.filters.startDate = moment(firstDayOfMonth).format(this.dateFormat);
    this.filters.endDate = moment(today).format(this.dateFormat);

    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
    });
  }

  _getDateRange() {
    const dates: string[] = [];
    let start = moment(this.filters.startDate);
    const end = moment(this.filters.endDate);
    while (start.isBefore(end)) {
      dates.push(start.format(this.dateFormat));
      start = start.add(1, 'days');
    }
    dates.push(end.format(this.dateFormat));
    return dates;
  }

  initChart() {
    this.chartData = [];
    const dateRange = this._getDateRange();
    const requests = dateRange.map(date => forkJoin([
        this._productService.getStatsRentedBikes(date, this.filters.rentalPointAccountId, this.currentLang),
        this._productService.getContingentBikesOnSpot(date, this.filters.rentalPointAccountId)
      ])
    );

    const tmp = new Map();

    forkJoin(requests).subscribe({
      next: results => results.forEach((result, index) =>   {
        tmp.set(dateRange[index], result[1].contingent.map((c: any) => ({
            id: c.bikeTypeId,
            name: c[`bikeTypeName_${this.currentLang}`] ?? c.bikeTypeName_en,
            sum: parseInt(c.numberOfProducts, 10) + parseInt(c.buffer, 10),
            rented: result[0].find((r: any) => r.id === c.bikeTypeId)?.count ?? 0
          })
        ));
      }),
      error: err => {
        console.warn('Error fetching data:', err);
      }
    }).add(() => {
      const bikeTypes = tmp.get(dateRange[0]).map((c: any) => c.name);
      for (let i = 0; i < bikeTypes.length; i++) {
        const contingents: number[] = dateRange.map(date => tmp.get(date).find(c => c.name === bikeTypes[i])?.sum ?? 0);
        const rentals: number[] = dateRange.map(date => tmp.get(date).find(c => c.name === bikeTypes[i])?.rented ?? 0);
        const labels = dateRange.map((date, ix) => `${moment(date).format('DD/MM')}\nC: ${contingents[ix]}\nR: ${rentals[ix]}`);
        this.chartData.push({
          id: bikeTypes[i].trim(),
          bikeType: bikeTypes[i],
          dates: dateRange,
          labels: labels,
          contingents: contingents,
          rentals: rentals,
        });
      }
      this.showResultContent = true;
    });
  }

  getColor(bikeChartId: string) {
    const data = this.chartData.find(c => c.id === bikeChartId);
    return data.rentals.some((r, i) => r > data.contingents[i]) ? 'red' : 'blue';
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onResetButtonClick() {
    this.showResultContent = false;
    this.firstLoading = true;
    this.chartData = [];
    this.filters = {};
  }

  onApplyButtonClick() {
    this.showResultContent = false;
    this.initChart();
    if (this.firstLoading) {
      this.firstLoading = false;
    }
  }


}
