<div class="container-fluid">
  <div class="row">
    <div class="col text-right">
      <a href="/wartung-users/add" class="btn btn-sm btn-primary">{{'wartungUser.addNew' | translate}}</a>
    </div>
  </div>
  <div class="row my-5">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters" [pageSize]="15" (beforeSubmit)="beforeSubmit()">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-select label="{{'wartungUser.client' | translate}}" [(model)]="filters.clientId" [options]="clientOptions">
            </app-data-filter-select>
            <app-data-filter-select label="{{'wartungUser.status' | translate}}" [(model)]="filters.status" [options]="statusOptions">
            </app-data-filter-select>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>
      <ng-template #email  let-email="email">{{ email }}</ng-template>
      <ng-template #username let-username="username">{{username}}</ng-template>
      <ng-template #firstName let-firstName="firstName">{{firstName}}</ng-template>
      <ng-template #lastName let-lastName="lastName">{{lastName}}</ng-template>
      <ng-template #status let-status="status">{{ status | wartungUserStatus | translate }}</ng-template>
      <ng-template #actions let-id="id">
        <div class="btn-group" role="group">
          <a href="wartung-users/edit/{{id}}" class="btn btn-primary btn-sm">{{'wartungUser.edit' | translate}}</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
