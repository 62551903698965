<app-header-auth></app-header-auth>
<div class="headText">
  <a (click)="back()">
    <img src="../../../assets/images/back.png">
  </a>
  <p>{{'orders.ordersTurnover.orderTurnover' | translate}}</p>
</div>
<hr>
<div class="container">
  <div class="row" id="main-filter-container">
    <div class="col-xs-2" *ngIf="(accountType == 'admin')">
      <select name="clients" (change)="changeClients($event)" [(ngModel)]="selectedClient"
        class="form-control custom-select">
        <option value="undefined">{{'orders.ordersTurnover.allClients' | translate}}</option>
        <option *ngFor="let client of clients" value="{{client.accountId}}" attr.data-clientid="{{client.id}}">
          {{client.businessName}}</option>
      </select>
    </div>
    <div class="col-xs-2">
      <select name="rentalpoints" [(ngModel)]="selectedRentalPoint" class="form-control custom-select">
        <option value="" *ngIf="(accountType != 'rentalPoint')">{{'orders.ordersTurnover.allRentalPoints' | translate}}
        </option>
        <option *ngFor="let rentalpoint of rentalPoints" value="{{rentalpoint.accountId}}">{{rentalpoint.businessName}}
        </option>
      </select>
    </div>
    <div class="col-xs-2">
      <input id="ex-start-day" class="form-control custom-select no-arrow" type="text" [(ngModel)]="startDate"
        placeholder="DD-MM-YYYY" bsDatepicker [bsConfig]="datePickerConfig" name="startDate">
    </div>
    <div class="col-xs-2">
      <input id="ex-end-day" class="form-control custom-select no-arrow" type="text" placeholder="DD-MM-YYYY"
        bsDatepicker [(ngModel)]="endDate" [bsConfig]="datePickerConfig" name="endDate">
    </div>
    <div class="col-xs-1">
    </div>
    <div class="col-xs-2">
      <button (click)="excelExportTurnover()"
        class="add-button">{{'orders.ordersTurnover.excelExport' | translate}}</button>
    </div>
  </div>
  <div class="row" style="margin: 0px;">
    <div class="order-table col-xs-12">
      <angular2-smart-table [settings]="settings" [source]="source"></angular2-smart-table>
    </div>
  </div>
</div>
