<div class="container-fluid">
  <div class="row" *ngIf="userType.userTypeNumber !== 2">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-2" *ngIf="userType.userTypeNumber === 0">
              <div class="form-group">
                <label class="control-label">{{'products.bikeEquipmentSummary.clients' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="selectedClientId"
                  (change)="onClientSelectChange()">
                  <option *ngFor="let client of clientOptions" value="{{client.value}}">{{client.label}}</option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <app-select-search label="{{'products.bikeEquipmentSummary.rentalPoints' | translate}}"
                [options]="rentalPointOptions" [(model)]="selectedRentalPointAccountId"></app-select-search>
            </div>
            <div class="col-1">
              <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick()"
                style="margin-top: 25px;">{{'products.bikeEquipmentSummary.filter' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-3 mb-3" *ngFor="let product of productSummary">
      <div class="card">
        <div class="card-body text-center">
          <h5 class="card-title">{{product.bikeType.bikeTypeTranslation[currentLang]}}</h5>
          <p class="card-text">
            <strong class="badge badge-primary" style="font-size: 16px;">{{product.quantity}}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>