<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                          <app-data-filters #dataFilters [showExcelExportButton]="showResultContent">
                            <app-data-filter-group>
                              <app-data-filter-select label="{{'statements.cashflow.clients' | translate}}"
                                  [(model)]="filters.clientId" [options]="clientOptions"
                                  *ngIf="currentUserType.userTypeNumber === 0;"
                                  (modelChange)="onClientSelectChange($event)">
                              </app-data-filter-select>
                              <app-data-filter-select-search label="{{'statements.cashflow.rentalPoints' | translate}}"
                                  [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
                                  *ngIf="currentUserType.userTypeNumber !== 2">
                              </app-data-filter-select-search>
                              <app-data-filter-datepicker label="{{'statements.cashflow.from' | translate}}"
                                  [(model)]="filters.startDate">
                              </app-data-filter-datepicker>
                              <app-data-filter-datepicker label="{{'statements.cashflow.to' | translate}}"
                                  [(model)]="filters.endDate">
                              </app-data-filter-datepicker>
                            </app-data-filter-group>
                          </app-data-filters>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3">
      <kendo-chart *ngIf="showResultContent && chartData.rentalPoints?.length > 0 && chartData.percentages?.length > 0" class="mt-3">
        <kendo-chart-series>
          <kendo-chart-area [height]="450"></kendo-chart-area>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="chartData.rentalPoints"></kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [labels]="{ format: '{0}%' }"></kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-series-item type="bar" [data]="chartData.percentages" [spacing]="1" [gap]="1">
            <kendo-chart-series-item-labels align="right" position="outsideEnd" background="none" format="{0:n2}%"></kendo-chart-series-item-labels>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
    <div *ngIf="!filters.clientId && !filters.rentalPointAccountId" style="display: flex; text-align: center">
      <span>{{'rentalPoint.shared.missingClientRentalPoint' | translate}}</span>
    </div>
    <ng-container>
        <div class="row mt-3" *ngIf="!showResultContent && (fetchesCounter > 1)">
            <div class="col">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" [hidden]="!showResultContent">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th style="font-size: 16px;text-align: right;">
                      {{'statements.cashflow.totalAmount' | translate}} {{amountSum | currency: ' € '}}
                    </th>
                  </tr>
                </thead>
              </table>
              <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
                  [pageSize]="25" (onReset)="onResetButtonClick()" (beforeSubmit)="beforeSubmit()"
                  [showSearch]="false" (onLoad)="onItemsFetched($event)" (onExcelExport)="onExcelExportButtonClick()" [customExcelExport]="true">
              </app-data-table>
              <ng-template #clientInformation let-clientInformation="clientInformation">
                {{clientInformation.firstName}} {{clientInformation.lastName}}
              </ng-template>
              <ng-template #rentalPoint let-rentalPoint="rentalPoint">
                {{rentalPoint.businessName}}
              </ng-template>
              <ng-template #contractId let-contractId="contractId">
                {{contractId}}
              </ng-template>
              <ng-template #actionDate let-actionDate="actionDate">
                {{actionDate | date: 'dd-MM-yyyy'}}
              </ng-template>
              <ng-template #amount let-amount="amount">
                {{amount | currency: ' €'}}
              </ng-template>
            </div>
        </div>
    </ng-container>
</div>
