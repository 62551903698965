import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { OrderCancellationService } from './order-cancellation.service';
import { SharedUtils } from '../../shared/shared-utils';

@Component({
  selector: 'app-order-cancellation',
  templateUrl: './order-cancellation.component.html',
  styleUrls: ['./order-cancellation.component.scss']
})
export class OrderCancellationComponent implements OnInit {
  public contractId: number;
  private _language: string;
  private _messageLabelCode: string;
  public confirmed: boolean;
  public clientMessage: string;

  public currentUserType = SharedUtils.getCurrentUserType();

  constructor(
    private _translate: TranslateService, private _route: ActivatedRoute,
    private _orderCancellationService: OrderCancellationService) {
    this.confirmed = false;
    _translate.setDefaultLang('en');
    this._language = 'en';
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
        this.contractId = params['contractId'];
        if (this._translate.getLangs().includes(params['lang'])) {
          this._translate.currentLang = this._language;
        }
      }
    );
  }

  public onCancelOrderConfirm() {
    this.confirmed = true;
    if (this.contractId) {
      this._orderCancellationService.cancelReservationEmail(this.contractId, this.currentUserType.accountId).subscribe({
        next: (res) => {
          this._messageLabelCode = 'contractCancelled';
          this.clientMessage = this._translate.instant('ordercancellation.messages.' + this._messageLabelCode);
        },
        error: (err) => {
          switch (err.status) {
            case 601:
              console.log('Error: send contract id');
              this._messageLabelCode = 'sendContractId';
              break;
            case 602:
              console.log('Error: send token');
              this._messageLabelCode = 'sendToken';
              break;
            case 603:
              console.log('Error: token is not same');
              this._messageLabelCode = 'tokenIsNotSame';
              break;
            case 604:
              console.log('Error: cannot find reservation');
              this._messageLabelCode = 'cannotFindReservation';
              break;
            case 605:
              console.log('Error: reservation already converted to booked rental');
              this._messageLabelCode = 'reservationAlreadyConvertedToBookedRental';
              break;
            case 606:
              console.log('Error: cannot cancel reservation');
              this._messageLabelCode = 'cannotCancelReservation';
              break;
            default:
              console.log('Error: internal error');
              this._messageLabelCode = 'internalError';
              break;
          }
          this.clientMessage = this._translate.instant('ordercancellation.messages.errors.' + this._messageLabelCode);
        }
      });
    } else {
      console.log('Error: cannot find reservation contractId');
      this.clientMessage = this._translate.instant('ordercancellation.messages.errors.cannotFindReservation');
      console.log(this.clientMessage);
      return;
    }
  }
}
