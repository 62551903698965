import 'hammerjs';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ChashFlowService } from '../shared/chash-flow.service';
import { ClientsService } from '../../clients/shared/clients.service';
import { Client } from '../../../models/Client';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { SharedUtils, UserType } from '../../../shared/shared-utils';
import { Observable, forkJoin } from 'rxjs';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import * as FileSaver from 'file-saver';
import { RentalPointCashFlow } from '../../../models/RentalPointCashFlow';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { ClientInformation } from '../../../models/ClientInformation';
import { RentalPoint } from '../../../models/RentalPoint';
import { SearchObjectResult } from '../../../models/SearchObject';
import * as moment from 'moment';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

class HeaderColumns {
  header: string;
  id: string;
  width?: number;
}


export class RentalPointCashFlowSearchCriteria {
  rentalPointAccountId?: string;
  clientId?: string;
  startDate?: string;
  endDate?: string;
}

@Component({
  selector: 'app-rental-point-cash-flow',
  templateUrl: './rental-point-cash-flow.component.html',
  styleUrls: ['./rental-point-cash-flow.component.scss']
})
export class RentalPointCashFlowComponent implements OnInit {

  public chartData: {rentalPoints: string[], percentages: number[]} = {rentalPoints: [], percentages: []};
  public filters: RentalPointCashFlowSearchCriteria = {};
  public currentLang: string;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public currentUserType: UserType;
  public contractsArray: any[] = [];
  public amountSum =  0;
  public showResultContent = false;
  public fetchesCounter = 0;

  private _clients: Client[];

  public selectedClientId;
  public selectedRentalPointId;

  public tableData = [];

  public selectionDateRange = {
    start: null,
    end: null,
  };

  columns: DataTableColumn[] = [];
  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('clientInformation', { static: true }) clientInformation: TemplateRef<ClientInformation>;
  @ViewChild('rentalPoint', { static: true }) rentalPoint: TemplateRef<RentalPoint>;
  @ViewChild('contractId', { static: true }) contractId: TemplateRef<string>;
  @ViewChild('actionDate', { static: true }) actionDate: TemplateRef<string>;
  @ViewChild('amount', { static: true }) amount: TemplateRef<number>;

  constructor(private _translate: TranslateService, public _snackBar: MatSnackBar,
    private _cashFlowService: ChashFlowService, private _clientsService: ClientsService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    this.currentUserType = SharedUtils.getCurrentUserType();
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        // this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        accountsRequest = this._clientsService.getClients();
        // this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this.filters.startDate = moment(firstDayOfMonth).format('YYYY-MM-DD');
    this.filters.endDate = moment(today).format('YYYY-MM-DD');
    this.selectionDateRange.start = firstDayOfMonth;
    this.selectionDateRange.end = today;

    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
    });
  }

  beforeSubmit() {
    this.showResultContent = false;
    this.fetchesCounter ++;

    if (this.filters.clientId || this.filters.rentalPointAccountId) {
      this._cashFlowService.getCashFlowTotalAmount(this.filters).subscribe(res => {
        this.amountSum = res.totalAmount;
      });
    }
  }

  applyFilters() {
    this.filters.clientId = this.selectedClientId;
    this.filters.rentalPointAccountId = this.selectedRentalPointId;
    this.filters.startDate = moment(this.selectionDateRange.start).format('YYYY-MM-DD');
    this.filters.endDate = moment(this.selectionDateRange.end).format('YYYY-MM-DD');
    forkJoin([this._cashFlowService.getCashFlow(this.filters), this._cashFlowService.getCashFlowTotalAmount(this.filters)]).subscribe(res => {
      this.tableData = [];
      res[0].forEach(e => {
        this.tableData.push({
          name: e.clientInformation.lastName + " " + e.clientInformation.firstName,
          business: e.rentalPoint.businessName,
          contractId: e.contractId,
          actionDate: e.startDate,
          amount: e.amount
        });
      });
      this.contractsArray = res[0];
      this.initChart();
      this.amountSum = res[1].totalAmount;
      this.showResultContent = true;
    });
  }

  aggregateCashFlow(): { rentalPoint: string, percentage: number }[] {
    const clientAmountMap = new Map<number, number>();

    this.contractsArray.forEach(cashFlow => {
      const clientId = cashFlow.rentalPoint.accountId;
      const currentAmount = clientAmountMap.get(clientId) || 0;
      clientAmountMap.set(clientId, currentAmount + cashFlow.amount);
    });

    const aggregatedCashFlows: any[] = [];

    clientAmountMap.forEach((amount, clientId) => {
      aggregatedCashFlows.push({
        rentalPoint: this.rentalPointOptions.find(option => option.value === clientId).label,
        percentage: (amount / this.amountSum) * 100
      });
    });

    return aggregatedCashFlows;
  }

  initChart() {
    if (this.filters.startDate && this.filters.endDate && this.filters.clientId &&
      (this.filters.rentalPointAccountId === undefined || this.filters.rentalPointAccountId == null)) {
      const aggregator = this.aggregateCashFlow().sort((a, b) => a.percentage - b.percentage);
      this.chartData = {
        rentalPoints: aggregator.map(a => a.rentalPoint),
        percentages: aggregator.map(a => a.percentage),
      };
    } else {
      this.chartData = {rentalPoints: [], percentages: []};
    }
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.selectedClientId = clientId;
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.bookingReservationOrders.all') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onRentalPointChange(rentalPointId: number) {
    this.selectedRentalPointId = rentalPointId;
  }

  onExcelExportButtonClick() {
    const headers: HeaderColumns[] = [
      { header: 'Rental Point Name', id: 'rentalPointName', width: 35 },
      { header: 'Contract Id', id: 'contractId', width: 25 },
      { header: 'Action date', id: 'actionDate', width: 15 },
      { header: 'Amount', id: 'amount', width: 15 }
    ];
    // Create workbook and worksheet
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('CashFlow');
    worksheet.columns = headers;

    this.contractsArray.forEach(contract => {
        worksheet.addRow([
          contract.rentalPoint.businessName,
          contract.contractId,
          contract.startDate,
          contract.amount
        ]);
    });
    worksheet.addRow([
      '',
      '',
      'Total amount:',
      this.amountSum
    ]);

    // tslint:disable-next-line:no-shadowed-variable
    workbook.xlsx.writeBuffer().then((data) => {
      this._saveAsExcelFile(data, 'CashFlow');
    });
  }

  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  onResetButtonClick() {
    this.showResultContent = false;
    this.fetchesCounter = 0;
    this.filters = {};
    this.contractsArray = [];
    this.amountSum = 0;
    this.chartData = { rentalPoints: [], percentages: [] };
  }

}
