import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { WartungUsersService } from '../shared/wartung-users.service';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Account } from '../../../models/Account';
import { ActivatedRoute, Router } from '@angular/router';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';

@Component({
  selector: 'app-wartung-user-add-update',
  templateUrl: './wartung-user-add-update.component.html',
  styleUrls: ['./wartung-user-add-update.component.scss']
})
export class WartungUserAddUpdateComponent implements OnInit {
  public formGroup: FormGroup;
  public errorArray = [];

  public isUpdateForm: boolean;
  public original: any;
  public clientOptions: Option[] = [];

  constructor(
    private _routes: ActivatedRoute,
    private _router: Router,
    private _translate: TranslateService,
    private _wartungUserService: WartungUsersService,
    private _snackBar: MatSnackBar) {

    this.formGroup = new FormGroup({
      clientId: new FormControl(),
      username: new FormControl(),
      email: new FormControl(),
      password: new FormControl(),
      lastName: new FormControl(),
      firstName: new FormControl(),
      status: new FormControl(true),
    });
  }

  ngOnInit() {
    this._routes.params.subscribe(params => {
      this.isUpdateForm = params['id'];
      this._wartungUserService.getWartungClients().subscribe({
        next: (result) => {
          this.clientOptions = result.map(client => ({ value: client.id, label: client.businessName }));
          if (!this.isUpdateForm) {
            this.formGroup.patchValue({ clientId: this.clientOptions[0].value });
          } else {
            const id = params['id'];
            if (!isNaN(id)) {
              this._wartungUserService.get(Number(id)).subscribe({
                next: (res: any) => {
                  this.formGroup.reset();
                  this.formGroup.patchValue(res);
                  this.original = res;
                },
                error: (error) => {
                  this._snackBar.open('Internal server error', '', { duration: 2500 });
                }
              });
            }
          }
        },
        error: () => console.log('Unable to fetch wartung clients'),
      });
    });
  }

  public submit() {
    if (this.formGroup.valid) {
      const newWartungUser = Account.fromDto(this.formGroup.value);
      if (this.isUpdateForm) {
        newWartungUser.id = this.original.id;
        newWartungUser.deleted = this.formGroup.controls.status.value === "false";
      }
      const submitObservable = this.isUpdateForm
        ? this._wartungUserService.update(newWartungUser)
        : this._wartungUserService.add(newWartungUser);

      submitObservable.subscribe({
        next: () => {
          const message = this.isUpdateForm ? this._translate.instant('wartungUser.updateSuccess') : this._translate.instant('wartungUser.addSuccess');
          this._snackBar.open(this._translate.instant(message), '', { duration: 1000 });
        },
        error: (error) => {
          const errorMessage = error.status === 400 ? 'Username already exists' : 'Internal server error';
          this._snackBar.open(errorMessage, '', { duration: 2500 });
        }
      });
    } else {
      this.errorArray = ValidationUtils.getFormValidationErrors(this.formGroup, this.formGroup.controls);
    }
  }

}
