import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { ORDERS_ROUTES } from './orders.routes';
import { CreditsComponent } from './credits/credits.component';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { CreditsStatusPipe } from './shared/pipes/credits-status.pipe';
import { OrdersComponent } from './orders.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { BookingReservationOrdersComponent } from './booking-reservation-orders/booking-reservation-orders.component';
import { BookingReservationStatusPipe } from './shared/pipes/booking-reservation-status.pipe';
import { OrderTypePipe } from './shared/pipes/order-type.pipe';
import { OrderTypeAllOrdersPipe } from './shared/pipes/order-type-allorders.pipe';
import { OrderStatusPipe } from './shared/pipes/order-status.pipe';
import { OrdersDetailsComponent } from './orders-details/orders-details.component';
import { ContractPaperComponent } from './papers/contract-paper/contract-paper.component';
import { NgxBarcode6Module } from 'ngx-barcode6';
import { DayDiffPipe } from './shared/pipes/day-diff.pipe';
import { ReturnPaperComponent } from './papers/return-paper/return-paper.component';
import { PartialReturnPaperComponent } from './papers/partial-return-paper/partial-return-paper.component';
import { ReturnTypePipe } from './shared/pipes/return-type.pipe';
import { PartialCancellationPaperComponent } from './papers/partial-cancellation-paper/partial-cancellation-paper.component';
import { TotalCancellationPaperComponent } from './papers/total-cancellation-paper/total-cancellation-paper.component';
import { UnsuccessfulPaymentsComponent } from './unsuccessful-payments/unsuccessful-payments.component';
import { CreditPaperComponent } from './papers/credit-paper/credit-paper.component';
import { TurnoverComponent } from './turnover/turnover.component';
import { RentalDurationPipe } from './shared/pipes/rental-duration.pipe';
import { UpdateOrderComponent } from './update-order/update-order.component';
import { OrdersByQrCodeComponent } from './orders-by-qr-code/orders-by-qr-code.component';
import { ConfirmWidgetOrderComponent } from './confirm-widget-order/confirm-widget-order.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ReturnOrdersComponent } from './return-orders/return-orders.component';
import { InvoiceTypeStatusPipe } from './shared/pipes/invoice-type-status.pipe';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(ORDERS_ROUTES),
    BsDatepickerModule,
    Angular2SmartTableModule,
    FormsModule,
    CommonModule,
    NgxBarcode6Module
  ],
  exports: [
    InvoiceTypeStatusPipe
  ],
  declarations: [
    OrdersComponent,
    CreditsComponent,
    CreditsStatusPipe,
    AllOrdersComponent,
    BookingReservationOrdersComponent,
    BookingReservationStatusPipe,
    InvoiceTypeStatusPipe,
    OrderTypePipe,
    OrderTypeAllOrdersPipe,
    OrderStatusPipe,
    OrdersDetailsComponent,
    ContractPaperComponent,
    DayDiffPipe,
    ReturnPaperComponent,
    PartialReturnPaperComponent,
    ReturnTypePipe,
    PartialCancellationPaperComponent,
    TotalCancellationPaperComponent,
    UnsuccessfulPaymentsComponent,
    CreditPaperComponent,
    TurnoverComponent,
    RentalDurationPipe,
    UpdateOrderComponent,
    OrdersByQrCodeComponent,
    ConfirmWidgetOrderComponent,
    ReturnOrdersComponent
  ]
})
export class OrdersModule { }
