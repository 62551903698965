<h4>{{ 'products.maintenanceHistory' | translate }}</h4>
<app-data-table
  [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [showSearch]="false">
</app-data-table>
<ng-template #staff let-firstName="firstName" let-lastName="lastName">
  @if (firstName && firstName === lastName) {
    <ng-container>{{ firstName }}</ng-container>
  } @else {
    <ng-container>{{ firstName + ' ' + lastName }}</ng-container>
  }
</ng-template>
<ng-template #defect let-defect="defect">
  <ng-container>{{ defect }}</ng-container>
</ng-template>
<ng-template #defectType let-defectType="defectType" let-dTid="defectTypeId">
  @if (dTid === 35) {
    <ng-container>{{ 'tickets.ticketDetails.defectTypeOthers' | translate }}</ng-container>
  } @else {
    <ng-container>{{ defectType }}</ng-container>
  }
</ng-template>
<ng-template #fixDate let-fixDate="fixedDate">
  <ng-container>{{ fixDate | date: 'dd-MM-yyyy' }}</ng-container>
</ng-template>
<ng-template #fixTime let-fixTime="fixedTime">
  <ng-container>{{ fixTime }}</ng-container>
</ng-template>
