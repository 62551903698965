import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../services/orders.service';
import { OrdersService as SharedOrdersService } from '../shared/orders.service';
import { UserService } from '../../../services/user.service';
import { ProductService } from '../../../services/product.service';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { OnlineBooking } from '../../../models/OnlineBooking';
import { Account } from '../../../models/Account';
import * as moment from 'moment';
import { CompanyInvoice } from '../../../models/Company';
import { IndividualInvoice } from '../../../models/IndividualInvoice';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss']
})
export class InvoiceComponent implements OnInit {

  public order: OnlineBooking = new OnlineBooking;
  public pickUpRentalPoint: Account = new Account();
  public returnRentalPoint: Account = new Account();
  public imagePath = '../../../../assets/images';
  public date = new Date();
  public countries = [];
  public daysDiff = 0;
  public costForBikes = 0;
  public pdfLink: string;

  constructor(private ordersService: OrdersService, private route: ActivatedRoute,
    private userService: UserService, private productService: ProductService,
    private titleService: Title,
    private sharedOrdersService: SharedOrdersService,
    private translate: TranslateService,
    private http: HttpClient) {
  }
  ngOnInit() {
    this.userService.getCountries().subscribe(countries => {
      for (let i = 0; i < countries.length; i++) {
        const element = countries[i];
        this.countries[element.id] = element.name;
      }
    });

    this.route.params.subscribe(params => {
      this.ordersService.getOrderForInvoice(params.contractId).subscribe(result => {
        this.order = result;
        this.costForBikes = this.order.costForBikes; // this solves initial value of 0
        const invoice = result['invoice'];
        const company = invoice['company'];
        const individual = invoice['private'];

        if (company) {
          this.order.invoice.company = CompanyInvoice.fromJson(company);
        } else if (individual) {
          this.order.invoice.individual = IndividualInvoice.fromJson(individual);
        } else {
          console.log('no company or individual found on invoice');
        }

        this.translate.use(this.order.language);
        this.daysDiff = this.getDayDiff(this.order.startDate, this.order.endDate);
        this.pickUpRentalPoint = Account.fromDto(this.order.startRentalPoint);
        this.returnRentalPoint = Account.fromDto(this.order.endRentalPoint);
        // // set page title
        const datePipe = new DatePipe('en-US');
        const placementDate = datePipe.transform(this.order.placementTime, 'yyyy-MM-dd');
        const pageTitle = `${placementDate}_Papin_Bike_Invoice`;
        this.titleService.setTitle(pageTitle);
      });
      this.sharedOrdersService.generatePdfLink(params.contractId, 'booking').subscribe({
        next: (response) => {
          this.pdfLink = response.link;
        }, error: (error) => {
          console.log(error);
        },
      });
    });
  }

  getDayDiff(startDate: string, endDate: string) {
    const momentStartDate = moment(startDate);
    const momentendDate = moment(endDate);
    return momentendDate.diff(momentStartDate, 'days') + 1;
  }

  calculateVatPrice(bruttoPrice) {
    return (bruttoPrice / (1 + Number(this.pickUpRentalPoint.rentalPoint.vat)));

  }

  calculateTaxablePrice(discount) {
    return (this.calculateVatPrice(discount) * Number(this.pickUpRentalPoint.rentalPoint.vat));
  }

  calculateTotalAmountVatInclusive(discount) {
    return (this.calculateTaxablePrice(discount) + this.calculateVatPrice(discount));
  }

  calculateNetPrice() {
    return this.calculateVatPrice(this.order.discountedPrice);
  }

  public print(): void {
    window.print();
  }

  public downloadPdf() {
    this.http.get(this.pdfLink, {responseType: 'blob'}).subscribe((pdfBlob: Blob) => {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = `invoice_${this.order.contractId}.pdf`;
      downloadLink.click();
      URL.revokeObjectURL(pdfUrl);
    });
  }

}
