import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { DataFiltersService } from '../data-filters/data-filters.service';
import { DataFiltersComponent } from '../data-filters/data-filters.component';
import { SearchObjectResult } from '../../models/SearchObject';
import { ExcelService } from '../excel.service';
import { Option } from '../data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../models/Client';
import { ActionSelectedBikes } from '../enums/ActionSelectedBikes';

export interface DataTableColumn {
  name: string;
  displayed: boolean;
  translationKey: string;
  template: TemplateRef<any>;
  sortable: boolean;
  additionalClass?: string;
  trAddidionalClass?: string;
  excel?: ExcelData;
}

interface ExcelData {
  objectRef?: any[];
  pipe?: string;
  pipeAdditionalValues?: any[];
}

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.css']
})
export class DataTableComponent implements OnInit, OnDestroy, OnChanges {

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private filtersService: DataFiltersService,
    private _excelSerivce: ExcelService
  ) {
    this.resetSubscription = filtersService.resetObservable.subscribe(() => this.reset());
    this.applySubscription = filtersService.applyObservable.subscribe(() => this.refresh());
    this.excelExportSubscription = filtersService.excelExportObservable.subscribe(() => this.excelExport());
  }

  items: any[] = [];

  @Input()
  columns: DataTableColumn[] = [];

  @Input()
  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<any>>;

  @Input()
  filters: any = {};

  @Input()
  pageSize = 10;

  @Input()
  filtersComponent: DataFiltersComponent;

  @Input()
  selectedItemId: string;

  @Input()
  selectedIds: number [] = [];

  @Input()
  showSearch = true;

  @Output()
  filtersChange: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  beforeReset = new EventEmitter<void>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onReset: EventEmitter<void> = new EventEmitter<void>();

  @Input()
  customExcelExport = false;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onExcelExport: EventEmitter<void> = new EventEmitter<void>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onLoad = new EventEmitter<SearchObjectResult<any>>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPageChange = new EventEmitter<number>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onTableRowClick = new EventEmitter<any>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  beforeSubmit = new EventEmitter<any>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onSubmit = new EventEmitter<any>();

  @Output()
  onDeleteSelected: EventEmitter<void>  = new EventEmitter<void>();

  @Output()
  onMoveBikes: EventEmitter<any>  = new EventEmitter<any>();

  @Input()
  allMoveClients: Client[] = [];

  @Input()
  moveClientOptions: Option[] = [];

  moveRentalPointOptions: Option[] = [];

  moveProduct = {
    clientId: undefined,
    rentalPointAccountId: undefined,
  };

  @Input()
  selectedAction: ActionSelectedBikes;

  availableActions: Option[];

  count = 0;
  totalCount = 0;
  page = 1;
  lastPage = 1;

  resetSubscription: Subscription;
  applySubscription: Subscription;
  excelExportSubscription: Subscription;

  loading = false;

  actionSelectedBikes = ActionSelectedBikes;

  ngOnInit() {
    this.refresh();
    const canDelete = localStorage.getItem('allowedDeleted') === '1';
    const canMoveBikes = localStorage.getItem('allowedMoveBikes') === '1';
    this.availableActions = [{ value: undefined }];
    if (canDelete) {
      this.availableActions.push({ value: ActionSelectedBikes.DELETE, label: this.translate.instant('products.bikeEquipmentList.delete') });
    }
    if (canMoveBikes) {
      this.availableActions.push({ value: ActionSelectedBikes.MOVE, label: this.translate.instant('products.bikeEquipmentList.move') });
    }
  }

  refresh() {
    this.beforeSubmit.emit();
    this.changePage(1);
    this.onSubmit.emit();
  }

  reset() {
    this.beforeReset.emit();

    this.filters = {};
    this.filtersChange.emit(this.filters);
    this.onReset.emit();

    this.refresh();
  }

  excelExport() {
    this.onExcelExport.emit();
    if (this.customExcelExport) {
      return;
    }
    this.loadPage(1, this.totalCount)
      .subscribe(res => {
        // console.log('my res', res);
        this._excelSerivce.exportAsExcelFile(res, 'data', this.columns);
      }, (error) => {
        console.log(error);
        this.toastr.error(error.error.message || this.translate.instant('data-table.errors.load-page'));
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.loadPage && changes.loadPage.currentValue) {
      this.changePage(1);
    }

    if (changes.filtersComponent && changes.filtersComponent.currentValue) {
      this.resetSubscription = this.filtersComponent.getResetObservable().subscribe(() => this.reset());
      this.applySubscription = this.filtersComponent.getApplyObservable().subscribe(() => this.refresh());
      this.excelExportSubscription = this.filtersComponent.excelExportObservable.subscribe(() => this.excelExport());
    }
  }

  changePage(page: number) {
    if (!this.loadPage) {
      return;
    }

    this.page = page;
    this.onPageChange.emit(this.page);

    this.loadPage(this.page, this.pageSize)
      .subscribe(res => {
        console.log('my res', res);
        this.count = res.count;
        this.totalCount = res.totalCount;
        this.items = res.results;
        this.lastPage = res.totalPages;

        this.onLoad.emit(res);
      }, (error) => {
        console.log(error);
        this.toastr.error(error.error.message || this.translate.instant('data-table.errors.load-page'));
      });
  }

  loadNextPage() {
    if (!this.loadPage) {
      return;
    }

    this.page = this.page + 1;
    this.onPageChange.emit(this.page);

    this.loading = true;

    this.loadPage(this.page, this.pageSize)
      .subscribe(res => {
        this.items.push(...res.results);

        this.onLoad.emit(res);
      }, (error) => {
        console.log(error);
        this.toastr.error(error.error.message || this.translate.instant('data-table.errors.load-page'));
      });
  }

  ngOnDestroy() {
    this.resetSubscription.unsubscribe();
    this.applySubscription.unsubscribe();
  }

  onTableRowClickEvent(item: any) {
    this.onTableRowClick.emit(item);
  }

  onDeleteAllButtonClickEvent() {
    this.onDeleteSelected.emit();
  }

  onMoveSelectedButtonClickEvent() {
    if (this.moveProduct.clientId > 0 && this.moveProduct.rentalPointAccountId > 0) {
      this.onMoveBikes.emit(this.moveProduct.rentalPointAccountId);
    } else {
      alert(this.translate.instant('products.bikeEquipmentList.error.missingRentalPoint'));
    }
  }

  onMoveProductClientSelectChange(clientId: number) {
    if (clientId) {
      const selectedClient = this.allMoveClients.filter(client => client.id === clientId)[0];
      this.moveRentalPointOptions = [];
      selectedClient.rentalPoints.forEach(rentalPoint => {
          this.moveRentalPointOptions.push({
            value: rentalPoint.accountId,
            label: rentalPoint.rentalPointTranslation[this.translate.currentLang]
          });
      });
    }
  }
}
