import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { ReturnOrderSearchCriteria } from '../shared/ReturnOrderSearchCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { SharedUtils, UserType } from '../../../shared/shared-utils';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';
import { OrdersService } from '../shared/orders.service';
import { ClientsService } from '../../clients/shared/clients.service';
import { Observable, forkJoin } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { OrderTypeEnum } from '../../../shared/enums/OrderTypeEnum';
import { OrderStatusEnum } from '../../../shared/enums/OrderStatusEnum';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-return-orders',
  templateUrl: './return-orders.component.html',
  styleUrls: ['./return-orders.component.scss']
})
export class ReturnOrdersComponent implements OnInit {
  public filters: ReturnOrderSearchCriteria = {};
  public filtersToSend: ReturnOrderSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;
  public currentUserType: UserType;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public orderTypeOptions: Option[];
  public orderStatusOptions: Option[];

  private _clients: Client[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('returnRentalPoint', { static: true })
  returnRentalPoint: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('returnDate', { static: true })
  returnDate: TemplateRef<any>;

  @ViewChild('orderAmount', { static: true })
  orderAmount: TemplateRef<any>;

  @ViewChild('orderType', { static: true })
  orderType: TemplateRef<any>;

  @ViewChild('orderStatus', { static: true })
  orderStatus: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  constructor(private _modalService: NgbModal, private _ordersService: OrdersService, private _clientsService: ClientsService,
    private _translate: TranslateService, public _snackBar: MatSnackBar) {
    this.currentLang = this._translate.currentLang;
    this.currentUserType = SharedUtils.getCurrentUserType();
    this.clientOptions = [{ value: undefined, label: 'orders.allOrders.all' }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.allOrders.all') }];
    this.orderTypeOptions = [{ value: undefined, label: 'orders.allOrders.all' }, { value: 1, label: OrderTypeEnum.ONE },
      { value: 2, label: OrderTypeEnum.TWO },  {value: 3, label: OrderTypeEnum.THREE }];
    this.orderStatusOptions = [
      { value: undefined, label: 'orders.allOrders.all' },
      { value: 1, label: OrderStatusEnum.ONE },
      { value: 2, label: OrderStatusEnum.TWO },
      { value: 3, label: OrderStatusEnum.THREE },
      { value: 99, label: OrderStatusEnum.FOUR },
      { value: 98, label: OrderStatusEnum.FIVE }
    ];
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      this.filtersToSend = { ... this.filters };
      this.loadPage = (page: number, pageSize: number) => this._ordersService.searchReturnOrders(page, pageSize, this.filtersToSend);
    });
    console.log(this.actions);

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.allOrders.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.allOrders.clientName',
        template: this.clientName,
        sortable: true,
        excel: {
          objectRef: ['clientInformation.firstName', 'clientInformation.lastName']
        }
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.allOrders.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['startRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'returnRentalPoint',
        displayed: true,
        translationKey: 'orders.allOrders.returnRentalPoint',
        template: this.returnRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['endRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.allOrders.startRentalDate',
        template: this.startDate,
        sortable: true,
        excel: {
          objectRef: ['startDate'],
          pipe: 'date'
        }
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'orders.allOrders.returnRentalDate',
        template: this.returnDate,
        sortable: true,
        excel: {
          objectRef: ['endDate'],
          pipe: 'date'
        }
      },
      {
        name: 'orderAmount',
        displayed: true,
        translationKey: 'orders.allOrders.orderAmount',
        template: this.orderAmount,
        sortable: true,
        excel: {
          objectRef: ['discountedPrice'],
          pipe: 'currency'
        }
      },
      {
        name: 'orderType',
        displayed: true,
        translationKey: 'orders.allOrders.orderType',
        template: this.orderType,
        sortable: true,
        excel: {
          objectRef: ['orderType'],
          pipe: 'orderTypeAllOrders'
        }
      },
      {
        name: 'orderStatus',
        displayed: true,
        translationKey: 'orders.allOrders.orderStatus',
        template: this.orderStatus,
        sortable: true,
        excel: {
          objectRef: ['status'],
          pipe: 'orderStatus',
          pipeAdditionalValues: ['endDate']
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
  }

  onTableReset() {
    this.filtersToSend = { ... this.filters };
  }

  onRentalCloseButtonClick(content, contractId, orderType) {
    console.log(content);
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((modalAction) => {
      this._ordersService.closeOrderManually(orderType, contractId).subscribe(result => {
        this._snackBar.open(this._translate.instant('orders.allOrders.message1'), '', {
          duration: 3500,
        });
        this.loadPage = (page: number, pageSize: number) => this._ordersService.searchOrders(page, pageSize, this.filtersToSend);
      });
    }, (reason) => {
    });
  }

  beforeSubmit() {
    this.filtersToSend = { ... this.filters };
  }
}
