<!-- <div class="row">
  <div class="col">
    <div class="card">
      <div class="card-body">

      </div>
    </div>
  </div>
</div> -->
<div class="row">
  <div class="col text-right">
    <a href="rental-points/add/{{currentClientId}}" class="btn btn-primary">{{'clients.clientDetails.addNewRentalPoint' | translate}}</a>
  </div>
</div>
<div class="row mt-3 mb-5">
  <div class="col">
    <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
      [pageSize]="15" (onReset)="onReset()">
      <app-data-filters #dataFilters>
        <app-data-filter-group>
          <app-data-filter-input label="{{'clients.clientDetails.rentalPointName' | translate}}" [(model)]="filters.rentalPointName"
            placeholder="{{'clients.clientDetails.searchRentalPoint' | translate}}">
          </app-data-filter-input>
        </app-data-filter-group>
      </app-data-filters>
    </app-data-table>

    <ng-template #rentalPointId let-id="id">
      {{id}}
    </ng-template>

    <ng-template #rentalPointName let-rentalPointTranslation="rentalPointTranslation">
      {{rentalPointTranslation[currentLang]}}
    </ng-template>

    <ng-template #rentalPointUsername let-account="account">
      {{account.username}}
    </ng-template>

    <ng-template #actions let-id="id">
      <a href="/rental-points/edit/{{id}}" class="btn btn-primary btn-sm">{{'clients.clientDetails.details' | translate}}</a>
    </ng-template>
  </div>
</div>
