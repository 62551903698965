import { Observable } from 'rxjs';
import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '../../../../shared/data-table/data-table.component';
import { SearchObjectResult } from '../../../../models/SearchObject';
import { BikeCheckinSearchCriteria } from './BikeCheckinSearchCriteria';
import { ProductsService } from '../../shared/products.service';
import { Product } from '../../../../models/Product';

@Component({
  selector: 'app-bike-checkin-list',
  templateUrl: './bike-checkin-list.component.html',
  styleUrls: ['./bike-checkin-list.component.css']
})
export class BikeCheckinListComponent implements OnInit {
  @Input () product: Product;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: BikeCheckinSearchCriteria = {};

  @ViewChild('registeredAt', { static: true }) registeredAt: TemplateRef<any>;
  @ViewChild('place', { static: true }) place: TemplateRef<any>;
  @ViewChild('registeredType', { static: true }) registeredType: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _productsService: ProductsService) {}

  ngOnInit() {
    this.filters.productId = this.product.id;
    this.loadPage = (page: number, pageSize: number) => this._productsService.searchProductHistory(page, pageSize, this.filters);
    this.columns = [
      {
        name: 'registeredAt',
        template: this.registeredAt,
        translationKey: 'products.editBikeEquipment.date',
        displayed: true,
        sortable: true
      },
      {
        name: 'place',
        template: this.place,
        translationKey: 'products.editBikeEquipment.place',
        displayed: true,
        sortable: true
      },
      {
        name: 'registeredType',
        template: this.registeredType,
        translationKey: 'products.editBikeEquipment.type',
        displayed: true,
        sortable: true
      }
    ];
  }
}
