import { BikeType } from './BikeType';
import { ContingentException } from './ContingentException';
import { Account } from './Account';

export class ProductsPerRentalPoint {
    public id: number;
    public buffer: number;
    public numberOfProducts: number;
    public bikeType: BikeType;
    public contingentException: ContingentException[];
    public account: Account;

    public static fromDto(d): ProductsPerRentalPoint {
        const productsPerRentalPoint = new ProductsPerRentalPoint();
        if (!d) {
            return null;
        }

        productsPerRentalPoint.id = d.id;
        productsPerRentalPoint.buffer = d.buffer;
        productsPerRentalPoint.numberOfProducts = d.numberOfProducts;
        productsPerRentalPoint.bikeType = d.bikeType;
        productsPerRentalPoint.contingentException = d.contingentException;
        productsPerRentalPoint.account = d.account;

        return productsPerRentalPoint;
    }

}
