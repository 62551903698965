import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../../services/orders.service';
import { OnlineBooking } from '../../../models/OnlineBooking';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-confirm-widget-order',
  templateUrl: './confirm-widget-order.component.html',
  styleUrls: ['./confirm-widget-order.component.scss']
})
export class ConfirmWidgetOrderComponent implements OnInit {

  public confirmationResult;
  public contractId;
  public pdfLink: string;

  constructor(private route: ActivatedRoute, private orderService: OrdersService, private _translate: TranslateService) {
    document.body.style.backgroundColor = '#72a7ff';
    this.route.queryParams.subscribe(params => {
      if (params.lang) {
        localStorage.setItem('selectedLanguage', params.lang);
        this._translate.setDefaultLang(params.lang);
        this._translate.use(params.lang);
      } else {
        localStorage.setItem('selectedLanguage', 'de');
        this._translate.use('de');
        this._translate.setDefaultLang('de');
      }
    });
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      if (params.contractId && params.resourcePath) {
        this.contractId = params.contractId;
        this.orderService.confirmOrder(params.contractId, params.resourcePath).subscribe({
          next: (confirmResult) => {
            this.confirmationResult = confirmResult;
            this.pdfLink = confirmResult.pdfLink;
          }, error: (error) => {
            this.confirmationResult = {orderId: '', status: 400, message: ''};
          }
        });
      } else {
        this.confirmationResult = { orderId: '', status: 400, message: '' };
      }
    });
  }

}
