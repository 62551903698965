import { ProductsStatusEnum } from '../../../shared/enums/ProductsStatusEnum';

export class ProductStatus {
    public status: number;
    public statusName: string;

    public static getProductStatus(): ProductStatus[] {
        const statusArray = [
            { status: 1, statusName: ProductsStatusEnum.ONE },
            { status: 2, statusName: ProductsStatusEnum.TWO },
            { status: 4, statusName: ProductsStatusEnum.FOUR },
            { status: 5, statusName: ProductsStatusEnum.FIVE },
            { status: 6, statusName: ProductsStatusEnum.SIX },
            { status: 7, statusName: ProductsStatusEnum.SEVEN },
            { status: 8, statusName: ProductsStatusEnum.EIGHT },
        ];
        return statusArray;
    }
}
