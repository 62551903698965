import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PROFILE_ROUTES } from './profile.routes';
import { ProfileComponent } from './profile.component';
import { UpdateProfileComponent } from './update-profile/update-profile.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(PROFILE_ROUTES),
        FormsModule,
        CommonModule
    ],
    exports: [],
    declarations: [
        ProfileComponent,
        UpdateProfileComponent
    ]
})

export class ProfileModule { }
