import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ServicePointService } from '../shared/service-points.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicePoint } from '../../../models/ServicePoint';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Router } from '@angular/router';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-service-point-add',
  templateUrl: './service-point-add.component.html',
  styleUrls: ['./service-point-add.component.scss']
})
export class ServicePointAddComponent implements OnInit {
  public addServicePointForm: FormGroup;
  public errorArray = [];
  public districtOptions: Option[] = [];
  public countryOptions: Option[] = [];

  constructor(private _servicePointService: ServicePointService, private _snackBar: MatSnackBar, private _router: Router,
    private _translate: TranslateService) {

    this.addServicePointForm = new FormGroup({
      id: new FormControl(0,  Validators.required),
      en: new FormControl(null, Validators.required),
      it: new FormControl(null, Validators.required),
      de: new FormControl(null, Validators.required),
      region: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      districtId: new FormControl(null, Validators.required),
      countryId: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      latitude: new FormControl(null, Validators.required),
      longitude: new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    const districts = this._servicePointService.getDistricts();
    const countries = this._servicePointService.getCountries();
    forkJoin([districts, countries]).subscribe(res => {
      console.log('districts', res[0]);
      console.log('countries', res[1]);
      res[0].forEach(district => {
        this.districtOptions.push({ value: district.id, label: district.name });
      });
      res[1].forEach(country => {
        this.countryOptions.push({ value: country.id, label: country.name });
      });
    });
  }

  addServicePointSubmit() {
    if (this.addServicePointForm.valid) {
      const newServicePoint = ServicePoint.fromDto(this.addServicePointForm.value);
      this._servicePointService.insertServicePoint(newServicePoint).subscribe(result => {
        this.addServicePointForm.reset();

        this._snackBar.open(this._translate.instant('servicePoints.addServicePoint.servicePointAdded'), '', {
          duration: 3500,
        }).afterDismissed().subscribe(() => {
          this._router.navigate(['/service-points/list']);
        });
      },
      (error => {
        switch (error.status) {
          case 601:
            this._snackBar.open('Object not sent', '', {
              duration: 2500
            });
            break;
          case 602:
            this._snackBar.open('Please insert english name', '', {
              duration: 2500
            });
            break;
          case 603:
            this._snackBar.open('Please insert italian name', '', {
              duration: 2500
            });
            break;
          case 604:
            this._snackBar.open('Please insert german name', '', {
              duration: 2500
            });
            break;
        }
      }));
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.addServicePointForm, this.addServicePointForm.controls);
  }
}
