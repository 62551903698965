<div class="container" style="padding-bottom: 20px;">
  <div class="row">
    <div class="col">
      <div class="card" id="partial-return-paper">
        <div class="card-body">
          <blockquote class="blockquote text-center" >
            <p class="mb-0" style="font-weight: normal;">
              {{'orders.papers.totalCancellationPaper.stornierung' | translate}} </p>
          </blockquote>
          <ng-container *ngIf="endRentalPoint.country === 1; else italyTitleTemplate">
            <blockquote class="blockquote text-center" >
              <p class="mb-0">Papin Sport & Freizeit GmbH <br /> Arnbach 41F 9920 Arnbach / Sillian<br />
                ATU65441346 </p>
            </blockquote>
          </ng-container>
          <ng-template #italyTitleTemplate>
            <blockquote class="blockquote text-center" >
              <p class="mb-0">
                Papin Sport GmbH / srl <br />
                Freisingerstr. 9 Via Freising <br />
                I-39038 Innichen / San Candido (BZ)<br />
                MwSt.Nr.-P.Iva 00480120211
              </p>
            </blockquote>
          </ng-template>
          <div class="text-center mt-2 mb-2">
            <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="papin-logo" alt="Papin Logo"
              class="img-responsive" height="65">
          </div>
          <div class="text-center">
            <ngx-barcode6 [bc-value]="contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1"></ngx-barcode6>
          </div>

          <div id="partial-return-details" class="mt-3">
            <dl class="row">
              <dt class="col-6">{{'orders.papers.totalCancellationPaper.date' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.orderReturn.returnDate | date: 'dd-MM-yyyy'}}</dd>
              <dt class="col-6">{{'orders.papers.totalCancellationPaper.contractNumber' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.contractId}}</dd>
              <dt class="col-6">{{'orders.papers.totalCancellationPaper.clientName' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.clientInformation.firstName}} {{rental.clientInformation.lastName}}
              </dd>
              <dt class="col-6">{{'orders.papers.totalCancellationPaper.von' | translate}}:</dt>
              <dd class="col-6 text-right">{{startRentalPoint.rentalPointTranslation[currentLang]}}</dd>
              <dt class="col-6">{{'orders.papers.totalCancellationPaper.bis' | translate}}:</dt>
              <dd class="col-6 text-right">{{endRentalPoint.rentalPointTranslation[currentLang]}}</dd>
            </dl>
          </div>
          <div id="bikes-list">
            <table class="table table-borderless">
              <tbody>
                <tr *ngFor="let bike of bikes;">
                  <td>
                    <strong>1 {{bike.bikeType.bikeTypeTranslation[currentLang]}}
                      {{(bike.qrCode ? '(' + bike.qrCode + ')' : '')}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div id="delay-credit-container"
            *ngIf="rental.orderReturn.credit !== 0 || rental.orderReturn.delayPrice !== 0">
            <hr class="dashed" />
            <div class="row">
              <ng-container *ngIf="rental.orderReturn.credit > 0; else delayTemplate">
                <dt class="col-6">{{'orders.papers.totalCancellationPaper.guthaben' | translate}}:</dt>
                <dd class="col-6 text-right">{{rental.orderReturn.credit | currency:'€ '}}</dd>
              </ng-container>
              <ng-template #delayTemplate>
                <dt class="col-6">{{'orders.papers.totalCancellationPaper.verspätung' | translate}}:</dt>
                <dd class="col-6 text-right">{{rental.orderReturn.delayPrice | currency:'€ '}}</dd>
              </ng-template>
            </div>
          </div>
          <p class="text-center mt-3 mb-3" style="font-size: 14px;">
            {{rental.orderReturn.comment}}</p>
          <p class="text-center" style="font-size: 14px;">
            <strong>{{'orders.papers.totalCancellationPaper.message1' | translate}}</strong>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
