import { Component, OnInit } from '@angular/core';
import { Rental } from '../../../../models/Rental';
import { Account } from '../../../../models/Account';
import { ActivatedRoute } from '@angular/router';
import { OrdersService } from '../../shared/orders.service';
import { RentalPointsService } from '../../../rental-point/shared/rental-points.service';
import { forkJoin } from 'rxjs';
import { RentalBike } from '../../../../models/RentalBike';

@Component({
  selector: 'app-partial-return-paper',
  templateUrl: './partial-return-paper.component.html',
  styleUrls: ['./partial-return-paper.component.scss']
})
export class PartialReturnPaperComponent implements OnInit {

  public contractId: string;
  public rental: Rental = new Rental();
  public bikes: RentalBike[];
  public returnDate;
  public account: Account = new Account();

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService,
    private _rentalPointsService: RentalPointsService) { }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.returnDate = params['date'];
      this.contractId = params['contractId'];
      const accountId = params['accountId'];
      forkJoin([
        this._ordersService.getReturnPaper(this.contractId),
        this._rentalPointsService.getRentalPointByAccountId(accountId)
      ]).subscribe(result => {
        console.log(result);
        this.rental = result[0];
        this.account = result[1];
        if (this.rental.orderType === 2) {
          this.bikes = this.rental.bookedBike;
        } else {
          this.bikes = this.rental.directBike;
        }
      });
    });
  }

}
