import { RentalPoint } from './RentalPoint';
import { Account } from './Account';

export class Client {
  public id: number;
  public address: string;
  public phone: string;
  public accountId: number;
  public businessName: string;
  public vatNumber: string;
  public deleted: boolean;
  public account: Account;
  public rentalPoints: RentalPoint[];

  public static fromDto(c): Client {
    const client = new Client();
    if (!c) {
      return null;
    }
    client.id = c.id;
    client.address = c.address;
    client.phone = c.phone;
    client.accountId = c.accountId;
    client.businessName = c.businessName;
    client.vatNumber = c.vatNumber;
    client.deleted = c.deleted;
    client.account = Account.fromDto(c.account);
    client.rentalPoints = c.rentalPoints;

    return client;
  }
}
