import { Injectable } from '@angular/core';
import { TicketSearchCritertia } from './TicketSearchCritertia';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Ticket } from '../../../models/Ticket';
import { BikeType } from '../../../models/BikeType';
import { DefectType } from '../../../models/DefectType';
import { Account } from '../../../models/Account';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: TicketSearchCritertia): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchTickets', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Ticket.fromDto);
      })
    );
  }


  /**
   * getBikeTypes
   */
  public getBikeTypes(): Observable<BikeType[]> {
    return this._http.get(environment.serverUrl + 'api/getBikeTypesExtended', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => BikeType.fromDto(res)))
    );
  }


  /**
   * getTicket
   */
  public getTicket(ticketId: string): Observable<Ticket> {
    return this._http.get(environment.serverUrl + 'api/getProductDefects', {
      headers: HttpUtils.createHeaders(),
      params: { ticketId }
    }).pipe(
      map((res: any) => Ticket.fromDto(res))
    );
  }

  public getDefectTypes(): Observable<DefectType[]> {
    return this._http.get(environment.serverUrl + 'api/getDefectTypes', {
      headers: HttpUtils.createHeaders()
    }).pipe(map((result: any) => result.map((res: any) => DefectType.formDto(res))));
  }

  public getAccountsTickets(): Observable<Account[]> {
    return this._http.get(environment.serverUrl + 'api/getAccountsTickets', {
      headers: HttpUtils.createHeaders()
    }).pipe(map((result: any) => result.map((res: any) => Account.fromDto(res))));
  }
}
