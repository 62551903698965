<div class="card mb-1" *ngFor="let item of bikeItems">
    <div class="card-header">{{item.name}}</div>
    <div class="card-body">
        <table class="table table-sm m-0 table-bordered">
            <caption>{{'products.bikeStatistics.listOfBikes' | translate}}</caption>
            <thead>
                <tr>
                    <th scope="col">{{'products.bikeStatistics.bikeType' | translate}}</th>
                    <th scope="col">{{'products.bikeStatistics.size' | translate}}</th>
                    <th scope="col">{{'products.bikeStatistics.quantity' | translate}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let bike of item.bikes">
                    <th scope="row">{{bike.name}}</th>
                    <td>{{bike.size}}</td>
                    <td>{{bike.count}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>