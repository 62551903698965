import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { SharedService } from './shared/shared.service';

registerLocaleData(localeDe, 'de');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Papin Bike';
  public logged = false;

  constructor(translate: TranslateService, private _sharedService: SharedService) {
    if (localStorage.getItem('selectedLanguage') !== null) {
      const selectedLanguage = localStorage.getItem('selectedLanguage');
      translate.setDefaultLang(selectedLanguage);
    }
  }

  ngOnInit() {
    this.logged = (localStorage.getItem('papinLogged') === 'true');
    this._sharedService.getEmittedValue().subscribe(item => {
      this.logged = item;
    });
  }
}
