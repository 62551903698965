<div class="container" style="padding-bottom: 20px;">
  <div class="row">
    <div class="col">
      <div class="card" id="partial-return-paper">
        <div class="card-body">
          <blockquote class="blockquote text-center" >
            <p class="mb-0" style="font-weight: normal;">{{'orders.papers.partialReturnPaper.teilrückgabe' | translate}}
            </p>
          </blockquote>
          <ng-container *ngIf="account.rentalPoint.country === 1; else italyTitleTemplate">
            <blockquote class="blockquote text-center" >
              <p class="mb-0">Papin Sport & Freizeit GmbH <br /> Arnbach 41F 9920 Arnbach / Sillian<br />
                ATU65441346 </p>
            </blockquote>
          </ng-container>
          <ng-template #italyTitleTemplate>
            <blockquote class="blockquote text-center" >
              <p class="mb-0">
                Papin Sport GmbH / srl <br />
                Freisingerstr. 9 Via Freising <br />
                I-39038 Innichen / San Candido (BZ)<br />
                MwSt.Nr.-P.Iva 00480120211
              </p>
            </blockquote>
          </ng-template>
          <div class="text-center mt-2 mb-2">
            <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="papin-logo" alt="Papin Logo"
              class="img-responsive" height="65">
          </div>
          <div class="text-center">
            <ngx-barcode6 [bc-value]="contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1"></ngx-barcode6>
          </div>

          <div id="partial-return-details" class="mt-3">
            <dl class="row" style="margin-bottom: 0px;">
              <dt class="col-6">{{'orders.papers.partialReturnPaper.date' | translate}}:</dt>
              <dd class="col-6 text-right">{{returnDate | date: 'dd-MM-yyyy'}}</dd>
              <dt class="col-6">{{'orders.papers.partialReturnPaper.contractNumber' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.contractId}}</dd>
              <dt class="col-6">{{'orders.papers.partialReturnPaper.clientName' | translate}}:</dt>
              <dd class="col-6 text-right">{{rental.clientInformation.firstName}} {{rental.clientInformation.lastName}}
              </dd>
              <dt class="col-6">{{'orders.papers.partialReturnPaper.rentalPoint' | translate}}:</dt>
              <dd class="col-6 text-right">{{account.rentalPoint.rentalPointTranslation['de']}}</dd>
            </dl>
          </div>
          <div id="bikes-list">
            <table class="table table-borderless">
              <tbody>
                <tr *ngFor="let bike of bikes;">
                  <td *ngIf="bike.returnType === 1">
                    <strong>1 {{bike.bikeType.bikeTypeTranslation['de']}}
                      {{(bike.qrCode ? '(' + bike.qrCode + ')' : '')}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
