import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { TICKETS_ROUTES } from './tickets.routes';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TicketsListComponent } from './tickets-list/tickets-list.component';
import { TicketsComponent } from './tickets.component';
import { TicketStatusPipe } from './shared/ticket-status.pipe';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { DefectStatusPipe } from './shared/defect-status.pipe';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(TICKETS_ROUTES),
        FormsModule,
        CommonModule
    ],
    exports: [],
    declarations: [
        TicketsComponent,
        TicketsListComponent,
        TicketStatusPipe,
        TicketDetailsComponent,
        DefectStatusPipe
    ]
})

export class TicketsModule { }
