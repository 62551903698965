import { RentalPoint } from './RentalPoint';
import { Discount } from './Discount';
import { Period } from './Period';

export class PriceList {
  public id: number;
  public name: string;
  public rentalPoints: RentalPoint[];
  public discounts: Discount[];
  public periods: Period[];

  public static fromDto(p): PriceList {
    const priceList = new PriceList();
    if (!p) {
      return null;
    }

    priceList.id = p.id;
    priceList.name = p.name;
    priceList.rentalPoints = (p.rentalPoints ? p.rentalPoints.map(rentalPoint => RentalPoint.fromDto(rentalPoint)) : null);
    priceList.discounts = (p.discounts ? p.discounts.map(discount => Discount.fromDto(discount)) : null);
    priceList.periods = (p.periods ? p.periods.map(period => Period.fromDto(period)) : null);

    return priceList;
  }
}
