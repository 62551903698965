<h2 class="mt-5 bg-dark" style="text-align: center; color: #FFFFFF; padding:10px;">{{'tableData.title.'+tableCode | translate}}</h2>
<div class="container-fluid">
  <div class="row mt-5">
    <div class="col text-right">
      <span (click)="redirectPage('insert', tableCode)" class="btn btn-sm btn-primary">{{'tableData.list.insert.label' | translate}}</span>
    </div>
  </div>
  <div class="row mt-5">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters" [pageSize]="15" [showSearch]="false">
        <app-data-filters #dataFilters>
          <app-data-filter-group>
            <app-data-filter-input label="{{'tableData.list.search.label' | translate}}" placeholder="{{'tableData.list.search.label' | translate}}">
            </app-data-filter-input>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>
      <!-- bikeType-->
      <ng-template #bikeType_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #bikeType_name_en let-name_en="name_en">
        {{name_en}}
      </ng-template>
      <ng-template #bikeType_name_it let-name_it="name_it">
        {{name_it}}
      </ng-template>
      <ng-template #bikeType_name_de let-name_de="name_de">
        {{name_de}}
      </ng-template>
      <ng-template #bikeType_bikeCost let-bikeCost="bikeCost">
        {{bikeCost}}
      </ng-template>
      <ng-template #bikeType_productType let-productType="productType">
        {{productType === 1 ? 'bike' : 'equipment'}}
      </ng-template>
      <!-- bikeBrand -->
      <ng-template #bikeBrand_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #bikeBrand_name let-name="name">
        {{name}}
      </ng-template>
      <!-- bikeModel -->
      <ng-template #bikeModel_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #bikeModel_name let-name="name">
        {{name}}
      </ng-template>
      <!-- bikeSize -->
      <ng-template #bikeSize_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #bikeSize_size let-size="size">
        {{size}}
      </ng-template>
      <!-- tyreSize -->
      <ng-template #tyreSize_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #tyreSize_size let-size="size">
        {{size}}
      </ng-template>
      <!-- bikeColor -->
      <ng-template #bikeColor_id let-id="id">
        {{id}}
      </ng-template>
      <ng-template #bikeColor_name let-name="name">
        {{name}}
      </ng-template>
      <!-- generic -->
      <ng-template #actions let-id="id">
        <div class="btn-group" role="group">
          <span (click)="redirectPage('update',tableCode, id)" class="btn btn-primary btn-sm">{{'clients.clientsList.edit' | translate}}</span>
        </div>
      </ng-template>
    </div>
  </div>
</div>

