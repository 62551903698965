<nav class="col-md-2 d-none d-md-block bg-light d-print-none" id="sidebar">
    <div class="sidebar-sticky">
        <ul class="nav justify-content-center flex-column">
            <li class="nav-item text-center">
                <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="img-fluid" width="150" />
            </li>
        </ul>
        <hr style="margin-bottom: 0px;" />
        <ul class="nav justify-content-center">
            <li class="nav-item">
                <button (click)="switchLanguage('en')" class="btn btn-link"><span
            class="flag-icon flag-icon-gb fi fi-gb"></span></button>
            </li>
            <li class="nav-item">
                <button (click)="switchLanguage('de')" class="btn btn-link"><span
            class="flag-icon flag-icon-de fi fi-de"></span></button>
            </li>
            <li class="nav-item">
                <button (click)="switchLanguage('it')" class="btn btn-link"><span
            class="flag-icon flag-icon-it fi fi-it"></span></button>
            </li>
        </ul>
        <hr style="margin-top: 0px;" />
        <app-nav-items-group *ngFor="let menuGroup of menuItems" [menuGroup]="menuGroup" [accessRightsArray]="accessRightsArray"></app-nav-items-group>
    </div>
</nav>
