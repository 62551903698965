import { Component, OnInit, ViewChild, TemplateRef} from '@angular/core';
import { Observable, forkJoin} from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SearchObjectResult } from '../../../models/SearchObject';
import { ProductSearchCritertia } from '../shared/ProductSearchCritertia';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { ProductsService } from '../shared/products.service';
import { TranslateService } from '@ngx-translate/core';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { ProductStatus } from '../shared/ProductStatus';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-bike-sell-list',
  templateUrl: './bike-sell-list.component.html',
  styleUrls: ['./bike-sell-list.component.scss']
})
export class BikeSellListComponent implements OnInit {
  public currentUserType = SharedUtils.getCurrentUserType();
  public currentLang: string;
  public bikeSizeOptions: Option[];
  public bikeTypeOptions: Option[];
  public bikeModelOptions: Option[];
  public bikeBrandOptions: Option[];
  public bikeTyreSizeOptions: Option[];
  public productsStatus: Option[];
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public year: Option[];

  public selectedArray: number [] = [];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: ProductSearchCritertia = {};

  @ViewChild('createdAt', { static: true })
  createdAt!: TemplateRef<any>;

  @ViewChild('accountName', { static: true })
  accountName!: TemplateRef<any>;

  @ViewChild('bikeType', { static: true })
  bikeType!: TemplateRef<any>;

  @ViewChild('brandName', { static: true })
  brandName: TemplateRef<any>;

  @ViewChild('model', { static: true })
  model: TemplateRef<any>;

  @ViewChild('bikeSize', { static: true })
  bikeSize: TemplateRef<any>;

  @ViewChild('tyreSize', { static: true })
  tyreSize: TemplateRef<any>;

  @ViewChild('qrCode', { static: true })
  qrCode: TemplateRef<any>;

  @ViewChild('serialNumber', { static: true })
  serialNumber: TemplateRef<any>;

  @ViewChild('keyNumber', { static: true })
  keyNumber: TemplateRef<any>;

  @ViewChild('frameId', { static: true })
  frameId: TemplateRef<any>;

  @ViewChild('status', { static: true })
  status: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _productsService: ProductsService, private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
    this.bikeSizeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeTypeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeModelOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeBrandOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.bikeTyreSizeOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.productsStatus = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.clientOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.year = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
  }

  ngOnInit() {

    const bikeSizes = this._productsService.getBikeSizes();
    const bikeTypes = this._productsService.getBikeTypes();
    const bikeModels = this._productsService.getBikeModels();
    const bikeBrands = this._productsService.getBikeBrands();
    const bikeTyreSizes = this._productsService.getBikeTyreSizes();
    forkJoin([bikeSizes, bikeTypes, bikeModels, bikeBrands, bikeTyreSizes]).subscribe(result => {
      result[0].forEach(bikeSize => {
        this.bikeSizeOptions.push({ value: bikeSize.id, label: bikeSize.size });
      });
      result[1].forEach(bikeType => {
        this.bikeTypeOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
      });
      result[2].forEach(bikeModel => {
        this.bikeModelOptions.push({ value: bikeModel.id, label: bikeModel.name });
      });
      result[3].forEach(bikeBrand => {
        this.bikeBrandOptions.push({ value: bikeBrand.id, label: bikeBrand.name });
      });
      result[4].forEach(bikeTyreSize => {
        this.bikeTyreSizeOptions.push({ value: bikeTyreSize.id, label: bikeTyreSize.size });
      });
      ProductStatus.getProductStatus().forEach(status => {
        this.productsStatus.push({ value: status.status, label: status.statusName });
      });
      this.loadPage = (page: number, pageSize: number) => this._productsService.searchSoldBike(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'createdAt',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.createdAt',
        template: this.createdAt,
        sortable: true
      },
      {
        name: 'accountName',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.accountName',
        template: this.accountName,
        sortable: true
      },
      {
        name: 'bikeType',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.bikeType',
        template: this.bikeType,
        sortable: true
      },
      {
        name: 'brandName',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.brandName',
        template: this.brandName,
        sortable: true
      },
      {
        name: 'model',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.model',
        template: this.model,
        sortable: true
      },
      {
        name: 'bikeSize',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.bikeSize',
        template: this.bikeSize,
        sortable: true
      },
      {
        name: 'tyreSize',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.tyreSize',
        template: this.tyreSize,
        sortable: true
      },
      {
        name: 'qrCode',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.qrCode',
        template: this.qrCode,
        sortable: true
      },
      {
        name: 'serialNumber',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.serialNumber',
        template: this.serialNumber,
        sortable: true
      },
      {
        name: 'keyNumber',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.keyNumber',
        template: this.keyNumber,
        sortable: true
      },
      {
        name: 'frameId',
        displayed: true,
        translationKey: 'Frame Id',
        template: this.frameId,
        sortable: true
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.status',
        template: this.status,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'products.bikeEquipmentList.actions',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      }
    ];
  }

  onTableReset() {
  }

}
