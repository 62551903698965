import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { OrdersService } from '../shared/orders.service';
import { SharedUtils } from '../../../shared/shared-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-create-credit',
  templateUrl: './create-credit.component.html',
  styleUrl: './create-credit.component.scss'
})
export class CreateCreditComponent implements OnInit {

  public userType = SharedUtils.getCurrentUserType();

  public creditForm: FormGroup = new FormGroup({
    name: new FormControl("", Validators.required),
    quantity: new FormControl(0, [Validators.required, Validators.min(1)]),
    amount: new FormControl(0, [Validators.required, Validators.min(0.01)])
  });

  constructor(private _orderService: OrdersService, private _snackBar: MatSnackBar, private _translate: TranslateService) {
  }


  ngOnInit(): void {
  }

  public submitCredit() {
    if (this.creditForm.valid) {
      const creditName = this.creditForm.controls.name.value;
      const creditQuantity = this.creditForm.controls.quantity.value;
      const creditAmount = this.creditForm.controls.amount.value;
      this._orderService.insertCredit(creditName, creditQuantity, creditAmount, this.userType.accountId).subscribe(res => {
        this._snackBar.open(this._translate.instant('credit.creditCreatedMsg'), '', { duration: 3500 });
      });
    } else {
      this._snackBar.open(this._translate.instant('credit.insertWarning'), '', { duration: 3500 });
    }
  }
}
