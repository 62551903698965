import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { ProductsService } from '../shared/products.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { forkJoin, Observable, Subject } from 'rxjs';
import { ProductStatus } from '../shared/ProductStatus';
import { Product } from '../../../models/Product';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { environment } from '../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { UserService } from '../../../services/user.service';


@Component({
  selector: 'app-edit-bike-equipment',
  templateUrl: './edit-bike-equipment.component.html',
  styleUrls: ['./edit-bike-equipment.component.scss']
})
export class EditBikeEquipmentComponent implements OnInit {

  private trigger: Subject<any> = new Subject();

  public webcamImage!: WebcamImage;
  public showWebcam = false;

  private nextWebcam: Subject<any> = new Subject();

  private _productId: string;
  public editProductFormGroup: FormGroup;
  public currentLang: string;
  public bikeSizeOptions: Option[] = [];
  public bikeTypeOptions: Option[] = [];
  public bikeModelOptions: Option[] = [];
  public bikeBrandOptions: Option[] = [];
  public bikeTyreSizeOptions: Option[] = [];
  public productsStatus: Option[];
  public bikeColorOptions: Option[] = [];
  public yearOptions: Option[] = [{ value: undefined, label: '' }];
  public wartungUserIdSelected: number;
  public wartungUserOptions: Option[] = [{ value: undefined, label: '' }];
  public productImagePath: string;
  public productImageBase64: string;

  public product: Product;

  constructor(private _translate: TranslateService, private _activatedRoute: ActivatedRoute, private _userService: UserService,
    private _productsService: ProductsService, private _snackBar: MatSnackBar, public datepipe: DatePipe) {
    this.currentLang = this._translate.currentLang;
    this.productsStatus = [{ value: undefined, label: this._translate.instant('products.bikeEquipmentList.all') }];
    this.editProductFormGroup = new FormGroup({
      id: new FormControl(),
      bikeTypeId: new FormControl(),
      bikeBrandId: new FormControl(),
      bikeColorId: new FormControl(),
      bikeModelId: new FormControl(),
      bikeSizeId: new FormControl(),
      bikeTyreSizeId: new FormControl(),
      frameId: new FormControl(),
      keyNumber: new FormControl(),
      qrCode: new FormControl(),
      serialNumber: new FormControl(),
      year: new FormControl(),
      status: new FormControl(),
      wartungUserId: new FormControl()
    });
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this._productId = params['productId'];
      this._productsService.getProductById(this._productId).subscribe(product => {
        console.log('prod', product);

        this.product = product;
        const bikeSizes = this._productsService.getBikeSizes();
        const bikeTypes = this._productsService.getBikeTypes();
        const bikeModels = this._productsService.getBikeModels();
        const bikeBrands = this._productsService.getBikeBrands();
        const bikeTyreSizes = this._productsService.getBikeTyreSizes();
        const bikeColors = this._productsService.getBikeColors();
        const wartungUsers = this._userService.getWartungUsers();

        forkJoin([bikeSizes, bikeTypes, bikeModels, bikeBrands, bikeTyreSizes, bikeColors, wartungUsers]).subscribe(result => {
          result[0].forEach(bikeSize => {
            this.bikeSizeOptions.push({ value: bikeSize.id, label: bikeSize.size });
          });
          result[1].forEach(bikeType => {
            this.bikeTypeOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
          });
          result[2].forEach(bikeModel => {
            this.bikeModelOptions.push({ value: bikeModel.id, label: bikeModel.name });
          });
          result[3].forEach(bikeBrand => {
            this.bikeBrandOptions.push({ value: bikeBrand.id, label: bikeBrand.name });
          });
          result[4].forEach(bikeTyreSize => {
            this.bikeTyreSizeOptions.push({ value: bikeTyreSize.id, label: bikeTyreSize.size });
          });
          result[5].forEach(bikeColor => {
            this.bikeColorOptions.push({ value: bikeColor.id, label: bikeColor.name });
          });
          result[6].forEach(wartungUser => {
            this.wartungUserOptions.push({value: wartungUser.id, label: wartungUser.firstName + ' ' + wartungUser.lastName});
          });
          ProductStatus.getProductStatus().forEach(status => {
            this.productsStatus.push({ value: status.status, label: status.statusName });
            console.log('status test', this.productsStatus);
          });
          // year option
          for (let year = 2010; year <= new Date().getFullYear() + 1 ; year++) {
            this.yearOptions.push({ value: year, label: year.toString() });
          }
          this.editProductFormGroup.patchValue(product);
        });
      });
    });
  }

  editBikeSubmit() {
    if (this.editProductFormGroup.valid) {
      const product: Product = this.editProductFormGroup.value;
      product.imagePath = this.product.imagePath;
      this._productsService.updateProduct(Product.fromDto(product), this.wartungUserIdSelected).subscribe(result => {
        this._snackBar.open(this._translate.instant('products.editBikeEquipment.productUpdated'), '', {
          duration: 2000,
        });
      });
    }
  }

  public openWebcam() {
    this.showWebcam = (this.showWebcam) ? false : true;
  }

  public triggerSnapshot(): void {
      this.trigger.next(null);
  }

  public handleImage(webcamImage: WebcamImage): void {
      this.webcamImage = webcamImage;
      this.productImagePath = webcamImage.imageAsDataUrl;
      this.productImageBase64 = webcamImage.imageAsBase64;
  }

  public get triggerObservable(): Observable<any> {
      return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<any> {
      return this.nextWebcam.asObservable();
  }

  public savePicture() {
    if (this.productImageBase64) {
      const productId = this.product.id;
      const fileName = this.datepipe.transform(new Date(), 'yyyyMMddHHmm') + '.jpeg';
      const imageAsDataUrl = this.webcamImage.imageAsDataUrl;
      const imageBlob = this.dataURItoBlob(this.productImageBase64);
      const imageFile = new File([imageBlob], fileName, { type: 'image/jpeg' });
      this._productsService.saveProductImage(productId, fileName, imageFile).subscribe({
        next: (res) => {
          this.product.imagePath = environment.serverUrl + 'public/products/' + this.product.id + '/' + this.datepipe.transform(new Date(), 'yyyyMMddHHmm') + '.jpeg';
          this._snackBar.open(this._translate.instant('products.editBikeEquipment.imageUpdated'), '', { duration: 2000});
          this.editBikeSubmit();
        },
        error: (err) => console.log(err)
      });
    }
  }

  private dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

}

