import { Rental } from './Rental';

export class DiscountCardValueRental {
    public id: number;
    public amount: number;
    public directRental: Rental;

    public static fromDto(d): DiscountCardValueRental {
        const discountCardValueRental = new DiscountCardValueRental();
        if (!d) {
            return null;
        }

        discountCardValueRental.id = d.id;
        discountCardValueRental.amount = d.amount;
        discountCardValueRental.directRental = Rental.fromDto(d.directRental);

        return discountCardValueRental;
    }
}
