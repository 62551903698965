import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RentalPointsService } from '../shared/rental-points.service';

class BikeTypeAvalibityTable {
  bikeAvailabilityPeriod: number;
  bikeType: string;
  bikeTypeId: number;
  rentalPointId: number;
}

@Component({
  selector: 'app-manage-bike-type-period',
  templateUrl: './manage-bike-type-period.component.html',
  styleUrls: ['./manage-bike-type-period.component.scss']
})
export class ManageBikeTypePeriodComponent implements OnInit {

  public rentalPointId;
  public bikeTypeAvalibities: BikeTypeAvalibityTable[] = [];

  constructor(private _rentalPointsService: RentalPointsService, private _activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar, private _translate: TranslateService) { }

  ngOnInit(): void {
    this._activatedRoute.params.subscribe(params => {
      this.rentalPointId = params.rentalPointId;
      this._rentalPointsService.getBikeTypes().subscribe(bikeTypes => {
        this._rentalPointsService.getBikeTypePeriodByRentalPointId(this.rentalPointId).subscribe(result => {
          bikeTypes.map(bikeType => {
            const objectElement = result.filter(r => r.bikeTypeId === bikeType.id)[0];
            this.bikeTypeAvalibities.push({
              bikeAvailabilityPeriod: (objectElement ? objectElement.bikeAvailabilityPeriod : 0),
              bikeType: bikeType.name,
              bikeTypeId: bikeType.id,
              rentalPointId: this.rentalPointId
            });
          });
        });
      });
    });
  }

  updateBikeTypeAvalibities() {
    let error = false;
    this.bikeTypeAvalibities.forEach(element => {
      if (element.bikeAvailabilityPeriod < 0) {
        error = true;
      }
    });
    if (!error) {
      this._rentalPointsService.saveBikeTypePeriod(this.bikeTypeAvalibities).subscribe(result => {
        console.log(result);
        this._snackBar.open(this._translate.instant('rentalPoint.manageBikeTypePeriod.message1'), '', {
          duration: 2000,
        });
      });
    }
  }

}
