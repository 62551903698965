import { Component, OnInit } from '@angular/core';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../shared/products.service';
import { forkJoin } from 'rxjs';
import { Product } from '../../../models/Product';
import { ClientsService } from '../../clients/shared/clients.service';

@Component({
  selector: 'app-multiple-qr-assig',
  templateUrl: './multiple-qr-assig.component.html',
  styleUrls: ['./multiple-qr-assig.component.scss']
})
export class MultipleQrAssigComponent implements OnInit {

  public product: Product = new Product();
  public startQrCode: number;
  public endQrCode: number;
  public clientId;
  public rentalPointAccountId;
  public currentLang = 'de';
  public bikeSizeOptions: Option[] = [];
  public bikeTypeOptions: Option[] = [];
  public bikeModelOptions: Option[] = [];
  public bikeBrandOptions: Option[] = [];
  public bikeTyreSizeOptions: Option[] = [];
  public productsStatus: Option[] = [];
  public bikeColorOptions: Option[] = [];
  public clientOption: Option[] = [{ value: null, label: 'Clients' }];
  public rentalPointOption: Option[] = [{ value: null, label: 'Rental Points' }];

  constructor(private _translate: TranslateService, private _clientsService: ClientsService,
    private _productsService: ProductsService) { }

  ngOnInit() {

    forkJoin([
      this._productsService.getBikeSizes(),
      this._productsService.getBikeTypes(),
      this._productsService.getBikeModels(),
      this._productsService.getBikeBrands(),
      this._productsService.getBikeTyreSizes(),
      this._productsService.getBikeColors(),
      this._clientsService.getClients()
    ]).subscribe(result => {
      console.log(result);
      // result[0].forEach(bikeSize => {
      //   this.bikeSizeOptions.push({ value: bikeSize.id, label: bikeSize.size });
      // });
      // result[1].forEach(bikeType => {
      //   this.bikeTypeOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
      // });
      // result[2].forEach(bikeModel => {
      //   this.bikeModelOptions.push({ value: bikeModel.id, label: bikeModel.name });
      // });
      // result[3].forEach(bikeBrand => {
      //   this.bikeBrandOptions.push({ value: bikeBrand.id, label: bikeBrand.name });
      // });
      // result[4].forEach(bikeTyreSize => {
      //   this.bikeTyreSizeOptions.push({ value: bikeTyreSize.id, label: bikeTyreSize.size });
      // });
      // result[5].forEach(bikeColor => {
      //   this.bikeColorOptions.push({ value: bikeColor.id, label: bikeColor.name });
      // });
      // ProductStatus.getProductStatus().forEach(status => {
      //   this.productsStatus.push({ value: status.status, label: status.statusName });
      // });
    });
  }

}
