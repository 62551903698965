import { Injectable, TemplateRef, Inject, LOCALE_ID } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as Excel from 'exceljs/dist/exceljs.min.js';
import * as ExcelProper from 'exceljs';
import { SearchObjectResult } from '../models/SearchObject';
import { TranslateService } from '@ngx-translate/core';
import { DataTableColumn } from './data-table/data-table.component';
import { DatePipe, CurrencyPipe } from '@angular/common';
import { OrderTypePipe } from '../components/orders/shared/pipes/order-type.pipe';
import { OrderStatusPipe } from '../components/orders/shared/pipes/order-status.pipe';
import { BookingReservationStatusPipe } from '../components/orders/shared/pipes/booking-reservation-status.pipe';
import { CreditsStatusPipe } from '../components/orders/shared/pipes/credits-status.pipe';
import { TicketStatusPipe } from '../components/tickets/shared/ticket-status.pipe';
import { InvoiceTypeStatusPipe } from '../components/orders/shared/pipes/invoice-type-status.pipe';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

class HeaderColumns {
  header: string;
  id: string;
  width?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  constructor(private _translate: TranslateService, @Inject(LOCALE_ID) private _locale: string) { }

  public exportAsExcelFile(result: SearchObjectResult<any>, excelFileName: string, headerArray: DataTableColumn[]): void {
    const headers: HeaderColumns[] = [];
    headerArray.forEach(element => {
      if (element['excel']) {
        headers.push({ header: this._translate.instant(element.translationKey), id: element.name, width: 15 });
      }
    });
    // Create workbook and worksheet
    const workbook: ExcelProper.Workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet(excelFileName.length > 0 ? excelFileName : 'data');
    worksheet.columns = headers;

    result.results.forEach(item => {
      const itemArray = [];
      headerArray.forEach(element => {
        let value = '';
        if (element['excel']) {
          const objRef = element.excel.objectRef;
          for (let i = 0; i < objRef.length; i++) {
            const currentValue = objRef[i];
            value += this._deepFind(item, currentValue) + ' ';
          }
          if (element.excel['pipe']) {
            const additionalValues = [];
            if (element.excel['pipeAdditionalValues']) {
              for (let j = 0; j < element.excel.pipeAdditionalValues.length; j++) {
                const additionalValue = element.excel.pipeAdditionalValues[j];
                additionalValues.push(this._deepFind(item, additionalValue));
              }
            }
            if (value !== '' && value !== undefined && value !== null && value !== 'null' && value !== 'null ' && value === '') {
              value = this._returnPipeConvertedValues(value, element.excel.pipe, additionalValues);
            }
          }
          itemArray.push(value);
        }
      });
      worksheet.addRow(itemArray);
    });

    // tslint:disable-next-line:no-shadowed-variable
    workbook.xlsx.writeBuffer().then((data) => {
      this._saveAsExcelFile(data, excelFileName);
    });
  }

  private _saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private _deepFind(obj, path) {
    const paths = path.split('.');
    let i: number;
    let current = obj;
    for (i = 0; i < paths.length; i++) {
      if (current[paths[i]] === undefined) {
        if (current[0] === undefined) {
          return undefined;
        } else {
          i--;
          current = current[0];
        }
      } else {
        current = current[paths[i]];
      }
      if (current === null) {
        return '';
      }
    }
    return current;
  }

  private _returnPipeConvertedValues(value, pipeKey, additionalValues?: any[]) {
    switch (pipeKey) {
      case 'date':
        return new DatePipe(this._locale).transform(value, 'dd-MM-yyyy');
      case 'currency':
        // return new CurrencyPipe(this._locale).transform(value, '€');
        // tslint:disable-next-line:radix
        return parseFloat(value);
      case 'orderType':
        // tslint:disable-next-line:radix
        return this._translate.instant(new OrderTypePipe().transform(parseInt(value)));
      case 'orderStatus':
        // tslint:disable-next-line:radix
        return this._translate.instant(new OrderStatusPipe().transform(parseInt(value), additionalValues[0]));
      case 'bookingReservationStatus':
        // tslint:disable-next-line:radix
        return this._translate.instant(new BookingReservationStatusPipe().transform(parseInt(value)));
      case 'invoiceTypeStatus':
        // tslint:disable-next-line:radix
        return this._translate.instant(new InvoiceTypeStatusPipe().transform(parseInt(value)));
      case 'creditsStatus':
        // tslint:disable-next-line:radix
        return this._translate.instant(new CreditsStatusPipe().transform(parseInt(value)));
      case 'ticketStatus':
        // tslint:disable-next-line:radix
        return this._translate.instant(new TicketStatusPipe().transform(parseInt(value)));
      default:
        return value;
    }
  }
}
