import { RentalPointSchedule } from './RentalPointSchedule';

export class ScheduleException {
    public id: number;
    public startDay: string;
    public endDay: string;
    public startHour: string;
    public endHour: string;
    public lunchBreakStart: string;
    public lunchBreakEnd: string;
    // Each day starting with Moday is represented by 1 if opened and 0 if closed
    // For example if Mon - Firday is opened and Saturday and Sunday closed we have : 1111100
    public openingDays: Array<string>;
    public badWeather = false;
    public closingException = false;
    public rentalPointSchedule: RentalPointSchedule;

    public static fromDto(s): ScheduleException {
        const scheduleException = new ScheduleException();
        if (!s) {
            return null;
        }

        scheduleException.id = s.id;
        scheduleException.startDay = s.startDay;
        scheduleException.endDay = s.endDay;
        scheduleException.startHour = s.startHour;
        scheduleException.endHour = s.endHour;
        scheduleException.lunchBreakStart = s.lunchBreakStart;
        scheduleException.lunchBreakEnd = s.lunchBreakEnd;
        scheduleException.openingDays = s.openingDays.split('');
        scheduleException.badWeather = s.badWeather;
        scheduleException.closingException = s.closingException;
        if (s.rentalPointSchedule) {
            scheduleException.rentalPointSchedule = RentalPointSchedule.fromDto(s.rentalPointSchedule);
        }

        return scheduleException;
    }
}
