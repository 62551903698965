import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TABLES_ROUTES } from './tables.routes';
import { TablesListComponent } from './tables-list/tables-list.component';
import { TablesUpdateComponent } from './tables-update/tables-update.component';
import { TablesInsertComponent } from './tables-insert/tables-insert.component';
import { TablesComponent } from './tables.component';

@NgModule({
    imports: [
        SharedModule,
        RouterModule.forChild(TABLES_ROUTES),
        FormsModule,
        CommonModule
    ],
    exports: [],
    declarations: [
      TablesComponent,
      TablesListComponent,
      TablesUpdateComponent,
      TablesInsertComponent
    ]
})

export class TablesModule { }
