import { DiscountCard } from './DiscountCard';
import { ClientInformation } from './ClientInformation';
import { OnlineBooking } from './OnlineBooking';
import { Account } from './Account';
import { RentalBike } from './RentalBike';
import { OrderReturn } from './OrderReturn';
import { RentalPoint } from './RentalPoint';
import { Credits } from './Credits';
import { DiscountCardValueRental } from './DiscountCardValueRental';

/**
 * This is a common Model for Booked and Direct Rentals
 */

export class Rental {
    public id: number;
    public contractDate: string;
    public startDate: string;
    public endDate: string;
    public pickUpTime: string;
    public startRentalPoint: number | RentalPoint = new RentalPoint();
    public endRentalPoint: number | RentalPoint = new RentalPoint();
    public onlineBookingId: number;
    public totalPrice: number;
    public discountedPrice: number;
    public placementTime: string;
    /**
     * 1 = On going
     * 2 = closed
     * 3 = cancelled
     */
    public status: number;
    public dayType: number;
    public contractText: string;
    public contractId: string;
    public discountCard: DiscountCard;
    public bookedBike: RentalBike[];
    public directBike: RentalBike[];
    public clientInformation: ClientInformation = new ClientInformation();
    public onlineBooking: OnlineBooking = new OnlineBooking();
    public orderReturn: OrderReturn = new OrderReturn();
    public orderType: number;
    public language: string;
    public credits: Credits[];
    public discountCardValueRental: DiscountCardValueRental[];
    public day: string;
    public signature: string;
    public monthId: number;
    public monthlyDurationDays: number;
    public isExtension: boolean;
    public bikeMobileCard: { juniorCards: number, adultCards: number };

    public static fromDto(r): Rental {
        const rental = new Rental();
        if (!r) {
            return null;
        }
        rental.id = r.id;
        if (r.contractDate) {
          rental.contractDate = r.contractDate;
        }
        rental.startDate = r.startDate;
        rental.endDate = r.endDate;
        rental.pickUpTime = r.pickUpTime;
        rental.startRentalPoint = (isNaN(r.startRentalPoint) ? RentalPoint.fromDto(r.startRentalPoint) : r.startRentalPoint);
        rental.endRentalPoint = (isNaN(r.endRentalPoint) ? RentalPoint.fromDto(r.endRentalPoint) : r.endRentalPoint);
        rental.onlineBookingId = r.onlineBookingId;
        rental.totalPrice = r.totalPrice;
        rental.discountedPrice = r.discountedPrice;
        rental.placementTime = r.placementTime;
        rental.status = r.status;
        rental.dayType = r.dayType;
        rental.contractText = r.contractText;
        rental.contractId = r.contractId;
        if (r.discountCard) {
            rental.discountCard = DiscountCard.fromDto(r.discountCard);
        }
        if (r.bookedBike) {
            rental.bookedBike = r.bookedBike.map(bike => RentalBike.fromDto(bike));
        }
        if (r.directBike) {
            rental.directBike = r.directBike.map(bike => RentalBike.fromDto(bike));
        }
        rental.clientInformation = ClientInformation.fromDto(r.clientInformation);
        rental.onlineBooking = OnlineBooking.fromDto(r.onlineBooking);
        rental.orderReturn = OrderReturn.fromDto(r.orderReturn);
        rental.orderType = r.orderType;
        rental.language = r.language;
        if (r.credits) {
            rental.credits = r.credits.map(credit => Credits.fromDto(credit));
        }
        if (r.discountCardValueRental) {
            rental.discountCardValueRental = r.discountCardValueRental.map(discount => DiscountCardValueRental.fromDto(discount));
        }
        rental.signature = r.signature;
        rental.monthId = r.monthId;
        rental.monthlyDurationDays = r.monthlyDurationDays;
        rental.isExtension = r.isExtension;
        rental.bikeMobileCard = r.bikeMobileCard;

        return rental;
    }
}
