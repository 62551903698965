import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';

export interface Option {
  value: any;
  label?: string;
}

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.scss']
})
export class SelectSearchComponent implements OnInit, OnChanges {

  @Input()
  label: string;

  @Input()
  model: any;

  @Input()
  disabled = false;

  @Input()
  options: Option[] = [];

  @Output()
  modelChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled && this.disabled) {
      this.model = null;
      this.change();
    }
  }

  change() {
    console.log(this.model);
    this.modelChange.emit(this.model);
  }

}
