import {Component, Input, OnInit} from '@angular/core';
import {User} from '../../../models/User';
import {UserService} from '../../../services/user.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-staff-add',
  templateUrl: './staff-add.component.html',
  styleUrls: ['./staff-add.component.scss']
})
export class StaffAddComponent implements OnInit {

  @Input() user = new User();
  public showErrors = false;

  constructor(private userService: UserService,
              public snackBar: MatSnackBar) { }

  updateStaff = (updateStaffForm) => {
    if (updateStaffForm.valid) {
      this.userService.addStaff(this.user)
        .subscribe(user => {
          this.snackBar.open(user.message, user.action, {
            duration: 2000,
          });
        }, error => {
          console.log(error);
          this.snackBar.open(error.error.error, '', {
            duration: 2000,
          });
        });
    } else {
      this.showErrors = true;
    }
  }

  ngOnInit() {
  }

}
