<div class="row mb-5">
    <div class="col">
        <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters" [pageSize]="15">
            <app-data-filters #dataFilters [showExcelExportButton]="true">
                <app-data-filter-group>
                    <app-data-filter-input label="{{'tickets.ticketsList.tickets' | translate}}" [(model)]="filters.code" placeholder="{{'tickets.ticketsList.tickets' | translate}}">
                    </app-data-filter-input>
                    <app-data-filter-input label="{{'tickets.ticketsList.bikeQrCode' | translate}}" [(model)]="filters.qrCode" placeholder="{{'tickets.ticketsList.bikeQrCode' | translate}}">
                    </app-data-filter-input>
                    <app-data-filter-select label="{{'tickets.ticketsList.bikeType' | translate}}" [(model)]="filters.bikeTypeId" [options]="bikeTypesOptions">
                    </app-data-filter-select>
                    <app-data-filter-select label="{{'tickets.ticketsList.ticketStatus' | translate}}" [(model)]="filters.status" [options]="ticketStatus">
                    </app-data-filter-select>
                    <app-data-filter-select label="{{'tickets.ticketsList.deleted' | translate}}" [(model)]="filters.deleted" [options]="[{value: undefined, label: 'All'}, {value: '1', label: 'Yes'}, {value: '0', label: 'No'}]">
                    </app-data-filter-select>
                    <app-data-filter-select label="{{'tickets.ticketsList.account' | translate}}" [(model)]="filters.accountId" [options]="accountOptions">
                    </app-data-filter-select>
                    <app-data-filter-select label="{{'tickets.ticketDetails.defect' | translate}}" [(model)]="filters.defectId" [options]="defectOptions">
                    </app-data-filter-select>
                </app-data-filter-group>
            </app-data-filters>
        </app-data-table>

        <ng-template #code let-code="code">
            {{code}}
        </ng-template>

        <ng-template #qrCode let-qrCode="qrCode">
            {{qrCode}}
        </ng-template>

        <ng-template #bikeType let-bikeType="bikeType">
            {{bikeType.bikeTypeTranslation['de']}}
        </ng-template>

        <ng-template #created let-createdDate="createdDate">
            {{createdDate | date:'dd-MM-yyyy'}}
        </ng-template>

        <ng-template #closed let-closedDate="closedDate">
            {{closedDate | date:'dd-MM-yyyy'}}
        </ng-template>

        <ng-template #status let-status="status">
            {{status | ticketStatus}}
        </ng-template>

        <ng-template #deleted let-deleted="deleted">
            {{deleted}}
        </ng-template>

        <ng-template #account let-account="account">
            {{account.username}}
        </ng-template>

        <ng-template #actions let-id="id">
            <div class="btn-group" role="group">
                <!-- <a href="/clients/edit/{{id}}" class="btn btn-primary btn-sm">Edit</a> -->
                <a href="/tickets/details/{{id}}" class="btn btn-primary btn-sm">{{'tickets.ticketsList.details' | translate}}</a>
            </div>
        </ng-template>
    </div>
</div>
