<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <h3 class="card-title text-center mt-3">
          {{ (isUpdateForm ? 'wartungUser.update' : 'wartungUser.addNew' ) | translate }}
        </h3>
        <div class="card-body">
          <form [formGroup]="formGroup" (ngSubmit)="submit()">
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <label>{{'wartungUser.client' | translate}} *</label>
                  <select formControlName="clientId" class="form-control {{errorArray['status'] ? 'is-invalid' : ''}}" required>
                    <option *ngFor="let client of clientOptions"
                      [value]="client.value"  [selected]="client.value === original?.clientId">{{client.label}}
                    </option>
                  </select>
                  <div class="invalid-feedback">
                    {{errorArray['clientId']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'wartungUser.username' | translate}}*</label>
                  <input type="text" name="username" id="username" formControlName="username" required
                         [placeholder]="isUpdateForm ? original?.username : ('wartungUser.username' | translate)"
                         class="form-control {{errorArray['username'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['de']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'wartungUser.firstName' | translate}}*</label>
                  <input type="text" name="firstName" id="firstName" formControlName="firstName" required
                         [placeholder]="isUpdateForm ? original?.firstName : ('wartungUser.firstName' | translate)" class="form-control {{errorArray['firstName'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['de']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'wartungUser.lastName' | translate}}*</label>
                  <input type="text" name="lastName" id="lastName" formControlName="lastName" required
                         [placeholder]="isUpdateForm ? original?.lastName : ('wartungUser.lastName' | translate)" class="form-control {{errorArray['lastName'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['de']}}
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="form-group">
                  <label>{{'wartungUser.email' | translate}}*</label>
                  <input type="email" name="email" id="email" formControlName="email" required
                         [placeholder]="isUpdateForm ? original?.email : ('wartungUser.email' | translate)" class="form-control {{errorArray['email'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['de']}}
                  </div>
                </div>
                <div class="form-group">
                  <label>{{'clients.addClients.password' | translate}} {{isUpdateForm ? '' : '*'}}</label>
                  <input type="password" name="password" id="password" formControlName="password" [required]="!isUpdateForm"
                         placeholder="{{'clients.addClients.password' | translate}}" class="form-control {{errorArray['password'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['de']}}
                  </div>
                </div>
                <div class="form-group" *ngIf="isUpdateForm">
                  <label>{{'wartungUser.status' | translate}} *</label>
                  <select formControlName="status" class="form-control {{errorArray['status'] ? 'is-invalid' : ''}}" required>
                    <option [value]="true" [selected]="original?.status === true">{{ 'wartungUser.active' | translate }}</option>
                    <option [value]="false" [selected]="original?.status === false">{{ 'wartungUser.inactive' | translate }}</option>
                  </select>
                  <div class="invalid-feedback">
                    {{errorArray['status']}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary">{{'clients.addClients.update' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
