export class BikeStatisticBike {
    name: string;
    bikes: {
        name: string;
        size: string;
        count: number;
    }[];
}
export class ReturnRentedStatisticBikes {
    qrCode: string;
    bikeName: string;
    return: string;
}

export class BikeStatistic {
    arrivedBikeCount: number;
    arrivedBikes: BikeStatisticBike[];
    arrivingBikeCount: number;
    arrivingBikes: BikeStatisticBike[];
    rentedBikeCount: number;
    rentedBikes: BikeStatisticBike[];
    returnRentedBikes: ReturnRentedStatisticBikes[];
}
