import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpUtils } from '../../../shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Turnover } from '../../../models/Turnover';

@Injectable({
  providedIn: 'root'
})
export class TurnoverListService {

  constructor(private _http: HttpClient) { }

  public getTurnovers(filters: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    const criteriaCopy = { ...filters };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const params = {
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/getTurnover', {
      headers, params
    }).pipe(
      map((result: any) => {
        console.log(result);
        return result.map(res => Turnover.fromDto(res));
      })
    );
  }
}
