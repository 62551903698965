import {Client} from './Client';
import {RentalPoint} from './RentalPoint';

export class User {
  public id: number;
  public email: string;
  public firstName: string;
  public lastName: string;
  public password: string;
  public avatar: string;
  public username: string;
  public accountType: string;
  public userType: number;
  public client: Client;
  public rentalPoint: RentalPoint;
  public deleted: boolean;

  constructor(user?: any) {
    if (user) {
      this.id = user.id;
      this.email = user.email;
      this.firstName = user.firstName;
      this.lastName = user.lastName;
      this.password = user.password;
      this.avatar = user.avatar;
      this.username = user.username;
      this.accountType = user.accountType;
      this.userType = user.userType;
      this.deleted = user.deleted;
      if (this.client) {
        this.client = user.client;
      }

      if (this.rentalPoint) {
        this.rentalPoint = user.rentalPoint;
      }

    } else {
      this.id = undefined;
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.password = '';
      this.avatar = '';
      this.username = '';
      this.accountType = '';
      this.userType = 0;
      this.deleted = false;
    }
  }

  addRentalPoint(rentalPoint?: RentalPoint) {
    // this.rentalPoint = new RentalPoint(rentalPoint);
  }

  updateUser(user: User) {
    this.id = user.id;
    this.email = user.email;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.password = user.password;
    this.avatar = user.avatar;
    this.username = user.username;
    this.accountType = user.accountType;
    this.userType = user.userType;
    this.deleted = user.deleted;
  }
}
