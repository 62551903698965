import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { SearchObjectResult } from '../../../models/SearchObject';
import { forkJoin, Observable } from 'rxjs';
import { TicketSearchCritertia } from '../shared/TicketSearchCritertia';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { TicketsService } from '../shared/tickets.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-tickets-list',
  templateUrl: './tickets-list.component.html',
  styleUrls: ['./tickets-list.component.scss']
})
export class TicketsListComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  filters: TicketSearchCritertia = {};

  @ViewChild('code', { static: true })
  code: TemplateRef<any>;

  @ViewChild('qrCode', { static: true })
  qrCode: TemplateRef<any>;

  @ViewChild('bikeType', { static: true })
  bikeType: TemplateRef<any>;

  @ViewChild('created', { static: true })
  created: TemplateRef<any>;

  @ViewChild('closed', { static: true })
  closed: TemplateRef<any>;

  @ViewChild('status', { static: true })
  status: TemplateRef<any>;

  @ViewChild('deleted', { static: true })
  deleted: TemplateRef<any>;

  @ViewChild('account', { static: true })
  account: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];
  public bikeTypesOptions: Option[] = [{ value: undefined, label: 'All' }];
  public accountOptions: Option[] = [{ value: undefined, label: 'All' }];
  public defectOptions: Option[] = [{ value: undefined, label: 'All' }];
  public ticketStatus: Option[] = [
    { value: undefined, label: 'All' },
    { value: '0', label: 'Open' },
    { value: '1', label: 'Partially' },
    { value: '2', label: 'Closed' }
  ];

  constructor(private _ticketsService: TicketsService) {
    forkJoin([
      this._ticketsService.getAccountsTickets(),
      this._ticketsService.getDefectTypes(),
      this._ticketsService.getBikeTypes(),
    ]).subscribe({
      next: (results) => {
        const accountsTickets = results[0];
        accountsTickets.forEach(user => this.accountOptions.push({value: user.id, label: user.username}));
        const defectTypes = results[1];
        defectTypes.forEach(defectType => this.defectOptions.push({ value: defectType.id, label: defectType.name }));
        const bikeTypes = results[2];
        bikeTypes.forEach(bikeType => this.bikeTypesOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation['de'] }));
        this.loadPage = (page: number, pageSize: number) => this._ticketsService.search(page, pageSize, this.filters);
      },
      error: () => console.log('Unable to fetch one or more filters'),
    });
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'code',
        displayed: true,
        translationKey: 'tickets.ticketsList.tickets',
        template: this.code,
        sortable: true,
        excel: {
          objectRef: ['code']
        }
      },
      {
        name: 'qrCode',
        displayed: true,
        translationKey: 'tickets.ticketsList.bikeQrCode',
        template: this.qrCode,
        sortable: true,
        excel: {
          objectRef: ['qrCode']
        }
      },
      {
        name: 'bikeType',
        displayed: true,
        translationKey: 'tickets.ticketsList.bikeType',
        template: this.bikeType,
        sortable: true,
        excel: {
          objectRef: ['bikeType.name'],
        }
      },
      {
        name: 'created',
        displayed: true,
        translationKey: 'tickets.ticketsList.created',
        template: this.created,
        sortable: true,
        excel: {
          objectRef: ['createdDate'],
          pipe: 'date'
        }
      },
      {
        name: 'closed',
        displayed: true,
        translationKey: 'tickets.ticketsList.closed',
        template: this.closed,
        sortable: true,
        excel: {
          objectRef: ['closedDate'],
          pipe: 'date'
        }
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'tickets.ticketsList.status',
        template: this.status,
        sortable: true,
        excel: {
          objectRef: ['status'],
          pipe: 'ticketStatus'
        }
      },
      {
        name: 'deleted',
        displayed: true,
        translationKey: 'tickets.ticketsList.deleted',
        template: this.deleted,
        sortable: true,
        excel: {
          objectRef: ['deleted']
        }
      },
      {
        name: 'account',
        displayed: true,
        translationKey: 'tickets.ticketsList.account',
        template: this.account,
        sortable: true,
        excel: {
          objectRef: ['account.username']
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'tickets.ticketsList.actions',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      },
      {
        name: 'defect',
        displayed: false,
        translationKey: 'tickets.ticketsList.defect',
        template: this.closed,
        sortable: false,
        excel: {
          objectRef: ['defectedProducts.defectType.defect.name']
        }
      },
      {
        name: 'defect type',
        displayed: false,
        translationKey: 'tickets.ticketsList.defectType',
        template: this.actions,
        sortable: false,
        excel: {
          objectRef: ['defectedProducts.defectType.name']
        }
      },
      {
        name: 'comment',
        displayed: false,
        translationKey: 'tickets.ticketsList.comment',
        template: this.actions,
        sortable: false,
        excel: {
          objectRef: ['defectedProducts.comment']
        }
      }
    ];
  }

}
