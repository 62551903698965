<app-header-auth></app-header-auth>
<div class="headText">
  <p><span>{{'dashboard.dashboard' | translate}}</span></p>
</div>
<hr>
<div class="container-fluid">
  <div class="row">
    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <a href="https://papin2019.rrsolutions.it/" target="_blank" class="btn btn-primary btn-lg btn-block">PapinSport 2019</a>
    </div>
    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <a href="https://papin2020.rrsolutions.it/" target="_blank" class="btn btn-primary btn-lg btn-block">PapinSport 2020</a>
    </div>
    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <a href="https://papin2021.rrsolutions.it/" target="_blank" class="btn btn-primary btn-lg btn-block">PapinSport 2021</a>
    </div>
    <div class="col-lg-3 col-sm-6 col-12 mt-2">
      <a href="https://papin2022.rrsolutions.it/" target="_blank" class="btn btn-primary btn-lg btn-block">PapinSport 2022</a>
    </div>
  </div>
</div>
