export enum ProductsStatusEnum {
    ONE = 'enums.productsStatus.available',
    TWO = 'enums.productsStatus.rented',
    THREE = 'enums.productsStatus.bigMaintainance',
    FOUR = 'enums.productsStatus.localMaintainance',
    FIVE = 'enums.productsStatus.inTransit',
    SIX = 'enums.productsStatus.noMoreAvailable',
    SEVEN = 'enums.productsStatus.sold',
    EIGHT = 'enums.productsStatus.stolen',
}
