<div class="container-fluid">
  <div class="row">
      <div class="col">
          <app-data-table #dataTable [columns]="columns" [loadPage]="loadPage" [(filters)]="filters"
              [filtersComponent]="dataFilters" [pageSize]="20" (onReset)="onTableReset()"
              (beforeSubmit)="beforeSubmit()">
              <app-data-filters #dataFilters [showExcelExportButton]="true" [loadOnStart]="loadOnStart">
                  <app-data-filter-group>
                      <app-data-filter-input label="{{'orders.allOrders.contractId' | translate}}"
                          [(model)]="filters.contractId" placeholder="{{'orders.allOrders.contractId' | translate}}">
                      </app-data-filter-input>
                      <app-data-filter-input label="{{'orders.allOrders.clientName' | translate}}"
                          [(model)]="filters.clientName" placeholder="{{'orders.allOrders.clientName' | translate}}">
                      </app-data-filter-input>
                    <app-data-filter-input label="{{'orders.bookingReservationOrders.phone' | translate}}"
                           [(model)]="filters.phone" placeholder="{{'orders.bookingReservationOrders.phone' | translate}}">
                    </app-data-filter-input>
                      <app-data-filter-select label="{{'orders.allOrders.clients' | translate}}"
                          [(model)]="filters.clientId" [options]="clientOptions"
                          *ngIf="currentUserType.userTypeNumber === 0;" (modelChange)="onClientSelectChange($event)">
                      </app-data-filter-select>
                      <app-data-filter-select-search label="{{'orders.allOrders.startRentalPoint' | translate}}"
                          *ngIf="currentUserType.userTypeNumber !== 2;" [(model)]="filters.startRentalPointAccountId"
                          [options]="rentalPointOptions" (modelChange)="onStartRentalSelectChange($event)">
                      </app-data-filter-select-search>
                      <app-data-filter-select-search label="Return Rental Point"
                          *ngIf="currentUserType.userTypeNumber !== 2;" [(model)]="filters.returnRentalPointAccountId"
                          [options]="rentalPointOptions" (modelChange)="onReturnRentalSelectChange($event)">
                      </app-data-filter-select-search>
                      <app-data-filter-datepicker label="{{'orders.allOrders.from' | translate}}"
                          [(model)]="filters.startDate">
                      </app-data-filter-datepicker>
                      <app-data-filter-datepicker label="{{'orders.allOrders.to' | translate}}"
                          [(model)]="filters.endDate">
                      </app-data-filter-datepicker>
                  </app-data-filter-group>
              </app-data-filters>
          </app-data-table>

          <ng-template #contractId let-contractId="contractId">
              {{contractId}}
          </ng-template>

          <ng-template #clientName let-clientInformation="clientInformation">
              {{clientInformation.firstName}} {{clientInformation.lastName}}
          </ng-template>

          <ng-template #startRentalPoint let-startRentalPoint="startRentalPoint">
              {{startRentalPoint.rentalPointTranslation[currentLang]}}
          </ng-template>

          <ng-template #returnRentalPoint let-endRentalPoint="endRentalPoint">
              {{endRentalPoint.rentalPointTranslation[currentLang]}}
          </ng-template>

          <ng-template #startDate let-startDate="startDate">
              {{startDate | date: 'dd-MM-yyyy'}}
          </ng-template>

          <ng-template #returnDate let-endDate="endDate">
              {{endDate | date: 'dd-MM-yyyy'}}
          </ng-template>

          <ng-template #orderAmount let-discountedPrice="discountedPrice">
              {{discountedPrice | currency: '€ '}}
          </ng-template>

          <ng-template #orderStatus let-status="status" let-endDate="endDate" let-isExtension="isExtension">
              {{6 | orderStatus | translate}}
          </ng-template>

          <ng-template #actions let-contractId="contractId" let-orderType="orderType" let-status="status" let-id="id">
              <ng-container>
                  <a href="orders/deleted-rental/details/{{contractId}}" target="_blank"
                      class="btn btn-sm btn-primary">{{'orders.allOrders.details' | translate}}</a>
              </ng-container>
          </ng-template>

          <ng-template #content let-modal>
              <div class="modal-header">
                  <h4 class="modal-title" id="modal-basic-title">
                      {{'orders.allOrders.closeRental' | translate}}</h4>
                  <button type="button" class="close" aria-describedby="modal-title"
                      (click)="modal.dismiss('Cross click')">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div class="modal-body">
                <div class="row" style="justify-content: center; margin-bottom: 10px">
                  <app-data-filter-datepicker style="width: 60%; margin-right: 20px" [(model)]="rentalCloseDate" label="{{'orders.allOrders.closeDate' | translate}}"></app-data-filter-datepicker>
                  <kendo-label text="{{'orders.allOrders.closeTime' | translate}}" [style.width.%]="20">
                    <kendo-timepicker format="HH:mm" [(value)]="rentalCloseTime"></kendo-timepicker>
                  </kendo-label>
                </div>
                  <p style="text-align: center;"><strong>{{'orders.allOrders.message2' | translate}}</strong></p>
                  <p style="text-align: center;">
                      <span class="text-danger bold">{{'orders.allOrders.message4' | translate}}</span>
                  </p>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-outline-secondary"
                      (click)="modal.dismiss('cancel click')">{{'orders.allOrders.cancel' | translate}}</button>
                  <button type="button" class="btn btn-outline-success"
                      (click)="modal.close('Ok click')">{{'orders.allOrders.ok' | translate}}</button>
              </div>
          </ng-template>

          <ng-template #deleteModal let-modal>
            <div class="modal-header">
              <h4 class="modal-title" id="modal-basic-title">{{'orders.allOrders.deleteRental' | translate}}</h4>
              <button type="button" class="close" aria-describedby="modal-title"
                  (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p style="text-align: center;"><strong>{{'orders.allOrders.deleteMessage' | translate}}</strong></p>
              <p style="text-align: center;"><span class="text-danger bold">{{'orders.allOrders.message4' | translate}}</span></p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-outline-secondary"
                (click)="modal.dismiss('cancel click')">{{'orders.allOrders.cancel' | translate}}</button>
              <button type="button" class="btn btn-outline success"
                (click)="modal.close('Ok click')">{{'orders.allOrders.ok' | translate}}</button>
            </div>
          </ng-template>
      </div>
  </div>
</div>
