<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="productFormGroup" (ngSubmit)="submitBike()">
            <div class="row">
              <div class="form-group col">
                <label>{{'products.addBikeEquipment.clients' | translate}}</label>
                <select name="clientId" id="clientId" formControlName="clientId"
                  class="form-control form-control-sm {{errorArray['clientId'] ? 'is-invalid' : ''}}" required
                  (change)="onClientSelectChange()">
                  <option *ngFor="let client of clientOption" value="{{client.value}}">{{client.label}}</option>
                </select>
                <div class="invalid-feedback">
                  {{errorArray['clientId']}}
                </div>
              </div>
              <div class="col">
                <app-select-search-reactive-form label="{{'products.addBikeEquipment.rentalPoints' | translate}}" [formGroup]="productFormGroup"
                  formControlNameValue="rentalPointAccountId" [options]="rentalPointOption">
                </app-select-search-reactive-form>
              </div>
              <div class="form-group col">
                <label >{{'products.addBikeEquipment.bikeType' | translate}}</label>
                <select name="bikeTypeId" id="bikeTypeId" formControlName="bikeTypeId"
                  class="form-control form-control-sm {{errorArray['bikeTypeId'] ? 'is-invalid' : ''}}" required>
                  <option *ngFor="let bikeType of bikeTypeOption" value="{{bikeType.value}}">
                    {{bikeType.label}}</option>
                </select>
                <div class="invalid-feedback">
                  {{errorArray['bikeTypeId']}}
                </div>
              </div>
              <div class="form-group col">
                <label for="quantity" translate>{{'products.addBikeEquipment.quantity' | translate}}</label>
                <input type="number" id="quantity" name="quantity" formControlName="quantity" placeholder="{{'products.addBikeEquipment.quantity' | translate}}"
                  required class="form-control form-control-sm {{errorArray['quantity'] ? 'is-invalid' : ''}}" />
                <div class="invalid-feedback">
                  {{errorArray['quantity']}}
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary btn-sm">{{'products.addBikeEquipment.add' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
