<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="20">
        <app-data-filters #dataFilters>
          <app-data-filter-group>
            <app-data-filter-select label="{{'orders.ordersUnsuccessfulPayments.allClients' | translate}}"
              [(model)]="filters.clientId" [options]="clientOptions" *ngIf="currentUserType.userTypeNumber === 0;"
              (modelChange)="onClientSelectChange($event)">
            </app-data-filter-select>
            <app-data-filter-select-search label="{{'orders.ordersUnsuccessfulPayments.allRentalPoints' | translate}}"
              [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions">
            </app-data-filter-select-search>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-contractId="contractId">
        {{contractId}}
      </ng-template>

      <ng-template #startRentalPoint let-startRentalPoint="startRentalPoint">
        {{startRentalPoint.rentalPointTranslation.de}}
      </ng-template>

      <ng-template #clientName let-clientInformation="clientInformation">
        {{clientInformation.firstName}} {{clientInformation.lastName}}
      </ng-template>

      <ng-template #clientPhone let-clientInformation="clientInformation">
        {{clientInformation.phone}}
      </ng-template>

      <ng-template #clientEmail let-clientInformation="clientInformation">
        {{clientInformation.email}}
      </ng-template>

      <ng-template #startDate let-startDate="startDate">
        {{startDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #endDate let-endDate="endDate">
        {{endDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #placementTime let-placementTime="placementTime">
        {{placementTime}}
      </ng-template>

      <ng-template #transactionError let-transactionStatus="transactionStatus">
        {{transactionStatus}}
      </ng-template>
    </div>
  </div>
</div>