import { BikeTypeTranslation } from './BikeTypeTranslation';
import { InvoiceBike } from './InvoiceBike';
import { Product } from './Product';

export class BikeType {
  public id: number;
  public name: string;
  public bikeCost: number;
  public itemOrder: number;
  public productType: number;
  public bikeAvailabilityPeriod: number;
  public bikeTypeTranslation: BikeTypeTranslation = new BikeTypeTranslation();
  public invoiceBike: InvoiceBike;
  public products: Product[];

  public static fromDto(b): BikeType {
    const bikeType = new BikeType();
    if (!b) {
      return null;
    }

    bikeType.id = b.id;
    bikeType.name = b.name;
    bikeType.itemOrder = b.itemOrder;
    bikeType.productType = b.productType;
    bikeType.bikeCost = b.bikeCost;
    bikeType.bikeAvailabilityPeriod = b.bikeAvailabilityPeriod;
    bikeType.bikeTypeTranslation = b.bikeTypeTranslation;
    bikeType.invoiceBike = b.invoiceBike;
    if (b.products) {
      bikeType.products = b.products.map(product => Product.fromDto(product));
    }

    return bikeType;
  }
}

export class BikeTypeSearch {
  public id: number;
  public name_en: string;
  public name_it: string;
  public name_de: string;
  public bikeCost: number;
  public productType: number;
  public bikeAvailabilityPeriod: number;

  public static fromDto(b): BikeTypeSearch {
    const bikeType = new BikeTypeSearch();
    if (!b) {
      return null;
    }

    bikeType.id = b.id;
    bikeType.name_en = b.name_en;
    bikeType.name_it = b.name_it;
    bikeType.name_de = b.name_de;
    bikeType.bikeCost = b.bikeCost;
    bikeType.productType = b.productType;
    bikeType.bikeAvailabilityPeriod = b.bikeAvailabilityPeriod;

    return bikeType;
  }
}
