import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Order } from '../models/Order';
import { OnlineBooking } from '../models/OnlineBooking';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class OrdersService {

  constructor(private http: HttpClient) { }

  // public getOrders(): Observable<OrderInterface[]> {
  //   return this.http.get<OrderInterface[]>(
  //     environment.serverUrl + 'api/getOrders',
  //     {headers: {'X-Access-Token': localStorage.getItem('X-Access-Token')}});
  // }

  // public getOrdersForBookedSummery(accountId): Observable<OrderInterface[]> {
  //   return this.http.get<OrderInterface[]>(
  //     environment.serverUrl + 'api/getOrdersForBookedSummery', {
  //       headers: {'X-Access-Token': localStorage.getItem('X-Access-Token')
  //     },
  //     params: {
  //       accountId
  //     }
  //   });
  // }

  // public getAllBookedAndDirectOrders(accountId): Observable<any[]> {
  //   return this.http.get<OrderInterface[]>(
  //     environment.serverUrl + 'api/getAllBookedAndDirectOrders',
  //     {
  //       headers: {'X-Access-Token': localStorage.getItem('X-Access-Token')
  //     },
  //     params: {
  //       accountId
  //     }
  //   });
  // }

  public getOrdersUnsuccessfulPayments(accountId): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getUnsucessfulyPayments', {
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        },
        params: {
          accountId
        }
      });
  }

  public getOrderByContractId(contractId: any): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getOrderByContractId',
      {
        params: {
          contractId
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
    });
  }

  public getOrder(id: any): Observable<Order> {
    return this.http.get<Order>(
      environment.serverUrl + 'api/getOrder',
      {
        params: {
          'orderId': id
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
    });
  }

  public confirmOrder(contractId: any, resourcePath: any): Observable<any> {
    return this.http.get<any>(environment.serverUrl + 'api/confirmOrder',
      {
        params: {
          contractId,
          resourcePath
        }
      });
  }

  public getOrderForPayment(id: any): Observable<OnlineBooking> {
    return this.http.get(environment.serverUrl + 'api/getOrderForPayment', { params: { contractId: id } }).pipe(
      map((res: OnlineBooking) => {
        return res;
      }));
  }

  public getOrderForInvoice(id: any): Observable<OnlineBooking> {
    return this.http.get(environment.serverUrl + 'api/getOrderForInvoice', { params: { contractId: id } }).pipe(
      map((res: OnlineBooking) => {
        return res;
      }));
  }

  public getOrderForContract(id: any): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getOrderForContractPage',
      {
        params: {
          'contractId': id
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      });
  }

  public getOrderForClosingPaper(contractId: string): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getOrderForClosingPaper',
      {
        params: {
          'contractId': contractId
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
      });
  }

  public getTurnover(startDate: string, endDate: string, accountId: string): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getTurnover',
      {
        params: {
          startDate,
          endDate,
          accountId
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
    });
  }

  public getCreditForPapinBike(startDate: string, endDate: string): Observable<any> {
    return this.http.get<any>(
      environment.serverUrl + 'api/getCreditForPapinBike',
      {
        params: {
          startDate,
          endDate
        },
        headers: {
          'X-Access-Token': localStorage.getItem('X-Access-Token')
        }
    });
  }
}
