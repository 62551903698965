<app-rental-point-subnav [rentalPointId]="currentRentalPointId"></app-rental-point-subnav>
<div class="container" style="padding-top: 20px; padding-bottom: 20px;">
  <div class="row">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>{{'rentalPoint.defaultBikeContingent.bikeType' | translate}}</th>
          <th>{{'rentalPoint.defaultBikeContingent.online' | translate}}</th>
          <th>{{'rentalPoint.defaultBikeContingent.buffer' | translate}}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contingent of contingentAmount">
          <td style="vertical-align: middle;">{{contingent.bikeType.bikeTypeTranslation[currentLang]}}</td>
          <td>
            <input type="number" class="form-control form-control-sm" [(ngModel)]="contingent.numberOfProducts" min="0"
              placeholder="{{'rentalPoint.defaultBikeContingent.quantity' | translate}}" />
          </td>
          <td>
            <input type="number" class="form-control form-control-sm" [(ngModel)]="contingent.buffer" min="0"
              placeholder="{{'rentalPoint.defaultBikeContingent.quantity' | translate}}" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row">
    <div class="col text-center">
      <button type="button" class="btn btn-primary"
        (click)="updateDefaultBikeContingent()">{{'rentalPoint.defaultBikeContingent.saveBikeContingent' | translate}}</button>
    </div>
  </div>
</div>