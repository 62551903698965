<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="col">
                        <div class="row" style="margin-bottom: 20px">
                          <kendo-label text="{{'statements.cashflow.clients' | translate}}" [style.width.px]="200" style="margin-right: 10px; font-weight: bold;">
                            <kendo-dropdownlist
                              [data]="clientOptions"
                              *ngIf="currentUserType.userTypeNumber === 0"
                              (valueChange)="onClientSelectChange($event.value)"
                              textField="label"
                              valueField="value"
                            ></kendo-dropdownlist>
                          </kendo-label>
                          <kendo-label text="{{'statements.cashflow.rentalPoints' | translate}}" [style.width.px]="200" style="margin-right: 10px; font-weight: bold;">
                            <kendo-dropdownlist
                              [data]="rentalPointOptions"
                              *ngIf="currentUserType.userTypeNumber !== 2"
                              (valueChange)="onRentalPointChange($event.value)"
                              textField="label"
                              valueField="value"
                            ></kendo-dropdownlist>
                          </kendo-label>
                          <kendo-daterange [style.width.px]="400">
                            <kendo-label text="{{'statements.cashflow.from' | translate}}" style="font-weight: bold">
                              <kendo-dateinput
                                kendoDateRangeStartInput
                                [(value)]="selectionDateRange.start"
                                [style.width.px]="200"
                                format="dd-MM-yyyy">
                              </kendo-dateinput>
                            </kendo-label>
                            <kendo-label text="{{'statements.cashflow.to' | translate}}" style="font-weight: bold;">
                              <kendo-dateinput
                                kendoDateRangeEndInput
                                [(value)]="selectionDateRange.end"
                                [style.width.px]="200"
                                format="dd-MM-yyyy">
                              </kendo-dateinput>
                            </kendo-label>
                            <kendo-daterange-popup [animate]="true"></kendo-daterange-popup>
                          </kendo-daterange>
                        </div>
                        <div style="display: flex; justify-content: end;">
                          <button type="button" class="btn btn-primary btn-sm" (click)="applyFilters()">
                            <i class="fa fa-check"></i>
                            {{'data-table.filters.buttons.apply' | translate}}
                          </button>
                          <button type="button" class="btn btn-secondary btn-sm" (click)="onResetButtonClick()">
                            <i class="fa fa-ban"></i>
                            {{'data-table.filters.buttons.reset' | translate}}
                          </button>
                          <button type="button" class="btn btn-success btn-sm" (click)="onExcelExportButtonClick()"
                            *ngIf="showResultContent">
                            <i class="fa fa-download"></i>
                            {{'data-table.filters.buttons.excelExport' | translate}}
                          </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-3">
      <kendo-chart *ngIf="showResultContent && chartData.rentalPoints?.length > 0 && chartData.percentages?.length > 0" class="mt-3">
        <kendo-chart-series>
          <kendo-chart-area [height]="450"></kendo-chart-area>
          <kendo-chart-category-axis>
            <kendo-chart-category-axis-item [categories]="chartData.rentalPoints"></kendo-chart-category-axis-item>
          </kendo-chart-category-axis>
          <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [labels]="{ format: '{0}%' }"></kendo-chart-value-axis-item>
          </kendo-chart-value-axis>
          <kendo-chart-series-item type="bar" [data]="chartData.percentages" [spacing]="1" [gap]="1">
            <kendo-chart-series-item-labels align="right" position="outsideEnd" background="none" format="{0:n2}%"></kendo-chart-series-item-labels>
          </kendo-chart-series-item>
        </kendo-chart-series>
      </kendo-chart>
    </div>
    <div *ngIf="!filters.clientId && !filters.rentalPointAccountId" style="display: flex; text-align: center">
      <span>{{'rentalPoint.shared.missingClientRentalPoint' | translate}}</span>
    </div>
    <ng-container>
        <div class="row mt-3" *ngIf="!showResultContent && (fetchesCounter > 1)">
            <div class="col">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" [hidden]="!showResultContent">
            <div class="col">
              <table class="table">
                <thead>
                  <tr>
                    <th style="font-size: 16px;text-align: right;">
                      {{'statements.cashflow.totalAmount' | translate}} {{amountSum | currency: ' € '}}
                    </th>
                  </tr>
                </thead>
              </table>
              <kendo-grid [kendoGridBinding]="tableData" [pageable]="true" [pageSize]="20">
                <kendo-grid-column
                  field="name"
                  title="{{'statements.cashflow.clientName' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column
                  field="business"
                  title="{{'statements.cashflow.rentalPointName' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column
                  field="contractId"
                  title="{{'statements.cashflow.contractId' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column
                  field="actionDate"
                  title="{{'statements.cashflow.actionDate' | translate}}">
                </kendo-grid-column>
                <kendo-grid-column
                  field="amount"
                  title="{{'statements.cashflow.amount' | translate}}"
                  format="#.00 €">
                </kendo-grid-column>
              </kendo-grid>
            </div>
        </div>
    </ng-container>
</div>
