import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { LoginComponent } from '../../login/login.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public imagePath = '../../../../assets/images/pbikecolor-02.png';

  constructor(private userService: UserService) {  }

  setPath() {
    this.imagePath = '../../../../assets/images/pbikealb-02.png';
  }

  ngOnInit() {
  }

}
