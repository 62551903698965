import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../shared/orders.service';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, forkJoin } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductsService } from '../../products/shared/products.service';
import { BikeType } from '../../../models/BikeType';
import { RentalBike } from '../../../models/RentalBike';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from '../../clients/shared/clients.service';
import { Client } from '../../../models/Client';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import * as moment from 'moment/moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

class NewBike {
  public quantity: number;
  public height = 0;
  public bikeTypeId: number;
  public bikeType: BikeType;
}

@Component({
  selector: 'app-update-order',
  templateUrl: './update-order.component.html',
  styleUrls: ['./update-order.component.scss']
})
export class UpdateOrderComponent implements OnInit {
  @ViewChild('bookingUpdateErrorModal', { static: true }) bookingUpdateErrorModal: TemplateRef<any>;

  public order: any;
  private _loadedBikes: RentalBike[] = [];
  public bikes: RentalBike[] = [];
  public orderType: string;
  public partialCancellation = false;
  public partialReturn = false;
  public currentLang: string;
  public orderFormGroup: FormGroup;

  public startDate: string;
  public endDate: string;
  public rentalPeriod: number;

  public startRentalPointName: string;
  public endRentalPointName: string;

  public newRentalBike: NewBike = new NewBike();

  public startClientOptions: Option[] = [{value: 0, label: 'All'}];
  public endClientOptions: Option[] = [{value: 0, label: 'All'}];

  public startRentalPointOptions: Option[] = [{value: null, label: 'All'}];
  public endRentalPointOptions: Option[] = [{value: null, label: 'All'}];

  public bikeTypeArray;
  private _clients: Client[];
  private _contractId: string;

  public selectedStartClientId = '0';
  public selectedStartRentalPointAccountId = 0;

  public selectedEndClientId = '0';
  public selectedEndRentalPointAccountId = 0;

  constructor(private _activatedRoute: ActivatedRoute, private _ordersService: OrdersService,
    private _clientsService: ClientsService, private _translateService: TranslateService,
    private _formBuilder: FormBuilder, private _router: Router, private _modalService: NgbModal,
    private _productsService: ProductsService, private _snackBar: MatSnackBar, private _translate: TranslateService) {
    this.currentLang = this._translateService.currentLang;
    this.orderFormGroup = this._formBuilder.group({
      id: [],
      startRentalPoint: [],
      endRentalPoint: [],
      startDate: ['', [<any>Validators.required]],
      endDate: ['', [<any>Validators.required]],
      clientInformation: this._formBuilder.group({
        firstName: [],
        lastName: [],
        email: [],
        phone: [],
        address: [],
        postalCode: [],
        invoiceBike: []
      })
    });
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.orderType = params['orderType'];
      this._contractId = params['contractId'];
      if (this.orderType !== 'reservation') {
        this._router.navigate(['orders/booking-reservations']);
      }
      let serviceCall1;
      switch (this.orderType) {
        case 'direct-rental':
          serviceCall1 = this._ordersService.getDirectRental(this._contractId);
          break;
        case 'booked-rental':
          serviceCall1 = this._ordersService.getBookedRentalByContractId(this._contractId);
          break;
        default:
          serviceCall1 = this._ordersService.getOnlineBookingReservationByContractId(this._contractId);
          break;
      }
      const serviceCall2 = this._productsService.getBikeTypes();
      const serviceCall3 = this._clientsService.getClients();
      combineLatest(serviceCall1, serviceCall2, serviceCall3).subscribe(result => {
        console.log(result);
        this.order = result[0];
        this.rentalPeriod = this.getDayDiff(this.order.startDate, this.order.endDate);
        this.bikeTypeArray = result[1];
        this._clients = result[2];
        this.orderFormGroup.patchValue(this.order);
        this.orderFormGroup.controls['startRentalPoint'].setValue(this.order.startRentalPoint.accountId);
        this.orderFormGroup.controls['endRentalPoint'].setValue(this.order.endRentalPoint.accountId);
        this.startDate = this.order.startDate;
        this.endDate = this.order.endDate;
        if (this.order.status === 2) {
          this.bikes = this.order.orderReturn.returnBike;
        } else {
          switch (this.orderType) {
            case 'direct-rental':
              this.bikes = this.order.directBike;
              break;
            case 'booked-rental':
              this.bikes = this.order.bookedBike;
              break;
            default:
              this.bikes = this.order.invoiceBike;
              break;
          }
        }
        this._clients.forEach(client => {
          this.startClientOptions.push({ value: client.id, label: client.businessName});
          this.endClientOptions.push({ value: client.id, label: client.businessName});
        });

        this.startRentalPointName = result[0]['startRentalPoint']['rentalPointTranslation'][this.currentLang];
        this.endRentalPointName = result[0]['endRentalPoint']['rentalPointTranslation'][this.currentLang];
        this._loadedBikes = [...this.bikes]; // make a copy of bikes

        if (this.order.reservation === 0) {
          const clientInformationFormGroup = this.orderFormGroup.get('clientInformation') as FormGroup;
          ['firstName', 'lastName', 'email', 'phone', 'address', 'postalCode'].forEach((controlName) => {
            clientInformationFormGroup.get(controlName).disable();
          });
        }
      });
    });
  }

  private submitUpdateReservationForm() {
    let isChanged = false;
    const rental = this.orderFormGroup.value;
    rental.startDate = this.startDate;
    rental.endDate = this.endDate;
    rental.invoiceBike = this.bikes;
    isChanged = (
      (this.order.startRentalPoint.accountId !== rental.startRentalPoint) ||
      (this.order.endRentalPoint.accountId !== rental.endRentalPoint) ||
      (this.order.startDate !== rental.startDate) ||
      (this.order.endDate !== rental.endDate) ||
      (this.order.clientInformation.firstName !== rental.clientInformation.firstName) ||
      (this.order.clientInformation.lastName !== rental.clientInformation.lastName) ||
      (this.order.clientInformation.email !== rental.clientInformation.email) ||
      (this.order.clientInformation.phone !== rental.clientInformation.phone) ||
      (this.order.clientInformation.address !== rental.clientInformation.address) ||
      (this.order.clientInformation.postalCode !== rental.clientInformation.postalCode) ||
      (this.bikes.length !== this._loadedBikes.length)
    );
    if (!isChanged) {
      isChanged = (JSON.stringify(this._loadedBikes) !== JSON.stringify(rental.invoiceBike));
    }
    rental.isChanged = (isChanged) ? 1 : 0;
    if (!rental.isChanged) {
      return this._snackBar.open(this._translate.instant('orders.updateOrder.noDataUpdate'), '', {
        duration: 3500,
      });
    }
    this._ordersService.updateReservation(rental).subscribe(result => {
        isChanged = false;
        this._snackBar.open(this._translate.instant('orders.updateOrder.reservationUpdate'), '', {
          duration: 3500,
        });
        this._router.navigate(['orders/reservation/details/' + this.order.contractId]);
      },
      (error => {
        this._snackBar.open(this._translate.instant(error.error), '', {
          duration: 3500,
        });
      })
    );
  }

  private submitUpdateBookingForm() {
    const rental = this.orderFormGroup.value;
    rental.startDate = this.startDate;
    rental.endDate = this.endDate;

    const rentalDaysDiff = this.getDayDiff(rental.startDate, rental.endDate);
    const orderDaysDiff = this.getDayDiff(this.order.startDate, this.order.endDate);

    if (rentalDaysDiff !== orderDaysDiff) {
      this._modalService.open(this.bookingUpdateErrorModal, {ariaLabelledBy: 'modal-basic-title', backdrop: false});
      return;
    }

    rental.isChanged = (
      (this.order.startRentalPoint !== rental.startRentalPoint) ||
      (this.order.endRentalPoint !== rental.endRentalPoint) ||
      (this.order.startDate !== rental.startDate) ||
      (this.order.endDate !== rental.endDate)
    );

    if (!rental.isChanged) {
      return;
    }

    this._ordersService.updateReservation(rental).subscribe(result => {
        this._snackBar.open(this._translate.instant('orders.updateOrder.reservationUpdate'), '', {
          duration: 3500,
        });
        this._router.navigate(['orders/booking/details/' + this.order.contractId]);
      },
      (error => {
        this._snackBar.open(this._translate.instant(error.error), '', {
          duration: 3500,
        });
      })
    );

  }

  private getDayDiff(startDate: string, endDate: string): number | null {
    if (!startDate || !endDate) {
      return null;
    }
    if (typeof startDate !== 'string' || typeof endDate !== 'string') {
      return null;
    }
    const momentStartDate = moment(startDate);
    const momentendDate = moment(endDate);
    return momentendDate.diff(momentStartDate, 'days') + 1;
  }

  onOrderFormSubmit() {
    return this.order.reservation === 0 ? this.submitUpdateBookingForm() : this.submitUpdateReservationForm();
  }

  onRemoveBikeClick(bikeObjectId: number) {
    const bikeIndex = this.bikes.indexOf(this.bikes.filter(bike => bike.id === bikeObjectId)[0]);
    this.bikes.splice(bikeIndex, 1);
  }

  onAddBikeClick() {
    if (this.newRentalBike.bikeTypeId && this.newRentalBike.quantity && this.newRentalBike.quantity > 0) {
      this.newRentalBike.bikeType = this.bikeTypeArray.filter(bikeType =>
        bikeType.id.toString() === this.newRentalBike.bikeTypeId.toString())[0];
      this.bikes.push(RentalBike.fromDto(this.newRentalBike));
      this.newRentalBike = new NewBike();
    }
  }

  onClientSelectChange(type: string) {
    if (type === 'startRP') {
      this.selectedStartRentalPointAccountId = 0;
      this.startRentalPointOptions = [{ value: 0, label: 'All' }];
      if (this.selectedStartClientId.toString() !== '0') {

        const selectedClient = this._clients.filter(client => client.id.toString() === this.selectedStartClientId.toString())[0];
        selectedClient.rentalPoints.forEach(rentalPoint => {
          this.startRentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
      }
    }
    if (type === 'endRP') {
      this.selectedEndRentalPointAccountId = 0;
      this.endRentalPointOptions = [{ value: 0, label: 'All' }];
      if (this.selectedEndClientId.toString() !== '0') {
        const selectedClient = this._clients.filter(client => client.id.toString() === this.selectedEndClientId.toString())[0];
        selectedClient.rentalPoints.forEach(rentalPoint => {
          this.endRentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
      }
    }

  }

  onFilterClick(type: string) {
    if (type === 'startRP') {
      this.orderFormGroup.controls['startRentalPoint'].setValue(this.selectedStartRentalPointAccountId);
      this._clients.forEach(client => {
        if (client) {
          client.rentalPoints.forEach(rentalPoint => {
            if (rentalPoint.accountId === this.selectedStartRentalPointAccountId) {
              this.startRentalPointName = rentalPoint.businessName;
            }
          });
        }
      });
    }
    if (type === 'endRP') {
      this.orderFormGroup.controls['endRentalPoint'].setValue(this.selectedEndRentalPointAccountId);
      this._clients.forEach(client => {
        if (client) {
          client.rentalPoints.forEach(rentalPoint => {
            if (rentalPoint.accountId === this.selectedEndRentalPointAccountId) {
              this.endRentalPointName = rentalPoint.businessName;
            }
          });
        }
      });
    }
  }

}
