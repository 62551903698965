<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="orderFormGroup" (ngSubmit)="onOrderFormSubmit()">
            <input type="hidden" class="form-control form-control-sm" formControlName="startRentalPoint" />
            <input type="hidden" class="form-control form-control-sm" formControlName="endRentalPoint" />
            <div class="row">
              <div class="col-sm-6 text-right align-middle rental-point-box">
                <div class="inner">
                  <label class="title-label control-label col-form-label-sm">{{'orders.updateOrder.startRentalPoint' | translate}}</label>
                  <div class="inner-inner">
                    <div class="content">
                      <div class="row">
                        <div class="col-12">
                          <label class="control-label actual">{{'orders.updateOrder.actualValue' | translate}} <span>{{startRentalPointName}}</span></label>
                        </div>
                        <div class="col-5">
                          <div class="form-group">
                            <label class="control-label">{{'orders.updateOrder.clients' | translate}}</label>
                            <select class="form-control form-control-sm" [(ngModel)]="selectedStartClientId"
                              [ngModelOptions]="{standalone: true}"
                              (change)="onClientSelectChange('startRP')">
                              <option *ngFor="let client of startClientOptions" value="{{client.value}}">{{client.label}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-7">
                          <app-select-search label="{{'orders.updateOrder.rentalPoints' | translate}}"
                            [options]="startRentalPointOptions" [(model)]="selectedStartRentalPointAccountId">
                          </app-select-search>
                        </div>
                        <div class="col-12">
                          <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick('startRP')">{{'orders.updateOrder.change' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-6 text-right align-middle rental-point-box">
                <div class="inner">
                  <label class="title-label control-label col-form-label-sm">{{'orders.updateOrder.endRentalPoint' | translate}}</label>
                  <div class="inner-inner">
                    <div class="content">
                      <div class="row">
                        <div class="col-12">
                          <label class="control-label actual">{{'orders.updateOrder.actualValue' | translate}}  <span>{{endRentalPointName}}</span></label>
                        </div>
                        <div class="col-5">
                          <div class="form-group">
                            <label class="control-label">{{'orders.updateOrder.clients' | translate}}</label>
                            <select class="form-control form-control-sm" [(ngModel)]="selectedEndClientId"
                              [ngModelOptions]="{standalone: true}"
                              (change)="onClientSelectChange('endRP')">
                              <option *ngFor="let client of endClientOptions" value="{{client.value}}">{{client.label}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-7">
                          <app-select-search label="{{'orders.updateOrder.rentalPoints' | translate}}"
                            [options]="endRentalPointOptions" [(model)]="selectedEndRentalPointAccountId">
                          </app-select-search>
                        </div>
                        <div class="col-12">
                          <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick('endRP')">{{'orders.updateOrder.change' | translate}}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <hr />
            <div class="row">
              <div class="col-sm-3 text-right align-middle">
                <label class="control-label col-form-label-sm">{{'orders.updateOrder.startDate' | translate}} *</label>
              </div>
              <div class="col-sm-3">
                <app-datepicker [(model)]="startDate"></app-datepicker>
              </div>
              <div class="col-sm-2 text-right align-middle">
                <label class="control-label col-form-label-sm">{{'orders.updateOrder.endDate' | translate}} *</label>
              </div>
              <div class="col-sm-3">
                <app-datepicker [(model)]="endDate"></app-datepicker>
              </div>
            </div>
            <hr />
            <ng-container formGroupName="clientInformation">
              <div class="row">
                <div class="col-sm-3 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.firstName' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="firstName" />
                </div>
                <div class="col-sm-2 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.lastName' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="lastName" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.email' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="email" />
                </div>
                <div class="col-sm-2 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.phone' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="phone" />
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.address' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="address" />
                </div>
                <div class="col-sm-2 text-right align-middle">
                  <label class="control-label col-form-label-sm">{{'orders.updateOrder.postalCode' | translate}}</label>
                </div>
                <div class="col-sm-3">
                  <input type="input" class="form-control form-control-sm" formControlName="postalCode" />
                </div>
              </div>
            </ng-container>
          </form>
          <hr />
          <div class="row">
            <div class="offset-2 col-9">
              <table class="table">
                <thead>
                  <tr>
                    <th>{{'orders.updateOrder.bikeType' | translate}}</th>
                    <th>{{'orders.updateOrder.quantity' | translate}}</th>
                    <th>{{'orders.updateOrder.size' | translate}}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let bike of bikes" style="background:rgba(0,0,0,.03);">
                    <td>
                      <select class="form-control form-control-sm" [(ngModel)]="bike.bikeType.id" disabled>
                        <option *ngFor="let bikeType of bikeTypeArray" [value]="bikeType.id">
                          {{bikeType.bikeTypeTranslation[currentLang]}}</option>
                      </select>
                    </td>
                    <td>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="bike.quantity" disabled/>
                    </td>
                    <td>
                      <input type="number" class="form-control form-control-sm" [(ngModel)]="bike.height" disabled/>
                    </td>
                    <td *ngIf="order && order.reservation !== 0">
                      <button type="button" class="btn btn-danger btn-sm" (click)="onRemoveBikeClick(bike.id)">
                        <i class="fa fa-trash"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="table table-borderless" *ngIf="order && order.reservation !== 0">
                <tbody>
                  <tr>
                    <td>
                      <select class="form-control form-control-sm" [(ngModel)]="newRentalBike.bikeTypeId">
                        <option *ngFor="let bikeType of bikeTypeArray" [value]="bikeType.id">
                          {{bikeType.bikeTypeTranslation[currentLang]}}</option>
                      </select>
                    </td>
                    <td>
                      <input type="number" class="form-control form-control-sm" min="1"
                        [(ngModel)]="newRentalBike.quantity" />
                    </td>
                    <td>
                      <input type="number" class="form-control form-control-sm" min="0"
                        [(ngModel)]="newRentalBike.height" />
                    </td>
                    <td>
                      <button type="button" class="btn btn-success btn-sm" (click)="onAddBikeClick()">
                        <i class="fa fa-plus"></i></button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <a class="btn btn-danger btn-sm" href="/orders/reservation/details/{{order.contractId}}" *ngIf="order">
            <i class="fa fa-times"></i> <br />
            {{'orders.updateOrder.cancel' | translate}}
          </a>
          <button class="btn btn-primary btn-sm float-right" type="button" (click)="onOrderFormSubmit()">
            <i class="fa fa-save"></i> <br />
            {{'orders.updateOrder.save' | translate}}
          </button>
        </div>
      </div>
      <ng-template #bookingUpdateErrorModal let-modal>
        <div class="modal-header">
          <h4 class="modal-title text-danger" id="modal-basic-title">
            {{'orders.bookingReservationOrders.attention' | translate}}</h4>
          <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>{{'orders.bookingReservationOrders.booking_update_error' | translate}} {{this.rentalPeriod}} {{ (this.rentalPeriod === 1 ? 'orders.bookingReservationOrders.day' : 'orders.bookingReservationOrders.days') | translate }}</p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-danger"
              (click)="modal.close('Ok click')">{{'orders.bookingReservationOrders.ok' | translate}}</button>
        </div>
      </ng-template>
    </div>
  </div>
</div>

