import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/User';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { userType } from '../../../config';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  public staff: User[];
  public manager: User[] = [];
  public administrator: User[] = [];
  public userType = userType;
  public isClient;
  public isRentalPoint;

  constructor(private userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute) {
    this.isClient = localStorage.getItem('isClient');
    this.isRentalPoint = localStorage.getItem('isRentalPoint');
  }

  openStaff(id) {
    this.router.navigate(['/staff/' + id]);
  }

  back() {
    this.router.navigate(['/dashboard']);
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.userService.getStaff().subscribe(result => {
        this.staff = result;
        console.log(result);
        for ( let i = 0; i < this.staff.length; i++) {
          if (this.staff[i].userType === 1) {
            console.log('test');
            this.manager.push(this.staff[i]);
          }
        }
        console.log('Managers:', this.manager);
        for ( let i = 0; i < this.staff.length; i++) {
          if (this.staff[i].userType === 1) {
            console.log('test');
            this.administrator.push(this.staff[i]);
          }
        }
        console.log('Admins: ', this.administrator);
      });
    });
  }

}
