import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { OrdersService } from '../shared/orders.service';
import { UnsuccessfulPaymentCriteria } from '../shared/UnsuccessfulPaymentCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { SearchObjectResult } from '../../../models/SearchObject';
import { SharedUtils } from '../../../shared/shared-utils';
import { Observable, forkJoin } from 'rxjs';
import { Client } from '../../../models/Client';
import { ClientsService } from '../../clients/shared/clients.service';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-unsuccessful-payments',
  templateUrl: './unsuccessful-payments.component.html',
  styleUrls: ['./unsuccessful-payments.component.scss']
})
export class UnsuccessfulPaymentsComponent implements OnInit {

  public filters: UnsuccessfulPaymentCriteria = {};
  myArray: [];
  public columns: DataTableColumn[] = [];
  public unsuccessullPaymetsClient: UnsuccessfulPaymentCriteria[];
  public currentUserType = SharedUtils.getCurrentUserType();
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public accountsRequest: Observable<Client> | Observable<Client[]>;
  public currentLang: string;

  private _clients: Client[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('clientPhone', { static: true })
  clientPhone: TemplateRef<any>;

  @ViewChild('clientEmail', { static: true })
  clientEmail: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('endDate', { static: true })
  endDate: TemplateRef<any>;

  @ViewChild('placementTime', { static: true })
  placementTime: TemplateRef<any>;

  @ViewChild('transactionError', { static: true })
  transactionError: TemplateRef<any>;


  constructor(private _ordersService: OrdersService, private _clientsService: ClientsService,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
    this.clientOptions = [{ value: undefined, label: this._translate.instant('orders.ordersUnsuccessfulPayments.all') }];
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.ordersUnsuccessfulPayments.all') }];
  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.accountId };
        break;
      case 2: // RENTALPOINT
        this.filters = { rentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
      this.loadPage = (page: number, pageSize: number) => this._ordersService.searchUnsuccessfulPayments(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.contractId',
        template: this.contractId,
        sortable: true
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.clientName',
        template: this.clientName,
        sortable: true
      },
      {
        name: 'clientPhone',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.clientPhone',
        template: this.clientPhone,
        sortable: true
      },
      {
        name: 'clientEmail',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.clientEmail',
        template: this.clientEmail,
        sortable: true
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.startDate',
        template: this.startDate,
        sortable: true
      },
      {
        name: 'endDate',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.endDate',
        template: this.endDate,
        sortable: true
      },
      {
        name: 'placementTime',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.placementTime',
        template: this.placementTime,
        sortable: true
      },
      {
        name: 'transactionError',
        displayed: true,
        translationKey: 'orders.ordersUnsuccessfulPayments.transactionStatus',
        template: this.transactionError,
        sortable: true
      }
    ];
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }


  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: 'All' }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

}



