import { Component, OnInit } from '@angular/core';
import { RentalPointsService } from '../shared/rental-points.service';
import { ActivatedRoute } from '@angular/router';
import { ProductsService } from '../../products/shared/products.service';
import { forkJoin } from 'rxjs';
import { ProductsPerRentalPoint } from '../../../models/ProductsPerRentalPoint';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Account } from '../../../models/Account';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

class ContingentException {
  public id: number;
  public accountId: number = undefined;
  public bikeQuantity: number = undefined;
  public bufferQuantity: number = undefined;
  public bikeTypeId: string = undefined;
  public startDate: string = undefined;
  public endDate: string = undefined;
}

@Component({
  selector: 'app-bike-contingent-exceptions',
  templateUrl: './bike-contingent-exceptions.component.html',
  styleUrls: ['./bike-contingent-exceptions.component.scss']
})
export class BikeContingentExceptionsComponent implements OnInit {

  public rentalPointId;
  public productsPerRentalPoint: ProductsPerRentalPoint[];
  public newContingentException: ContingentException = new ContingentException();
  public bikeTypeOptions: Option[] = [];
  public currentLang: string;

  private _currentAccount: Account;

  constructor(private _rentalPointsService: RentalPointsService, private _activatedRoute: ActivatedRoute,
    private _productsService: ProductsService, private _snackBar: MatSnackBar,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this._activatedRoute.params.subscribe(params => {
      this.rentalPointId = params.rentalPointId;
      this._rentalPointsService.getRentalPoint(params.rentalPointId).subscribe(accounts => {
        this._currentAccount = Account.fromDto(accounts);
        this._getContingentProducts();
      });
    });
  }

  onNewContingentExceptionSubmit() {
    this.newContingentException.accountId = this._currentAccount.id;
    let error = false;
    for (const key in this.newContingentException) {
      if (this.newContingentException.hasOwnProperty(key)) {
        const element = this.newContingentException[key];
        if (!element) {
          console.log('key', key);
          error = true;
          if (key === 'bikeQuantity') {
            error = false;
          }
          if (key === 'bufferQuantity') {
            error = false;
          }
        }
      }
    }
    console.log('error', error);
    if (!error) {
      console.log(this.newContingentException);
      this._rentalPointsService.addContingentException(this.newContingentException).subscribe(result => {
        this.newContingentException = new ContingentException();
        this._snackBar.open(this._translate.instant('rentalPoint.bikeContingentExceptions.addContingentException'), '', {
          duration: 2000,
        });
        this._getContingentProducts();
      });
    }
  }

  deleteException(exceptionId) {
    this._rentalPointsService.deleteBikeContingentException(exceptionId).subscribe(result => {
      this._rentalPointsService.getContingentProductsPerRentalPoint(this._currentAccount.id).subscribe(results => {
        this.productsPerRentalPoint = results;
      });
    });
  }

  private _getContingentProducts() {
    forkJoin(
      [
        this._rentalPointsService.getContingentProductsPerRentalPoint(this._currentAccount.id),
        this._productsService.getBikeTypes()
      ]
    ).subscribe(result => {
      this.productsPerRentalPoint = result[0];
      result[1].forEach(bikeType => {
        this.bikeTypeOptions.push({ value: bikeType.id, label: bikeType.bikeTypeTranslation[this.currentLang] });
      });
    });
  }
}
