import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { HttpUtils } from '../../../shared/http-utils';
import { Credits } from '../../../models/Credits';
import { CreditSearchCritertia } from './CreditSearchCritertia';
import { BookedOrderSearchCriteria } from './BookedOrderSearchCriteria';
import { OnlineBooking } from '../../../models/OnlineBooking';
import { OrderSearchCriteria } from './OrderSearchCriteria';
import { Rental } from '../../../models/Rental';
import { UnsuccessfulPaymentCriteria } from './UnsuccessfulPaymentCriteria';
import { OrdersByQrCodeSearchCriteria } from './OrdersByQrCodeSearchCriteria';
import { ReturnOrderSearchCriteria } from './ReturnOrderSearchCriteria';
import { InvoiceSearchCriteria } from './InvoiceSearchCriteria';


@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  constructor(private _http: HttpClient) { }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public search(page: number, size: number, criteria: CreditSearchCritertia): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchCredits', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, Credits.fromDto);
      })
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchOrders(page: number, size: number, criteria: OrderSearchCriteria, deleted?: boolean): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      deleted: deleted ? true : false,
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchOrders', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Rental.fromDto);
      })
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchReturnOrders(page: number, size: number, criteria: ReturnOrderSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      returnRentalPointAccountId: String(localStorage.getItem('accountId')),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchReturnOrders', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Rental.fromDto);
      })
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchOrdersByQrCode(page: number, size: number, criteria: OrdersByQrCodeSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchOrdersByQrCode', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, Rental.fromDto);
      })
    );
  }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchReservedOrders(page: number, size: number, criteria: BookedOrderSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchReservedOrders', { headers, params }).pipe(
      map((res: any) => {
        const searchObjectResult = SearchObjectResult.fromDto(res, OnlineBooking.fromDto);
        searchObjectResult.results.forEach((order: OnlineBooking) => {
          if (order.reservation === 0 ) {
            order.discountedPrice = order.discountedBookingPrice;
            if (order.transactionStatus === 99) {
              order.reservation = 3;
            }
          } else {
            if (order.transactionStatus === 99) {
              order.reservation = 4;
            }
          }
        });
        return searchObjectResult;
      })
    );
  }

  public searchInvoices(page: number, size: number, criteria: InvoiceSearchCriteria): Observable<SearchObjectResult<Event>> {
    if (criteria.invoiceNumber) {
      criteria.invoiceNumber = criteria.invoiceNumber.split('-')[0];
    }
    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };

    return this._http.get(environment.serverUrl + 'api/searchInvoices', { headers, params }).pipe(
      map((res: any) => {
        res.results.forEach((invoice: any) => {
          invoice.invoiceNumber = invoice.invoiceNumber.split('-')[0];
          invoice.invoicesNumbers = invoice.invoiceNumber;
          if (invoice.cancelledInvoiceNumber) {
            invoice.cancelledInvoiceNumber = invoice.cancelledInvoiceNumber.split('-')[0];
            invoice.invoicesNumbers += ' - ' + invoice.cancelledInvoiceNumber;
          }
        });
        return res;
      })
    );
  }

  public refundOrder(contractId: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/makeRefund', { contractId: contractId }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * getDirectRental
   */
  public getDirectRental(contractId: string): Observable<Rental> {
    return this._http.get(environment.serverUrl + 'api/getDirectRentalByContractId', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => Rental.fromDto(result))
    );
  }

  /**
   * getBookedRentalByContractId
   */
  public getBookedRentalByContractId(contractId: string): Observable<Rental> {
    return this._http.get(environment.serverUrl + 'api/getBookedRentalByContractId', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => Rental.fromDto(result))
    );
  }

  /**
   * getOnlineBookingReservationByContractId
   */
  public getOnlineBookingReservationByContractId(contractId: string): Observable<OnlineBooking> {
    return this._http.get(environment.serverUrl + 'api/getOnlineBookingReservationByContractId', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => OnlineBooking.fromDto(result))
    );
  }

  /**
   * getContractPaper
   */
  public getContractPaper(contractId: string): Observable<Rental> {
    return this._http.get(environment.serverUrl + 'api/getContractPaper', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => Rental.fromDto(result))
    );
  }
  /**
   * getReturnPaper
   */
  public getReturnPaper(contractId: string): Observable<Rental> {
    return this._http.get(environment.serverUrl + 'api/getReturnPaper', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => Rental.fromDto(result))
    );
  }

  /**
   * getCreditByCreditCode
   */
  public getCreditByCreditCode(code: string): Observable<Credits> {
    return this._http.get(environment.serverUrl + 'api/getCreditByCreditCode', {
      headers: HttpUtils.createHeaders(), params: { code }
    }).pipe(
      map((result: any) => Credits.fromDto(result))
    );
  }

  // Cancel if reservation
  public cancelReservation(contractId: any, accountId: any): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/cancelReservation', {
      headers: HttpUtils.createHeaders(), params: { contractId, accountId }
    });
  }

  // search unsuccessful payments
  public searchUnsuccessfulPayments(page: number, size: number, criteria: UnsuccessfulPaymentCriteria):
    Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchUnsuccessfulPayments', { headers, params }).pipe(
      map((res: any) => {
        console.log(res);
        return SearchObjectResult.fromDto(res, OnlineBooking.fromDto);
      })
    );
  }

  /**
   * updateReservation
  */
  public updateReservation(order: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/updateReservation', { order }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * closeOrderManually
   */
  public closeOrderManually(orderType: any, contractId, accountId?: any, closeDate?: any, updatedAt?: any): Observable<any> {
    const headers = HttpUtils.createHeaders();
    console.log(closeDate);
    return this._http.post(environment.serverUrl + 'api/closeOrderManually', { orderType, contractId, accountId, closeDate, updatedAt }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public changeExtensionRental(contractId: number, rentalType: number, value: boolean): Observable<void> {
    console.log({
      id: contractId,
      type: rentalType,
      extension: (value ? 1 : 0)
    });
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/changeExtension', {
      id: contractId,
      type: rentalType,
      extension: (value ? 1 : 0)
    }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  public generatePdfLink(contractId: string, orderType: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    const body = {
      contractId: contractId,
      orderType: orderType,
    };
    return this._http.post(environment.serverUrl + 'api/generatePdfLink', body, { headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public insertCredit(name: string, quantity: number, amount: number, accountId: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    let credits;
    if (quantity == 1) {
      const credit: Credits = new Credits;
      credit.creditTitle = name;
      credit.amount = amount;

      const id = new Date().getTime().toString();
      credit.creditId = id + accountId;
      credit.creditCode = id.substring(id.length - 10, id.length);
      credits = credit;
    } else {
      credits = [];
      for (let i = 0; i < quantity; i++) {
        const credit: Credits = new Credits;
        credit.creditTitle = name;
        credit.amount = amount;

        const random = Math.floor(100000 + Math.random() * 900000);
        const id = (new Date().getTime() + random).toString();
        console.log(id);
        credit.creditId = id + accountId;
        credit.creditCode = id.substring(id.length - 10, id.length);

        credits.push(credit);
      }
    }

    const body = JSON.stringify(credits);

    return this._http.post(environment.serverUrl + 'api/insertCredit', { credit: body }, { headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public deleteDirectRental(id: number, accountId: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    const body = {
      id: id,
      accountId: accountId
    }
    return this._http.post(environment.serverUrl + 'api/deleteDirectRental', body, { headers }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getDeletedDirectRental(contractId: string): Observable<any> {
    return this._http.get(environment.serverUrl + 'api/getDeletedDirectRental', {
      headers: HttpUtils.createHeaders(), params: { contractId }
    }).pipe(
      map((result: any) => Rental.fromDto(result))
    );
  }

}
