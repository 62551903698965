import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wartungUserStatus'
})
export class WartungUserStatusPipe implements PipeTransform {

  transform(value: boolean): string {
    return  `wartungUser.${value ? 'active' : 'inactive'}`;
  }

}
