import { Routes } from '@angular/router';
import { CashFlowComponent } from './cash-flow.component';
import { AuthGuard } from '../../auth-guard.service';
import { OnlineBookingsComponent } from './online-bookings/online-bookings.component';
import { RentalPointCashFlowComponent } from './rental-point-cash-flow/rental-point-cash-flow.component';
import { TurnoverCashFlowComponent } from './turnover-cash-flow/turnover-cash-flow.component';
import { InvoicesListComponent } from './invoices-list/invoices-list.component';

export const CASH_FLOW_ROUTES: Routes = [
    {
        path: 'cash-flow', component: CashFlowComponent, canActivate: [AuthGuard], children: [
            { path: 'online-bookings', component: OnlineBookingsComponent, canActivate: [AuthGuard] },
            { path: 'rental-points', component: RentalPointCashFlowComponent, canActivate: [AuthGuard] },
            { path: 'turnover', component: TurnoverCashFlowComponent, canActivate: [AuthGuard] },
            { path: 'invoices-list', component: InvoicesListComponent, canActivate: [AuthGuard] },
        ]
    }
];
