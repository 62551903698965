import { DiscountCardBikeType } from './DiscountCardBikeType';
import { RentalPoint } from './RentalPoint';

export class DiscountCard {
  public id: number;
  public name: string;
  public discount: number;
  public deleted: number;
  public discountCardBikeType: DiscountCardBikeType;
  public rentalPoints: RentalPoint[];
  public rental: any;

  public static fromDto(d): DiscountCard {
    const discountCard = new DiscountCard();
    if (!d) {
      return null;
    }
    discountCard.id = d.id;
    discountCard.name = d.name;
    discountCard.discount = d.discount;
    discountCard.deleted = d.deleted;
    discountCard.discountCardBikeType = d.discountCardBikeType;
    discountCard.rentalPoints = d.rentalPoints;
    discountCard.rental = d.rental;

    return discountCard;
  }
}
