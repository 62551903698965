<ng-container *ngIf="valueInArray(menuGroup.levelAccess, userType.userTypeNumber)">
  <ng-container *ngIf="menuGroup.groupTitle !== ''">
    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1 text-muted"
      style="border-bottom: 1px solid #cccccc;">
      <span>{{menuGroup.groupTitle | translate}}</span>
    </h6>
  </ng-container>
  <ul class="nav flex-column">
    <li class="nav-item" *ngFor="let item of menuGroup.groupItems"
      [hidden]="!valueInArray(item.levelAccess, userType.userTypeNumber) || rentalPointException(item.path)">
      <a class="nav-link" [routerLinkActive]="['active']" [routerLink]="item.path">
        <span>{{item.title | translate}}</span>
      </a>
    </li>
  </ul>
</ng-container>
