import {Component, OnInit} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {OrdersService} from '../../../services/orders.service';
import {OrdersService as SharedOrdersService} from '../shared/orders.service';
import {UserService} from '../../../services/user.service';
import {ProductService} from '../../../services/product.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import { OnlineBooking } from '../../../models/OnlineBooking';
import * as moment from 'moment';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.scss']
})
export class ReservationComponent implements OnInit {
  constructor(private ordersService: OrdersService, private route: ActivatedRoute,
              private userService: UserService, private productService: ProductService,
              private titleService: Title,
              private sharedOrdersService: SharedOrdersService,
              private translate: TranslateService,
              private http: HttpClient,
              ) {
  }

  public order = new OnlineBooking();
  public imagePath = '../../../../assets/images';
  public date = new Date();
  public countries = [];
  public daysDiff = 0;
  public pdfLink: string;

  calculateVatPrice(bruttoPrice) {
    return (bruttoPrice / (1 + Number(this.order.startRentalPoint.rentalPoint.vat)));

  }

  calculateTaxablePrice(discount) {
    return (this.calculateVatPrice(discount) * Number(this.order.startRentalPoint.rentalPoint.vat));
  }

  calculateTotalAmountVatInclusive(discount) {
    return (this.calculateTaxablePrice(discount) + this.calculateVatPrice(discount));
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }
  public print(): void {
    window.print();
  }

  ngOnInit() {
    this.userService.getCountries().subscribe(countries => {
      for (let i = 0; i < countries.length; i++) {
        const element = countries[i];
        this.countries[element.id] = element.name;
      }
    });

    this.route.params.subscribe(params => {
      this.ordersService.getOrderForInvoice(params.contractId).subscribe(result => {
        this.order = result;
        this.daysDiff = this.getDayDiff(this.order.startDate, this.order.endDate);
        const datePipe = new DatePipe('en-US');
        const placementDate = datePipe.transform(this.order.placementTime, 'yyyy-MM-dd');
        const pageTitle = `${placementDate}_Papin_Bike_Reservation`;
        this.setTitle(pageTitle);
        this.translate.use(this.order.language);
      });
      this.sharedOrdersService.generatePdfLink(params.contractId, 'reservation').subscribe({
        next: (response) => {
          this.pdfLink = response.link;
        }, error: (error) => {
          console.log(error);
        },
      });
    });
  }

  getDayDiff(startDate: string, endDate: string) {
    const momentStartDate = moment(startDate);
    const momentendDate = moment(endDate);
    return momentendDate.diff(momentStartDate, 'days') + 1;
  }

  public downloadPdf() {
    this.http.get(this.pdfLink, {responseType: 'blob'}).subscribe((pdfBlob: Blob) => {
      const pdfUrl = URL.createObjectURL(pdfBlob);
      const downloadLink = document.createElement('a');
      downloadLink.href = pdfUrl;
      downloadLink.download = `reservation_${this.order.contractId}.pdf`;
      downloadLink.click();
      URL.revokeObjectURL(pdfUrl);
    });
  }

}
