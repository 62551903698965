import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../models/User';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { userType } from '../../../config';

@Component({
  selector: 'app-staff-view',
  templateUrl: './staff-view.component.html',
  styleUrls: ['./staff-view.component.scss']
})
export class StaffViewComponent implements OnInit {
  @Input() staff = new User();
  public password = '';
  public showErrors = false;
  public accountDeleted: boolean;
  public accountDeactivated: boolean;

  constructor(private staffService: UserService,
              private route: ActivatedRoute,
              private router: Router) { }

  updateStaff(updateStaffForm) {
    console.log(updateStaffForm);
    if (updateStaffForm.valid) {
      this.staffService.updateStaffAccount(this.staff).subscribe(result => {
        this.router.navigate(['/staff']);
      });
    } else {
      this.showErrors = true;
    }
  }

  deleteStaff() {
    this.staffService.deleteClient(this.staff.id).subscribe(result => {
      this.router.navigate(['/staff']);
    });
  }

  public onChangeActivestatus($event) {
    $event.preventDefault();
    this.accountDeactivated = !this.accountDeactivated;
  }

  ngOnInit() {
    this.route.params.subscribe(params => {
      this.staffService.getUser(params.staffId).subscribe(result => {
        this.staff = result;
        this.staffService.getStaff().subscribe(item => {
          if (Number(item[0].id) === Number(params.staffId)) {
            this.accountDeleted = item[0].deleted;
            this.accountDeactivated = this.accountDeleted;
          }
        });
      });
    });
  }
}
