import { Component, OnInit } from '@angular/core';
import { SharedUtils } from '../../../shared/shared-utils';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';
import { forkJoin } from 'rxjs';
import { ClientsService } from '../../clients/shared/clients.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from '../shared/products.service';
import { BikePreparation } from '../../../models/BikePreparation';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-bike-equipment-preparation',
  templateUrl: './bike-equipment-preparation.component.html',
  styleUrls: ['./bike-equipment-preparation.component.scss']
})
export class BikeEquipmentPreparationComponent implements OnInit {

  public userType = SharedUtils.getCurrentUserType();
  public clientOptions: Option[] = [{ value: '', label: 'products.bikeEquipmentPreparation.all' }];
  public rentalPointOptions: Option[] = [{ value: '', label: 'All' }];
  public selectedClientId = '';
  public selectedRentalPointAccountId = '';
  public startDate: string;
  public endDate: string;
  public loading = true;
  public hideResultContainer = true;
  public bikePreparation: BikePreparation = new BikePreparation();
  public currentLang: string;

  private _currentUserAccountId = this.userType.accountId;
  private _clients: Client[] = [];

  constructor(private _clientsService: ClientsService, private _snackbar: MatSnackBar, private _productsService: ProductsService,
    private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    if (this.userType.userTypeNumber === 1) {
      this.selectedClientId = this.userType.clientId;
    } else {
      if (this.userType.userTypeNumber === 2) {
        this.selectedRentalPointAccountId = this._currentUserAccountId;
      }
    }
    forkJoin([
      this._clientsService.getClients()
    ]).subscribe(result => {
      this._clients = result[0];
      this._clients.forEach(client => {
        this.clientOptions.push({ value: client.id, label: client.businessName });
      });
      if (this.userType.userTypeNumber === 1) {
        this.onClientSelectChange();
      }
    });
  }

  onClientSelectChange() {
    this.selectedRentalPointAccountId = '';
    this.rentalPointOptions = [{ value: 0, label: this._translate.instant('products.bikeEquipmentPreparation.all') }];
    if (this.selectedClientId.toString() !== '0') {
      const selectedClient = this._clients.filter(client => client.id.toString() === this.selectedClientId.toString())[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onFilterClick() {
    this.loading = true;
    if (this.userType.userTypeNumber !== 2) {
      if (this.userType.userTypeNumber !== 1) {
        if (this.selectedClientId === '') {
          this._snackbar.open(this._translate.instant('products.bikeEquipmentPreparation.pleaseSelectAClient'), '', {
            duration: 2500
          });
          return;
        }
      }
      if (this.startDate === undefined) {
        this._snackbar.open(this._translate.instant('products.bikeEquipmentPreparation.pleaseSelectAStartDate'), '', {
          duration: 2500
        });
        return;
      }
    }
    this.hideResultContainer = false;
    this._productsService.getPrepareBikes(this.startDate, this.selectedClientId, this.endDate,
      this.selectedRentalPointAccountId.toString() === '' || this.selectedRentalPointAccountId.toString() === '0' ? undefined :
        this.selectedRentalPointAccountId.toString()).subscribe(result => {
          console.log(result);
          this.bikePreparation = result;
          this.loading = false;
        });
  }

  onRentalPreparedValueChange(value: any) {
    console.log(value);
    this.bikePreparation.rentalPoint.forEach(rentalPoint => {
      const rental = rentalPoint.onlineBooking.filter(booking => booking.contractId === value.contractId)[0];
      if (rental) {
        console.log(rental);
        rental.prepared = value.prepared;
      }
    });
  }

}
