<div class="container">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <form [formGroup]="updateAccountFormGorup" (ngSubmit)="updateAccountSubmit()">
            <div class="form-group">
              <label>{{'profile.updateProfile.userName' | translate}}*</label>
              <input type="text" name="username" id="username" formControlName="username" required
                placeholder="{{'profile.updateProfile.userName' | translate}}" class="form-control {{errorArray['username'] ? 'is-invalid' : ''}}" />
              <div class="invalid-feedback">
                {{errorArray['username']}}
              </div>
            </div>
            <div class="form-group">
              <label>{{'profile.updateProfile.email' | translate}}*</label>
              <input type="email" name="email" id="email" formControlName="email" required placeholder="{{'profile.updateProfile.email' | translate}}"
                class="form-control {{errorArray['email'] ? 'is-invalid' : ''}}" />
              <div class="invalid-feedback">
                {{errorArray['email']}}
              </div>
            </div>
            <div class="form-group">
              <label>{{'profile.updateProfile.firstName' | translate}}*</label>
              <input type="text" name="firstName" id="firstName" formControlName="firstName" required
                placeholder="{{'profile.updateProfile.firstName' | translate}}" class="form-control {{errorArray['firstName'] ? 'is-invalid' : ''}}" />
              <div class="invalid-feedback">
                {{errorArray['firstName']}}
              </div>
            </div>
            <div class="form-group">
              <label>{{'profile.updateProfile.lastName' | translate}}*</label>
              <input type="text" name="lastName" id="lastName" formControlName="lastName" required
                placeholder="{{'profile.updateProfile.lastName' | translate}}" class="form-control {{errorArray['lastName'] ? 'is-invalid' : ''}}" />
              <div class="invalid-feedback">
                {{errorArray['lastName']}}
              </div>
            </div>
            <div class="form-group">
              <label>{{'profile.updateProfile.newPassword' | translate}}</label>
              <input type="password" name="newPassword" id="newPassword" formControlName="newPassword"
                placeholder="{{'profile.updateProfile.newPassword' | translate}}" class="form-control {{errorArray['newPassword'] ? 'is-invalid' : ''}}" />
              <div class="invalid-feedback">
                {{errorArray['newPassword']}}
              </div>
            </div>
            <div class="form-group text-right">
              <button type="submit" class="btn btn-sm btn-primary">{{'profile.updateProfile.submit' | translate}}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>