import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketsService } from '../shared/tickets.service';
import { Ticket } from '../../../models/Ticket';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {

  public ticket: Ticket = new Ticket();

  public enviroment = environment;

  constructor(private _routes: ActivatedRoute,
    private _ticketsService: TicketsService) { }

  ngOnInit() {
    this._routes.params.subscribe(params => {
      this._ticketsService.getTicket(params['ticketId']).subscribe(result => {
        console.log(result);
        this.ticket = result;
      });
    });
  }

}
