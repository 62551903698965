<div class="container-fluid">
  <div class="row">
      <div class="col">
          <div class="card">
              <div class="card-body">
                  <div class="row">
                      <div class="col-2">
                          <div class="form-group">
                              <label class="control-label">{{'products.bikesByQrCode.qrCode' | translate}}</label>
                              <input type="text" class="form-control form-control-sm"matInput [formControl]="qrCodeFormControl" />
                            </div>
                      </div>
                      <div class="col-1">
                          <button type="button" class="btn btn-primary btn-sm" (click)="onFilterClick()"
                            style="margin-top: 25px;">{{'products.bikeStatistics.filter' |
                              translate}}</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="row mt-3">
      <div class="col-6" *ngIf="showData">
        <br />{{'products.bikesByQrCode.bike' | translate}}: <b>{{bike}}</b>
        <br />{{'products.bikesByQrCode.bikeBrand' | translate}}: <b>{{bikeBrand}}</b>
        <br />{{'products.bikesByQrCode.bikeColor' | translate}}: <b>{{bikeColor}}</b>
        <br />{{'products.bikesByQrCode.qrCode' | translate}}: <b>{{qrCode}}</b>
        <br />{{'products.bikesByQrCode.rentalPointName' | translate}}: <b>{{rentalPointName}}</b>
        <br />{{'products.bikesByQrCode.status' | translate}}: <b>{{status}}</b>
      </div>
      <div class="col-6" *ngIf="showError">
        <b>{{'products.bikesByQrCode.errorNotFound' | translate}}</b>
      </div>
  </div>
</div>
