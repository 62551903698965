import { Account } from './Account';
import { RentalPointTranslations } from './RentalPointTranslations';
import { DiscountCard } from './DiscountCard';
import { isArray } from 'util';
import { PriceList } from './PriceList';
import { OnlineBooking } from './OnlineBooking';
import { ExtraField } from './ExtraField';

export class RentalPoint {
    public id: number;
    public city: string;
    public phone: string;
    public businessName: string;
    public region: string;
    public street: string;
    public postalCode: string;
    public clientId: number;
    public accountId: number;
    public deleted: boolean;
    public isHotel: boolean;
    public latitude: string;
    public longitude: string;
    public sorting: number;
    public country: number;
    public vat: number;
    public account: Account;
    public client: any;
    public district: any;
    public discountCards: DiscountCard[] = [];
    public priceLists: PriceList[] = [];
    public rentalPointSchedule: any;
    public rentalPointTranslation: RentalPointTranslations = new RentalPointTranslations();
    public extraField: ExtraField = new ExtraField();
    public onlineBooking?: OnlineBooking[] = [];

    public static fromDto(r): RentalPoint {
        const rentalPoint = new RentalPoint();
        if (!r) {
            return null;
        }
        rentalPoint.id = r.id;
        rentalPoint.city = r.city;
        rentalPoint.phone = r.phone;
        rentalPoint.businessName = r.businessName;
        rentalPoint.region = r.region;
        rentalPoint.street = r.street;
        rentalPoint.postalCode = r.postalCode;
        rentalPoint.clientId = r.clientId;
        rentalPoint.accountId = r.accountId;
        rentalPoint.deleted = r.deleted;
        rentalPoint.isHotel = r.isHotel;
        rentalPoint.latitude = r.latitude;
        rentalPoint.longitude = r.longitude;
        rentalPoint.sorting = r.sorting;
        rentalPoint.country = r.country;
        rentalPoint.vat = r.vat;
        rentalPoint.account = r.account;
        rentalPoint.district = r.district;
        if (isArray(r.priceLists)) {
            r.priceLists.forEach(priceList => {
                rentalPoint.priceLists.push(PriceList.fromDto(priceList));
            });
        } else {
            rentalPoint.priceLists.push(PriceList.fromDto(r.priceLists));
        }
        if (isArray(r.discountCards)) {
            r.discountCards.forEach(discountCard => {
                rentalPoint.discountCards.push(DiscountCard.fromDto(discountCard));
            });
        } else {
            rentalPoint.discountCards.push(DiscountCard.fromDto(r.discountCards));
        }
        rentalPoint.rentalPointTranslation = RentalPointTranslations.fromDto(r.rentalPointTranslation);
        if (r.onlineBooking) {
            r.onlineBooking.forEach(onlineBooking => {
                rentalPoint.onlineBooking.push(OnlineBooking.fromDto(onlineBooking));
            });
        }
        rentalPoint.extraField = ExtraField.fromDto(r.extraField);

        return rentalPoint;
    }
}
