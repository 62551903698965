export class CompanyInvoice {
  public companyName: string;
  public vatNumber: string;
  public taxNumber?: string;
  public streetName: string;
  public streetNumber: string;
  public postalCode: string;
  public village: string;
  public country: string;

  public static fromJson(json): CompanyInvoice {
    const res = new CompanyInvoice();
    if (!json) {
      return undefined;
    }

    res.companyName = json.companyName;
    res.vatNumber = json.vatNumber;
    res.taxNumber = json.taxNumber;
    res.streetName = json.streetName;
    res.streetNumber = json.streetNumber;
    res.postalCode = json.postalCode;
    res.village = json.village;
    res.country = json.country;
    return res;
  }

}
