import { DefectedProduct } from './DefectedProduct';
import { Defect } from './Defect';

export class DefectType {
    public id: number;
    public name: string;
    public itemOrder: number;
    public deleted: boolean;
    public defect: Defect;
    public defectedProducts: DefectedProduct[];

    public static formDto(d): DefectType {
        const defectType = new DefectType();
        if (!d) {
            return null;
        }

        defectType.id = d.id;
        defectType.name = d.name;
        defectType.itemOrder = d.itemOrder;
        defectType.deleted = d.deleted;
        defectType.defect = Defect.formDto(d.defect);
        if (d.defectedProducts) {
            defectType.defectedProducts = d.defectedProducts.map(defectedProduct => DefectedProduct.fromDto(defectedProduct));
        }

        return defectType;
    }
}
