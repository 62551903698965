<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body" *ngIf="soldCheckListBike">
          <p class="title">{{ 'products.soldBikeDetail.general'| translate}}</p>
          <div class="row">
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.createdAt'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.createdAt | date:'dd/MM/yyyy HH:mm:ss'}}</div>
              </div>
            </div>
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.accountName'| translate}}:</div>
                <div class="data col-8">{{soldCheckListBike.accountName}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.bikeType'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.bikeType.name}}</div>
              </div>
            </div>
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.serialNumber'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.serialNumber}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.qrCode'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.qrCode}}</div>
              </div>
            </div>
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.keyNumber'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.keyNumber}}</div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.frameId'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.frameId}}</div>
              </div>
            </div>
            <div class="form-group col-6">
              <div class="row">
                <div class="label col-4">{{'products.soldBikeDetail.comment'| translate}}</div>
                <div class="data col-8">{{soldCheckListBike.comment}}</div>
              </div>
            </div>
          </div>
          <p class="title">{{'products.soldBikeDetail.tasks'| translate}}</p>
          <div class="row">
            <div class="form-group col-4" *ngFor="let task of soldCheckListBike.tasks">
              <div class="row">
                <div class="label col-9">
                  <div>{{task.name}}</div>
                </div>
                <div class="data col-3">
                  <div>{{task.value}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
