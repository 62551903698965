export class ProductSearchCritertia {
    public bikeTypeId?: string;
    public brandNameId?: string;
    public bikeModelId?: string;
    public bikeSizeId?: string;
    public tyreSizeId?: string;
    public qrCode?: string;
    public serialNumber?: string;
    public status?: string;
    public clientId?: string;
    public rentalPointAccountId?: string;
    public frameId?: string;
    public keyNumber?: string;
    public sortBy?: string;
    public orderBy?: string;
    public lang?: string;
}
