<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
                      [pageSize]="20" (onReset)="onTableReset()">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.contractId' | translate}}"
               [(model)]="filters.contractId" placeholder="{{'orders.bookingReservationOrders.contractId' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'statements.invoicesList.invoiceNumber' | translate}}"
              [(model)]="filters.invoiceNumber" placeholder="{{'statements.invoicesList.invoiceNumber' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'statements.cashflow.clientName' | translate}}"
               [(model)]="filters.invoiceName" placeholder="{{'statements.cashflow.clientName' | translate}}">
            </app-data-filter-input>
            <app-data-filter-datepicker label="{{'orders.invoice.date' | translate}}"
                [(model)]="filters.invoiceDate">
            </app-data-filter-datepicker>
            <app-data-filter-select label="{{'servicePoints.addServicePoint.country' | translate}}"
              [(model)]="filters.country" [options]="countriesOptions">
            </app-data-filter-select>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-contractId="contractId">
        {{contractId}}
      </ng-template>

      <ng-template #name let-name="name">
          {{name}}
      </ng-template>

      <ng-template #rentalPoint let-rentalPoint="rentalPoint">
        {{rentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #invoiceDate let-invoiceDate="invoiceDate">
        {{invoiceDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #amount let-amount="amount">
        {{amount | currency: '€ '}}
      </ng-template>

      <ng-template #invoiceNumber let-invoiceNumber="invoiceNumber" let-cancelledInvoiceNumber="cancelledInvoiceNumber">
        {{invoiceNumber}}<br>
        {{cancelledInvoiceNumber}}
      </ng-template>

      <ng-template #type let-type="type">
        {{ type | invoiceTypeStatus | translate }}
      </ng-template>

      <ng-template #actions let-invoiceLink="invoiceLink" let-cancelledInvoiceLink="cancelledInvoiceLink"
           let-canRefund="canRefund" let-amount="amount" let-name="name" let-cid="contractId">
        <div class="d-flex flex-column">
            <a *ngIf="invoiceLink" [href]="invoiceLink" download target="_blank"
               title="{{'statements.cashflow.showInvoice' | translate}}">
              <i class="fa fa-print mr-2"></i>{{ 'orders.ordersDetails.invoice' | translate }}
            </a>
            <a *ngIf="cancelledInvoiceLink" [href]="cancelledInvoiceLink" download target="_blank"
               title="{{'statements.cashflow.showCreditNote' | translate}}">
              <i class="fa fa-newspaper-o mr-2"></i>{{ 'orders.credits.creditNote' | translate }}
            </a>
            <a *ngIf="canRefund === true" href="javascript:void(0)" title="{{'statements.cashflow.doRefund' | translate}}"
              (click)="openRefundModal(contentModalRefund, cid, name, amount)">
              <i class="fa fa-money mr-2"></i>{{'statements.cashflow.doRefund' | translate}}
            </a>
        </div>
      </ng-template>
      <ng-template #contentModalRefund let-modal>
        <div class="modal-header">
          <h4 class="modal-title" id="modal-refund">
            {{'statements.cashflow.refund' | translate}}</h4>
          <button type="button" class="close" aria-describedby="modal-title" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p><strong>{{refundMsg1}}</strong></p>
          <p>{{'statements.cashflow.refundMsg2' | translate}}<br>
            <span class="text-danger bold">{{'orders.bookingReservationOrders.message4' | translate}}</span>
          </p>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-secondary"
                  (click)="modal.dismiss()">{{'orders.bookingReservationOrders.cancel' | translate}}</button>
          <button type="button" class="btn btn-danger"
                  (click)="modal.close()">{{'orders.bookingReservationOrders.ok' | translate}}</button>
        </div>
      </ng-template>

    </div>
  </div>
</div>
