import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { ServicePointsSearchCriteria } from '../shared/ServicePointsSearchCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { ServicePointService } from '../shared/service-points.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-service-points-list',
  templateUrl: './service-points-list.component.html',
  styleUrls: ['./service-points-list.component.scss']
})
export class ServicePointsListComponent implements OnInit {


  private _message: string;
  private _selectedId: number;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: ServicePointsSearchCriteria = {};

  @ViewChild('id', { static: true })
  id: TemplateRef<any>;

  @ViewChild('en', { static: true })
  en: TemplateRef<any>;

  @ViewChild('it', { static: true })
  it: TemplateRef<any>;

  @ViewChild('de', { static: true })
  de: TemplateRef<any>;

  @ViewChild('clientRentalPointsAmount', { static: true })
  clientRentalPointsAmount: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _servicePointService: ServicePointService, public _snackBar: MatSnackBar, private _modalService: NgbModal,
    private _translate: TranslateService) {
    this.loadPage = (page: number, pageSize: number) => this._servicePointService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'id',
        displayed: true,
        translationKey: 'servicePoints.servicePointsList.id',
        template: this.id,
        sortable: true
      },
      {
        name: 'en',
        displayed: true,
        translationKey: 'servicePoints.servicePointsList.en',
        template: this.en,
        sortable: true
      },
      {
        name: 'it',
        displayed: true,
        translationKey: 'servicePoints.servicePointsList.it',
        template: this.it,
        sortable: true
      },
      {
        name: 'de',
        displayed: true,
        translationKey: 'servicePoints.servicePointsList.de',
        template: this.de,
        sortable: true
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        trAddidionalClass: 'text-right',
        additionalClass: 'text-right'
      },
    ];

    this._message = this._translate.instant('servicePoints.servicePointsList.servicePointDeleted');
  }

  servicePointDelete() {
    this._modalService.dismissAll();
    if (this._selectedId) {
      this._servicePointService.deleteServicePoint(this._selectedId).subscribe(() => {
        this.loadPage = (page: number, pageSize: number) => this._servicePointService.search(page, pageSize, this.filters);
        this._snackBar.open(this._translate.instant('servicePoints.servicePointsList.servicePointDeleted'), '', {
          duration: 2000,
        });
      });
    }
  }

  deleteSelectedConfirm(content, id) {
    this._selectedId = id;
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((result) => {});
  }

}
