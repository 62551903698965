<div class="container-fluid">
  <div class="row">
    <div class="col text-right">
      <a href="/clients/add" class="btn btn-sm btn-primary">{{'clients.clientsList.addClient' | translate}}</a>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="15">
        <app-data-filters #dataFilters>
          <app-data-filter-group>
            <app-data-filter-input label="{{'clients.clientsList.clientName' | translate}}"
              [(model)]="filters.clientName" placeholder="{{'clients.clientsList.clientName' | translate}}">
            </app-data-filter-input>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>
      <ng-template #clientId let-id="id">
        {{id}}
      </ng-template>
      <ng-template #clientName let-businessName="businessName">
        {{businessName}}
      </ng-template>

      <ng-template #clientAccountUsername let-account="account">
        {{account.username}}
      </ng-template>

      <ng-template #clientRentalPointsAmount let-rentalPoints="rentalPoints">
        {{rentalPoints.length}}
      </ng-template>

      <ng-template #actions let-id="id">
        <div class="btn-group" role="group">
          <a href="/clients/edit/{{id}}" class="btn btn-primary btn-sm">{{'clients.clientsList.edit' | translate}}</a>
          <a href="/clients/details/{{id}}"
            class="btn btn-primary btn-sm">{{'clients.clientsList.details' | translate}}</a>
        </div>
      </ng-template>
    </div>
  </div>
</div>
