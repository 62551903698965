import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { OrdersByQrCodeSearchCriteria } from '../shared/OrdersByQrCodeSearchCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { OrdersService } from '../shared/orders.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-orders-by-qr-code',
  templateUrl: './orders-by-qr-code.component.html',
  styleUrls: ['./orders-by-qr-code.component.scss']
})
export class OrdersByQrCodeComponent implements OnInit {

  public filters: OrdersByQrCodeSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('returnRentalPoint', { static: true })
  returnRentalPoint: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('returnDate', { static: true })
  returnDate: TemplateRef<any>;

  @ViewChild('orderAmount', { static: true })
  orderAmount: TemplateRef<any>;

  @ViewChild('orderType', { static: true })
  orderType: TemplateRef<any>;

  @ViewChild('orderStatus', { static: true })
  orderStatus: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  constructor(private _ordersService: OrdersService, private _translate: TranslateService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
    this.loadPage = (page: number, pageSize: number) => this._ordersService.searchOrdersByQrCode(page, pageSize, this.filters);

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.searchByQrCode.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.searchByQrCode.clientName',
        template: this.clientName,
        sortable: true,
        excel: {
          objectRef: ['clientInformation.firstName', 'clientInformation.lastName']
        }
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.searchByQrCode.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['startRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'returnRentalPoint',
        displayed: true,
        translationKey: 'orders.searchByQrCode.returnRentalPoint',
        template: this.returnRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['endRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.searchByQrCode.startRentalDate',
        template: this.startDate,
        sortable: true,
        excel: {
          objectRef: ['startDate'],
          pipe: 'date'
        }
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'orders.searchByQrCode.returnRentalDate',
        template: this.returnDate,
        sortable: true,
        excel: {
          objectRef: ['endDate'],
          pipe: 'date'
        }
      },
      {
        name: 'orderAmount',
        displayed: true,
        translationKey: 'orders.searchByQrCode.orderAmount',
        template: this.orderAmount,
        sortable: true,
        excel: {
          objectRef: ['discountedPrice'],
          pipe: 'currency'
        }
      },
      {
        name: 'orderType',
        displayed: true,
        translationKey: 'orders.searchByQrCode.orderType',
        template: this.orderType,
        sortable: true,
        excel: {
          objectRef: ['orderType'],
          pipe: 'orderType'
        }
      },
      {
        name: 'orderStatus',
        displayed: true,
        translationKey: 'orders.searchByQrCode.orderStatus',
        template: this.orderStatus,
        sortable: true,
        excel: {
          objectRef: ['status'],
          pipe: 'orderStatus',
          pipeAdditionalValues: ['endDate']
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
  }

  onTableReset() {

  }
}
