<!-- <app-header-auth title="Client Credits"></app-header-auth> -->
<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="15">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-input label="{{'orders.credits.contractId' | translate}}" [(model)]="filters.id"
              placeholder="{{'orders.credits.contractId' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'orders.credits.creditCode' | translate}}" [(model)]="filters.code"
              placeholder="{{'orders.credits.creditCode' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'orders.credits.clientName' | translate}}" [(model)]="filters.clientName"
              placeholder="{{'orders.credits.clientName' | translate}}">
            </app-data-filter-input>
            <app-data-filter-datepicker label="{{'orders.credits.createdFrom' | translate}}"
              [(model)]="filters.createdFrom"></app-data-filter-datepicker>
            <app-data-filter-datepicker label="{{'orders.credits.createdTo' | translate}}"
              [(model)]="filters.createdTo"></app-data-filter-datepicker>
            <app-data-filter-datepicker label="{{'orders.credits.consumedFrom' | translate}}"
              [(model)]="filters.consumedFrom"></app-data-filter-datepicker>
            <app-data-filter-datepicker label="{{'orders.credits.consumedTo' | translate}}"
              [(model)]="filters.consumedTo"></app-data-filter-datepicker>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-creditId="creditId">
        {{creditId}}
      </ng-template>

      <ng-template #creditCode let-creditCode="creditCode">
        {{creditCode}}
      </ng-template>

      <ng-template #creditTitle let-creditTitle="creditTitle">
        {{creditTitle}}
      </ng-template>

      <ng-template #createdDate let-createdDate="createdDate">
        {{createdDate | date:'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #consumedDate let-consumedDate="consumedDate">
        {{consumedDate | date:'dd-MM-yyyy'  }}
      </ng-template>

      <ng-template #amount let-amount="amount">
        {{amount | currency:'€ '}}
      </ng-template>

      <ng-template #consumed let-consumed="consumed">
        {{consumed | currency:'€ '}}
      </ng-template>

      <ng-template #remaining let-remaining="remaining">
        {{remaining | currency:'€ '}}
      </ng-template>

      <ng-template #comment let-comment="comment">
        {{comment}}
      </ng-template>

      <ng-template #expiredDate let-expiredDate="expiredDate">
        {{expiredDate | date:'dd-MM-yyyy'  }}
      </ng-template>

      <ng-template #status let-status="status">
        {{status | creditsStatus | translate}}
      </ng-template>

      <ng-template #actions let-creditCode="creditCode">
        <a href="/orders/credit-paper/{{creditCode}}" target="_blank" class="btn btn-sm btn-primary"><i
            class="fa fa-print"></i></a>
      </ng-template>
    </div>
  </div>
</div>