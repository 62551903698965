<div class="container" style="width: 1140px !important; max-width: 1140px !important;">
  <div class="row">
    <div class="col-12">
      <div class="card m-1">
        <div class="card-body p-0">
          <div class="row p-3">
            <div class="col-md-6">
              <img [src]="'assets/images/papin-rent-a-bike-logo.png'" height="100">
            </div>
            <div class="col-md-6 text-right">
              <p class="font-weight-bold mb-1">{{order.contractId}}</p>
              <p class="text-muted">{{currentDate | date: 'dd/MM/yyyy'}}</p>
            </div>
          </div>
          <hr class="my-3">
          <div class="row p-3">
            <div class="col-md-6">
              <p class="font-weight-bold mb-4">{{'orders.orderForm.clientInformations' | translate}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.name' | translate}}</span>:
                {{order.clientInformation.firstName}}
                {{(order.clientInformation.lastName ? order.clientInformation.lastName : '')}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.address' | translate}}</span>:
                {{order.clientInformation.address}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.phoneNumber' | translate}}</span>:
                {{order.clientInformation.phone}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.emailAddress' | translate}}</span>:
                {{order.clientInformation.email}}</p>
            </div>
            <div class="col-md-6 text-right">
              <p class="font-weight-bold mb-4">{{'orders.orderForm.orderDetails' | translate}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.pickUpDate' | translate}}</span>:
                {{order.startDate | date: 'dd/MM/yyyy'}}
              </p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.returnDate' | translate}}</span>:
                {{order.endDate | date: 'dd/MM/yyyy'}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.from' | translate}}</span>:
                {{order.startRentalPoint.rentalPoint.rentalPointTranslation[order.language]}}</p>
              <p class="mb-1"><span class="text-muted">{{'orders.orderForm.to' | translate}}</span>:
                {{order.endRentalPoint.rentalPoint.rentalPointTranslation[order.language]}}</p>
            </div>
          </div>
          <div class="row p-5">
            <div class="col-md-12">
              <table class="table text-center">
                <thead>
                  <tr>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      {{'orders.orderForm.bikeType' | translate}}</th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.quantity' | translate}}</span></th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.size' | translate}}</span></th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.price' | translate}}</span></th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.rentalDays' | translate}}</span></th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.fullPrice' | translate}}</span></th>
                    <th class="border-0 text-uppercase small font-weight-bold">
                      <span>{{'orders.orderForm.discountedPrice' | translate}}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let bike of order.invoiceBike">
                    <tr>
                      <td>{{bike.bikeType.bikeTypeTranslation[order.language]}}</td>
                      <td>{{bike.quantity}}</td>
                      <td>{{bike.height}}</td>
                      <td>{{bike.bikePrice | currency : "€ "}}</td>
                      <td>{{daysDiff}}</td>
                      <td>{{(bike.quantity * bike.bikePrice * daysDiff)   | currency : "€ "}}</td>
                      <td>{{bike.price | currency : "€ "}}</td>
                    </tr>
                    <tr *ngIf="bike.diffRentalCost !== 0">
                      <td>{{'orders.orderForm.differentRental' | translate}}</td>
                      <td>1</td>
                      <td></td>
                      <td>{{bike.diffRentalCost | currency : "€ "}}</td>
                      <td></td>
                      <td>{{bike.diffRentalCost | currency : "€ "}}</td>
                      <td>{{bike.diffRentalCost | currency : "€ "}}</td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
          <div class="bg-dark p-4">
            <div class="row">
              <div class="col-6 py-3 px-5">
                <ng-container *ngIf="paymentEnv; else resultTemplate">
                  <form action="{{enviroment.domain}}order-confirm/?contractId={{order.contractId}}"
                    class="paymentWidgets">VISA MASTER AMEX</form>
                </ng-container>
                <ng-template #resultTemplate>
                  <ng-container *ngIf="confirmationResult.status === 200; else errorTemplate">
                    <div class="text-white text-center">
                      <h3><span>{{'orders.orderForm.finalMessage1' | translate}}</span> <br /><span
                          class="blue-color">{{order.contractId}}</span>
                        <span>{{'orders.orderForm.finalMessage2' | translate}}</span></h3>
                      <br />
                      <p>{{'orders.orderForm.finalMessage4' | translate}}</p>
                      <p><span>{{'orders.orderForm.finalMessage5' | translate}} </span> <strong
                          class="blue-color text-uppercase"><a target="_blank"
                            [routerLink]="['/orders/invoice/', order.contractId]"><span>{{'orders.orderForm.here' | translate}} </span></a></strong> <span
                          translate>{{'orders.orderForm.finalMessage6' | translate}}</span></p>
                      <br />
                      <p>{{'orders.orderForm.finalMessage7' | translate}}</p>
                      <br />
                      <p><span>{{'orders.orderForm.finalMessage8' | translate}} </span><a
                          href="mailto:order@papinsport.com">order&#64;papinsport.com</a></p>
                    </div>
                  </ng-container>
                  <ng-template #errorTemplate>
                    <div class="text-white text-center">
                      <h2>{{'orders.orderForm.finalMessageNoSuccess1' | translate}}</h2>
                      <p><span>{{'orders.orderForm.finalMessageNoSuccess2' | translate}}</span> {{order.contractId}}
                        <span>{{'orders.orderForm.finalMessageNoSuccess3' | translate}}</span></p>
                      <p>{{'orders.orderForm.finalMessageNoSuccess4' | translate}}</p>
                      <p>{{confirmationResult.message}}</p>
                    </div>
                  </ng-template>
                </ng-template>
              </div>
              <div class="col-3 py-3 px-5 text-white text-right">
                <div class="mb-2"><span>{{'orders.orderForm.totalPrice' | translate}}</span></div>
                <div class="h2 font-weight-light">{{order.totalPrice | currency : "€ "}}</div>
              </div>
              <div class="col-3 py-3 px-5 text-white text-right">
                <div class="mb-2"><span>{{'orders.orderForm.discountedPrice' | translate}}</span></div>
                <div class="h2 font-weight-light">{{order.discountedPrice | currency : "€ "}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
