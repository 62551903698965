import { OnlineBooking } from './OnlineBooking';

export class ClientInformation {
    public id: number;
    public firstName: string;
    public lastName: string;
    public address: string;
    public postalCode: string;
    public phone: string;
    public email: string;
    public marketingUsage: boolean;
    public comment: string;
    public country: number;
    public onlineBooking: OnlineBooking;
    public bookedRental: any;
    public directRental: any;

    public static fromDto(c): ClientInformation {
        const clientInformation = new ClientInformation();
        if (!c) {
            return null;
        }
        clientInformation.id = c.id;
        clientInformation.firstName = c.firstName;
        clientInformation.lastName = c.lastName;
        clientInformation.address = c.address;
        clientInformation.postalCode = c.postalCode;
        clientInformation.phone = c.phone;
        clientInformation.email = c.email;
        clientInformation.marketingUsage = c.marketingUsage;
        clientInformation.comment = c.comment;
        clientInformation.country = c.country;
        clientInformation.onlineBooking = c.onlineBooking;
        clientInformation.bookedRental = c.bookedRental;
        clientInformation.directRental = c.directRental;

        return clientInformation;
    }
}
