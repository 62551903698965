import { Routes } from '@angular/router';
import { TicketsComponent } from './tickets.component';
import { AuthGuard } from '../../auth-guard.service';
import { TicketsListComponent } from './tickets-list/tickets-list.component';
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';

export const TICKETS_ROUTES: Routes = [
    {
        path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard], children: [
            { path: 'list', component: TicketsListComponent, canActivate: [AuthGuard] },
            { path: 'details/:ticketId', component: TicketDetailsComponent, canActivate: [AuthGuard] }
        ]
    }
];
