import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ClientSearchCritertia } from './ClientSearchCriteria';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { Client } from '../../../models/Client';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private _http: HttpClient) { }

  public search(page: number, size: number, criteria: ClientSearchCritertia): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchClients', { headers, params }).pipe(
      map((res: any) => {
        return SearchObjectResult.fromDto(res, Client.fromDto);
      })
    );
  }

  /**
   * getClient
   */
  public getClient(clientId: string, accountId?: string): Observable<Client> {
    const params = (accountId ? { accountId } : { clientId });
    return this._http.get(environment.serverUrl + 'api/getClient', {
      headers: HttpUtils.createHeaders(), params
    }).pipe(
      map((result: any[]) => Client.fromDto(result))
    );
  }

  /**
   * getClients
   */
  public getClients(): Observable<Client[]> {
    return this._http.get(environment.serverUrl + 'api/getClients', {
      headers: HttpUtils.createHeaders()
    }).pipe(
      map((result: any[]) => result.map(res => Client.fromDto(res)))
    );
  }


  /**
   * updateClient
   */
  public updateClient(client: Client): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/updateClient', { account: client }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }

  /**
   * addClient
   */
  public addClient(client: Client): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/addClient', { account: client }, { headers }).pipe(
      map((res: any) => {
        return res;
      }));
  }
}
