import { BikeType } from './BikeType';

export class Bike {
  constructor(
    public id?: number,
    public bikeTypeId?: number,
    public bikeBrandId?: number,
    public bikeModelId?: number,
    public bikeSizeId?: number,
    public bikeTyreSizeId?: number,
    public year?: number,
    public bikeColorId?: number,
    public location?: number,
    public keyNumber?: string,
    public serialNumber?: string,
    public frameId?: string,
    public price?: number,
    public quantity?: number,
    public bikeType?: BikeType) {
  }
}
