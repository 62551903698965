<div class="container">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="rentalPointForm" (ngSubmit)="addRentalPoint()" class="container-fluid">
            <fieldset formGroupName="rentalPoint">
              <fieldset formGroupName="rentalPointTranslation">
                <div class="row">
                  <div class="form-group col">
                    <label for="name-de">{{'rentalPoint.rentalPointAdd.name' | translate }}<span> DE*</span></label>
                    <input type="text" name="name-de" id="name-de" formControlName="de" required placeholder="Name"
                      class="form-control {{errorArray['de'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['de']}}
                    </div>
                  </div>
                  <div class="form-group col">
                    <label for="name-en">{{'rentalPoint.rentalPointAdd.name' | translate }}<span> EN*</span></label>
                    <input type="text" name="name-en" id="name-en" formControlName="en" placeholder="Name" required
                      class="form-control {{errorArray['en'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['en']}}
                    </div>
                  </div>
                  <div class="form-group col">
                    <label for="name-it">{{'rentalPoint.rentalPointAdd.name' | translate }}<span> IT*</span></label>
                    <input type="text" name="name-it" id="name-it" formControlName="it" placeholder="Name" required
                      class="form-control {{errorArray['it'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['it']}}
                    </div>
                  </div>
                </div>
              </fieldset>
              <hr />
              <div class="row">
                <div class="form-group col-3">
                  <label for="region">{{'rentalPoint.rentalPointAdd.region' | translate }}</label>
                  <input type="text" name="region" id="region" formControlName="region" placeholder="Region"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="address">{{'rentalPoint.rentalPointAdd.address' | translate }}</label>
                  <input type="text" id="address" name="address" formControlName="street" placeholder="Address"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="district">{{'rentalPoint.rentalPointAdd.district' | translate }}<span>*</span></label>
                  <select name="district" id="district" formControlName="district"
                    class="form-control {{errorArray['district'] ? 'is-invalid' : ''}}" required>
                    <option *ngFor="let district of districts" value="{{district.id}}">{{district.name}}</option>
                  </select>
                  <div class="invalid-feedback">
                    {{errorArray['district']}}
                  </div>
                </div>
                <div class="form-group col-3">
                  <label for="country">{{'rentalPoint.rentalPointAdd.country' | translate }}<span>*</span></label>
                  <select name="country" id="country" formControlName="country" class="form-control" required
                    (ngModelChange)="onCountrySelectChange($event)">
                    <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
                  </select>
                </div>
                <div class="form-group col-3">
                  <label for="town">{{'rentalPoint.rentalPointAdd.town' | translate }}</label>
                  <input type="text" name="town" id="town" formControlName="city" placeholder="Town"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="phone">{{'rentalPoint.rentalPointAdd.phoneNumber' | translate }}</label>
                  <input type="text" name="phone" id="phone" formControlName="phone" placeholder="Phone"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="latitude">{{'rentalPoint.rentalPointAdd.latitude' | translate }}</label>
                  <input type="text" name="latitude" id="latitude" formControlName="latitude" placeholder="Latitude"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="longitude">{{'rentalPoint.rentalPointAdd.longitude' | translate }}</label>
                  <input type="text" name="longitude" id="longitude" formControlName="longitude" placeholder="Longitude"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="postalCode">{{'rentalPoint.rentalPointAdd.postalCode' | translate }}</label>
                  <input type="text" name="postalCode" id="postalCode" class="form-control"
                         formControlName="postalCode" placeholder="{{'rentalPoint.rentalPointAdd.postalCode' | translate }}"
                  />
                </div>
                <div class="form-group col-3">
                  <label for="vat">{{'rentalPoint.rentalPointAdd.vatNumber' | translate }}<span>*</span></label>
                  <input type="text" name="vat" id="vat" formControlName="vat" placeholder="vat" class="form-control"
                    readonly />
                </div>
                <div class="form-group col-3" *ngIf="(accountType == 'admin')">
                  <label for="district">{{'rentalPoint.rentalPointAdd.priceList' | translate }}<span>*</span></label>
                  <select name="priceLists" id="priceLists" formControlName="priceLists"
                    class="form-control {{errorArray['priceLists'] ? 'is-invalid' : ''}}" required>
                    <option *ngFor="let pricelist of priceLists" [ngValue]="pricelist">{{pricelist.name}}</option>
                  </select>
                  <div class="invalid-feedback">
                    {{errorArray['priceLists']}}
                  </div>
                </div>
                <div class="form-group col-12" *ngIf="(accountType == 'admin')">
                  <label formArrayName="discountCards" style="display: block;">{{'rentalPoint.rentalPointEdit.discountCard' | translate }}</label>
                  <!-- <select name="discountCards" id="discountCards" formControlName="discountCards" class="form-control">
                    <option [ngValue]="undefined">No Discount Card</option>
                    <option *ngFor="let discountCard of discountCardsList" [ngValue]="discountCard">
                      {{discountCard.name}}
                    </option>
                  </select> -->
                  <div class="form-check form-check-inline"
                    *ngFor="let discountCard of discountCardsList; let i = index">
                    <input class="form-check-input" type="checkbox" id="inlineCheckbox{{i}}" [value]="discountCard"
                      (change)="onDiscountCardCheckBoxChange($event, discountCard)">
                    <label class="form-check-label" for="inlineCheckbox{{i}}">{{discountCard.name}}</label>
                  </div>
                </div>
              </div>
            </fieldset>
            <hr />
            <fieldset>
              <div class="row">
                <div class="form-group col-3">
                  <label for="firstName">{{'rentalPoint.rentalPointAdd.firstName' | translate }}</label>
                  <input type="text" name="firstName" id="firstName" formControlName="firstName"
                    placeholder="First name" class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="LastName">{{'rentalPoint.rentalPointAdd.lastName' | translate }}</label>
                  <input type="text" name="LastName" id="LastName" formControlName="lastName" placeholder="Last Name"
                    class="form-control" />
                </div>
              </div>
              <div class="row">
                <div class="form-group col-3">
                  <label for="username">{{'rentalPoint.rentalPointAdd.userName' | translate }}</label>*
                  <input type="text" name="username" id="username" formControlName="username" placeholder="Username"
                    required class="form-control {{errorArray['username'] ? 'is-invalid' : ''}}" />
                  <div class="invalid-feedback">
                    {{errorArray['username']}}
                  </div>
                </div>
                <div class="form-group col-3">
                  <label for="email">{{'rentalPoint.rentalPointAdd.emailAddress' | translate }}</label>
                  <input type="email" name="email" id="email" formControlName="email" placeholder="email"
                    class="form-control" />
                </div>
                <div class="form-group col-3">
                  <label for="password">{{'rentalPoint.rentalPointAdd.password' | translate }}</label>
                  <input type="text" name="password" id="password" formControlName="password" placeholder="password"
                    class="form-control" />
                </div>
              </div>

            </fieldset>
            <div class="row">
              <div class="col-md-12 text-right">
                <button type="submit" class="btn btn-primary">{{'rentalPoint.rentalPointAdd.addRentalPoint' | translate }}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
