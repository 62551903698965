<div class="container-fluid gray-color">
  <div class="row">
    <div class="col-xs-12" *ngIf="(result && result.status === 200)">
      <h1>{{'orders.orderConfirmed.messageSuccess1' | translate}} <br /><span class="blue-color">{{contractId}}</span>{{'orders.orderConfirmed.messageSuccess2' | translate}}</h1>
      <br />
      <p>{{'orders.orderConfirmed.messageSuccess3' | translate}}<strong>{{'orders.orderConfirmed.messageSuccess4 | translate'}}</strong>{{'orders.orderConfirmed.messageSuccess5' | translate}}</p>
      <p>{{'orders.orderConfirmed.messageSuccess6' | translate}}<strong class="blue-color"><a target="_blank" [href]="pdfLink">{{'orders.orderConfirmed.messageSuccess7' | translate}}</a></strong>{{'orders.orderConfirmed.messageSuccess8' | translate}}</p>
      <br />
      <p>{{'orders.orderConfirmed.messageSuccess9' | translate}}</p>
      <br/>
      <p>{{'orders.orderConfirmed.messageSuccess10' | translate}}</p>
    </div>
  </div>
</div>

<div class="confirm-order" *ngIf="(result && result.status === 400)">
  <h2 translate>{{'orders.orderConfirmed.finalMessageNoSuccess1' | translate}}</h2>
  <p>{{'orders.orderConfirmed.finalMessageNoSuccess2' | translate}}{{'orders.orderConfirmed.finalMessageNoSuccess3' | translate}}</p>
  <p>{{'orders.orderConfirmed.finalMessageNoSuccess4' | translate}}</p>
  <p>{{result.message}}</p>
</div>
