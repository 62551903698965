import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ScheduleException } from '../../../../models/ScheduleException';
import * as moment from 'moment';
import { RentalPointSchedule } from '../../../../models/RentalPointSchedule';
import { RentalPointsService } from '../rental-points.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-new-schedule-exception-form',
  templateUrl: './new-schedule-exception-form.component.html',
  styleUrls: ['./new-schedule-exception-form.component.scss']
})
export class NewScheduleExceptionFormComponent implements OnInit {
  public disableOpeningHours = false;
  public disableLunchHours = true;
  public disableLunchBreakCheckbox = false;
  public openingDaysArray = [false, false, false, false, false, false, false];
  public errorMsg: string;

  private _lunchBrake = false;

  @Input()
  scheduleException: ScheduleException;

  @Input()
  rentalPointSchedule: RentalPointSchedule;


  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onNewScheduleExceptionSubmit: EventEmitter<ScheduleException> = new EventEmitter<ScheduleException>();

  constructor(private _rentalPointsService: RentalPointsService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  lunchBreackCheckboxChange(event) {
    const checked = event.target.checked;
    this._lunchBrake = checked;
    this.scheduleException.lunchBreakStart = undefined;
    this.scheduleException.lunchBreakEnd = undefined;
    this.disableLunchHours = !checked;
  }

  closeDayCheckboxChange() {
    this.scheduleException.startHour = undefined;
    this.scheduleException.endHour = undefined;
    this.disableOpeningHours = this.scheduleException.closingException;
    this.disableLunchBreakCheckbox = this.scheduleException.closingException;
  }

  addNewScheduleException() {
    this.errorMsg = '';
    this.scheduleException.openingDays = this.openingDaysArray.map(day => (day ? '1' : '0'));
    if (!this.scheduleException.startDay || !this.scheduleException.endDay) {
      this.errorMsg = 'Please choose a start and end day.';
      return;
    }
    if (moment(this.scheduleException.startDay) > moment(this.scheduleException.endDay)) {
      this.errorMsg = 'Start Day can not be after the end Day';
      return;
    }
    if (!this.scheduleException.closingException) {
      if (!this.scheduleException.startHour || !this.scheduleException.endHour) {
        this.errorMsg = 'Please choose a start and end Hour.';
        return;
      }
    }
    if (this._lunchBrake) {
      if (!this.scheduleException.lunchBreakStart || !this.scheduleException.lunchBreakEnd) {
        this.errorMsg = 'Please choose a start and end lunch break hour.';
        return;
      }
    }
    this._rentalPointsService.addScheduleException(this.scheduleException, this.rentalPointSchedule.id).subscribe(result => {
      this.scheduleException = new ScheduleException();
      this.openingDaysArray = [false, false, false, false, false, false, false];
      this.rentalPointSchedule.scheduleExceptions.push(result);
      this._snackBar.open('Exception added successfuly', '', {
        duration: 2500
      });
    });
  }

}
