<app-header-auth></app-header-auth>
<div class="client container-form">
  <div class="headText">
    <a routerLink="/staff">
      <img src="../../../assets/images/back.png">
    </a>
    <p><span>{{'staff.staffView.manageStaff' | translate}}</span> | {{staff.firstName}} {{staff.lastName}}</p>
  </div>

  <hr class="mb-5">

  <div class="error" *ngIf="showErrors">
    <span>{{'staff.staffView.errorMessage' | translate}}</span>
  </div>
  <form (ngSubmit)="updateStaff(updateStaffForm)" #updateStaffForm="ngForm">
    <div class="container d-flex justify-content-center profile-container mb-4">
      <div class="card col-md-8">
        <div class="row">

          <div class="col-sm">
            <div class="d-flex">
              <div class="col-sm-6 mb-5">
                <label for="firstname" class="form-text"><span>{{'staff.staffView.firstName' | translate}}<span>*</span></span></label>
                <input type="text" id="firstname" name="firstName" [(ngModel)]="staff.firstName" required placeholder="First Name" class="form-control custom-form-control" />
              </div>
              <div class="col-sm-6 mb-5">
                <label for="lastname" class="form-text">{{'staff.staffView.lastName' | translate}}<span>*</span></label>
                <input type="text" id="lastname" name="lastName" [(ngModel)]="staff.lastName" required placeholder="Last Name" class="form-control custom-form-control" />
              </div>
            </div>

            <div class="d-flex">
              <div class="col-sm-6 mb-5">
                <label for="username" class="form-text"><span>{{'staff.staffView.userName' | translate}}<span>*</span></span></label>
                <input type="text" id="username" name="username" [(ngModel)]="staff.username" required placeholder="Username" class="form-control custom-form-control" />
              </div>
              <div class="col-sm-6 mb-5">
                <label for="password" class="form-text"><span translate>{{'staff.staffView.password' | translate}}</span></label>
                <input type="password" id="password" name="password" [(ngModel)]="staff.password" placeholder="Password" class="form-control custom-form-control" />
              </div>
            </div>

            <div class="d-flex">
                <div class="col-sm-12 d-flex justify-content-around align-items-center">
                  <input type="hidden" [(ngModel)]="staff.deleted" name="deleted" #deleted [value]="staff.deleted">
                  <ng-container *ngIf="accountDeactivated; else accountActivated">
                    <label [ngStyle]="{'line-height':'40px', 'height': '40px'}" class="text-muted text-left">{{'staff.staffView.accountIdDeactivated' | translate}}</label>
                    <input type="button"
                      (click)="updateStaffForm.control.markAsTouched(); onChangeActivestatus($event); staff.deleted = !staff.deleted"
                      name="activate"
                      class="btn btn-outline-success"
                      value="Activate"
                      >
                  </ng-container>
                  <ng-template #accountActivated>
                    <label [ngStyle]="{'line-height':'40px', 'height': '40px'}" class="text-muted text-left">{{'staff.staffView.accountIsActivated' | translate}}</label>
                    <input type="button"
                      (click)="updateStaffForm.control.markAsTouched(); onChangeActivestatus($event); staff.deleted = !staff.deleted"
                      name="deactivate"
                      class="btn btn-outline-danger"
                      value="Deactivate"
                    >
                  </ng-template>

                </div>

              </div>

          </div>

          <div class="col-sm mb-5">
            <div class="avatar form-group">
              <label>
                <span class="form-text mb-5"><span>{{'staff.staffView.profilePicture' | translate}}</span></span>
                <img src={user.avatar} *ngIf="staff.avatar" class="user-avatar"/>
                <div class="no-avatar" *ngIf="!staff.avatar"></div>
                <input type="file" name="avatar" id="avatar" [(ngModel)]="staff.avatar" placeholder="Avatar" class="form-control" accept="image/*"/>
              </label>
            </div>
          </div>

        </div>

        <hr>
        <div class="d-flex justify-content-center align-items-center">
          <div class="col-sm-5 d-flex justify-content-around">
            <button id="submit" class="btn btn-primary btn-round text-uppercase pl-5 pr-5 btn-staff" type="submit">{{'staff.staffView.save' | translate}}</button>
            <button id="delete" class="btn btn-danger btn-round text-uppercase pl-5 pr-5 btn-staff" type="button" (click)="deleteStaff()">{{'staff.staffView.delete' | translate}}</button>
          </div>
        </div>

      </div>
    </div>
  </form>
</div>
