import { OrderReturn } from './OrderReturn';

export class Credits {
    public id: number;
    public creditTitle: string;
    public creditId: string;
    public creditCode: string;
    public createdDate: string;
    public createdTime: string;
    public consumedDate: string;
    public consumedTime: string;
    public expiredDate: string;
    public amount: number;
    public consumed: number;
    public remaining: number;
    public comment: string;
    public status: number;
    public orderReturn: OrderReturn;

    public static fromDto(c): Credits {
        const credits = new Credits();
        if (!c) {
            return null;
        }

        credits.id = c.id;
        credits.creditTitle = c.creditTitle;
        credits.creditId = c.creditId;
        credits.creditCode = c.creditCode;
        credits.createdDate = c.createdDate;
        credits.createdTime = c.createdTime;
        credits.consumedDate = c.consumedDate;
        credits.consumedTime = c.consumedTime;
        credits.expiredDate = c.expiredDate;
        credits.amount = c.amount;
        credits.consumed = c.consumed;
        credits.remaining = c.remaining;
        credits.comment = c.comment;
        credits.status = c.status;
        if (c.orderReturn) {
            credits.orderReturn = OrderReturn.fromDto(c.orderReturn);
        }

        return credits;
    }
}
