export class IndividualInvoice {
  public firstName: string;
  public lastName: string;
  public taxNumber?: string;
  public streetName: string;
  public streetNumber: string;
  public postalCode: string;
  public village: string;
  public country: string;

  public static fromJson(json): IndividualInvoice {
    const res = new IndividualInvoice();
    if (!json) {
      return undefined;
    }

    res.firstName = json.firstName;
    res.lastName = json.lastName;
    res.taxNumber = json.taxNumber;
    res.streetName = json.streetName;
    res.streetNumber = json.streetNumber;
    res.postalCode = json.postalCode;
    res.village = json.village;
    res.country = json.country;
    return res;
  }

}
