import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { RentalPoint } from '../../../../models/RentalPoint';
import { BikeType } from '../../../../models/BikeType';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../../shared/products.service';

class BikeTypeSummery {
  public rentalPointId: string;
  public bikes: Bike[] = [];
}

class Bike {
  bikeType: BikeType;
  quantity: number;
}

@Component({
  selector: 'app-rental-point-card-item',
  templateUrl: './rental-point-card-item.component.html',
  styleUrls: ['./rental-point-card-item.component.scss']
})
export class RentalPointCardItemComponent implements OnInit, OnChanges {

  public bikeTypeSummery: BikeTypeSummery[] = [];
  public currentLang: string;

  @Input()
  rentalPoint: RentalPoint;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onPreparationChange: EventEmitter<any> = new EventEmitter();

  constructor(private _translate: TranslateService, private _productsService: ProductsService) {
    this.currentLang = this._translate.currentLang;
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.bikeTypeSummery);
    let newRentalPoint = true;
    if (changes.rentalPoint.currentValue) {
      this.currentLang = this._translate.currentLang;
      const rentalPoint = changes.rentalPoint.currentValue;
      let bikeTypeSummery = this.bikeTypeSummery.filter(summery => summery.rentalPointId === rentalPoint.id)[0];
      if (!bikeTypeSummery) {
        bikeTypeSummery = new BikeTypeSummery();
      }
      bikeTypeSummery.rentalPointId = changes.rentalPoint.currentValue.id;
      rentalPoint.onlineBooking.forEach(onlineBooking => {
        onlineBooking.invoiceBike.forEach(invoiceBike => {
          let currentBike = (!newRentalPoint ? bikeTypeSummery.bikes.filter(bike => bike.bikeType.id === invoiceBike.bikeType.id)[0] :
            undefined);
          if (!currentBike) {
            currentBike = new Bike();
            currentBike.bikeType = BikeType.fromDto(invoiceBike.bikeType);
            currentBike.quantity = invoiceBike.quantity;
            bikeTypeSummery.bikes.push(currentBike);
            newRentalPoint = false;
          } else {
            currentBike.quantity += invoiceBike.quantity;
          }
        });
      });
      this.bikeTypeSummery.push(bikeTypeSummery);
    }
  }

  onDoneCheckBoxChange(event: any, contractId: string) {
    const checkBoxValue = event.currentTarget.checked;
    this._productsService.changeOnlineOrderPreparationValue(contractId, checkBoxValue).subscribe(result => {
      this.onPreparationChange.emit({ contractId, prepared: checkBoxValue });
    });
  }

}
