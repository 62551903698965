<app-header-auth></app-header-auth>
<div class="headText">
  <a (click)="back()">
    <img src="../../../assets/images/back.png">
  </a>
  <p>{{'products.qrAssign.product' | translate}} | {{'products.qrAssign.multipleQrCodeAssign' | translate}}</p>
</div>
<hr>
<div class="container-form">
  <form (ngSubmit)="assignBike(assignBikeForm)" #assignBikeForm="ngForm">
    <div class="row">
      <div class="form-group col-md-3 col-xs-12">
        <label >{{'products.qrAssign.startQrCode' | translate}}*</label>
        <input type="text" name="startQrCode" id="startQrCode" [(ngModel)]="startQrCode" class="form-control" required="required">
      </div>
      <div class="form-group col-md-3 col-xs-12">
        <label >{{'products.qrAssign.endQrCode' | translate}}*</label>
        <input type="text" name="endQrCode" id="endQrCode" [(ngModel)]="endQrCode" class="form-control" required="required">
      </div>
      <div *ngIf="accountType != 'rentalPoint'; else rentalPoint_content" class="form-group col-md-3 col-xs-12">
        <label >{{'products.qrAssign.rentalPoints' | translate}}*</label>
        <select name="rentalpoints" [(ngModel)]="selectedRentalPoint" class="form-control" required="required">
          <option *ngFor="let rentalpoint of rentalPoints" value="{{rentalpoint.accountId}}">{{rentalpoint.businessName}}</option>
        </select>
      </div>
      <ng-template #rentalPoint_content>
        <div class="form-group col-md-3 col-xs-12">
          <label >{{'products.qrAssign.rentalPoint' | translate}}</label>
          <input type="text" name="rentalPointName" id="rentalPointName" [(ngModel)]="rentalPointName" class="form-control" disabled="disabled">
        </div>
      </ng-template>
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-xs-12">
        <label for="bikeType">{{'products.qrAssign.bikeType' | translate}}*</label>
        <select name="bikeType" id="bikeType" [(ngModel)]="bike.bikeTypeId" required class="form-control">
          <option *ngFor="let bikeType of bikeTypes" [value]="bikeType.id">{{bikeType.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3 col-xs-12">
        <label for="brandName">{{'products.qrAssign.brandName' | translate}}</label>
        <select name="brandName" id="brandName" [(ngModel)]="bike.bikeBrandId" class="form-control">
          <option value=""></option>
          <option *ngFor="let bikeBrand of bikeBrands" value="{{bikeBrand.id}}">{{bikeBrand.name}}</option>
        </select>
      </div>
      <div class="form-group col-md-3 col-xs-12">
        <label for="model">{{'products.qrAssign.model' | translate}}</label>
        <select name="model" id="model" [(ngModel)]="bike.bikeModelId" class="form-control">
          <option value=""></option>
          <option *ngFor="let bikeModel of bikeModels" value="{{bikeModel.id}}">{{bikeModel.name}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-xs-12">
        <label for="bikeSize">{{'products.qrAssign.bikeSize' | translate}}</label>
        <select name="bikeSize" id="bikeSize" [(ngModel)]="bike.bikeSizeId" class="form-control">
          <option value=""></option>
          <option *ngFor="let bikeSize of bikeSizes" value="{{bikeSize.id}}">{{bikeSize.size}}</option>
        </select>
      </div>
      <div class="form-group col-md-3 col-xs-12">
        <label for="tyreSize">{{'products.qrAssign.tyreSize' | translate}}</label>
        <select name="tyreSize" id="tyreSize" [(ngModel)]="bike.bikeTyreSizeId" class="form-control">
          <option value=""></option>
          <option *ngFor="let bikeTyreSize of bikeTyreSizes" value="{{bikeTyreSize.id}}">{{bikeTyreSize.size}}</option>
        </select>
      </div>
      <div class="form-group col-md-3 col-xs-12">
        <label for="year">{{'products.qrAssign.year' | translate}}</label>
        <select id="year" name="year" [(ngModel)]="bike.year" class="form-control">
          <option value=""></option>
          <option *ngFor="let year of years" [value]="year">{{year}}</option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-xs-12">
        <label for="color">{{'products.qrAssign.color' | translate}}</label>
        <select name="color" id="color" [(ngModel)]="bike.bikeColorId" class="form-control">
          <option value=""></option>
          <option *ngFor="let bikeTyreSize of bikeColors" value="{{bikeTyreSize.id}}">{{bikeTyreSize.name}}</option>
        </select>
      </div>

      <div class="form-group col-md-3 col-xs-12">
        <label for="keyNumber">{{'products.qrAssign.keyNumber' | translate}}</label>
        <input type="text" id="keyNumber" name="keyNumber" [(ngModel)]="bike.keyNumber" placeholder="{{'KeyNumber'|translate}}" class="form-control"/>
      </div>

      <div class="form-group col-md-3 col-xs-12">
        <label for="serialNumber">{{'products.qrAssign.serialNumber' | translate}}</label>
        <input type="text" id="serialNumber" name="serialNumber" [(ngModel)]="bike.serialNumber" placeholder="{{'SerialNumber'|translate}}" class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-3 col-xs-12">
        <label for="frameId">{{'products.qrAssign.frameId' | translate}}</label>
        <input type="text" id="frameId" name="frameId" [(ngModel)]="bike.frameId" placeholder="{{'FrameID'|translate}}" class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12 col-xs-12">
        <input type="submit" class="form-control submit-button" value="{{'products.qrAssign.assignBike' | translate}}" />
      </div>
    </div>
  </form>
</div>
