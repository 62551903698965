<div class="container">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row mb-4">
                        <div class="col-5">
                            <div style="text-align: left;">
                                <h3>{{'tickets.ticketDetails.ticketCode' | translate}} <strong>{{ticket.code}}</strong>
                                </h3>
                            </div>
                            <h3><strong *ngIf="ticket.name !== null">{{ticket.name}}</strong></h3>
                        </div>
                        <div class="col text-right">
                            <h3>{{'tickets.ticketDetails.status' | translate}}:
                                <strong>{{ticket.status | ticketStatus}}</strong></h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.createDate' | translate}}</dt>
                                <dd class="col">{{ticket.createdDate | date:'dd-MM-yyyy'}} {{ticket.createdTime}}</dd>
                            </div>
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.closedDate' | translate}}</dt>
                                <dd class="col" style="height: 18px;">{{ticket.closedDate | date:'dd-MM-yyyy'}}
                                    {{ticket.closedTime}}
                                </dd>
                            </div>
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.deleted' | translate}}</dt>
                                <dd class="col">{{(ticket.deleted === false ? 'No' : 'Yes')}}</dd>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.reporterName' | translate}}</dt>
                                <dd class="col">{{ticket.account.firstName}} {{ticket.account.lastName}}</dd>
                            </div>
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.reporterUserName' | translate}}</dt>
                                <dd class="col">{{ticket.account.username}}</dd>
                            </div>
                        </div>
                        <div class="col">
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.productId' | translate}}</dt>
                                <dd class="col">{{ticket.productId}}</dd>
                            </div>
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.productQrCode' | translate}}</dt>
                                <dd class="col">{{ticket.qrCode}}</dd>
                            </div>
                            <div class="row">
                                <dt class="col">{{'tickets.ticketDetails.productBikeType' | translate}}</dt>
                                <dd class="col">{{ticket.bikeType.bikeTypeTranslation['de']}}</dd>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <dt class="col-12">{{'tickets.ticketDetails.comment' | translate}}</dt>
                        <dd class="col-12">{{ticket.comment}}</dd>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-3">
        <div class="col">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>{{'tickets.ticketDetails.id' | translate}}</th>
                        <th>{{'tickets.ticketDetails.staff' | translate}}</th>
                        <th>{{'tickets.ticketDetails.defect' | translate}}</th>
                        <th>{{'tickets.ticketDetails.defectType' | translate}}</th>
                        <th>{{'tickets.ticketDetails.comment' | translate}}</th>
                        <th>{{'tickets.ticketDetails.status' | translate}}</th>
                        <th>{{'tickets.ticketDetails.fixDate' | translate}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let defectedProduct of ticket.defectedProducts">
                        <td>{{defectedProduct.id}}</td>
                        <ng-container *ngIf="defectedProduct.account; else emptyRowTemplate">
                            <td>{{defectedProduct.account.firstName}} {{defectedProduct.account.lastName}}</td>
                        </ng-container>
                        <td>{{defectedProduct.defectType.defect.name}}</td>
                        <ng-container *ngIf="defectedProduct.defectType.id === 35; else otherDefectTypeTemplate">
                            <td>{{'tickets.ticketDetails.defectTypeOthers' | translate}}</td>
                        </ng-container>
                        <ng-template #otherDefectTypeTemplate>
                            <td>{{defectedProduct.defectType.name}}</td>
                        </ng-template>
                        <td>{{defectedProduct.comment}}</td>
                        <td>{{defectedProduct.status | defectStatus}}</td>
                        <ng-container *ngIf="defectedProduct.fixedDate; else emptyRowTemplate">
                            <td>{{defectedProduct.fixedDate | date:'dd-MM-yyyy'}} {{defectedProduct.fixedTime}}</td>
                        </ng-container>
                        <ng-template #emptyRowTemplate>
                            <td></td>
                        </ng-template>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="row mt-3" *ngIf="ticket.images?.length > 0">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <ng-container *ngFor="let image of ticket.images">
                        <div class="col">
                            <img style="height: 250px"
                                src="{{enviroment.serverUrl}}public/maintenance/{{ticket.id}}/{{image}}"
                                class="img-thumbnail" alt="Responsive image">
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
