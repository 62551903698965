import { Routes } from '@angular/router';
import { ServicePointsComponent } from './service-points.component';
import { ServicePointsListComponent } from './service-points-list/service-points-list.component';
import { ServicePointAddComponent } from './service-point-add/service-point-add.component';
import { ServicePointEditComponent } from './service-point-edit/service-point-edit.component';
import { ServicePointDetailsComponent } from './service-point-details/service-point-details.component';
import { AuthGuard } from '../../auth-guard.service';

export const SERVICEPOINTS_ROUTES: Routes = [
    {
        path: 'service-points', component: ServicePointsComponent, canActivate: [AuthGuard], children: [
            {path: 'list', component: ServicePointsListComponent, canActivate: [AuthGuard]},
            {path: 'add', component: ServicePointAddComponent, canActivate: [AuthGuard]},
            {path: 'edit/:servicePointId', component: ServicePointEditComponent, canActivate: [AuthGuard]},
            {path: 'details/:servicePointId', component: ServicePointDetailsComponent, canActivate: [AuthGuard]}
        ]
    }
];
