import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxBarcode6Component, NgxBarcode6Module } from 'ngx-barcode6';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, itLocale, enGbLocale } from 'ngx-bootstrap/locale';
defineLocale('de', deLocale);
defineLocale('it', itLocale);
defineLocale('en', enGbLocale);

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { HeaderComponent } from './components/general/header/header.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';

// Import services and modules
import { UserService } from './services/user.service';
import { ProductService } from './services/product.service';
import { OrdersService } from './services/orders.service';
import { ExcelService } from './services/excel.service';
import { RentalPointEditComponent } from './components/rental-point/rental-point-edit/rental-point-edit.component';
import { RentalPointAddComponent } from './components/rental-point/rental-point-add/rental-point-add.component';
import { StaffComponent } from './components/staff/staff-list/staff.component';
import { StaffViewComponent } from './components/staff/staff-view/staff-view.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { StaffAddComponent } from './components/staff/staff-add/staff-add.component';
import { OrderFormComponent } from './components/orders/order-form/order-form.component';
import { OrderConfirmedComponent } from './components/orders/order-confirmed/order-confirmed.component';
import { CustomTableFilterDirective } from './directives/custom-table-filter/custom-table-filter.directive';
import { Angular2SmartTableModule } from 'angular2-smart-table';
import { InvoiceComponent } from './components/orders/invoice/invoice.component';
import { ArrayToObjectPipe } from './pipes/array-to-object.pipe';
import { OrdersTurnoverComponent } from './components/orders/orders-turnover/orders-turnover.component';
import { ReservationComponent } from './components/orders/reservation/reservation.component';
import { QrAssignComponent } from './components/products/qr-assign/qr-assign.component';
import { OrdersModule } from './components/orders/orders.module';
import { ToastrModule } from 'ngx-toastr';
import { RentalPointModule } from './components/rental-point/rental-point.module';
import { ClientsModule } from './components/clients/clients.module';
import { CoreModule } from './components/core/core.module';
import { GeneralModule } from './components/general/general.module';
import { TicketsModule } from './components/tickets/tickets.module';
import { ProductsModule } from './components/products/products.module';
import { ProfileModule } from './components/profile/profile.module';
import { CoreComponent } from './components/core/core.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CashFlowModule } from './components/cash-flow/cash-flow.module';
import { PriceListModule } from './components/price-list/price-list.module';
import { NgbAlertModule, NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { OrderCancellationComponent } from './components/order-cancellation/order-cancellation.component';
import { TablesModule } from './components/tables/tables.module';
import { TestPdfComponent } from './components/test-pdf/test-pdf.component';
import { ServicePointsModule } from './components/service-points/service-points.module';
import { StatisticsModule } from './components/statistics/statistics.modules';
import { WartungUsersModule } from './components/wartung-users/wartung-users.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { ExcelModule, GridModule, PDFModule } from '@progress/kendo-angular-grid';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { ChartModule } from '@progress/kendo-angular-charts';



const toastrConfig = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-left',
  timeOut: 3000
};

@NgModule({
  exports: [
    MatSnackBarModule
  ],
  declarations: [
    AppComponent,
    LoginComponent,
    HeaderComponent,
    DashboardComponent,
    RentalPointEditComponent,
    RentalPointAddComponent,
    StaffComponent,
    StaffViewComponent,
    ForgotPasswordComponent,
    StaffAddComponent,
    OrderFormComponent,
    OrderConfirmedComponent,
    CustomTableFilterDirective,
    InvoiceComponent,
    ArrayToObjectPipe,
    OrdersTurnoverComponent,
    ReservationComponent,
    QrAssignComponent,
    CoreComponent,
    OrderCancellationComponent,
    TestPdfComponent,
  ],
  imports: [
    HammerModule,
    MatSnackBarModule,
    ToastrModule.forRoot(toastrConfig),
    BrowserModule,
    FormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    Angular2SmartTableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxBarcode6Module,
    NgbAlertModule,
    BsDatepickerModule,
    NgbModalModule,
    OrdersModule,
    RentalPointModule,
    ClientsModule,
    CoreModule,
    GeneralModule,
    TicketsModule,
    ProductsModule,
    ProfileModule,
    CashFlowModule,
    PriceListModule,
    NgbModule,
    TablesModule,
    ServicePointsModule,
    StatisticsModule,
    WartungUsersModule,
    DateInputsModule,
    LabelModule,
    GridModule,
    ButtonsModule,
    DropDownsModule,
    PDFModule,
    ExcelModule,
    InputsModule,
    ChartModule
  ],
  providers: [
    UserService,
    ProductService,
    OrdersService,
    ExcelService,
    Title,
    { provide: LOCALE_ID, useValue: 'en' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

