import { RentalPoint } from './RentalPoint';

export class TurnoverActions {
    public amount: number;
    public date: string;
    public rentalPoint: RentalPoint;
    public type: string;

    public static fromDto(t): TurnoverActions {
        const turnoverActions = new TurnoverActions();
        if (!t) {
            return null;
        }
        turnoverActions.amount = t.amount;
        turnoverActions.date = t.date;
        turnoverActions.rentalPoint = RentalPoint.fromDto(t.rentalPoint);
        turnoverActions.type = t.type;

        return turnoverActions;
    }
}
