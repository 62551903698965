import { Pipe, PipeTransform } from '@angular/core';
import { RegisteredType } from '../../../../shared/enums/RegisteredType';

@Pipe({
  name: 'registeredType'
})
export class RegisteredTypePipe implements PipeTransform {

  transform(registeredType: RegisteredType): any {
    switch (registeredType) {
      case RegisteredType.AppCheckIn:
        return 'App check-in';
      case RegisteredType.DirectRentalRented:
        return 'Direct rental rented';
      case RegisteredType.BookedRentalRented:
        return 'Booked rental rented';
      case RegisteredType.DirectRentalReturn:
        return 'Direct rental return';
      case RegisteredType.BookedRentalReturn:
        return 'Booked rental return';
      case RegisteredType.MultipleCheckIn:
        return 'Multiple check-in';
    }
  }

}
