import { Component, OnInit, AfterViewInit, ElementRef, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from '../../../services/orders.service';
import { OnlineBooking } from '../../../models/OnlineBooking';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html',
  styleUrls: ['./order-form.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OrderFormComponent implements OnInit {

  public order: OnlineBooking = new OnlineBooking();
  public discountValue: number;
  public daysDiff: number;
  public currentDate = new Date();
  public paymentEnv = true;
  public confirmationResult = { orderId: '', status: 200, message: '' };
  public enviroment = environment;

  private _routeParams;

  constructor(private elementRef: ElementRef,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private orderService: OrdersService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this._routeParams = params;
      if (params.contractId) {
        this.orderService.getOrderForPayment(params.contractId).subscribe(result => {
          this.order = result;
          this.translate.use(this.order.language);
          const startDateTime = new Date(this.order.startDate).getTime();
          const endDateTime = new Date(this.order.endDate).getTime();
          const start = Math.round(startDateTime / (3600 * 24 * 1000));
          const end = Math.round(endDateTime / (3600 * 24 * 1000));
          this.daysDiff = end - start + 1;
          if (params.resourcePath) {
            console.log(this._routeParams);
            this.paymentEnv = false;
            this.orderService.confirmOrder(params.contractId, params.resourcePath).subscribe(confirmResult => {
              console.log(confirmResult);
              this.confirmationResult = confirmResult;
            });
          }
        }, error => {
          console.log(error);
        });
      }
    });
  }

  // ngAfterViewInit(): void {
  //   if (this._routeParams.id && this._routeParams.resourcePath) {
  //     console.log(this._routeParams);
  //     this.paymentEnv = false;
  //     this.orderService.confirmOrder(this._routeParams.contractId, this._routeParams.resourcePath).subscribe(confirmResult => {
  //       this.confirmationResult = confirmResult;
  //     });
  //   }
  // }
}
