import { BikeType } from './BikeType';

export class InvoiceBikeAccessory {
  public id: number;
  public bikePrice: number;
  public bikeType: BikeType;
  public quantity: number;
  public diffRentalCost: number;
  public price: number;
  public singleBikeCost: number;

  public static fromDto(i): InvoiceBikeAccessory {
    const obj = new InvoiceBikeAccessory();
    if (!i) return null;
    obj.id = i.id;
    obj.bikePrice = i.bikePrice;
    obj.bikeType = i.bikeType;
    obj.quantity = i.quantity;
    obj.diffRentalCost = i.diffRentalCost;
    obj.price = i.price;
    obj.singleBikeCost = i.singleBikeCost;

    return obj;
  }

}
