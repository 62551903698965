import { Product } from './Product';

export class BikeTyreSize {
    public id: number;
    public size: string;
    public products: Product[];

    public static fromDto(b): BikeTyreSize {
        const bikeTyreSize = new BikeTyreSize();
        if (!b) {
            return null;
        }
        bikeTyreSize.id = b.id;
        bikeTyreSize.size = b.size;
        if (b.products) {
            bikeTyreSize.products = b.products.map(product => Product.fromDto(product));
        }

        return bikeTyreSize;
    }
}

export class BikeTyreSizeSearch {
  public id: number;
  public size: string;

  public static fromDto(b): BikeTyreSizeSearch {
      const bikeTyreSize = new BikeTyreSizeSearch();
      if (!b) {
          return null;
      }
      bikeTyreSize.id = b.id;
      bikeTyreSize.size = b.size;

      return bikeTyreSize;
  }
}
