import { Injectable } from '@angular/core';
import { Page } from '../models/Page';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {


  maxNbPages = 5;

  constructor() {
  }

  getPages(page: number, lastPage: number): Page[] {
    if (lastPage <= 1) {
      return [{ value: 1, canClick: false }];
    }

    if (lastPage < this.maxNbPages) {
      return Array(lastPage).fill(1).map((x, i) => {
        return { value: i + 1, canClick: true };
      });
    }

    const pages: Page[] = [{ value: 1, canClick: true }];
    const around        = Math.floor(this.maxNbPages / 2);
    let start, end;

    if (page < around + 2) {
      start = 2;
      end   = Math.min(2 + this.maxNbPages - 1, lastPage - 1);
    } else if (page > lastPage - around) {
      start = Math.max(2, lastPage - this.maxNbPages);
      end   = lastPage - 1;
    } else {
      start = Math.max(page - around, 2);
      end   = Math.min(page + around, lastPage - 1);
    }

    if (start > 2) {
      pages.push({ value: '...', canClick: false });

      if (start > 3) {
        pages.push({ value: Math.floor(start / 2), canClick: true });
        pages.push({ value: '...', canClick: false });
      }
    }

    for (let i = start; i <= end; i++) {
      pages.push({ value: i, canClick: true });
    }

    if (end < lastPage - 1) {
      pages.push({ value: '...', canClick: false });

      if (end < lastPage - 2) {
        pages.push({ value: end + Math.floor((lastPage - end) / 2), canClick: true });
        pages.push({ value: '...', canClick: false });
      }
    }

    pages.push({ value: lastPage, canClick: true });

    return pages;
  }
}
