<div class="container-fluid">
  <div class="row mt-3 mb-5">
    <div class="col">
      <app-data-table [selectedIds]="selectedArray" [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="25" (onReset)="onTableReset()" (onDeleteSelected)="deleteSelectedConfirm(contentConfirmDeleteModal)" [allMoveClients]="allMoveClients"
            (onMoveBikes)="moveBikesSelectedConfirm(confirmMoveBikeDialog, errorMoveUnavailableBikeDialog, $event)" [moveClientOptions]="moveClientOptions">
        <app-data-filters #dataFilters [showExcelExportBikeListButton]="true" (onExcelExportBikeListClick)="openExcelExportBikeList(filters)" [showCSVExportBikeListForInsuranceButton]="true" (onCSVExportBikeListForInsuranceClick)="openCSVExportBikeListForInsurance(filters)">
          <app-data-filter-group>
            <app-data-filter-select label="{{'products.bikeEquipmentList.clients' | translate}}"
              [(model)]="filters.clientId" [options]="clientOptions" *ngIf="currentUserType.userTypeNumber === 0;"
              (modelChange)="onClientSelectChange($event)">
            </app-data-filter-select>
            <app-data-filter-select-search label="{{'products.bikeEquipmentList.rentalPoints' | translate}}"
              [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
              *ngIf="currentUserType.userTypeNumber !== 2">
            </app-data-filter-select-search>
            <app-data-filter-select label="{{'products.bikeEquipmentList.bikeType' | translate}}"
              [(model)]="filters.bikeTypeId" [options]="bikeTypeOptions">
            </app-data-filter-select>
            <app-data-filter-select label="{{'products.bikeEquipmentList.brandName' | translate}}"
              [(model)]="filters.brandNameId" [options]="bikeBrandOptions">
            </app-data-filter-select>
            <app-data-filter-select label="{{'products.bikeEquipmentList.model' | translate}}"
              [(model)]="filters.bikeModelId" [options]="bikeModelOptions">
            </app-data-filter-select>
            <app-data-filter-select label="{{'products.bikeEquipmentList.bikeSize' | translate}}"
              [(model)]="filters.bikeSizeId" [options]="bikeSizeOptions">
            </app-data-filter-select>
            <app-data-filter-select label="{{'products.bikeEquipmentList.tyreSize' | translate}}"
              [(model)]="filters.tyreSizeId" [options]="bikeTyreSizeOptions">
            </app-data-filter-select>
            <app-data-filter-input label="{{'products.bikeEquipmentList.qrCode' | translate}}"
              [(model)]="filters.qrCode" placeholder="{{'products.bikeEquipmentList.qrCode' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.keyNumber' | translate}}"
              [(model)]="filters.keyNumber" placeholder="{{'products.bikeEquipmentList.keyNumber' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.serialNumber' | translate}}"
              [(model)]="filters.serialNumber" placeholder="{{'products.bikeEquipmentList.serialNumber' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'products.bikeEquipmentList.frameId' | translate}}"
              [(model)]="filters.frameId" placeholder="{{'products.bikeEquipmentList.frameId' | translate}}">
            </app-data-filter-input>
            <app-data-filter-select label="{{'products.bikeEquipmentList.status' | translate}}"
              [(model)]="filters.status" [options]="productsStatus">
            </app-data-filter-select>
            <app-data-filter-select label="{{'data-table.sortBy' | translate}}"  [(model)]="filters.sortBy" [options]="sortOptions"></app-data-filter-select>
            <app-data-filter-select label="{{'data-table.orderBy' | translate}}"  [(model)]="filters.orderBy" [options]="orderOptions"></app-data-filter-select>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #bikeId let-id="id" let-status="status">
        <ng-container *ngIf="this.allowDelete() || this.allowMoveBikes()">
          <input type="checkbox" name="bikeId" value="{{id}}" [checked]="isInSelectedArray(id)" (change)="addRemoveFromSelectedArray(id, $event)" *ngIf="status !== 2"/>
        </ng-container>
      </ng-template>

      <ng-template #bikeType let-bikeType="bikeType">
        {{bikeType.bikeTypeTranslation[currentLang]}}
      </ng-template>

      <ng-template #brandName let-bikeBrand="bikeBrand">
        <ng-container *ngIf="bikeBrand">{{bikeBrand.name}}</ng-container>
      </ng-template>

      <ng-template #model let-bikeModel="bikeModel">
        <ng-container *ngIf="bikeModel">{{bikeModel.name}}</ng-container>
      </ng-template>

      <ng-template #bikeSize let-bikeSize="bikeSize">
        <ng-container *ngIf="bikeSize">{{bikeSize.size}}</ng-container>
      </ng-template>

      <ng-template #tyreSize let-bikeTyreSize="bikeTyreSize">
        <ng-container *ngIf="bikeTyreSize">{{bikeTyreSize.size}}</ng-container>
      </ng-template>

      <ng-template #qrCode let-qrCode="qrCode">
        <ng-container *ngIf="qrCode">
          <button type="button" class="btn btn-sm btn-primary" (click)="openQrCodeModal(content, qrCode)"><i
              class="fa fa-qrcode"></i> {{qrCode}}</button>
        </ng-container>
      </ng-template>

      <ng-template #serialNumber let-serialNumber="serialNumber">
        <ng-container *ngIf="serialNumber">{{serialNumber}}</ng-container>
      </ng-template>

      <ng-template #keyNumber let-keyNumber="keyNumber">
        <ng-container *ngIf="keyNumber">{{keyNumber}}</ng-container>
      </ng-template>

      <ng-template #frameId let-frameId="frameId">
        <ng-container *ngIf="frameId">{{frameId}}</ng-container>
      </ng-template>

      <ng-template #location let-location="location">
        <ng-container *ngIf="location.rentalPoint">
          {{location.rentalPoint.rentalPointTranslation[currentLang]}}
        </ng-container>
        <ng-container *ngIf="!location.rentalPoint && location.client">
          {{location.client.businessName}}
        </ng-container>
        <ng-container *ngIf="!location.rentalPoint && !location.client">
          {{location.firstName}}
        </ng-container>
      </ng-template>

      <ng-template #imagePath let-imagePath="imagePath">
        <a [href]="imagePath" target="_blank" class="btn btn-secondary btn-sm btn-block" *ngIf="imagePath"
          style=" display:block; width:35px; margin:0 auto;">
            <i class="fa fa-image"></i>
        </a>
      </ng-template>

      <ng-template #status let-status="status">
        {{status | productStatus | translate}}
      </ng-template>

      <ng-template #createdAt let-createdAt="createdAt">
        {{createdAt | date: 'YYYY-MM-dd'}}
      </ng-template>

      <ng-template #actions let-id="id">
        <a href="products/edit/{{id}}"
          class="btn btn-primary btn-sm btn-block">{{'products.bikeEquipmentList.edit' | translate}}</a>
      </ng-template>

    </div>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Bike Qr-Code</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center" style="padding-left: 33%">
    <qrcode [qrdata]="choosenQrCode" [width]="126"></qrcode>
    <br />
    {{choosenQrCode}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close')">Close</button>
  </div>
</ng-template>
<ng-template #contentConfirmDeleteModal let-modal>
  <div class="modal-header" style="background-color:#dc3545; color:#FFFFFF">
    <h4 class="modal-title">{{'products.bikeEquipmentList.confirmDeleteModal.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('close')">
      <span aria-hidden="true" style="color:#FFFFFF">&times;</span>
    </button>
  </div>
  <div class="modal-body text-left" style="font-size: 15px;">
    {{'products.bikeEquipmentList.confirmDeleteModal.question' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="deleteSelected()">{{'products.bikeEquipmentList.confirmDeleteModal.confirm' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('close')">{{'products.bikeEquipmentList.confirmDeleteModal.abort' | translate}}</button>
  </div>
</ng-template>
<ng-template #confirmMoveBikeDialog let-modal>
  <div class="modal-header" style="background-color:#1093ff; color:#FFFFFF">
    <h4 class="modal-title">{{'products.bikeEquipmentList.confirmMoveBikesModal.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true" style="color:#FFFFFF">&times;</span>
    </button>
  </div>
  <div class="modal-body text-left" style="font-size: 15px;">
    {{'products.bikeEquipmentList.confirmMoveBikesModal.question' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">{{'products.bikeEquipmentList.confirmMoveBikesModal.confirm' | translate}}</button>
    <button type="button" class="btn btn-outline-dark" (click)="modal.close(false)">{{'products.bikeEquipmentList.confirmMoveBikesModal.abort' | translate}}</button>
  </div>
</ng-template>
<ng-template #errorMoveUnavailableBikeDialog let-modal>
  <div class="modal-header" style="background-color:#c10000; color:#FFFFFF">
    <h4 class="modal-title">{{'products.bikeEquipmentList.errorMoveUnavailableBikeDialog.title' | translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true" style="color:#FFFFFF">&times;</span>
    </button>
  </div>
  <div class="modal-body text-left" style="font-size: 15px;">
    {{'products.bikeEquipmentList.errorMoveUnavailableBikeDialog.question' | translate}}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">{{'products.bikeEquipmentList.confirmMoveBikesModal.abort' | translate}}</button>
  </div>
</ng-template>


