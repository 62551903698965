<div class="card mb-3">
  <div class="card-header">
    {{rentalPoint.rentalPointTranslation[currentLang]}}
  </div>
  <div class="card-body">
    <div class="list-group mb-3" *ngFor="let rental of rentalPoint.onlineBooking">
      <div class="list-group-item list-group-item-action" [ngClass]="{'list-group-item-success': rental.prepared}">
        <div class="d-flex w-100 justify-content-between">
          <h5 class="mb-1">{{'products.bikeEquipmentPreparation.contractId' | translate}} {{rental.contractId}}
          </h5>
          {{rental.startDate | date: 'dd-MM-yyyy'}} - {{rental.endDate | date: 'dd-MM-yyyy'}}
        </div>
        <table class="table table-bordored">
          <thead>
            <tr>
              <th>{{'products.bikeEquipmentPreparation.bikeType' | translate}}</th>
              <th>{{'products.bikeEquipmentPreparation.amount' | translate}}</th>
              <th>{{'products.bikeEquipmentPreparation.size' | translate}}</th>
            </tr>
          </thead>
          <tbody>
          @for (bike of rental.invoiceBike; track bike.id) {
            <tr>
              <td>{{ bike.bikeType.bikeTypeTranslation[currentLang] }}</td>
              <td>{{ bike.quantity }}</td>
              <td>{{ bike?.height > 0 ? bike.height : '&nbsp;' }}</td>
            </tr>
            @if (bike?.accessories && bike.accessories.length > 0) {
              @for (accessory of bike.accessories; track accessory.id) {
                <tr>
                  <td>&nbsp; &#8627;&nbsp; {{ accessory.bikeType.bikeTypeTranslation[currentLang] }}</td>
                  <td>{{ accessory.quantity }}</td>
                  <td></td>
                </tr>
              }
            }
          }
          </tbody>
        </table>
        <div class="card" style="margin-bottom: 10px">
          <div class="card-body" style="padding: 10px;">
            <ul class="list-inline" style="margin: 0px;">
              <li class="list-inline-item">{{rental.clientInformation.firstName}} {{rental.clientInformation.lastName}}
              </li>
              <li class="list-inline-item"> | </li>
              <li class="list-inline-item">{{rental.clientInformation.phone}}</li>
              <li class="list-inline-item"> | </li>
              <li class="list-inline-item">{{rental.clientInformation.address}}</li>
            </ul>
            <ul class="list-inline" style="margin: 0px;">
              <li class="list-inline-item">{{rental.clientInformation.comment}}</li>
            </ul>
          </div>
        </div>
        <small>
          <ng-container *ngIf="rental.reservation !== 0; else onlineBookingTemplate">
            <a href="orders/reservation/details/{{rental.contractId}}" target="_blank"
              class="btn btn-sm btn-primary float-left">Contract</a>
          </ng-container>
          <ng-template #onlineBookingTemplate>
            <a href="orders/booking/details/{{rental.contractId}}" target="_blank"
              class="btn btn-sm btn-primary float-left">Contract</a>
          </ng-template>
          <div class="form-group form-check pull-right" style="margin: 0; margin-top: 7px;">
            <input type="checkbox" class="form-check-input" [attr.id]="'doneCheck-' + rental.contractId"
              [checked]="rental.prepared" (change)="onDoneCheckBoxChange($event, rental.contractId)">
            <label class="form-check-label" [attr.for]="'doneCheck-' + rental.contractId"
              style="font-size: 16px;"><strong>Erledigt</strong></label>
          </div>
        </small>
      </div>
    </div>
    <hr />
    <div>
      <h6>{{'products.bikeEquipmentPreparation.generalOverview' | translate}}</h6>
      <table class="table table-bordored">
        <thead>
          <tr>
            <th>{{'products.bikeEquipmentPreparation.bikeType' | translate}}</th>
            <th>{{'products.bikeEquipmentPreparation.amount' | translate}}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of bikeTypeSummery | objectFilter:rentalPoint.id">
            <td>
              {{item.bikeType.bikeTypeTranslation[currentLang]}}
            </td>
            <td>
              {{item.quantity}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
