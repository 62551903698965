<div class="container-fluid">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <app-data-filter-select label="{{'orders.bookingReservationOrders.clients' | translate}}"
                        [(model)]="filters.clientId" [options]="clientOptions"
                        (modelChange)="onClientSelectChange($event)">
                    </app-data-filter-select>
                </div>
                <div class="col">
                    <app-data-filter-select-search
                        label="{{'orders.bookingReservationOrders.rentalPoints' | translate}}"
                        [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions">
                    </app-data-filter-select-search>
                </div>
                <div class="col">
                    <app-data-filter-input label="Quantity" [(model)]="filters.quantity" placeholder="Quantity">
                    </app-data-filter-input>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col text-right">
                    <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-secondary" (click)="onApplyButtonClick()"
                            [disabled]="disableApplyButton">{{'rentalPoint.leakExcess.apply' | translate}}</button>
                        <button type="button" class="btn btn-secondary"
                            (click)="onResetButtonClick()">{{'rentalPoint.leakExcess.reset' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card" style="margin-top:30px" [hidden]="!hideTables">
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <h5>{{'rentalPoint.leakExcess.bikeLack' | translate}}</h5>
                    <div [hidden]="!loadingLack">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="loadingLack">
                        <app-leack-table (onLoadingFinish)="showTableContent($event)" [filters]="childFilter"
                            [currentLang]="currentLang"></app-leack-table>
                    </div>
                </div>
                <div class="col">
                    <h5>{{'rentalPoint.leakExcess.bikeExcess' | translate}}</h5>
                    <div [hidden]="!loadingExcess">
                        <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <div [hidden]="loadingExcess">
                        <app-excess-table (onLoadingFinish)="showTableContent($event)" [filters]="childFilter"
                            [currentLang]="currentLang"></app-excess-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
