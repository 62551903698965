import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { TablesService } from '../shared/tables.service';
import { TablesSearchCriteria } from '../shared/tablesSearchCriteria';

@Component({
  selector: 'app-tables-list',
  templateUrl: './tables-list.component.html',
  styleUrls: ['./tables-list.component.scss']
})
export class TablesListComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: TablesSearchCriteria = {};

  // bikeType columns

  @ViewChild('bikeType_id', { static: true })
  bikeType_id: TemplateRef<any>;

  @ViewChild('bikeType_name_en', { static: true })
  bikeType_name_en: TemplateRef<any>;

  @ViewChild('bikeType_name_it', { static: true })
  bikeType_name_it: TemplateRef<any>;

  @ViewChild('bikeType_name_it', { static: true })
  bikeType_name_de: TemplateRef<any>;

  @ViewChild('bikeType_itemOrder', { static: true })
  bikeType_itemOrder: TemplateRef<any>;

  @ViewChild('bikeType_bikeCost', { static: true })
  bikeType_bikeCost: TemplateRef<any>;

  @ViewChild('bikeType_productType', { static: true })
  bikeType_productType: TemplateRef<any>;

  @ViewChild('bikeType_bikeAvailabilityPeriod', { static: true })
  bikeType_bikeAvailabilityPeriod: TemplateRef<any>;

  // bikeBrand columns

  @ViewChild('bikeBrand_id', { static: true })
  bikeBrand_id: TemplateRef<any>;

  @ViewChild('bikeBrand_name', { static: true })
  bikeBrand_name: TemplateRef<any>;

  // bikeBrand columns

  @ViewChild('bikeModel_id', { static: true })
  bikeModel_id: TemplateRef<any>;

  @ViewChild('bikeModel_name', { static: true })
  bikeModel_name: TemplateRef<any>;

  // bikeSize columns

  @ViewChild('bikeSize_id', { static: true })
  bikeSize_id: TemplateRef<any>;

  @ViewChild('bikeSize_size', { static: true })
  bikeSize_size: TemplateRef<any>;

  // tyreSize columns

  @ViewChild('tyreSize_id', { static: true })
  tyreSize_id: TemplateRef<any>;

  @ViewChild('tyreSize_size', { static: true })
  tyreSize_size: TemplateRef<any>;

  // bikeColor columns

  @ViewChild('bikeColor_id', { static: true })
  bikeColor_id: TemplateRef<any>;

  @ViewChild('bikeColor_name', { static: true })
  bikeColor_name: TemplateRef<any>;

  // generic columns

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  public tableCode: string;

  public recordset: any[];

  constructor(private _tablesService: TablesService, private _routes: ActivatedRoute, private _router: Router) {
    this._routes.params.subscribe(params => {
      this.tableCode = params['tableCode'];
    });
  }

  ngOnInit() {
    if (this.tableCode === 'bikeType') {
      this.columns = [
        {
          name: 'bikeType_id',
          displayed: true,
          translationKey: 'tableData.list.bikeType_id',
          template: this.bikeType_id,
          sortable: true
        },
        {
          name: 'bikeType_name_en',
          displayed: true,
          translationKey: 'tableData.list.bikeType_name_en',
          template: this.bikeType_name_en,
          sortable: true
        },
        {
          name: 'bikeType_name_it',
          displayed: true,
          translationKey: 'tableData.list.bikeType_name_it',
          template: this.bikeType_name_it,
          sortable: true
        },
        {
          name: 'bikeType_name_de',
          displayed: true,
          translationKey: 'tableData.list.bikeType_name_de',
          template: this.bikeType_name_de,
          sortable: true
        },
        {
          name: 'bikeType_bikeCost',
          displayed: true,
          translationKey: 'tableData.list.bikeType_bikeCost',
          template: this.bikeType_bikeCost,
          sortable: true
        },
        {
          name: 'bikeType_productType',
          displayed: true,
          translationKey: 'tableData.list.bikeType_productType',
          template: this.bikeType_productType,
          sortable: true
        },
        {
          name: 'bikeType_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }

      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeTypes(page, pageSize, this.filters);

    }

    if (this.tableCode === 'bikeBrand') {
      this.columns = [
        {
          name: 'bikeBrand_id',
          displayed: true,
          translationKey: 'tableData.list.bikeBrand_id',
          template: this.bikeBrand_id,
          sortable: true
        },
        {
          name: 'bikeBrand_name',
          displayed: true,
          translationKey: 'tableData.list.bikeBrand_name',
          template: this.bikeBrand_name,
          sortable: true
        },
        {
          name: 'bikeBrand_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }
      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeBrands(page, pageSize, this.filters);

    }

    if (this.tableCode === 'bikeModel') {
      this.columns = [
        {
          name: 'bikeModel_id',
          displayed: true,
          translationKey: 'tableData.list.bikeModel_id',
          template: this.bikeModel_id,
          sortable: true
        },
        {
          name: 'bikeModel_name',
          displayed: true,
          translationKey: 'tableData.list.bikeModel_name',
          template: this.bikeModel_name,
          sortable: true
        },
        {
          name: 'bikeModel_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }
      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeModels(page, pageSize, this.filters);

    }

    if (this.tableCode === 'bikeSize') {
      this.columns = [
        {
          name: 'bikeSize_id',
          displayed: true,
          translationKey: 'tableData.list.bikeSize_id',
          template: this.bikeSize_id,
          sortable: true
        },
        {
          name: 'bikeSize_size',
          displayed: true,
          translationKey: 'tableData.list.bikeSize_size',
          template: this.bikeSize_size,
          sortable: true
        },
        {
          name: 'bikeSize_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }
      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeSizes(page, pageSize, this.filters);

    }

    if (this.tableCode === 'tyreSize') {
      this.columns = [
        {
          name: 'tyreSize_id',
          displayed: true,
          translationKey: 'tableData.list.tyreSize_id',
          template: this.tyreSize_id,
          sortable: true
        },
        {
          name: 'tyreSize_size',
          displayed: true,
          translationKey: 'tableData.list.tyreSize_size',
          template: this.tyreSize_size,
          sortable: true
        },
        {
          name: 'tyreSize_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }
      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeTyreSizes(page, pageSize, this.filters);

    }

    if (this.tableCode === 'bikeColor') {
      this.columns = [
        {
          name: 'bikeColor_id',
          displayed: true,
          translationKey: 'tableData.list.bikeColor_id',
          template: this.bikeColor_id,
          sortable: true
        },
        {
          name: 'bikeColor_name',
          displayed: true,
          translationKey: 'tableData.list.bikeColor_name',
          template: this.bikeColor_name,
          sortable: true
        },
        {
          name: 'bikeColor_actions',
          displayed: true,
          translationKey: '',
          template: this.actions,
          sortable: true,
          trAddidionalClass: 'text-right action',
          additionalClass: 'text-right action'
        }
      ];
      this.loadPage = (page: number, pageSize: number) => this._tablesService.searchBikeColors(page, pageSize, this.filters);

    }
  }

  redirectPage(pageType: string, tableCode: string, id: number = 0) {
    if (pageType === 'insert') {
      this._router.navigate(['/table-data-management/insert', tableCode]);
    }
    if (pageType === 'update') {
      this._router.navigate(['/table-data-management/update', tableCode, id]);
    }
  }

}
