<div class="container-fluid">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <app-data-filter-select label="{{'statements.turnover.clients' | translate}}"
                                [(model)]="filters.clientId" [options]="clientOptions"
                                *ngIf="currentUserType.userTypeNumber === 0;"
                                (modelChange)="onClientSelectChange($event)">
                            </app-data-filter-select>
                        </div>
                        <div class="col">
                            <app-data-filter-select-search label="{{'statements.turnover.rentalPoints' | translate}}"
                                [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
                                *ngIf="currentUserType.userTypeNumber !== 2">
                            </app-data-filter-select-search>
                        </div>
                        <div class="col">
                            <app-data-filter-datepicker label="{{'statements.turnover.from' | translate}}"
                                [(model)]="filters.startDate">
                            </app-data-filter-datepicker>
                        </div>
                        <div class="col">
                            <app-data-filter-datepicker label="{{'statements.turnover.to' | translate}}"
                                [(model)]="filters.endDate">
                            </app-data-filter-datepicker>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col text-right">
                            <div class="btn-group btn-group-sm" role="group" aria-label="Basic example">
                                <button type="button" class="btn btn-secondary"
                                    (click)="onApplayButtonClick()">{{'statements.turnover.apply' | translate}}</button>
                                <button type="button" class="btn btn-secondary"
                                    (click)="onResetButtonClick()">{{'statements.turnover.reset' | translate}}</button>
                                <button type="button" class="btn btn-secondary" (click)="onExcelExportButtonClick()"
                                    [disabled]="disableExcelExportButton">{{'statements.turnover.excelExport' | translate}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-container *ngIf="filterClick">
        <div class="row mt-3" [hidden]="showResultContent">
            <div class="col">
                <div class="d-flex justify-content-center">
                    <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" [hidden]="!showResultContent">
            <div class="col-12" *ngFor="let turnoverElement of turnoverList">
                <div class="card mb-3">
                    <div class="card-header">
                        {{turnoverElement.contractId}} || {{turnoverElement.name}} ||
                        {{turnoverElement.amount | currency:"€ "}}
                    </div>
                    <div class="card-body">
                        <table width="100%" class="table table-hover table-bordered table-sm table-striped">
                            <thead class="thead-dark">
                                <th class="text-center">{{'statements.turnover.date' | translate}}</th>
                                <th class="text-center">{{'statements.turnover.rentalPointName' | translate}}
                                </th>
                                <th class="text-center">{{'statements.turnover.amount' | translate}}</th>
                                <th class="text-center">{{'statements.turnover.type' | translate}}</th>
                            </thead>
                            <tbody>
                                <tr *ngFor="let turnoverRows of turnoverElement.payments">
                                    <td class="text-center">{{turnoverRows.date | date:'dd-MM-yyyy'}}</td>
                                    <td class="text-center">
                                        {{(turnoverRows.rentalPoint ? turnoverRows.rentalPoint.rentalPointTranslation['de'] : 'N/D')}}
                                    </td>
                                    <td class="text-center">{{turnoverRows.amount | currency:'€ '}}</td>
                                    <td class="text-center">{{turnoverRows.type}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
