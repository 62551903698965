<app-data-table [columns]="columns" [loadPage]="loadPage" [filters]="childFilter" [pageSize]="15" (onLoad)="onTableFinishLoading()">
</app-data-table>


<ng-template #rentalPointName let-rentalPoint="rentalPoint">
    {{rentalPoint.rentalPointTranslation[currentLang]}}
</ng-template>

<ng-template #bikeTypeName let-bikeType="bikeType">
    {{bikeType.bikeTypeTranslation[currentLang]}}
</ng-template>

<ng-template #contingentBuffer let-quantity="quantity">
    {{quantity.contingentBuffer}}
</ng-template>

<ng-template #contingentProducts let-quantity="quantity">
    {{quantity.contingentNumberOfProducts}}
</ng-template>

<ng-template #lackAmount let-quantity="quantity">
    {{quantity.count}}
</ng-template>