import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'dayDiff'
})
export class DayDiffPipe implements PipeTransform {

  transform(startDate: any, endDate: any, translations?: any): any {
    const dayDiff = moment(endDate, 'YYYY-MM-DD').diff(moment(startDate, 'YYYY-MM-DD'), 'days') + 1;
    if (translations) {
      return dayDiff + ' ' + (dayDiff > 1 ? translations.days : translations.day);
    } else {
      return dayDiff;
    }
  }

}
