import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked, AfterViewInit, Input } from '@angular/core';
import { OrderSearchCriteria } from '../shared/OrderSearchCriteria';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { SharedUtils, UserType } from '../../../shared/shared-utils';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Client } from '../../../models/Client';
import { OrdersService } from '../shared/orders.service';
import { ClientsService } from '../../clients/shared/clients.service';
import { Observable, Subject, forkJoin } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { OrderTypeEnum } from '../../../shared/enums/OrderTypeEnum';
import { OrderStatusEnum } from '../../../shared/enums/OrderStatusEnum';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatePipe } from '@angular/common';
import * as moment from 'moment/moment';

@Component({
  selector: 'app-all-orders',
  templateUrl: './all-orders.component.html',
  styleUrls: ['./all-orders.component.scss']
})
export class AllOrdersComponent implements OnInit, AfterViewInit {

  @Input() allOrdersType = true;

  public filters: OrderSearchCriteria = {};
  public filtersToSend: OrderSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;
  public currentUserType: UserType;
  public clientOptions: Option[];
  public rentalPointOptions: Option[];
  public orderTypeOptions: Option[];
  public orderStatusOptions: Option[];

  public rentalCloseDate: Date = new Date();
  public rentalCloseTime: Date = new Date();

  public currentPage: number;
  public pageSize: number;

  private _clients: Client[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('dataTable', { static: true })
  dataTable

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('clientName', { static: true })
  clientName: TemplateRef<any>;

  @ViewChild('startRentalPoint', { static: true })
  startRentalPoint: TemplateRef<any>;

  @ViewChild('returnRentalPoint', { static: true })
  returnRentalPoint: TemplateRef<any>;

  @ViewChild('startDate', { static: true })
  startDate: TemplateRef<any>;

  @ViewChild('returnDate', { static: true })
  returnDate: TemplateRef<any>;

  @ViewChild('orderAmount', { static: true })
  orderAmount: TemplateRef<any>;

  @ViewChild('orderType', { static: true })
  orderType: TemplateRef<any>;

  @ViewChild('orderStatus', { static: true })
  orderStatus: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  loadOnStart: Subject<boolean> = new Subject();

  constructor(private _modalService: NgbModal, private _ordersService: OrdersService, private _clientsService: ClientsService,
    private _translate: TranslateService, public _snackBar: MatSnackBar, private datePipe: DatePipe) {
     this.currentLang = this._translate.currentLang;
     this.currentUserType = SharedUtils.getCurrentUserType();
     this.clientOptions = [{ value: undefined, label: 'orders.allOrders.all' }];
     this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.allOrders.all') }];
     this.orderTypeOptions = [
        { value: undefined, label: 'orders.allOrders.all' },
        { value: 1, label: OrderTypeEnum.ONE },
        { value: 2, label: OrderTypeEnum.TWO }
      ];
      this.orderStatusOptions = [
      { value: undefined, label: 'orders.allOrders.all' },
      { value: 1, label: OrderStatusEnum.ONE },
      { value: 2, label: OrderStatusEnum.TWO },
      { value: 3, label: OrderStatusEnum.THREE },
      { value: 99, label: OrderStatusEnum.FOUR },
      { value: 98, label: OrderStatusEnum.FIVE }
    ];

  }

  ngOnInit() {
    let accountsRequest: Observable<Client> | Observable<Client[]>;
    switch (this.currentUserType.userTypeNumber) {
      case 1: // CLIENT
        accountsRequest = this._clientsService.getClient(undefined, this.currentUserType.accountId);
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2: // STARTRENTAL
        accountsRequest = this._clientsService.getClients();
        this.filters = { startRentalPointAccountId: this.currentUserType.accountId };
        break;
      default:
        accountsRequest = this._clientsService.getClients();
        break;
    }
    forkJoin([accountsRequest]).subscribe(result => {
      if (this.currentUserType.userTypeNumber !== 2) {
        this.populateClientRentalPointOptions(result[0]);
      }
      this.filtersToSend = { ... this.filters };
      this.loadPage = (page: number, pageSize: number) => {
        this.currentPage = page;
        this.pageSize = pageSize;
        if (!this.allOrdersType) {
          this.filtersToSend.orderType = '3'; // Long term Rentals
        }
        return this._ordersService.searchOrders(page, pageSize, this.filtersToSend);
      };
    });

    this.setDefaultDates();

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.allOrders.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'clientName',
        displayed: true,
        translationKey: 'orders.allOrders.clientName',
        template: this.clientName,
        sortable: true,
        excel: {
          objectRef: ['clientInformation.firstName', 'clientInformation.lastName']
        }
      },
      {
        name: 'startRentalPoint',
        displayed: true,
        translationKey: 'orders.allOrders.startRentalPoint',
        template: this.startRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['startRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'returnRentalPoint',
        displayed: true,
        translationKey: 'orders.allOrders.returnRentalPoint',
        template: this.returnRentalPoint,
        sortable: true,
        excel: {
          objectRef: ['endRentalPoint.rentalPointTranslation.de']
        }
      },
      {
        name: 'startDate',
        displayed: true,
        translationKey: 'orders.allOrders.startRentalDate',
        template: this.startDate,
        sortable: true,
        excel: {
          objectRef: ['startDate'],
          pipe: 'date'
        }
      },
      {
        name: 'returnDate',
        displayed: true,
        translationKey: 'orders.allOrders.returnRentalDate',
        template: this.returnDate,
        sortable: true,
        excel: {
          objectRef: ['endDate'],
          pipe: 'date'
        }
      },
      {
        name: 'orderAmount',
        displayed: true,
        translationKey: 'orders.allOrders.orderAmount',
        template: this.orderAmount,
        sortable: true,
        excel: {
          objectRef: ['discountedPrice'],
          pipe: 'currency'
        }
      },
      {
        name: 'orderType',
        displayed: true,
        translationKey: 'orders.allOrders.orderType',
        template: this.orderType,
        sortable: true,
        excel: {
          objectRef: ['orderType'],
          pipe: 'orderTypeAllOrders'
        }
      },
      {
        name: 'orderStatus',
        displayed: true,
        translationKey: 'orders.allOrders.orderStatus',
        template: this.orderStatus,
        sortable: true,
        excel: {
          objectRef: ['status'],
          pipe: 'orderStatus',
          pipeAdditionalValues: ['endDate']
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
  }

  private setDefaultDates() {
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), 0, 1);
    this.filters.startDate = moment(firstDayOfMonth).format('YYYY-MM-DD');
    this.filters.endDate = moment(today).format('YYYY-MM-DD');

  }

  ngAfterViewInit() {
    this.loadOnStart.next(true);
  }

  populateClientRentalPointOptions(result: any) {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        result.rentalPoints.forEach(rentalPoint => {
          this.rentalPointOptions = [...this.rentalPointOptions,
          { value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] }];
        });
        break;
      default:
        result.forEach(client => {
          this.clientOptions.push({ value: client.id, label: client.businessName });
        });
        this._clients = result;
        break;
    }
  }

  onClientSelectChange(clientId: number) {
    this.rentalPointOptions = [{ value: undefined, label: this._translate.instant('orders.allOrders.all') }];
    if (clientId) {
      const selectedClient = this._clients.filter(client => client.id === clientId)[0];
      selectedClient.rentalPoints.forEach(rentalPoint => {
        this.rentalPointOptions.push({ value: rentalPoint.accountId, label: rentalPoint.rentalPointTranslation[this.currentLang] });
      });
    }
  }

  onStartRentalSelectChange(startRentalPointAccountId: string) {
    if (startRentalPointAccountId) {
      this.filters.returnRentalPointAccountId = undefined;
    }
  }

  onReturnRentalSelectChange(returnRentalPointAccountId: string) {
    if (returnRentalPointAccountId) {
      this.filters.startRentalPointAccountId = undefined;
    }
  }

  onTableReset() {
    switch (this.currentUserType.userTypeNumber) {
      case 1:
        this.filters = { clientId: this.currentUserType.clientId };
        break;
      case 2:
        this.filters = { startRentalPointAccountId: this.currentUserType.accountId };
        break;
    }
    this.filtersToSend = { ... this.filters };
    this.setDefaultDates();
  }

  onRentalCloseButtonClick(content, contractId, orderType) {
    this.rentalCloseDate = new Date();
    this._modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((modalAction) => {
      const closeDate = moment(this.rentalCloseDate).format('YYYY-MM-DD') + " " + moment(this.rentalCloseTime).format('HH:mm');
      const today = new Date();
      this._ordersService.closeOrderManually(orderType, contractId, this.currentUserType.accountId, closeDate, today).subscribe(result => {
        this._snackBar.open(this._translate.instant('orders.allOrders.message1'), '', {
          duration: 3500,
        });
        this.loadPage = (page: number, pageSize: number) => this._ordersService.searchOrders(page, pageSize, this.filtersToSend);
      });
    }, (reason) => {
    });
  }

  beforeSubmit() {
    this.filtersToSend = { ... this.filters };
    if (this.filtersToSend.returnRentalPointAccountId || this.filtersToSend.startRentalPointAccountId) {
      this.filtersToSend.clientId = undefined;
    }
  }

  onRentalDeleteButtonClick(modal, id) {
    this._modalService.open(modal, { ariaLabelledBy: 'modal-basic-title', backdrop: false }).result.then((modalAction) => {
      this._ordersService.deleteDirectRental(id, parseInt(this.currentUserType.accountId)).subscribe(res => {
        this.dataTable.refresh();
      });
    });
  }
}


