import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicePointService } from '../shared/service-points.service';
import { ServicePoint } from '../../../models/ServicePoint';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-service-point-edit',
  templateUrl: './service-point-edit.component.html',
  styleUrls: ['./service-point-edit.component.scss']
})
export class ServicePointEditComponent {
  public updateServicePointForm: FormGroup;
  public errorArray = [];
  public districtOptions: Option[] = [];
  public countryOptions: Option[] = [];

  constructor(private _translate: TranslateService, private _route: ActivatedRoute, private _servicePointService: ServicePointService,
    public _snackBar: MatSnackBar, private _router: Router) {
    this.updateServicePointForm = new FormGroup({
      id: new FormControl(),
      en: new FormControl(null, Validators.required),
      it: new FormControl(null, Validators.required),
      de: new FormControl(null, Validators.required),
      region: new FormControl(null, Validators.required),
      street: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      districtId: new FormControl(null, Validators.required),
      countryId: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      latitude: new FormControl(null, Validators.required),
      longitude: new FormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._servicePointService.getServicePoint(params['servicePointId']).subscribe(result => {
        console.log('res:', result);
        const servicePoint = result;
        const districts = this._servicePointService.getDistricts();
        const countries = this._servicePointService.getCountries();
        forkJoin([districts, countries]).subscribe(res => {
          console.log('districts', res[0]);
          console.log('countries', res[1]);
          res[0].forEach(district => {
            this.districtOptions.push({ value: district.id, label: district.name });
          });
          res[1].forEach(country => {
            this.countryOptions.push({ value: country.id, label: country.name });
          });
        });
        this.updateServicePointForm.patchValue(servicePoint);
      });
    });
  }

  updateServicePointSubmit() {
    if (this.updateServicePointForm.valid) {
      const newServicePoint = ServicePoint.fromDto(this.updateServicePointForm.value);
      this._servicePointService.updateServicePoint(newServicePoint).subscribe(result => {
        this._snackBar.open(this._translate.instant('servicePoints.editServicePoint.servicePointUpdated'), '', {
          duration: 2000,
        }).afterDismissed().subscribe(() => {
          this._router.navigate(['/service-points/list']);
        });
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.updateServicePointForm, this.updateServicePointForm.controls);
  }
}
