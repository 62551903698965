export const userType = {
  1: 'Administrator',
  2: 'Manager'
};

// TODO: add the different stauts of the bikes here
export const bikeStatus = {
  1: {value: 'Available', title: 'Available'},
  2: {value: 'Rented', title: 'Rented'},
  3: {value: 'Big maintainance', title: 'Big maintainance'},
  4: {value: 'Local maintainance', title: 'Local maintainance'},
  5: {value: 'Bike in transit', title: 'Bike in transit'},
  6: {value: 'Bike no more available', title: 'Bike no more available'},
  7: {value: 'Bike Sold', title: 'Bike Sold'}
};

export const orderStatus = {
  1: 'Booked Order',
  2: 'On Going',
  3: 'Closed',
  4: 'Reservation'
};
