import { PriceList } from './PriceList';

export class Discount {
  public id: number;
  public day: number;
  public discount: number;
  public priceList: PriceList;

  public static fromDto(d): Discount {
    const discount = new Discount();
    if (!d) {
      return null;
    }

    discount.id = d.id;
    discount.day = d.day;
    discount.discount = d.discount;
    discount.priceList = PriceList.fromDto(d.priceList);

    return discount;
  }
}
