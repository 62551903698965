import { Routes } from '@angular/router';
import { AuthGuard } from '../../auth-guard.service';
import { WartungUsersComponent } from './wartung-users.component';
import { WartungUsersListComponent } from './wartung-users-list/wartung-users-list.component';
import { WartungUserAddUpdateComponent } from './wartung-user-add-update/wartung-user-add-update.component';

export const WARTUNG_USERS_ROUTES: Routes = [
  {
    path: 'wartung-users', component: WartungUsersComponent, canActivate: [AuthGuard], children: [
      {path: 'list', component: WartungUsersListComponent, canActivate: [AuthGuard]},
      {path: 'add', component: WartungUserAddUpdateComponent, canActivate: [AuthGuard]},
      {path: 'edit/:id', component: WartungUserAddUpdateComponent, canActivate: [AuthGuard]}
    ]
  }
];
