import { FormGroup, ValidationErrors } from '@angular/forms';

export class ValidationUtils {

    public static getFormValidationErrors(form, controls, subFormGroupNames?: Array<string>, errorsArray?): Array<any> {
        errorsArray = (!errorsArray ? new Array() : errorsArray);
        const subFormGroupArray = (subFormGroupNames ? subFormGroupNames : new Array());
        Object.keys(controls).forEach(key => {
            let subFormGroup;
            if (subFormGroupArray.length > 0) {
                subFormGroup = (<FormGroup>form.get(subFormGroupArray).get(key)).controls;
            } else {
                subFormGroup = (<FormGroup>form.get(key)).controls;
            }
            if (subFormGroup !== undefined) {
                subFormGroupArray.push(key);
                this.getFormValidationErrors(form, subFormGroup, subFormGroupArray, errorsArray);
            } else {
                let controlErrors: ValidationErrors;
                if (subFormGroupArray.length > 0) {
                    controlErrors = form.get(subFormGroupArray).get(key).errors;
                } else {
                    controlErrors = form.get(key).errors;
                }
                if (controlErrors != null) {
                    errorsArray[key] = '';
                    Object.keys(controlErrors).forEach(keyError => {
                        console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
                        errorsArray[key] = 'This field is required';
                    });
                }
            }
        });
        return errorsArray;
    }
}
