import { Component, OnInit, ViewChild, TemplateRef, AfterViewChecked } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { CreditSearchCritertia } from '../shared/CreditSearchCritertia';
import { OrdersService } from '../shared/orders.service';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html',
  styleUrls: ['./credits.component.scss']
})
export class CreditsComponent implements OnInit {

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;
  filters: CreditSearchCritertia = {};

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('creditCode', { static: true })
  creditCode: TemplateRef<any>;

  @ViewChild('creditTitle', { static: true })
  creditTitle: TemplateRef<any>;

  @ViewChild('createdDate', { static: true })
  createdDate: TemplateRef<any>;

  @ViewChild('consumedDate', { static: true })
  consumedDate: TemplateRef<any>;

  @ViewChild('amount', { static: true })
  amount: TemplateRef<any>;

  @ViewChild('consumed', { static: true })
  consumed: TemplateRef<any>;

  @ViewChild('remaining', { static: true })
  remaining: TemplateRef<any>;

  @ViewChild('comment', { static: true })
  comment: TemplateRef<any>;

  @ViewChild('expiredDate', { static: true })
  expiredDate: TemplateRef<any>;

  @ViewChild('status', { static: true })
  status: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  columns: DataTableColumn[] = [];

  constructor(private _ordersService: OrdersService) {
    this.loadPage = (page: number, pageSize: number) => this._ordersService.search(page, pageSize, this.filters);
  }

  ngOnInit() {
    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.credits.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['creditId']
        }
      },
      {
        name: 'creditCode',
        displayed: true,
        translationKey: 'orders.credits.creditCode',
        template: this.creditCode,
        sortable: true,
        excel: {
          objectRef: ['creditCode']
        }
      },
      {
        name: 'creditTitle',
        displayed: true,
        translationKey: 'orders.credits.clientName',
        template: this.creditTitle,
        sortable: true,
        excel: {
          objectRef: ['creditTitle']
        }
      },
      {
        name: 'createdDate',
        displayed: true,
        translationKey: 'orders.credits.createdDate',
        template: this.createdDate,
        sortable: true,
        excel: {
          objectRef: ['createdDate'],
          pipe: 'date'
        }
      },
      {
        name: 'consumedDate',
        displayed: true,
        translationKey: 'orders.credits.consumedDate',
        template: this.consumedDate,
        sortable: true,
        excel: {
          objectRef: ['consumedDate'],
          pipe: 'date'
        }
      },
      {
        name: 'amount',
        displayed: true,
        translationKey: 'orders.credits.amount',
        template: this.amount,
        sortable: true,
        excel: {
          objectRef: ['amount'],
          pipe: 'currency'
        }
      },
      {
        name: 'consumed',
        displayed: true,
        translationKey: 'orders.credits.consumed',
        template: this.consumed,
        sortable: true,
        excel: {
          objectRef: ['consumed'],
          pipe: 'currency'
        }
      },
      {
        name: 'remaining',
        displayed: true,
        translationKey: 'orders.credits.remaining',
        template: this.remaining,
        sortable: true,
        excel: {
          objectRef: ['remaining'],
          pipe: 'currency'
        }
      },
      {
        name: 'comment',
        displayed: true,
        translationKey: 'orders.credits.comment',
        template: this.comment,
        sortable: true,
        excel: {
          objectRef: ['comment']
        }
      },
      {
        name: 'expiredDate',
        displayed: true,
        translationKey: 'orders.credits.expiredDate',
        template: this.expiredDate,
        sortable: true,
        excel: {
          objectRef: ['expiredDate']
        }
      },
      {
        name: 'status',
        displayed: true,
        translationKey: 'orders.credits.status',
        template: this.status,
        sortable: true,
        excel: {
          objectRef: ['status'],
          pipe: 'creditsStatus'
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: '',
        template: this.actions,
        sortable: true,
        additionalClass: 'text-right',
        trAddidionalClass: 'text-right'
      },
    ];
  }

}
