<div id="resultContainer">
  <ng-container *ngIf="confirmationResult; else loadingTemplate">
    <ng-container *ngIf=" confirmationResult.status===200; else errorTemplate">
      <div class="text-white text-center">
        <h3>{{'orders.orderForm.finalMessage1' | translate}}<br />
          <span class="blue-color">{{contractId}}</span>
          {{'orders.orderForm.finalMessage2' | translate}}
        </h3>
        <br />
        <p>{{'orders.orderForm.finalMessage4' | translate}}</p>
        <p><span>{{'orders.orderForm.finalMessage5' | translate}} </span>
          <strong class="blue-color text-uppercase">
            <a [href]="pdfLink" target="_blank" >{{'orders.orderForm.here' | translate}}</a></strong> <span translate>
            {{'orders.orderForm.finalMessage6' | translate}}
          </span>
        </p>
        <br />
        <p>{{'orders.orderForm.finalMessage7' | translate}}</p>
        <br />
        <p><span>{{'orders.orderForm.finalMessage8' | translate}} </span><a
            href="mailto:order@papinsport.com">order&#64;papinsport.com</a></p>
      </div>
    </ng-container>
    <ng-template #errorTemplate>
      <div class="text-white text-center">
        <h2>{{'orders.orderForm.finalMessageNoSuccess1' | translate}}</h2>
        <p><span>{{'orders.orderForm.finalMessageNoSuccess2' | translate}}</span> {{contractId}}
          <span>{{'orders.orderForm.finalMessageNoSuccess3' | translate}}</span></p>
        <p>{{'orders.orderForm.finalMessageNoSuccess4' | translate}}</p>
        <p>{{confirmationResult.message}}</p>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #loadingTemplate>
    <div class="d-flex justify-content-center">
      <div class="spinner-border" role="status" style="color: #FFFFFF;">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
</div>
