import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { DataTableComponent } from './data-table/data-table.component';
import { DataTableColumnComponent } from './data-table/data-table-column/data-table-column.component';
import { PaginationComponent } from './pagination/pagination.component';
import { DataFiltersComponent } from './data-filters/data-filters.component';
import { DataFilterGroupComponent } from './data-filters/data-filter-group/data-filter-group.component';
import { PaginationService } from './pagination.service';
import { DataFilterInputComponent } from './data-filters/data-filter-input/data-filter-input.component';
import { DataFilterButtonComponent } from './data-filters/data-filter-button/data-filter-button.component';
import { DataFilterSelectComponent } from './data-filters/data-filter-select/data-filter-select.component';
import { DataFilterDatepickerComponent } from './data-filters/data-filter-datepicker/data-filter-datepicker.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DatepickerComponent } from './datepicker/datepicker.component';
import { DataFilterSelectSearchComponent } from './data-filters/data-filter-select-search/data-filter-select-search.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { SelectSearchComponent } from './select-search/select-search.component';
import { SelectSearchReactiveFormComponent } from './select-search-reactive-form/select-search-reactive-form.component';
import { ReplaceTxtPipe } from '../pipes/replace-txt.pipe';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule,
    RouterModule,
    NgbModule,
    NgSelectModule,
    QRCodeModule
  ],
  declarations: [
    DataTableComponent,
    DataTableColumnComponent,
    PaginationComponent,
    DataFiltersComponent,
    DataFilterGroupComponent,
    DataFilterInputComponent,
    DataFilterButtonComponent,
    DataFilterSelectComponent,
    DataFilterDatepickerComponent,
    DatepickerComponent,
    DataFilterSelectSearchComponent,
    SelectSearchComponent,
    SelectSearchReactiveFormComponent,
    ReplaceTxtPipe
  ],
  exports: [
    QRCodeModule,
    ReactiveFormsModule,
    DataTableComponent,
    DataTableColumnComponent,
    PaginationComponent,
    DataFiltersComponent,
    DataFilterGroupComponent,
    DataFilterInputComponent,
    DataFilterButtonComponent,
    DataFilterSelectComponent,
    DataFilterDatepickerComponent,
    DatepickerComponent,
    DataFilterSelectSearchComponent,
    SelectSearchComponent,
    SelectSearchReactiveFormComponent,
    TranslateModule
  ], providers: [
    PaginationService
  ]
})
export class SharedModule { }
