import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { ProductsComponent } from './products.component';
import { RouterModule } from '@angular/router';
import { PRODUCTS_ROUTES } from './products.routes';
import { BikeEquipmentListComponent } from './bike-equipment-list/bike-equipment-list.component';
import { ProductStatusPipe } from './shared/pipes/product-status.pipe';
import { BikeEquipmentSummaryComponent } from './bike-equipment-summary/bike-equipment-summary.component';
import { AddBikeEquipmentComponent } from './add-bike-equipment/add-bike-equipment.component';
import { EditBikeEquipmentComponent } from './edit-bike-equipment/edit-bike-equipment.component';
import { BikeEquipmentPreparationComponent } from './bike-equipment-preparation/bike-equipment-preparation.component';
import { RentalPointCardItemComponent } from './bike-equipment-preparation/rental-point-card-item/rental-point-card-item.component';
import { ObjectFilterPipe } from './bike-equipment-preparation/pipes/object-filter.pipe';
import { MultipleQrAssigComponent } from './multiple-qr-assig/multiple-qr-assig.component';
import { BikeStatisticsComponent } from './bike-statistics/bike-statistics.component';
import { BikeStatisticsCardItemComponent } from './bike-statistics/bike-statistics-card-item/bike-statistics-card-item.component';
import { BikesByQrCodeComponent } from './bikes-by-qr-code/bikesByQrCode.component';
import { BikeStatisticsReturnRentedCardItemComponent } from './bike-statistics/bike-statistics-return-rented-card-item/bike-statistics-return-rented-card-item.component';
import { WebcamModule } from 'ngx-webcam';
import { BikeSellListComponent } from './bike-sell-list/bike-sell-list.component';
import { BikeSellDetailComponent } from './bike-sell-detail/bike-sell-detail.component';
import { BikeCheckinListComponent } from './edit-bike-equipment/bike-checkin-list/bike-checkin-list.component';
import { RegisteredTypePipe } from './shared/pipes/registered-type.pipe';

@NgModule({
    providers: [DatePipe],
    imports: [
        SharedModule,
        FormsModule,
        CommonModule,
        RouterModule.forChild(PRODUCTS_ROUTES),
        WebcamModule
    ],
    exports: [],
    declarations: [
        ProductsComponent,
        BikeEquipmentListComponent,
        BikeCheckinListComponent,
        ProductStatusPipe,
        RegisteredTypePipe,
        BikeEquipmentSummaryComponent,
        AddBikeEquipmentComponent,
        EditBikeEquipmentComponent,
        BikeEquipmentPreparationComponent,
        RentalPointCardItemComponent,
        ObjectFilterPipe,
        MultipleQrAssigComponent,
        BikeStatisticsComponent,
        BikeStatisticsCardItemComponent,
        BikesByQrCodeComponent,
        BikeStatisticsReturnRentedCardItemComponent,
        BikeSellListComponent,
        BikeSellDetailComponent
    ],
})

export class ProductsModule { }
