import { Routes } from '@angular/router';
import { ProfileComponent } from './profile.component';
import { AuthGuard } from '../../auth-guard.service';
import { UpdateProfileComponent } from './update-profile/update-profile.component';


export const PROFILE_ROUTES: Routes = [
    {
        path: 'profile', component: ProfileComponent, canActivate: [AuthGuard], children: [
            { path: 'update', component: UpdateProfileComponent, canActivate: [AuthGuard]}
        ]
    }
];
