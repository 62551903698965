import { Product } from './Product';

export class BikeModel {
    public id: number;
    public name: string;
    public products: Product[];

    public static fromDto(b): BikeModel {
        const bikeModel = new BikeModel();
        if (!b) {
            return null;
        }
        bikeModel.id = b.id;
        bikeModel.name = b.name;
        if (b.products) {
            bikeModel.products = b.products.map(product => Product.fromDto(product));
        }

        return bikeModel;
    }
}

export class BikeModelSearch {
  public id: number;
  public name: string;

  public static fromDto(b): BikeModelSearch {
      const bikeModel = new BikeModelSearch();
      if (!b) {
          return null;
      }
      bikeModel.id = b.id;
      bikeModel.name = b.name;

      return bikeModel;
  }
}
