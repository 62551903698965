import { Pipe, PipeTransform } from '@angular/core';
import { OrderTypeEnum } from '../../../../shared/enums/OrderTypeEnum';

@Pipe({
  name: 'orderType'
})
export class OrderTypePipe implements PipeTransform {

  transform(value: number): string {
    if (value === 1) {
      return OrderTypeEnum.ONE;
    } else if (value === 2) {
      return OrderTypeEnum.TWO;
    } else if (value === 3) {
      return OrderTypeEnum.THREE;
    } else {
      return '';
    }
  }

}
