import { OrderReturn } from './OrderReturn';
import { BikeType } from './BikeType';
import { Account } from './Account';
import { RentalPoint } from './RentalPoint';

export class ReturnBike {
    public id: number;
    public productId: number;
    public diffRentalCost: number;
    public qrCode: string;
    public returnedAt: string;
    public returnedRentalPoint: any;
    public replaced: boolean;
    /**
     * 0 = Normal
     * 1 = Partial return
     * 2 = Partial cancellation
     */
    public returnType: number;
    public comment: string;
    public replacedAt: string;
    public replacedPrice: number;
    public replacedDiscountedPrice: number;
    public replacedProductId: number;
    public replacedBikeTypeId: BikeType;
    public replacedQRCode: number;
    public orderReturn: OrderReturn;
    public bikeType: BikeType;

    public static fromDto(r): ReturnBike {
        const returnBike = new ReturnBike();
        if (!r) {
            return null;
        }

        returnBike.id = r.id;
        returnBike.productId = r.productId;
        returnBike.diffRentalCost = r.diffRentalCost;
        returnBike.qrCode = r.qrCode;
        returnBike.returnedAt = r.returnedAt;
        if (r.returnedRentalPoint) {
            returnBike.returnedRentalPoint = (isNaN(r.returnedRentalPoint) ?
                RentalPoint.fromDto(r.returnedRentalPoint) : r.returnedRentalPoint);
        }
        returnBike.replaced = r.replaced;
        returnBike.returnType = r.returnType;
        returnBike.comment = r.comment;
        returnBike.orderReturn = OrderReturn.fromDto(r.orderReturn);
        returnBike.replacedAt = r.replacedAt;
        returnBike.replacedPrice = r.replacedPrice;
        returnBike.replacedDiscountedPrice = r.replacedDiscountedPrice;
        returnBike.replacedProductId = r.replacedProductId;
        returnBike.replacedBikeTypeId = BikeType.fromDto(r.replacedBikeTypeId);
        returnBike.replacedQRCode = r.replacedQRCode;
        returnBike.bikeType = BikeType.fromDto(r.bikeType);

        return returnBike;
    }
}
