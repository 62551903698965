import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { ProductSearchCritertia } from '../../components/products/shared/ProductSearchCritertia';


class User {
  public username: string;
  public password: string;
}
@Component({
  selector: 'app-data-filters',
  templateUrl: './data-filters.component.html',
  styleUrls: ['./data-filters.component.css']
})


export class DataFiltersComponent implements OnInit {

  user = new User();

  @Input()
  showExcelExportButton: boolean;

  @Input()
  filters: ProductSearchCritertia;

  @Input()
  showExcelExportBikeListButton: boolean;

  @Input()
  showCSVExportBikeListForInsuranceButton: boolean;

  @Input()
  loadOnStart: Subject<boolean>;

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onExcelExportBikeListClick: EventEmitter<ProductSearchCritertia> = new EventEmitter<ProductSearchCritertia>();

  // tslint:disable-next-line:no-output-on-prefix
  @Output()
  onCSVExportBikeListForInsuranceClick: EventEmitter<ProductSearchCritertia> = new EventEmitter<ProductSearchCritertia>();

  resetEmitter = new Subject<void>();
  resetObservable = this.resetEmitter.asObservable();

  applyEmitter = new Subject<void>();
  applyObservable = this.applyEmitter.asObservable();

  excelExportEmitter = new Subject<void>();
  excelExportObservable = this.excelExportEmitter.asObservable();


  excelExportBikeListEmitter = new Subject<void>();

  CSVExportBikeListForInsuranceEmitter = new Subject<void>();

  constructor() {
  }

  ngOnInit() {
    this.loadOnStart?.subscribe(l => this.apply());
  }

  reset() {
    this.resetEmitter.next();
  }

  apply() {
    console.log('testemit');
    this.applyEmitter.next();
  }

  excelExport() {
    console.log('excelExport');
    this.excelExportEmitter.next();
  }

  onExcelExportBikeListClickEvent() {
    this.onExcelExportBikeListClick.emit();
  }

  onCSVExportBikeListForInsuranceClickEvent() {
    this.onCSVExportBikeListForInsuranceClick.emit();
  }

  getResetObservable() {
    return this.resetObservable;
  }

  getApplyObservable() {
    return this.applyObservable;
  }

  getExcelExportObservable() {
    return this.excelExportObservable;
  }


}
