import { DiscountCard } from './DiscountCard';
import { ClientInformation } from './ClientInformation';
import { InvoiceBike } from './InvoiceBike';
import { Account } from './Account';
import { Invoice } from './Invoice';

export class OnlineBooking {
    public id: number;
    public startDate: string;
    public endDate: string;
    public pickUpTime: string;
    public startRentalPoint: any;
    public endRentalPoint: any;
    public totalPrice: number;
    public discountedPrice: number;
    public discountedBookingPrice: number;
    public transactionId: string;
    public invoiceNo: number;
    public invoiceNoIt: number;
    public placementTime: string;
    public contractId: string;
    public transactionStatus: number;
    public reservation: number;
    public country: number;
    public language: string;
    public discountCard: DiscountCard[];
    public onlineBooking: boolean;
    public invoice: Invoice;
    public invoiceBike: InvoiceBike[];
    public clientInformation = new ClientInformation();
    public bookedRental: any;
    public costForBikes = 0;
    public prepared: boolean;
    public cancelledAt: string;
    public cancelledBy: Account;

    constructor() {
        this.startRentalPoint = new Account();
        this.endRentalPoint = new Account();
    }

    public static fromDto(o): OnlineBooking {
        const onlineBooking = new OnlineBooking();
        if (!o) {
            return undefined;
        }

        onlineBooking.id = o.id;
        onlineBooking.startDate = o.startDate;
        onlineBooking.endDate = o.endDate;
        onlineBooking.pickUpTime = o.pickUpTime;
        onlineBooking.startRentalPoint = (!isNaN(o.startRentalPoint) ? Account.fromDto(o.startRentalPoint) : o.startRentalPoint);
        onlineBooking.endRentalPoint = (!isNaN(o.endRentalPoint) ? Account.fromDto(o.endRentalPoint) : o.endRentalPoint);
        onlineBooking.totalPrice = o.totalPrice;
        onlineBooking.discountedPrice = o.discountedPrice;
        onlineBooking.discountedBookingPrice = o.discountedBookingPrice;
        onlineBooking.transactionId = o.transactionId;
        onlineBooking.invoiceNo = o.invoiceNo;
        onlineBooking.invoiceNoIt = o.invoiceNoIt;
        onlineBooking.placementTime = o.placementTime;
        onlineBooking.contractId = o.contractId;
        onlineBooking.transactionStatus = o.transactionStatus;
        onlineBooking.reservation = o.reservation;
        onlineBooking.country = o.country;
        onlineBooking.language = o.language;
        onlineBooking.discountCard = o.discountCard;
        onlineBooking.invoiceBike = o.invoiceBike?.map(i => InvoiceBike.fromDto(i));
        onlineBooking.clientInformation = o.clientInformation;
        onlineBooking.bookedRental = o.bookedRental;
        if (o.costForBikes) {
            onlineBooking.costForBikes = o.costForBikes;
        }
        onlineBooking.prepared = o.prepared;
        if (o.cancelledAt) {
            onlineBooking.cancelledAt = o.cancelledAt;
        }
        if (o.cancelledBy) {
            onlineBooking.cancelledBy = Account.fromDto(o.cancelledBy);
        }

        return onlineBooking;
    }
}
