<div class="container" style="padding-bottom: 20px;">
  <div class="row">
    <div class="col">
      <div class="card" id="credit-paper">
        <div class="card-body">
          <ng-container *ngIf="!credit.orderReturn; else elseTemplate">
            <blockquote class="blockquote text-center">
              <p class="mb-0">
                Papin Sport GmbH / srl <br />
                Freisingerstr. 9 Via Freising <br />
                I-39038 Innichen / San Candido (BZ)<br />
                MwSt.Nr.-P.Iva 00480120211
              </p>
            </blockquote>
          </ng-container>
          <ng-template #elseTemplate>
            <ng-container *ngIf="credit.orderReturn.rentalPointId.country === 1; else italyTitleTemplate">
              <blockquote class="blockquote text-center">
                <p class="mb-0">Papin Sport & Freizeit GmbH <br /> Arnbach 41F 9920 Arnbach / Sillian<br />
                  ATU65441346 </p>
              </blockquote>
            </ng-container>
            <ng-template #italyTitleTemplate>
              <blockquote class="blockquote text-center">
                <p class="mb-0">
                  Papin Sport GmbH / srl <br />
                  Freisingerstr. 9 Via Freising <br />
                  I-39038 Innichen / San Candido (BZ)<br />
                  MwSt.Nr.-P.Iva 00480120211
                </p>
              </blockquote>
            </ng-template>
          </ng-template>
          <div class="text-center mt-2 mb-2">
            <img [src]="'assets/images/papin-rent-a-bike-logo.png'" class="papin-logo" alt="Papin Logo"
              class="img-responsive" height="65">
          </div>
          <div class="text-center">
            <ngx-barcode6 [bc-value]="credit.creditId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1">
            </ngx-barcode6>
          </div>
          <div id="credit-details" class="mt-3">
            <dl class="row" style="margin-bottom: 0px;">
              <dt class="col-6">{{'orders.papers.credit.date' | translate}}:</dt>
              <dd class="col-6 text-right">{{credit.createdDate | date: 'dd-MM-yyyy'}} {{credit.createdTime}}</dd>
              <dt class="col-6">{{'orders.papers.credit.creditId' | translate}}:</dt>
              <dd class="col-6 text-right">{{credit.creditId}}</dd>
              <dt class="col-6">{{'orders.papers.credit.creditCode' | translate}}:</dt>
              <dd class="col-6 text-right">{{credit.creditCode}}</dd>
            </dl>
          </div>
          <div class="mb-3">
            <hr class="dashed" />
            <div class="row">
              <dt class="col-6">{{'orders.papers.credit.credit' | translate}}:</dt>
              <dd class="col-6 text-right">{{credit.amount | currency:'€ '}}</dd>
            </div>
          </div>
          <div>
            <blockquote class="blockquote" style="font-size: 14px;">
              {{'orders.papers.credit.warningMsg' | translate}}{{validYear}} {{'orders.papers.credit.warningMsg2' | translate}}
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
