<h4>{{'products.editBikeEquipment.checkinHistory' | translate}}</h4>
<app-data-table
  [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [showSearch]="false">
</app-data-table>
<ng-template #registeredAt let-registeredAt="registeredAt">
  <ng-container>{{registeredAt | date: 'dd-MM-yyyy HH:mm'}}</ng-container>
</ng-template>
<ng-template #place let-place="place">
  <ng-container>{{place}}</ng-container>
</ng-template>
<ng-template #registeredType let-registeredType="registeredType">
  <ng-container>{{registeredType | registeredType}}</ng-container>
</ng-template>
