<div class="container-fluid">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-2">
              <div class="form-group">
                <label>{{'priceList.update.priceList' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="priceListId"
                  (change)="onPriceListSelectChange()">
                  <option *ngFor="let priceList of priceListsArray" [value]="priceList.value">{{priceList.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-2">
              <div class="form-group">
                <label>{{'priceList.update.period' | translate}}</label>
                <select class="form-control form-control-sm" [(ngModel)]="periodId" [disabled]="disablePeriodSelect">
                  <option *ngFor="let period of periodsArray" [value]="period.value">{{period.label}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col text-right">
              <button type="button" class="btn btn-primary btn-sm" style="margin-top: 30px;"
                (click)="onSelectButtonClick()">{{'priceList.update.select' | translate}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3">
    <div class="col">
      <div class="card" [hidden]="!showContainer">
        <div class="card-header text-right">
          <button type="button" class="btn btn-primary btn-sm" [disabled]="disableSaveBtn"
            (click)="onSaveButtonClick()">{{'priceList.update.save' | translate}}</button>
        </div>
        <div class="card-body">
          <div [hidden]="loading">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
          <table class="table" [hidden]="!loading">
            <thead>
              <tr>
                <th>ID</th>
                <th>{{'priceList.update.price' | translate}}</th>
                <th>{{'priceList.update.pricePerHour' | translate}}</th>
                <th>{{'priceList.update.priceHalfDay' | translate}}</th>
                <th>{{'priceList.update.bikeType' | translate}}</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of pricePerPeriodArray">
                <td>
                  {{element.id}}
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" [(ngModel)]="element.price" />
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" [(ngModel)]="element.pricePerHour" />
                </td>
                <td>
                  <input type="number" class="form-control form-control-sm" [(ngModel)]="element.priceHalfDay" />
                </td>
                <td>
                  {{element.bikeType.bikeTypeTranslation['de']}}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer text-right">
          <button type="button" class="btn btn-primary btn-sm" [disabled]="disableSaveBtn"
            (click)="onSaveButtonClick()">{{'priceList.update.save' | translate}}</button>
        </div>
      </div>
    </div>
  </div>
</div>