import { Routes } from '@angular/router';
import { ProductsComponent } from './products.component';
import { AuthGuard } from '../../auth-guard.service';
import { BikeEquipmentListComponent } from './bike-equipment-list/bike-equipment-list.component';
import { BikeEquipmentSummaryComponent } from './bike-equipment-summary/bike-equipment-summary.component';
import { AddBikeEquipmentComponent } from './add-bike-equipment/add-bike-equipment.component';
import { EditBikeEquipmentComponent } from './edit-bike-equipment/edit-bike-equipment.component';
import { BikeEquipmentPreparationComponent } from './bike-equipment-preparation/bike-equipment-preparation.component';
import { MultipleQrAssigComponent } from './multiple-qr-assig/multiple-qr-assig.component';
import { BikeStatisticsComponent } from './bike-statistics/bike-statistics.component';
import { BikesByQrCodeComponent } from './bikes-by-qr-code/bikesByQrCode.component';
import { BikeSellListComponent } from './bike-sell-list/bike-sell-list.component';
import { BikeSellDetailComponent } from './bike-sell-detail/bike-sell-detail.component';

export const PRODUCTS_ROUTES: Routes = [
    {
        path: 'products', component: ProductsComponent, canActivate: [AuthGuard], children: [
            { path: 'bikes-equipments', component: BikeEquipmentListComponent, canActivate: [AuthGuard] },
            { path: 'summary', component: BikeEquipmentSummaryComponent, canActivate: [AuthGuard] },
            { path: 'preparation', component: BikeEquipmentPreparationComponent, canActivate: [AuthGuard] },
            { path: 'add', component: AddBikeEquipmentComponent, canActivate: [AuthGuard] },
            { path: 'edit/:productId', component: EditBikeEquipmentComponent, canActivate: [AuthGuard] },
            { path: 'multiple-qr-assign', component: MultipleQrAssigComponent, canActivate: [AuthGuard] },
            { path: 'statistics', component: BikeStatisticsComponent, canActivate: [AuthGuard] },
            { path: 'bikebyqrcode', component: BikesByQrCodeComponent, canActivate: [AuthGuard] },
            { path: 'bikes-sell', component: BikeSellListComponent, canActivate: [AuthGuard] },
            { path: 'bikes-sell-detail/:soldCheckListBikeId', component: BikeSellDetailComponent, canActivate: [AuthGuard] },
        ]
    }
];
