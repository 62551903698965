<div id="invoice-print-container" class="container" style="width: 1100px !important; max-width: 1100px !important;">
  <div class="row" id="title-bar">
    <div class="col-4">
      <img [attr.src]="imagePath + '/papin-rent-a-bike-logo.png'" id="invoice-logo" alt="Papin Logo"
        class="img-responsive">
    </div>
    <div class="col-4">
      <ng-container *ngIf="order.startRentalPoint.rentalPoint.country === 1; else italyDataTemplate">
        <p>Papin Sport & Freizeit GmbH <br /> Arnbach 41F <br /> 9920 - Arnbach / Sillian<br /> ATU65441346 </p>
      </ng-container>
      <ng-template #italyDataTemplate>
        <p>
          Papin Sport GmbH / srl <br />
          Freisingerstr. 9 Via Freising <br />
          I-39038 Innichen / San Candido (BZ)<br />
          MwSt.Nr.-P.Iva 00480120211
        </p>
      </ng-template>
    </div>
    <div class="col-4 text-right right-column">
      <p class="invoice-blue"><span>{{'orders.reservation.reservation' | translate}}</span></p>
      <p class="invoice-blue">papinsport.com</p>
    </div>
  </div>

  <div class="row">
    <div class="col-6">
      <p><span>{{'orders.reservation.orderNr' | translate}}</span> {{order.contractId}} </p>
    </div>
    <div class="col-6 text-right">
      <p><span>{{'orders.reservation.date' | translate}}</span>: {{order.placementTime | date:"dd.MM.yyyy"}}
    </div>
  </div>
  <div class="row" id="body-container">
    <div class="col-6">
      <div id="rentail-point-container">
        <div id="bike-rental-box">
          <h4 class="invoice-blue"><span>{{'orders.reservation.startRentalPoint' | translate}}</span></h4>
          <p id="">
            {{order.startRentalPoint.rentalPoint.businessName}}<br />
            {{order.startRentalPoint.rentalPoint.street}}<br />
            {{order.startRentalPoint.rentalPoint.region}} - {{countries[order.startRentalPoint.rentalPoint.country]}}
            <br /><br />
            Tel. : {{order.startRentalPoint.rentalPoint.phone}}<br />
            E-Mail: order&#64;papinsport.com
          </p>
        </div>
        <div id="return-bike-rental-box">
          <h4 class="invoice-blue"><span translate>{{'orders.reservation.returnRentalPoint' | translate}}</span></h4>
          <p>
            {{order.endRentalPoint.rentalPoint.businessName}}<br />
            {{order.endRentalPoint.rentalPoint.street}}<br />
            {{order.endRentalPoint.rentalPoint.region}} - {{countries[order.endRentalPoint.rentalPoint.country]}}
            <br /><br />
            Tel. : {{order.endRentalPoint.rentalPoint.phone}}<br />
            E-Mail: order&#64;papinsport.com
          </p>
        </div>
      </div>
      <div id="rental-period-box">
        <h4 class="invoice-blue"><span>{{'orders.reservation.rentalPeriod' | translate}}</span></h4>
        <p>
          <span translate>{{'orders.reservation.from' | translate}}</span>:
          <strong>{{order.startDate | date:"dd.MM.yyyy"}}</strong><br />
          <span translate>{{'orders.reservation.to' | translate}}</span>:
          <strong>{{order.endDate | date:"dd.MM.yyyy"}}</strong>
        </p>
      </div>
    </div>
    <div class="col-6">
      <div class="text-right">
        <ngx-barcode6 [bc-value]="order.contractId" [bc-format]="'CODE39'" [bc-height]="" [bc-width]="1"></ngx-barcode6>
      </div>
      <div id="custumer-box">
        <h4 class="invoice-blue"><span>{{'orders.reservation.customer' | translate}}</span></h4>
        <p>
          {{order.clientInformation.firstName}} {{order.clientInformation.lastName}}<br />
          {{order.clientInformation.address}}<br />
          <!-- {{order.clientInformation.postalCode}} - {{order.clientInformation.county}}<br /> -->
          <!-- {{countries[order.country]}}<br/><br/>
          Tel. {{order.clientInformation.phone}}<br /> -->
          <!-- E-Mail: {{order.clientInformation.email}} -->
        </p>
      </div>
      <div id="rental-duratin-box" class=text-right>
        <h4 class="invoice-blue"><span>{{'orders.reservation.rentalDuration' | translate}}</span></h4>
        <p>
          <strong>{{daysDiff}} <span>{{'orders.reservation.days' | translate}}</span></strong>
        </p>
      </div>
    </div>
    <div class="col-12" style="margin-top: 20px;" *ngIf="order.clientInformation.comment">
      <b>{{'orders.reservation.note' | translate}}: </b>{{order.clientInformation.comment}}
    </div>
    <div class="col-12" id="order-table-container">
      <table class="table">
        <thead>
          <td><span>{{'orders.reservation.bikeType' | translate}}</span></td>
          <td><span>{{'orders.reservation.quantity' | translate}}</span></td>
          <!-- <td><span translate>Presi voll</span></td> -->
          <td><span>{{'orders.reservation.priceLordo' | translate}}</span></td>
          <td><span>{{'orders.reservation.rentalDays' | translate}}</span></td>
          <td><span>{{'orders.reservation.height' | translate}}</span></td>
          <!-- <td><span translate>FullPrice</span></td> -->
          <td class="text-center"><span>{{'orders.reservation.fullPrice' | translate}}</span></td>
        </thead>
        <tbody>
          <tr *ngFor="let bike of order.invoiceBike">
            <td>{{bike.bikeType.name}}</td>
            <td>{{bike.quantity}}</td>
            <td>{{bike.bikePrice | currency : "€ "}}</td>
            <td>{{daysDiff}}</td>
            <td><span *ngIf="bike.height>0">{{bike.height}} cm</span></td>
            <td class="text-center">
              <span>{{(bike.quantity * daysDiff * bike.bikePrice) | currency : "€ "}}</span>
            </td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td>
              <p><span>{{'orders.reservation.extraChargeDifferentReturnPoint' | translate}}</span></p>
              <div *ngIf="order.totalPrice > order.discountedPrice">
                <p><span>{{'orders.reservation.discount' | translate}}</span></p>
                <!-- <p><span translate>NetProductPrice</span></p> -->
                <!-- <p><span translate>ReducedNetProductPrice</span></p> -->
              </div>
              <div *ngIf="order.totalPrice == order.discountedPrice">
                <p><span>{{'orders.reservation.netProductPrice' | translate}}</span></p>
              </div>
              <!-- <p><span translate>Taxable</span></p> -->
            </td>
            <td class="text-center">
              <p>{{order.costForBikes | currency : "€ "}}</p>
              <div *ngIf="order.totalPrice > order.discountedPrice">
                <p>
                  - {{ order.totalPrice - order.discountedPrice | currency : "€ " }}
                </p>
                <!-- <p style="text-decoration: line-through;">
                  {{ (calculateVatPrice(order.totalPrice) - calculateVatPrice(order.costForBikes)) | currency : "€ " }}
                </p> -->
                <!-- <p>
                  {{ (calculateVatPrice(order.discountedPrice) - calculateVatPrice(order.costForBikes)) | currency : "€ "}}
                </p> -->
              </div>
              <div *ngIf="order.totalPrice == order.discountedPrice">
                <!-- Ivan fixed task PAP-540 on 25-07-2022 -->
                <!--
                <p>{{ (calculateVatPrice(order.totalPrice) - calculateVatPrice(order.costForBikes)) | currency : "€ "}}
                </p>
                -->
                <p>{{ calculateVatPrice(order.discountedPrice) | currency : "€ "}}</p>
              </div>
              <!-- <p *ngIf="order.totalPrice > order.discountedPrice">
                {{(calculateVatPrice(order.discountedPrice)) | currency : "€ "}}</p>
              <p *ngIf="order.totalPrice == order.discountedPrice">
                {{(calculateVatPrice(order.totalPrice)) | currency : "€ "}}</p> -->
            </td>
          </tr>
          <tr>
            <td colspan="4"></td>
            <td>
              <p><span>{{'orders.reservation.vat' | translate}}</span>
                {{(order.startRentalPoint.rentalPoint.vat * 100) | number:'1.2-2'}} %</p>
            </td>
            <td class="text-center">
              <p>{{ calculateTaxablePrice(order.discountedPrice) | currency : "€ "}}</p>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td><strong>{{'orders.reservation.payInRentalPoints' | translate}}</strong></td>
            <td colspan="4"></td>
            <td class="text-center">
              <strong>{{calculateTotalAmountVatInclusive(order.discountedPrice) | currency : "€ "}}</strong></td>
          </tr>
        </tfoot>
      </table>
      <div id="print-invoice">
        <a class="btn btn-primary btn-round"
         (click)="downloadPdf()">{{'orders.reservation.printReservation' | translate}}</a>
      </div>
    </div>
  </div>
</div>
