import { Component, OnInit} from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ProductsService } from '../shared/products.service';

@Component({
  selector: 'app-bikesbyqrcode',
  templateUrl: './bikesByQrCode.component.html',
  styleUrls: ['./bikesByQrCode.component.scss']
})


export class BikesByQrCodeComponent implements OnInit {

  public showData = false;
  public showError = false;
  public bike = '';
  public bikeBrand = '';
  public bikeColor = '';
  public bikeSize = '';
  public qrCode = '';
  public rentalPointName = '';
  public status = '';

  public qrCodeFormControl = new FormControl('');

  constructor(private _productsService: ProductsService,
    private _translate: TranslateService, private _snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onFilterClick() {

    const qrCode = this.qrCodeFormControl.value.toString();
    this.showData = false;
    this.showError = false;
    this._productsService.getBikeByQrCode(qrCode).subscribe({
      next: (result: any) => {
        this.showData = true;
        this.bike = result.bike;
        this.bikeBrand = result.bikeBrand;
        this.bikeColor = result.bikeSize;
        this.qrCode = result.qrCode;
        this.rentalPointName = result.rentalPointName;
        this.status = result.status;
      },
      error: () => {
        this.showError = true;
        this._snackBar.open(this._translate.instant('products.bikesByQrCode.errorNotFound'), '', {
          duration: 2000,
        });
      }
    });
  }
}
