<div class="container-fluid">
  <div class="row">
    <div class="col">
      <app-data-table [columns]="columns" [loadPage]="loadPage" [(filters)]="filters" [filtersComponent]="dataFilters"
        [pageSize]="20">
        <app-data-filters #dataFilters [showExcelExportButton]="true">
          <app-data-filter-group>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.contractId' | translate}}"
              [(model)]="filters.contractId" placeholder="{{'orders.bookingReservationOrders.contractId' | translate}}">
            </app-data-filter-input>
            <app-data-filter-input label="{{'orders.bookingReservationOrders.clientName' | translate}}"
              [(model)]="filters.clientName" placeholder="{{'orders.bookingReservationOrders.clientName' | translate}}">
            </app-data-filter-input>
            <app-data-filter-select label="{{'orders.bookingReservationOrders.clients' | translate}}"
              [(model)]="filters.clientId" [options]="clientOptions" *ngIf="currentUserType.userTypeNumber === 0;"
              (modelChange)="onClientSelectChange($event)">
            </app-data-filter-select>
            <app-data-filter-select-search label="{{'orders.bookingReservationOrders.rentalPoints' | translate}}"
              [(model)]="filters.rentalPointAccountId" [options]="rentalPointOptions"
              *ngIf="currentUserType.userTypeNumber !== 2">
            </app-data-filter-select-search>
            <app-data-filter-datepicker label="{{'orders.bookingReservationOrders.from' | translate}}"
              [(model)]="filters.startDate">
            </app-data-filter-datepicker>
            <app-data-filter-datepicker label="{{'orders.bookingReservationOrders.to' | translate}}"
              [(model)]="filters.endDate">
            </app-data-filter-datepicker>
          </app-data-filter-group>
        </app-data-filters>
      </app-data-table>

      <ng-template #contractId let-contractId="contractId">
        {{contractId}}
      </ng-template>

      <ng-template #clientName let-clientInformation="clientInformation">
        {{clientInformation.firstName}} {{clientInformation.lastName}}
      </ng-template>

      <ng-template #startRentalPoint let-startRentalPoint="startRentalPoint">
        {{startRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #returnRentalPoint let-endRentalPoint="endRentalPoint">
        {{endRentalPoint.rentalPointTranslation[currentLang]}}
      </ng-template>

      <ng-template #startDate let-startDate="startDate">
        {{startDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #returnDate let-endDate="endDate">
        {{endDate | date: 'dd-MM-yyyy'}}
      </ng-template>

      <ng-template #orderAmount let-discountedPrice="discountedPrice">
        {{discountedPrice | currency: '€ '}}
      </ng-template>
    </div>
  </div>
</div>
