import { Routes } from '@angular/router';
import { ClientsComponent } from './clients.component';
import { AuthGuard } from '../../auth-guard.service';
import { ClientsListComponent } from './clients-list/clients-list.component';
import { ClientDetailsComponent } from './client-details/client-details.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { AddClientComponent } from './add-client/add-client.component';

export const CLIENTS_ROUTES: Routes = [
    {
        path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], children: [
            {path: 'list', component: ClientsListComponent, canActivate: [AuthGuard]},
            {path: 'add', component: AddClientComponent, canActivate: [AuthGuard]},
            {path: 'edit/:clientId', component: EditClientComponent, canActivate: [AuthGuard]},
            {path: 'details/:clientId', component: ClientDetailsComponent, canActivate: [AuthGuard]}
        ]
    }
];
