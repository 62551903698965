import { RentalPoint } from './RentalPoint';

export class RentalPointTranslations {
    public id: number;
    public en: string;
    public it: string;
    public de: string;
    public rentalPoint: RentalPoint;

    public static fromDto(r): RentalPointTranslations {
        const rentalPointTranslations = new RentalPointTranslations();

        if (!r) {
            return null;
        }

        rentalPointTranslations.id = r.id;
        rentalPointTranslations.en = r.en;
        rentalPointTranslations.it = r.it;
        rentalPointTranslations.de = r.de;
        rentalPointTranslations.rentalPoint = r.rentalPoint;

        return rentalPointTranslations;
    }
}
