<h2 class="mt-5 bg-dark" style="text-align: center; color: #FFFFFF; padding:10px;">{{'tableData.title.'+tableCode | translate}} - Update</h2>
<div class="container mt-5">
  <div class="row">
    <div class="col">
      <div class="card">
        <div class="card-body">
          <form [formGroup]="tableForm" (ngSubmit)="onSubmitHandler()">
            <!--Bike type section-->
            <ng-container *ngIf="tableCode == 'bikeType'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_name_en' | translate}}*</label>
                    <input type="text" name="bikeType_name_en" id="bikeType_name_en" formControlName="bikeType_name_en" required
                      placeholder="{{'tableData.form.bikeType_name_en' | translate}}"
                      class="form-control {{errorArray['bikeType_name_en'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_name_en']}}
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_name_it' | translate}}*</label>
                    <input type="text" name="bikeType_name_it" id="bikeType_name_it" formControlName="bikeType_name_it" required
                      placeholder="{{'tableData.form.bikeType_name_it' | translate}}"
                      class="form-control {{errorArray['bikeType_name_it'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_name_it']}}
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_name_de' | translate}}*</label>
                    <input type="text" name="bikeType_name_de" id="bikeType_name_de" formControlName="bikeType_name_de" required
                      placeholder="{{'tableData.form.bikeType_name_de' | translate}}"
                      class="form-control {{errorArray['bikeType_name_de'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_name_de']}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_bikeCost' | translate}}*</label>
                    <input type="text" name="bikeType_bikeCost" id="bikeType_bikeCost" formControlName="bikeType_bikeCost" required
                      placeholder="{{'tableData.form.bikeType_bikeCost' | translate}}"
                      class="form-control {{errorArray['bikeType_bikeCost'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_bikeCost']}}
                    </div>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_productType' | translate}}*</label>
                    <select name="bikeType_productType" id="bikeType_productType" formControlName="bikeType_productType" required
                      class="form-control  {{errorArray['bikeType_productType'] ? 'is-invalid' : ''}} ">
                      <option *ngFor="let item of productTypeOptions" [value]="item.value">{{item.label}}</option>
                    </select>
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_productType']}}
                    </div>
                  </div>
                </div>
                <!--
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeType_bikeAvailabilityPeriod' | translate}}*</label>
                    <input type="text" name="bikeType_bikeAvailabilityPeriod" id="bikeType_bikeAvailabilityPeriod" formControlName="bikeType_bikeAvailabilityPeriod" required
                      placeholder="{{'tableData.form.bikeType_bikeAvailabilityPeriod' | translate}}"
                      class="form-control {{errorArray['bikeType_bikeAvailabilityPeriod'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeType_bikeAvailabilityPeriod']}}
                    </div>
                  </div>
                </div>
                -->
              </div>
            </ng-container>
            <!--Bike brand section-->
            <ng-container *ngIf="tableCode == 'bikeBrand'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeBrand_name' | translate}}*</label>
                    <input type="text" name="bikeBrand_name" id="bikeBrand_name" formControlName="bikeBrand_name" required
                      placeholder="{{'tableData.form.bikeBrand_name' | translate}}"
                      class="form-control {{errorArray['bikeBrand_name'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeBrand_name']}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--Bike model section-->
            <ng-container *ngIf="tableCode == 'bikeModel'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeModel_name' | translate}}*</label>
                    <input type="text" name="bikeModel_name" id="bikeModel_name" formControlName="bikeModel_name" required
                      placeholder="{{'tableData.form.bikeModel_name' | translate}}"
                      class="form-control {{errorArray['bikeModel_name'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeModel_name']}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--Bike size section-->
            <ng-container *ngIf="tableCode == 'bikeSize'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeSize_size' | translate}}*</label>
                    <input type="text" name="bikeSize_size" id="bikeSize_size" formControlName="bikeSize_size" required
                      placeholder="{{'tableData.form.bikeSize_size' | translate}}"
                      class="form-control {{errorArray['bikeSize_size'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeSize_size']}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--Tyre size section-->
            <ng-container *ngIf="tableCode == 'tyreSize'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.tyreSize_size' | translate}}*</label>
                    <input type="text" name="tyreSize_size" id="tyreSize_size" formControlName="tyreSize_size" required
                      placeholder="{{'tableData.form.tyreSize_size' | translate}}"
                      class="form-control {{errorArray['tyreSize_size'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['tyreSize_size']}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <!--Bike color section-->
            <ng-container *ngIf="tableCode == 'bikeColor'">
              <div class="row">
                <div class="col-4">
                  <div class="form-group">
                    <label>{{'tableData.form.bikeColor_name' | translate}}*</label>
                    <input type="text" name="bikeColor_name" id="bikeColor_name" formControlName="bikeColor_name" required
                      placeholder="{{'tableData.form.bikeColor_name' | translate}}"
                      class="form-control {{errorArray['bikeColor_name'] ? 'is-invalid' : ''}}" />
                    <div class="invalid-feedback">
                      {{errorArray['bikeColor_name']}}
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
            <div class="row">
              <div class="col text-right">
                <button type="submit" class="btn btn-primary">{{'tableData.form.buttonSave' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
