<app-header-auth></app-header-auth>
<div class="headText">
  <a>
    <img src="../../../../assets/images/back.png" (click)="back()">
  </a>
  <p><span>{{'staff.staffList.manageAccounts' | translate}}</span> | <span>{{'staff.staffList.staff' | translate}}</span></p>
</div>

<hr>

<div class="row all-elements">
  <div class="col-lg-4 col-md-6 col-xs-12 single-element"
       *ngFor="let person of staff">
    <button class="btn btn-action btn-default" (click)="openStaff(person.id)">
      {{person.firstName}} {{person.lastName}} - {{userType[person.userType]}}
    </button>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 addButton">
    <a routerLink="/staff-add">+</a>
  </div>
</div>
