import { DefectType } from './DefectType';

export class Defect {
    public id: number;
    public name: string;
    public itemOrder: number;
    public deleted: boolean;
    public defectTypes: DefectType[];

    public static formDto(d): Defect {
        const defect = new Defect();
        if (!d) {
            return null;
        }

        defect.id = d.id;
        defect.name = d.name;
        defect.itemOrder = d.itemOrder;
        defect.deleted = d.deleted;
        if (d.defectTypes) {
            defect.defectTypes = d.defectTypes.map(defectType => DefectType.formDto(defectType));
        }

        return defect;
    }
}
