import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { map } from 'rxjs/operators';
import { WartungUsersSearchCriteria } from './WartungUsersSearchCriteria';
import { Client } from '../../../models/Client';
import { Account } from '../../../models/Account';

@Injectable({
  providedIn: 'root'
})
export class WartungUsersService {

  constructor(private _http: HttpClient) {
  }

  public getWartungClients(): Observable<Client[]> {
      const headers = HttpUtils.createHeaders();
      return this._http.get(environment.serverUrl + 'api/getWartungClients', {headers}).pipe(
        map((res: any) => {
          return res.map(Client.fromDto);
        })
      );
  }

  public search(page: number, size: number, criteria: WartungUsersSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = {...criteria};

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchAccounts', {headers, params}).pipe(
      map((res: any) => {
        res.results = res.results.map((a: Account) => ({
          ...a,
          status: !a.deleted
        }));
        return SearchObjectResult.fromDto(res, Account.fromDto);
      })
    );
  }

  public get(id: number): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(environment.serverUrl + 'api/getWartungUser/' + id, {headers}).pipe(
      map((res: any) => {
        res.status = !res.deleted;
        return res;
      })
    );
  }

  public add(account: Account): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(environment.serverUrl + 'api/addWartungUser', account, {headers});
  }

  public update(account: Account): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(environment.serverUrl + 'api/updateWartungUser', account, {headers});
  }

}
