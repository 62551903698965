import { PriceList } from './PriceList';
import { PricePerPeriod } from './PricePerPeriod';

export class Period {
  public id: number;
  public name: string;
  public startDate: string;
  public endDate: string;
  public priceList: PriceList;
  public pricePerPeriod: PricePerPeriod[];

  public static fromDto(p): Period {
    const period = new Period();
    if (!p) {
      return null;
    }
    period.id = p.id;
    period.name = p.name;
    period.startDate = p.startDate;
    period.endDate = p.endDate;
    period.priceList = PriceList.fromDto(p.priceList);
    period.pricePerPeriod = (p.pricePerPeriod ? p.pricePerPeriod.map(price => PricePerPeriod.fromDto(price)) : null);

    return period;
  }
}
