import { RentalPoint } from './RentalPoint';

export class BikePreparation {
    public rentalPoint: RentalPoint[] = [];

    public static fromDto(b): BikePreparation {
        const bikePreparation = new BikePreparation();
        if (!b) {
            return null;
        }
        b.forEach(rentalPoint => {
            bikePreparation.rentalPoint.push(RentalPoint.fromDto(rentalPoint));
        });

        return bikePreparation;
    }
}
