import { Injectable } from '@angular/core';
import { OnlineBookingSearchCriteria } from './OnlineBookingSearchCriteria';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { HttpUtils } from '../../../shared/http-utils';
import { environment } from '../../../../environments/environment';
import { OnlineBooking } from '../../../models/OnlineBooking';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { RentalPointCashFlow } from '../../../models/RentalPointCashFlow';
import { RentalPointCashFlowSearchCriteria } from '../rental-point-cash-flow/rental-point-cash-flow.component';

@Injectable({
  providedIn: 'root'
})

export class ChashFlowService {

  constructor(private _http: HttpClient) { }

  /**
   * get all the companies from the database
   * ERROR 600 -> No companies in the database
   */
  public searchOnlineBookings(page: number, size: number, criteria: OnlineBookingSearchCriteria): Observable<SearchObjectResult<Event>> {

    const criteriaCopy = { ...criteria };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const headers = HttpUtils.createHeaders();
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/searchOnlineBooking', { headers, params }).pipe(
      map((res: any) => {
        const searchObjectResult = SearchObjectResult.fromDto(res, OnlineBooking.fromDto);
        searchObjectResult.results.forEach((order: OnlineBooking) => {
          if (order.reservation === 0 ) { order.discountedPrice = order.discountedBookingPrice; }
        });
        return searchObjectResult;
      })
    );
  }

  /**
   * getCashFlow
   */
  public getCashFlow(filters: RentalPointCashFlowSearchCriteria, page?: number, size?: number): Observable<SearchObjectResult<Event>> {
    const tempFilters = { ... filters };
    if (tempFilters.rentalPointAccountId) {
      tempFilters.clientId = undefined;
    }

    const headers = HttpUtils.createHeaders();
    const criteriaCopy = { ...tempFilters };

    for (const key of Object.keys(criteriaCopy)) {
      if (!criteriaCopy[key]) {
        delete criteriaCopy[key];
      }
    }
    const params = {
      page: String(page),
      size: String(size),
      ...criteriaCopy
    };
    return this._http.get(environment.serverUrl + 'api/getCashFlow', { headers, params }).pipe(
      map((result: any[]) => {
        const res = {
          results: result,
          count: result.length,
          totalCount: result.length,
          page: 1,
          totalPages: 1,
          pageSize: size,
          extra: {
            totalAmount: result.reduce((acc, cashFlow) => acc + cashFlow.amount, 0)
          }
        };
        return SearchObjectResult.fromDto(res, RentalPointCashFlow.fromDto);
      })
    );
  }


}
