import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataFiltersService {

  private resetEmitter = new Subject<void>();
  public resetObservable = this.resetEmitter.asObservable();

  private applyEmitter = new Subject<void>();
  public applyObservable = this.applyEmitter.asObservable();

  private excelExportEmitter = new Subject<void>();
  public excelExportObservable = this.excelExportEmitter.asObservable();

  private excelExportBikeListEmitter = new Subject<void>();
  public excelExportBikeListObservable = this.excelExportBikeListEmitter.asObservable();

  private CSVExportBikeListForInsuranceEmitter = new Subject<void>();
  public CSVExportBikeListForInsuranceObservable = this.CSVExportBikeListForInsuranceEmitter.asObservable();

  constructor() {
  }

  triggerReset() {
    this.resetEmitter.next();
  }

  triggerApply() {
    this.applyEmitter.next();
  }

  triggerExcelExport() {
    this.excelExportEmitter.next();
  }

  triggerCSVForInsuranceExport() {
    this.CSVExportBikeListForInsuranceEmitter.next();
  }

}
