import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientsService } from '../shared/clients.service';
import { FormGroup, FormControl } from '@angular/forms';
import { ValidationUtils } from '../../../shared/validation.utils';
import { Client } from '../../../models/Client';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  public updateClientForm: FormGroup;
  public errorArray = [];


  constructor(private _translate: TranslateService, private _route: ActivatedRoute, private _clientsService: ClientsService,
    public _snackBar: MatSnackBar) {
    this.updateClientForm = new FormGroup({
      id: new FormControl(),
      address: new FormControl(),
      businessName: new FormControl(),
      phone: new FormControl(),
      vatNumber: new FormControl(),
      accountId: new FormControl(),
      deleted: new FormControl(),
      account: new FormGroup({
        id: new FormControl(),
        accountType: new FormControl(),
        clientId: new FormControl(),
        deleted: new FormControl(),
        parentId: new FormControl(),
        userType: new FormControl(),
        rentalPoint: new FormControl(),
        rentalPointId: new FormControl(),
        username: new FormControl(),
        email: new FormControl(),
        newPassword: new FormControl(),
        lastName: new FormControl(),
        firstName: new FormControl(),
      })
    });
  }

  ngOnInit() {
    this._route.params.subscribe(params => {
      this._clientsService.getClient(params['clientId']).subscribe(result => {
        const client = result;
        this.updateClientForm.patchValue(client);
      });
    });
  }

  updateCientSubmit() {
    if (this.updateClientForm.valid) {
      const newClient = Client.fromDto(this.updateClientForm.value);
      if (this.updateClientForm.value.account.newPassword !== null) {
        newClient.account.password = this.updateClientForm.value.account.newPassword;
      }
      this._clientsService.updateClient(newClient).subscribe(result => {

        this._snackBar.open(this._translate.instant('clients.editClient.clientUpdated'), '', {
          duration: 3500,
        });
      });
    }
    this.errorArray = ValidationUtils.getFormValidationErrors(this.updateClientForm, this.updateClientForm.controls);
  }

}
