<app-rental-point-subnav [rentalPointId]="rentalPointId"></app-rental-point-subnav>
<div class="container-fluid mt-3">
    <div class="row">
        <div class="col">
            <div class="card">
                <div class="card-body">
                    <div class="row align-items-center">
                        <div class="form-group col">
                            <label>{{'rentalPoint.bikeContingentExceptions.bikeType' | translate}}</label>
                            <select class="form-control form-control-sm" name="bikeTypeId" id="bikeTypeId" [(ngModel)]="newContingentException.bikeTypeId" required>
                <option *ngFor="let bikeType of bikeTypeOptions" [value]="bikeType.value">{{bikeType.label}}</option>
              </select>
                        </div>
                        <div class="form-group col">
                            <label>{{'rentalPoint.bikeContingentExceptions.startDate' | translate}}</label>
                            <app-datepicker [(model)]="newContingentException.startDate" ngDefaultControl></app-datepicker>
                        </div>
                        <div class="form-group col">
                            <label>{{'rentalPoint.bikeContingentExceptions.endDate' | translate}}</label>
                            <app-datepicker [(model)]="newContingentException.endDate" ngDefaultControl></app-datepicker>
                        </div>
                        <div class="form-group col">
                            <label>{{'rentalPoint.bikeContingentExceptions.quantity' | translate}}</label>
                            <input type="number" class="form-control form-control-sm" min="0" name="numberOfProducts" id="numberOfProducts" [(ngModel)]="newContingentException.bikeQuantity" required />
                        </div>
                        <div class="form-group col">
                            <label>{{'rentalPoint.bikeContingentExceptions.buffer' | translate}}</label>
                            <input type="number" class="form-control form-control-sm" min="0" name="buffer" id="buffer" [(ngModel)]="newContingentException.bufferQuantity" required />
                        </div>
                        <div class="col text-right">
                            <button type="button" class="btn btn-primary btn-sm" (click)="onNewContingentExceptionSubmit()">{{'rentalPoint.bikeContingentExceptions.add' | translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th>{{'rentalPoint.bikeContingentExceptions.startDate' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.endDate' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.bikeType' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.quantity' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.buffer' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.defaultQuantity' | translate}}</th>
                        <th>{{'rentalPoint.bikeContingentExceptions.defaultBuffer' | translate}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let product of productsPerRentalPoint;">
                        <tr *ngFor="let exception of product.contingentException">
                            <td>{{exception.startDate | date:'dd-MM-yyyy'}}</td>
                            <td>{{exception.endDate | date:'dd-MM-yyyy'}}</td>
                            <td>{{product.bikeType.bikeTypeTranslation[currentLang]}}</td>
                            <td>{{exception.numberOfProducts}}</td>
                            <td>{{exception.buffer}}</td>
                            <td>{{product.numberOfProducts}}</td>
                            <td>{{product.buffer}}</td>
                            <td class="text-right">
                                <button type="button" class="btn btn-danger btn-sm" (click)="deleteException(exception.id)"><i
                    class="fa fa-trash"></i></button>
                            </td>
                        </tr>
                    </ng-container>
                </tbody>
            </table>
        </div>
    </div>
</div>
