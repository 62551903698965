import { Injectable } from '@angular/core';
import {
  CanActivate, Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { UserService } from './services/user.service';
import { SharedService } from './shared/shared.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router,
    private _sharedService: SharedService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    const url: string = state.url;

    return this.checkLogin(url);
  }

  checkLogin = async (url: string) => {
    if (localStorage.getItem('X-Access-Token')) {
      try {
        const message = await this.userService.checkAuthStatus();
        if (message.message && !localStorage.getItem('papinLogged')) {
          localStorage.setItem('papinLogged', 'true');
        }
        if (message.message && !localStorage.getItem('papinLogged')) {
          localStorage.setItem('papinLogged', 'true');
        }
        return message.message;
      } catch (error) {
        // Navigate to the login page with extras
        localStorage.clear();
        this._sharedService.change(false);
        this.router.navigate(['/login']);
      }

    }

    localStorage.clear();
    this._sharedService.change(false);
    // Store the attempted URL for redirecting
    this.userService.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']);
    return false;
  }
}
