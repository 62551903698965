import { RentalPoint } from './RentalPoint';
import { Client } from './Client';

export class Account {
    public id: number;
    public email: string;
    public username: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public avatar: string;
    public accountType: number;
    public userType: string;
    public deleted: boolean;
    // is contrary of deleted
    public status: boolean;
    public parentId: number;
    public clientId: number;
    public rentalPointId: number;
    public rentalPoint = new RentalPoint();
    public client = new Client();

    public static fromDto(a): Account {
        const account = new Account();
        if (!a) {
            return null;
        }

        account.id = a.id;
        account.email = a.email;
        account.username = a.username;
        account.password = a.password;
        account.firstName = a.firstName;
        account.lastName = a.lastName;
        account.avatar = a.avatar;
        account.accountType = a.accountType;
        account.userType = a.userType;
        account.deleted = a.deleted;
        account.status = a.status;
        account.parentId = a.parentId;
        account.clientId = a.clientId;
        account.rentalPointId = a.rentalPointId;
        account.rentalPoint = RentalPoint.fromDto(a.rentalPoint);
        account.client = Client.fromDto(a.client);

        return account;
    }
}
