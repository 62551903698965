import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { WartungUsersComponent } from './wartung-users.component';
import { WARTUNG_USERS_ROUTES } from './wartung-users.routes';
import { WartungUsersListComponent } from './wartung-users-list/wartung-users-list.component';
import { WartungUserAddUpdateComponent } from './wartung-user-add-update/wartung-user-add-update.component';
import { WartungUserStatusPipe } from './shared/wartung-user-status.pipe';

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(WARTUNG_USERS_ROUTES),
    FormsModule,
    CommonModule
  ],
  exports: [],
  declarations: [
    WartungUsersComponent,
    WartungUsersListComponent,
    WartungUserAddUpdateComponent,
    WartungUserStatusPipe
  ]
})
export class WartungUsersModule { }
