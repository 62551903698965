import { Routes } from '@angular/router';
import { OrdersComponent } from './orders.component';
import { AuthGuard } from '../../auth-guard.service';
import { CreditsComponent } from './credits/credits.component';
import { ReservationComponent } from './reservation/reservation.component';
import { ReturnPaperComponent } from './papers/return-paper/return-paper.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { OrderFormComponent } from './order-form/order-form.component';
import { AllOrdersComponent } from './all-orders/all-orders.component';
import { BookingReservationOrdersComponent } from './booking-reservation-orders/booking-reservation-orders.component';
import { OrdersDetailsComponent } from './orders-details/orders-details.component';
import { ContractPaperComponent } from './papers/contract-paper/contract-paper.component';
import { PartialReturnPaperComponent } from './papers/partial-return-paper/partial-return-paper.component';
import { PartialCancellationPaperComponent } from './papers/partial-cancellation-paper/partial-cancellation-paper.component';
import { TotalCancellationPaperComponent } from './papers/total-cancellation-paper/total-cancellation-paper.component';
import { UnsuccessfulPaymentsComponent } from './unsuccessful-payments/unsuccessful-payments.component';
import { CreditPaperComponent } from './papers/credit-paper/credit-paper.component';
import { TurnoverComponent } from './turnover/turnover.component';
import { UpdateOrderComponent } from './update-order/update-order.component';
import { OrdersByQrCodeComponent } from './orders-by-qr-code/orders-by-qr-code.component';
import { ConfirmWidgetOrderComponent } from './confirm-widget-order/confirm-widget-order.component';
import { ReturnOrdersComponent } from './return-orders/return-orders.component';
export const ORDERS_ROUTES: Routes = [
    {
        path: 'orders', component: OrdersComponent, children: [
            { path: 'all', component: AllOrdersComponent, canActivate: [AuthGuard] },
            { path: 'return-orders', component: ReturnOrdersComponent, canActivate: [AuthGuard] },
            { path: 'credits', component: CreditsComponent, canActivate: [AuthGuard] },
            { path: 'booking-reservations', component: BookingReservationOrdersComponent, canActivate: [AuthGuard] },
            { path: ':orderType/details/:contractId', component: OrdersDetailsComponent, canActivate: [AuthGuard] },
            { path: ':orderType/update/:contractId', component: UpdateOrderComponent, canActivate: [AuthGuard] },
            { path: 'contract-paper/:contractId', component: ContractPaperComponent, canActivate: [AuthGuard] },
            { path: 'return-paper/:contractId', component: ReturnPaperComponent, canActivate: [AuthGuard] },
            { path: 'credit-paper/:creditCode', component: CreditPaperComponent, canActivate: [AuthGuard] },
            { path: 'reservation/:contractId', component: ReservationComponent },
            { path: 'invoice/:contractId', component: InvoiceComponent },
            { path: 'unsuccessful-payments', component: UnsuccessfulPaymentsComponent, canActivate: [AuthGuard] },
            { path: 'turnover', component: TurnoverComponent, canActivate: [AuthGuard] },
            {
                path: 'partial-return-paper/:date/:accountId/:contractId',
                component: PartialReturnPaperComponent, canActivate: [AuthGuard]
            },
            {
                path: 'partial-cancellation-paper/:date/:accountId/:contractId',
                component: PartialCancellationPaperComponent, canActivate: [AuthGuard]
            },
            {
                path: 'total-cancellation-paper/:contractId', component: TotalCancellationPaperComponent, canActivate: [AuthGuard]
            },
            { path: 'search-by-qr-code', component: OrdersByQrCodeComponent, canActivate: [AuthGuard] },
            { path: '**', redirectTo: 'orders/all' }
        ]
    },
    { path: 'order-confirm', component: ConfirmWidgetOrderComponent }
];
