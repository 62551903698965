import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceListComponent } from './price-list.component';
import { SharedModule } from '../../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { PRICE_LIST_ROUTES } from './price-list.routes';
import { UpdatePriceListComponent } from './update-price-list/update-price-list.component';

@NgModule({
  declarations: [
    PriceListComponent,
    UpdatePriceListComponent
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(PRICE_LIST_ROUTES),
    FormsModule,
    CommonModule
  ]
})
export class PriceListModule { }
