import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DataTableColumn } from '../../../shared/data-table/data-table.component';
import { Option } from '../../../shared/data-filters/data-filter-select/data-filter-select.component';
import { Observable } from 'rxjs';
import { SearchObjectResult } from '../../../models/SearchObject';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrdersService } from '../../orders/shared/orders.service';
import { InvoiceSearchCriteria } from '../../orders/shared/InvoiceSearchCriteria';
import { CurrencyPipe } from '@angular/common';
import { RentalPointsService } from '../../rental-point/shared/rental-points.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit {
  public filters: InvoiceSearchCriteria = {};
  public columns: DataTableColumn[] = [];
  public currentLang: string;
  public countriesOptions: Option[];

  loadPage: (page: number, pageSize: number) => Observable<SearchObjectResult<Event>> = null;

  @ViewChild('invoiceNumber', { static: true })
  invoiceNumber: TemplateRef<any>;

  @ViewChild('contractId', { static: true })
  contractId: TemplateRef<any>;

  @ViewChild('name', { static: true })
  name: TemplateRef<any>;

  @ViewChild('rentalPoint', { static: true })
  rentalPoint: TemplateRef<any>;

  @ViewChild('invoiceDate', { static: true })
  invoiceDate: TemplateRef<any>;

  @ViewChild('amount', { static: true })
  amount: TemplateRef<any>;

  @ViewChild('type', { static: true })
  type: TemplateRef<any>;

  @ViewChild('actions', { static: true })
  actions: TemplateRef<any>;

  public refundMsg1 = '';

  constructor(private _modalService: NgbModal, private _translate: TranslateService, public _snackBar: MatSnackBar,
    private _ordersService: OrdersService, private _rentalPointService: RentalPointsService) {
    this.countriesOptions = [{ value: undefined, label: this._translate.instant('statements.invoicesList.all') }];
  }

  ngOnInit() {
    this.currentLang = this._translate.currentLang;
    this._rentalPointService.getCountries(this.currentLang).subscribe(result => {
      result.forEach(country => this.countriesOptions.push({ value: country.id, label: country.name }));
      this.loadPage = (page: number, pageSize: number) => this._ordersService.searchInvoices(page, pageSize, this.filters);
    });

    this.columns = [
      {
        name: 'contractId',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.contractId',
        template: this.contractId,
        sortable: true,
        excel: {
          objectRef: ['contractId']
        }
      },
      {
        name: 'name',
        displayed: true,
        translationKey: 'statements.cashflow.clientName',
        template: this.name,
        sortable: true,
        excel: {
          objectRef: ['name']
        }
      },
      {
        name: 'rentalPoint',
        displayed: true,
        translationKey: 'orders.ordersTurnover.rentalPoint',
        template: this.rentalPoint,
        sortable: true,
        excel: {
          objectRef: [`rentalPoint.rentalPointTranslation.${this.currentLang}`]
        }
      },
      {
        name: 'invoiceDate',
        displayed: true,
        translationKey: 'orders.invoice.date',
        template: this.invoiceDate,
        sortable: true,
        excel: {
          objectRef: ['invoiceDate'],
          pipe: 'date'
        }
      },
      {
        name: 'amount',
        displayed: true,
        translationKey: 'statements.cashflow.amount',
        template: this.amount,
        sortable: true,
        excel: {
          objectRef: ['amount'],
          pipe: 'currency'
        }
      },
      {
        name: 'invoiceNumber',
        displayed: true,
        translationKey: 'statements.invoicesList.invoiceNumber',
        template: this.invoiceNumber,
        sortable: true,
        excel: {
          objectRef: ['invoicesNumbers']
        }
      },
      {
        name: 'type',
        displayed: true,
        translationKey: 'statements.invoicesList.invoiceType',
        template: this.type,
        sortable: true,
        excel: {
          objectRef: ['type'],
          pipe: 'invoiceTypeStatus'
        }
      },
      {
        name: 'actions',
        displayed: true,
        translationKey: 'orders.bookingReservationOrders.actions',
        template: this.actions,
        sortable: true
      },
    ];
  }

  onTableReset() {
    this.filters = new InvoiceSearchCriteria();
  }

  async openRefundModal(content: any, contractId: any, name: any, amount: any)  {
    this.refundMsg1 = this._translate.instant('statements.cashflow.refundMsg1');
    const amountStr = new CurrencyPipe('en').transform(amount, 'EUR', 'symbol', '1.2-2');
    this.refundMsg1 = this.refundMsg1.replace('{amount}', amountStr);
    this.refundMsg1 = this.refundMsg1.replace('{clientName}', name);

    this._modalService.open(content, { ariaLabelledBy: 'modal-refund', centered: true }).result.then((modalAction) => {
          this._ordersService.refundOrder(contractId).subscribe({
        next: (res) => {
          this.loadPage = (page: number, pageSize: number) => this._ordersService.searchInvoices(page, pageSize, this.filters);
          setTimeout(() => {
            this._snackBar.open(this._translate.instant('statements.cashflow.refundOK'), '', { duration: 3500 });
          }, 1000);
        },
        error: (err) => {
          setTimeout(() => {
            this._snackBar.open(this._translate.instant('statements.cashflow.refundError'), '', { duration: 3500 });
          }, 1000);
      }});
    }, (reason) => {});
  }
}
