import { DefectType } from './DefectType';
import { Ticket } from './Ticket';
import { Account } from './Account';

export class DefectedProduct {
    public id: number;
    public fixedDate: string;
    public fixedTime: string;
    public comment: string;
    public status: number; // 0 = Not fixed, 1 = Fixed
    public deleted: boolean;
    public defectType: DefectType;
    public ticket: Ticket;
    public account: Account;

    public static fromDto(d): DefectedProduct {
        const defectedProduct = new DefectedProduct();
        if (!d) {
            return null;
        }

        defectedProduct.id = d.id;
        defectedProduct.fixedDate = d.fixedDate;
        defectedProduct.fixedTime = d.fixedTime;
        defectedProduct.comment = d.comment;
        defectedProduct.status = d.status;
        defectedProduct.deleted = d.deleted;
        defectedProduct.defectType = DefectType.formDto(d.defectType);
        defectedProduct.ticket = Ticket.fromDto(d.ticket);
        defectedProduct.account = Account.fromDto(d.account);

        return defectedProduct;
    }
}
