import { Account } from './Account';
import { BikeType } from './BikeType';
import { DefectedProduct } from './DefectedProduct';

export class Ticket {
    public id: number;
    public name: string;
    public code: string;
    public qrCode: string;
    public productId: string;
    public createdDate: string;
    public createdTime: string;
    public closedDate: string;
    public closedTime: string;
    public comment: string;
    public status: number; // 0 = Open, 1 = Partially, 2 = Closed
    public deleted: boolean;
    public account: Account = new Account();
    public defectedProducts: DefectedProduct[];
    public bikeType: BikeType = new BikeType();
    public images: string[];
    public static fromDto(t): Ticket {
        const ticket = new Ticket();
        if (!t) {
            return null;
        }

        ticket.id = t.id;
        ticket.name = t.name;
        ticket.code = t.code;
        ticket.qrCode = t.qrCode;
        ticket.productId = t.productId;
        ticket.createdDate = t.createdDate;
        ticket.createdTime = t.createdTime;
        ticket.closedDate = t.closedDate;
        ticket.closedTime = t.closedTime;
        ticket.comment = t.comment;
        ticket.status = t.status;
        ticket.deleted = t.deleted;
        ticket.account = Account.fromDto(t.account);
        ticket.defectedProducts = t.defectedProducts.map(defectedProduct => DefectedProduct.fromDto(defectedProduct));
        ticket.bikeType = BikeType.fromDto(t.bikeType);
        ticket.images = t.images;

        return ticket;
    }
}
